import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import BrandDiv from './BrandDiv';
import { createChainedFunction, TextField } from '@material-ui/core';

import { Modal } from 'react-bootstrap';

import request from '../../../services/Request';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 200,
    maxWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function BrandPositionModal(props) {
  const classes = useStyles();

  const { rowData, showAdmin, data, brand, setBrand, handleSubmit, machine, setB_row, ...rest } =
    props;

  const [num, setNum] = useState();
  const [origin, setOrigin] = useState([...brand]);
  const [max, setMax] = useState(1);

  useEffect(() => {
    if (brand?.length > 0) {
      const index = num * 3;
      const newB = brand.slice(0, index);
      setOrigin(newB);
    }
  }, [num, rest.show]);

  useEffect(() => {
    const mymax = Math.ceil(brand.length / 3);

    setMax(mymax);
    if (machine?.themeColor?.b_row) {
      if (machine?.themeColor?.b_row > mymax) {
        setNum(mymax);
      } else {
        setNum(machine?.themeColor?.b_row);
      }
    } else {
      setNum(mymax);
    }
  }, [rest.show]);

  useEffect(() => {
    setOrigin([...brand]);
  }, [brand]);

  useEffect(() => {
    setB_row(num);
  }, [num]);

  const result = origin.map((prod) => {
    // const url = `${process.env.REACT_APP_SERVER_API}/pictures/${prod.picture}/${prod.referTo}`;
    const logo = `${process.env.REACT_APP_SERVER_API}/pictures/${prod.logo}/${prod.referTo}`;
    return (
      <div className="brand-logo-card d-flex justify-content-center align-items-center">
        <img className="brand-logo-img" src={logo} alt=""></img>
      </div>
    );
  });

  return (
    <Modal className="modal-w" {...rest} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Arrange Your Brand Position</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body" style={{ textAlign: 'center' }}>
        <Container>
          <h5 style={{ textAlign: 'center' }}>
            Drag and drop the list on the left to arrang your logos
          </h5>
          <TextField
            style={{ marginTop: '20px', width: '200px' }}
            value={num}
            variant="outlined"
            onChange={(e) => {
              setNum(e.target.value);
            }}
            type="number"
            label="Show Rows"
            InputProps={{ inputProps: { min: 1, max: max } }}
          />
          <div className="d-flex">
            <DndProvider backend={HTML5Backend}>
              <BrandDiv data={origin} setData={setOrigin} />
            </DndProvider>
            <div className="home-brand-div">{result}</div>
          </div>

          <Button
            fullWidth
            onClick={() => handleSubmit()}
            // disabled={ssid == ''}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

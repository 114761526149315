import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import request from '../../services/Request';
import Title from './Title';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Deposits(props) {
  const send = () => {
    request({
      method: 'get',
      url: 'https://dev.magexusa.com/api/v1/users/get/60d380500b38ff1598d08ada',
    }).then((e) => {
      console.log('sent');
    });
  };

  const classes = useStyles();
  return (
    <div
      onClick={() => {
        send();
      }}
    >
      <React.Fragment>
        <Title>Welcome</Title>
        <Typography component="p" variant="h5" style={{ marginTop: '70px' }}>
          {/* {props.name} */}
          {props.role}
        </Typography>
        {props.role === 'User' && (
          <Typography color="textSecondary" className={classes.depositContext}>
            {props.fullAccess ? `You have full access` : `You have limited access`}
          </Typography>
        )}
        <div></div>
      </React.Fragment>
    </div>
  );
}

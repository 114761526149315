import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
export default function BannerChart(props) {
  const { data, machinesName, type } = props;
  const [cate, setCate] = useState([]);
  const [detail, setDetail] = useState([]);
  const [conv, setConv] = useState([]);

  // const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
  //   <Typography variant={variant} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //     {`Product Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
  //   </Typography>
  // );

  useEffect(() => {
    if (data?.length > 0) {
      const result = [];
      const detail = [];
      const conv = [];
      data.forEach((e) => {
        const filename = e.file.split('-').pop();
        const name = e.ssTitle + '(' + filename + ')';

        result.push(name);
        detail.push(e.count);
        conv.push(e.conv);
      });
      setCate(result);
      setDetail(detail);
      setConv(conv);
    }
  }, [data]);

  // const FinalData = [
  //   {
  //     name: 'Basic Info',
  //     data: [
  //       data.session_start,
  //       data.session_com,
  //       data.all_prod,
  //       data.all_brand,
  //       data.cart_aband,
  //       data.cart_page,
  //       data.faq,
  //       data.language?.en,
  //       data.language?.fr,
  //       data.language?.es,
  //       data.aveSession,
  //     ],
  //   },
  // ];

  const options = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: cate,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],

    title: {
      text: 'Banner Image Count(' + machinesName + ')',
      offsetY: 4,
    },
  };

  const options2 = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: cate,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],

    title: {
      text: 'Banner Conversion(' + machinesName + ')',
      offsetY: 4,
    },
  };
  return (
    <Grid container>
      <Grid item xs={type === 1 ? 12 : 6}>
        <ReactApexChart options={options} series={detail} type="pie" height={250} />;
      </Grid>
      <Grid item xs={type === 1 ? 12 : 6}>
        <ReactApexChart options={options2} series={conv} type="pie" height={250} />;
      </Grid>
    </Grid>
  );
  // return null;
}

// material
import MaterialTable from 'material-table';

import { Typography } from '@material-ui/core';

import { fCurrency } from './utils/formatNumber';

export default function GroupsSalesChart(props) {
  const { data, endDate, startDate, machinesName } = props;
  const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
    <Typography
      variant={variant}
      style={{ overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '20px' }}
    >
      {`Sales By Group Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
    </Typography>
  );

  const columns = [
    {
      title: 'Group Name',
      field: 'name',
    },
    {
      title: 'Total Sale',
      field: 'totalSale',
      render: (rowData) => `${fCurrency(rowData.totalSale)}`,
    },
    {
      title: 'Total Tax',
      field: 'totalTax',
      render: (rowData) => `${fCurrency(rowData.totalTax)}`,
    },
    {
      title: 'Total Transactions',
      field: 'totalTrans',
      render: (rowData) => `${rowData.totalTrans ? rowData.totalTrans : 0}`,
    },
  ];

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={data}
        title={<MyNewTitle variant="h6" />}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100, 200],
        }}
      />
    </div>
  );
}

import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Swal from 'sweetalert2';
import { FormLabel, Select, MenuItem } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import request from '../../../services/Request';
import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Controller, useController } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { FormControl, InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const updateAdress = '/machines/updateAdress';

export default function MagexUserModal(props) {
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({});
  const classes = useStyles();
  const { data, user, setReflesh, reflesh, setshow, field, ...rest } = props;

  const defaultValues = {
    location_info: data?.location_info ? data?.location_info : '',
    paid_period: data?.paid_period ? data?.paid_period : 0,
    p_date: data?.p_date ? data?.p_date : null,
    extra_info: data?.extra_info ? data?.extra_info : '',
    extra_info2: data?.extra_info2 ? data?.extra_info2 : '',
    inputFields: data?.service?.length > 0 ? data.service : [{ id: uuidv4(), service: {} }],
    email: data?.email ? data?.email : '',
  };

  const [inputFields, setInputFields] = useState([{ id: uuidv4(), service: {} }]);

  const serviceChange = (id, fieldId) => {
    const findIndex = inputFields.findIndex((e) => e.id === fieldId);
    inputFields[findIndex].service = id;
    setInputFields([...inputFields]);
  };

  useEffect(async () => {
    if (props.show) {
      reset(defaultValues);
      setInputFields(data?.service?.length > 0 ? data.service : [{ id: uuidv4(), service: {} }]);
      console.log(' data.service', data.service);
    }
  }, [props.show]);

  const handleAddFields = () => {
    setInputFields([...inputFields, { id: uuidv4(), service: {} }]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const onSubmit = async (formData) => {
    console.log('date', formData.p_date);
    // return console.log('formData', formData);

    request({
      method: 'post',
      url: `/magexuser/editMagexUser/` + data?._id,
      data: {
        name: data.name,
        pw: data.pw,
        operator: data.operator,
        tolerance: data.tolerance,
        license_fee: data.license_fee,
        location_info: formData.location_info,
        extra_info: formData.extra_info,
        extra_info2: formData.extra_info2,
        service: JSON.stringify(inputFields),
        paid_period: formData.paid_period,
        currency: data.currency,
        tax: data.tax,
        email: formData.email,
        p_date: formData.p_date,
      },
    }).then(() => {
      Swal.fire({
        title: 'Success',
        text: 'User has been successfully updated',
        timer: 2000,
      });
      setshow(false);
      setReflesh(!reflesh);
    });
  };

  const handleChange = (event, id) => {
    const findIndex = field.findIndex((e) => e._id === event.target.value);
    // console.log('select', event.target.value);
    console.log('findIndex', findIndex);
    if (findIndex > -1) {
      serviceChange(field[findIndex], id);
    }
  };

  const hanldechangeatt = (e, id) => {
    const findIndex = inputFields.findIndex((e) => e.id === id);
    inputFields[findIndex].service.quantity = e.target.value;
    setInputFields([...inputFields]);
  };

  const hanldechangeunitprice = (e, id) => {
    const findIndex = inputFields.findIndex((e) => e.id === id);
    inputFields[findIndex].service.unit_price = e.target.value;
    setInputFields([...inputFields]);
  };

  return (
    <Modal className="modal-w" {...rest} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.2rem' }}>
          Update Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlignLast: 'center' }}>
                  <FormLabel component="legend">More Info</FormLabel>
                </Grid>
                {/* <Select onChange={handleChange}>
                  {field.map((field) => (
                    <MenuItem key={field._id} value={field._id}>
                      {field.service}
                    </MenuItem>
                  ))}
                </Select> */}

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Location Info"
                    name="location_info"
                    multiline
                    InputLabelProps={{ shrink: true }}
                    {...register('location_info', {})}
                  />
                  {errors.location_info && (
                    <p className="signUpErrorMessage">{errors.location_info.message}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Extra Info"
                    name="extra_info"
                    multiline
                    InputLabelProps={{ shrink: true }}
                    {...register('extra_info', {})}
                  />
                  {errors.extra_info && (
                    <p className="signUpErrorMessage">{errors.extra_info.message}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Extra Info 2"
                    name="extra_info2"
                    multiline
                    InputLabelProps={{ shrink: true }}
                    {...register('extra_info2', {})}
                  />
                  {errors.extra_info2 && (
                    <p className="signUpErrorMessage">{errors.extra_info2.message}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    InputLabelProps={{ shrink: true }}
                    {...register('email', {
                      pattern: {
                        value:
                          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                        message: 'Please enter a valid email',
                      },
                    })}
                  />
                  {errors.email && <p className="signUpErrorMessage">{errors.email.message}</p>}
                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="p_date"
                      render={({ field: { ref, ...rest } }) => (
                        <KeyboardDatePicker
                          label="Select Start Date"
                          format="MM/dd/yyyy"
                          margin="normal"
                          fullWidth
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="mt-2">
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Paid Period"
                        name="paid_period"
                        multiline
                        InputLabelProps={{ shrink: true }}
                        {...register('paid_period', {})}
                      />
                      {errors.paid_period && (
                        <p className="signUpErrorMessage">{errors.paid_period.message}</p>
                      )}
                    </div>
                  </Grid>
                </MuiPickersUtilsProvider>

                <div className="d-flex flex-column mt-3" style={{ width: '100%' }}>
                  <Grid item xs={12} style={{ textAlignLast: 'center' }}>
                    <FormLabel component="legend">Services</FormLabel>
                  </Grid>
                  {inputFields.length > 0 &&
                    inputFields.map((inputField, index) => {
                      return (
                        <div key={inputField.id} className="mt-1 mb-3">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ width: '30%' }}>
                              <Select
                                value={inputField?.service?._id?._id}
                                onChange={(event) => {
                                  handleChange(event, inputField.id);
                                }}
                              >
                                {field.map((field) => (
                                  <MenuItem key={field._id} value={field._id}>
                                    {field.service}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                            <div style={{ width: '20%', marginRight: '30px' }}>
                              <TextField
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Quantity"
                                value={inputField?.service?.quantity}
                                onChange={(e) => {
                                  hanldechangeatt(e, inputField.id);
                                }}
                              />
                            </div>
                            <div style={{ width: '20%', marginRight: '30px' }}>
                              <TextField
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Unit Price"
                                value={inputField?.service?.unit_price}
                                onChange={(e) => {
                                  hanldechangeunitprice(e, inputField.id);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <IconButton
                                disabled={inputFields.length === 1}
                                onClick={() => handleRemoveFields(inputField.id)}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <IconButton onClick={handleAddFields}>
                                <AddIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Update
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

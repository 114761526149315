import React, { useState, useEffect } from 'react';
import Floor from './Floor';
import './Plano.css';

export default function Plano({
  plano,
  prods,
  heights,
  setHeights,
  name,
  floorNumber,
  lineNumber,
  floors,
  setFloors,
  newPlano,
  setNewPlano,
  height,
  laneHeight,
}) {
  // const [heights, setHeights] = useState([]);
  const [floor, setfloor] = useState([]);

  useEffect(() => {
    let Floors = [];
    for (let i = 1; i <= floorNumber; i++) {
      Floors.push(
        <Floor
          plano={plano}
          prods={prods}
          heights={heights}
          setHeights={setHeights}
          floorNumber={floorNumber}
          setNewPlano={setNewPlano}
          newPlano={newPlano}
          floors={floors}
          setFloors={setFloors}
          height={height}
          lineNumber={lineNumber}
          floor={floorNumber - i + 1}
          key={floorNumber - i + 1}
          i={i}
        />
      );
    }
    setfloor(Floors);
  }, [heights]);

  return (
    <div>
      <h5>{name}</h5>
      <div>{floor}</div>
    </div>
  );
}

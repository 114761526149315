import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import Swal from 'sweetalert2';

import { Modal, Row, Col } from 'react-bootstrap';

import request from '../services/Request';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 200,
    maxWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function DimensionChangeModal(props) {
  const classes = useStyles();
  const { prod, prods, i, setProds, newProds, ...rest } = props;

  const handleChangeWidth = (e) => {
    newProds[i].dimension.length = parseFloat(e.target.value);
    setProds([...prods]);
    // console.log('prod', prod);
  };

  const handleChangeHeight = (e) => {
    newProds[i].dimension.originalHeight = parseFloat(e.target.value);
    setProds([...prods]);
  };

  const handleChangeLength = (e) => {
    newProds[i].dimension.width = parseFloat(e.target.value);
    setProds([...prods]);
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Temporary dimensions change</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body" onClick={() => {}}>
        <Grid item xs={12} style={{ fontSize: '20px', marginBottom: '10px' }}></Grid>

        <Container component="main">
          <CssBaseline />
        </Container>
        <TextField
          style={{ marginBottom: '30px' }}
          fullWidth
          label="Width"
          variant="outlined"
          type="number"
          inputProps={{
            step: '0.1',
            min: '0',
          }}
          value={prod?.dimension?.length}
          onChange={handleChangeWidth}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          fullWidth
          style={{ marginBottom: '30px' }}
          label="Depth"
          type="number"
          variant="outlined"
          inputProps={{
            step: '0.1',
            min: '0',
          }}
          onChange={handleChangeLength}
          value={prod?.dimension?.width}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          style={{ marginBottom: '30px' }}
          fullWidth
          label="Height"
          type="number"
          variant="outlined"
          inputProps={{
            step: '0.1',
            min: '0',
          }}
          value={prod?.dimension?.originalHeight}
          onChange={handleChangeHeight}
          InputLabelProps={{ shrink: true }}
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            props.onHide();
          }}
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
}

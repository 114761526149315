import CategoryCard from '../items/categoryCard';
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/userContext';
import axios from 'axios';
import ToggleButtons from '../items/buttons/ToggleButtons';
import CategoryList from './CategoryList';
import { Container } from 'react-bootstrap';

function ManageCategories(props) {
  const { userEmail, role, referTo } = useContext(UserContext);
  const userId = props.match?.params.user;
  const id = role === 'Admin' || role === 'SuperAdmin' ? (userId ? userId : userEmail) : referTo;
  const getAllCateURL = `${process.env.REACT_APP_SERVER_API}/categories/`;
  const [cate, setcates] = useState([]);
  const [view, setView] = useState('module');

  function setList(event) {
    setView('list');
  }
  function setModule(event) {
    setView('module');
  }

  useEffect(() => {
    axios
      .get(role === 'SuperAdmin' && !userId ? getAllCateURL : getAllCateURL + id, {
        headers: {
          'auth-token': localStorage.getItem('token'),
        },
      })
      .then((res) => {
        setcates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const categories = cate.map((cate) => {
    return (
      <CategoryCard
        key={cate._id}
        id={cate._id}
        catename={cate.name}
        image={`${process.env.REACT_APP_SERVER_API}/pictures/${cate.categoryPicture}/${cate.referTo}`}
        referTo={cate.referTo}
        linkTo={userId ? '/dashboard/categories' : null}
      />
    );
  });

  const listCategories = cate.map((category) => {
    return (
      <CategoryList
        key={category._id}
        id={category._id}
        name={category.name}
        picture_name={category.categoryPicture}
        referTo={id}
        linkTo={userId ? '/dashboard/categories' : null}
      />
    );
  });

  function categoryStructure() {
    if (role === 'SuperAdmin' && userId && cate.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '90%',
          }}
        >
          <div className="container">
            <h2 className="mt-4 mb-2">No categories to display</h2>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {role === 'SuperAdmin' && userId && cate.length > 0 && (
            <h2 className="mt-4 mb-2">Categories of: {userId}</h2>
          )}
          <ToggleButtons setList={setList} setModule={setModule} view={view} />
          <div className="container">
            {view === 'module' && <div className="row items-container mt-4">{categories}</div>}
            {view === 'list' && (
              <Container className="footer mt-4" fluid>
                {listCategories}
              </Container>
            )}
          </div>
        </div>
      );
    }
  }

  return <>{categoryStructure()}</>;
}

export default ManageCategories;

import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import request from '../../services/Request';
import '../user/form.css';
import { languages } from '../../staticData/languages';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TimezoneSelect from 'react-timezone-select';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const model = [
  {
    name: 'Colosseo',
    lane: 18,
    floor: 6,
    laneLength: 7.8,
    height: 85,
    slot_height: 2.54,
    step_depth: 2.4,
    step_num: 16,
  },
  {
    name: 'Big Store',
    lane: 14,
    floor: 6,
    laneLength: 7.8,
    height: 85,
    slot_height: 2.54,
    step_depth: 2.4,
    step_num: 19,
  },
  {
    name: 'Easy 14',
    lane: 14,
    floor: 6,
    laneLength: 7.8,
    height: 85,
    slot_height: 2.54,
    step_depth: 2.4,
    step_num: 16,
  },
  {
    name: 'Easy 9',
    lane: 9,
    floor: 6,
    laneLength: 7.8,
    height: 85,
    slot_height: 2.54,
    step_depth: 2.4,
    step_num: 16,
  },
  {
    name: 'Arena 9',
    lane: 9,
    floor: 6,
    laneLength: 7.8,
    height: 85,
    slot_height: 2.54,
    step_depth: 2.4,
    step_num: 19,
  },
  {
    name: 'Idea 9',
    lane: 9,
    floor: 6,
    laneLength: 7.8,
    height: 85,
    slot_height: 2.54,
    step_depth: 2.4,
    step_num: 16,
  },
  {
    name: 'Idea 6',
    lane: 6,
    floor: 6,
    laneLength: 7.8,
    height: 85,
    slot_height: 2.54,
    step_depth: 2.4,
    step_num: 16,
  },
  {
    name: 'Gamma 13',
    lane: 15,
    floor: 7,
    laneLength: 7.8,
    height: 85,
    slot_height: 2.54,
    step_depth: 2.4,
    step_num: 16,
    width: 158,
    depth: 53,
  },
  {
    name: 'Gamma 10',
    lane: 15,
    floor: 7,
    laneLength: 7.8,
    height: 85,
    slot_height: 2.54,
    step_depth: 2.4,
    step_num: 16,
    width: 158,
    depth: 53,
  },
  {
    name: 'Inspire 10',
    lane: 10,
    floor: 7,
    laneLength: 7.8,
    height: 114,
    width: 101,
    depth: 51,
  },
  {
    name: 'Delta 7',
    lane: 7,
    floor: 7,
    laneLength: 7.8,
    height: 121,
    width: 69,
    depth: 44,
  },
];

export default function CreateMachine() {
  const classes = useStyles();

  const createMachineURL = `/machines/create`;
  const getAllAdminURL = `/users/getAllEmployees`;

  const [admin, setAdmin] = useState([]);
  const [select, setSelect] = useState(null);
  const [select1, setSelect1] = useState(null);
  const [moreModel, setMoreModel] = useState(false);
  const [lane, setLane] = useState('0');
  const [floor, setFloor] = useState('0');
  const [lane1, setLane1] = useState('0');
  const [floor1, setFloor1] = useState('0');
  const [selectedTimezone, setSelectedTimezone] = useState({
    value: 'America/Detroit',
    label: '(GMT-5:00) Eastern Time',
    offset: -5,
    abbrev: 'EST',
    altName: 'Eastern Standard Time',
  });
  const defaultValues = {
    stock: '1',
    dl_radio: '0',
  };

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    control,
    formState: { errors },
  } = useForm({ defaultValues });
  let history = useHistory();

  const watchAll = watch();

  useEffect(() => {
    let isCancelled = false;

    //getAllEmployees
    async function getAllAdmin() {
      await request({
        method: 'get',
        url: getAllAdminURL,
      }).then((res) => {
        if (!isCancelled) {
          setAdmin(res.data);
        }
      });
    }

    getAllAdmin();

    return () => {
      isCancelled = true;
    };
  }, []);

  const onSubmit = async (data) => {
    console.log(data);

    if (select) {
      selectedTimezone.tz = watchAll.dl_radio === '0' ? false : true;
      const regExp = /\(([^)]+)\)/;
      const belongTo = regExp.exec(data.belongTo)[1];
      const mydata = {
        name: data.Name,
        description: data.description,
        belongTo: belongTo,
        lane: parseInt(data.lane),
        floor: parseInt(data.floor),
        model: data.model,
        stock: data.stock ? parseInt(data.stock) : 1,
        languages: data.language,
        height: data.height,
        slot_height: data.slot_height,
        step_depth: data.step_depth,
        step_num: data.step_num,
        laneLength: data.laneLength,
        tz: selectedTimezone,
      };

      if (data.stock && parseInt(data.stock) > 1 && moreModel) {
        if (select1) {
          mydata.model1 = data.model1;
          mydata.modelNum = data.modelNum;
          mydata.modelNum1 = data.modelNum1;
          mydata.lane1 = parseInt(data.lane1);
          mydata.floor1 = parseInt(data.floor1);
        } else {
          Swal.fire({
            text: `Machine Model can not be empty`,
            icon: 'error',
          });
        }
      }
      await request({
        method: 'post',
        url: createMachineURL,
        data: mydata,
      })
        .then((res) => {
          Swal.fire({
            text: `The machine has been added.`,
            icon: 'success',
          }).then(() => {
            history.push('/dashboard');
          });
        })
        .catch((err) => {
          if (err) {
            if (err.response) {
              if (err.response.data.code == 1) {
                setError('belongTo', {
                  message: err.response.data.message,
                });
              }
              if (err.response.data.code == 2) {
                setError('Name', {
                  message: err.response.data.message,
                });
              }
            }
          }
        });
    } else {
      Swal.fire({
        text: `Machine Model can not be empty`,
        icon: 'error',
      });
    }
  };

  const handleChange = async (event) => {
    console.log('aaa');
    if (
      event.target.value.name != 'Easy 14' &&
      event.target.value.name != 'Easy 9' &&
      event.target.value.name != 'Colosseo'
    ) {
      setValue('stock', '1');
    }
    setSelect(event.target.value);
    setLane(event.target.value.lane);
    setFloor(event.target.value.floor);
    setValue('model', event.target.value.name);
    setValue('lane', event.target.value.lane);
    setValue('floor', event.target.value.floor);
    setValue('laneLength', event.target.value.laneLength);
    setValue('height', event.target.value.height);
    setValue('slot_height', event.target.value.slot_height);
    setValue('step_depth', event.target.value.step_depth);
    setValue('step_num', event.target.value.step_num);
  };

  const handleChange1 = async (event) => {
    setSelect1(event.target.value);
    setLane1(event.target.value.lane);
    setFloor1(event.target.value.floor);
    setValue('model1', event.target.value.name);
    setValue('lane1', event.target.value.lane);
    setValue('floor1', event.target.value.floor);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountBalanceWalletIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Add Mchine
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="name"
                name="Name"
                variant="outlined"
                required
                fullWidth
                id="Name"
                label="Machine ID"
                autoFocus
                {...register('Name', { required: 'name is required' })}
              />
              {errors.Name && <p className="signUpErrorMessage">{errors.Name.message}</p>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="description"
                label="Description"
                name="description"
                autoComplete="desc"
                {...register('description')}
              />
              {errors.description && (
                <p className="signUpErrorMessage">{errors.description.message}</p>
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="select-machine">Select a Model 1</InputLabel>
                    <Select fullWidth value={select || ''} onChange={handleChange}>
                      {model.map((i) => {
                        return (
                          <MenuItem key={i.name} value={i}>
                            {i.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                name="model"
                control={control}
              />
            </Grid>
            {select &&
              (select.name == 'Easy 14' ||
                select.name == 'Easy 9' ||
                select.name == 'Colosseo') && (
                <Grid item sm={12}>
                  <Controller
                    name="stock"
                    control={control}
                    rules={{ required: 'stock is required' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        onChange={onChange}
                        defaultValue={1}
                        InputProps={{ inputProps: { min: 1, max: 4 } }}
                        label="stock"
                      />
                    )}
                  />
                  {errors.stock && <p className="signUpErrorMessage">{errors.stock.message}</p>}
                </Grid>
              )}
            <Grid item sm={6}>
              <div key={lane}>
                <Controller
                  name="lane"
                  control={control}
                  rules={{ required: 'lane is required' }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      type="number"
                      InputProps={{ inputProps: { min: 1, max: 24 } }}
                      defaultValue={lane}
                      onChange={(e) => {
                        setLane(e.target.value);
                        setValue('lane', e.target.value);
                      }}
                      label="lane"
                    />
                  )}
                />
                {errors.lane && <p className="signUpErrorMessage">{errors.lane.message}</p>}
              </div>
            </Grid>
            <Grid item sm={6}>
              <div key={floor}>
                <Controller
                  name="floor"
                  control={control}
                  rules={{ required: 'floor is required' }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      type="number"
                      InputProps={{ inputProps: { min: 1, max: 24 } }}
                      defaultValue={floor}
                      onChange={(e) => {
                        setFloor(e.target.value);
                        setValue('floor', e.target.value);
                      }}
                      label="floor"
                    />
                  )}
                />
                {errors.floor && <p className="signUpErrorMessage">{errors.floor.message}</p>}
              </div>
            </Grid>

            {watchAll.stock && watchAll.stock !== '1' && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setMoreModel(!moreModel);
                  }}
                >
                  {!moreModel ? 'Add One More Model' : 'Remove Extra Model'}
                </Button>
              </Grid>
            )}

            {watchAll.stock && watchAll.stock !== '1' && moreModel && (
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="select-machine">Select a Model 2</InputLabel>
                      <Select fullWidth value={select1 || ''} onChange={handleChange1}>
                        {model.map((i) => {
                          return (
                            <MenuItem key={i.name} value={i}>
                              {i.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  name="model1"
                  control={control}
                />
              </Grid>
            )}

            {watchAll.stock && watchAll.stock !== '1' && moreModel && (
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Model 1 quantity"
                  type="number"
                  defaultValue={1}
                  InputProps={{ inputProps: { min: 1, max: 4 } }}
                  {...register('modelNum', { required: 'Model 1 quantity is required' })}
                />
                {errors.modelNum && <p className="signUpErrorMessage">{errors.modelNum.message}</p>}
              </Grid>
            )}

            {watchAll.stock && watchAll.stock !== '1' && moreModel && (
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Model 2 quantity"
                  type="number"
                  defaultValue={1}
                  InputProps={{ inputProps: { min: 1, max: 4 } }}
                  {...register('modelNum1', { required: 'Model 2 quantity is required' })}
                />
                {errors.modelNum1 && (
                  <p className="signUpErrorMessage">{errors.modelNum1.message}</p>
                )}
              </Grid>
            )}
            {watchAll.stock && watchAll.stock !== '1' && moreModel && (
              <Grid item sm={6}>
                <div key={lane1}>
                  <Controller
                    name="lane1"
                    control={control}
                    rules={{ required: 'lane is required' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        InputProps={{ inputProps: { min: 1, max: 24 } }}
                        defaultValue={lane1}
                        onChange={(e) => {
                          setLane1(e.target.value);
                          setValue('lane1', e.target.value);
                        }}
                        label="lane"
                      />
                    )}
                  />
                  {errors.lane1 && <p className="signUpErrorMessage">{errors.lane1.message}</p>}
                </div>
              </Grid>
            )}
            {watchAll.stock && watchAll.stock !== '1' && moreModel && (
              <Grid item sm={6}>
                <div key={floor1}>
                  <Controller
                    name="floor1"
                    control={control}
                    rules={{ required: 'floor is required' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        InputProps={{ inputProps: { min: 1, max: 24 } }}
                        defaultValue={floor1}
                        onChange={(e) => {
                          setFloor1(e.target.value);
                          setValue('floor1', e.target.value);
                        }}
                        label="floor"
                      />
                    )}
                  />
                  {errors.floor1 && <p className="signUpErrorMessage">{errors.floor1.message}</p>}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Autocomplete
                id="addToMachine"
                options={admin}
                getOptionLabel={(option) => `${option.groupname} (${option.email})`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add to"
                    variant="outlined"
                    {...register('belongTo', { required: 'Add to is required' })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="language"
                rules={{ required: 'language is required' }}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    id="language"
                    //  value={prodValue}
                    options={languages}
                    disableCloseOnSelect
                    onChange={(event, value) => {
                      //  setProdValue(value);
                      field.onChange(value);
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name + '(' + option.code + ')'}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name + '(' + option.code + ')'}
                      </React.Fragment>
                    )}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Languages" />
                    )}
                  />
                )}
              />
            </Grid>
            {errors.language && (
              <p
                style={{ marginTop: '5px', marginBottom: '-5px', paddingLeft: '12px' }}
                className="signUpErrorMessage"
              >
                {errors.language.message}
              </p>
            )}
            <p style={{ marginTop: '5px', marginBottom: '-5px', paddingLeft: '12px' }}>
              The first language you select will be the primary language
            </p>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <FormControl component="fieldset" style={{ width: '100%', marginTop: '20px' }}>
                <FormLabel component="legend">Daylight Saving Time</FormLabel>
                <RadioGroup
                  row
                  style={{ justifyContent: 'space-evenly' }}
                  name="dl_radio"
                  {...register('dl_radio1', {
                    onChange: (e) => {
                      setValue('dl_radio', e.target.value);
                    },
                  })}
                >
                  <FormControlLabel
                    value="1"
                    checked={watchAll.dl_radio === '1'}
                    control={<Radio />}
                    label="On"
                  />
                  <FormControlLabel
                    value="0"
                    checked={watchAll.dl_radio === '0'}
                    control={<Radio />}
                    label="Off"
                  />
                </RadioGroup>
              </FormControl>
              <div className="select-wrapper">
                <p style={{ marginBottom: '10px' }}>Select the time zone</p>
                <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
              </div>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Add Machine
          </Button>
        </form>
      </div>
    </Container>
  );
}

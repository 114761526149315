// material
import MaterialTable from 'material-table';
import { useState } from 'react';
import { CloudDownload } from '@material-ui/icons';
import { Email } from '@material-ui/icons';
import request from '../../../services/Request';
import Swal from 'sweetalert2';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import { fShortenNumber } from './utils/formatNumber';
import { fCurrency } from './utils/formatNumber';
import { useEffect } from 'react';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import Chart from 'react-apexcharts';
import { FormControlLabel, Switch } from '@material-ui/core';

export default function DateArrayTable(props) {
  const { endDate, startDate, dateArray, machinesName } = props;

  const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
    <Typography
      variant={variant}
      style={{ overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '20px' }}
    >
      {`Sales By Date Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
    </Typography>
  );
  const [format, setFormat] = useState('bar');
  // const [x, setx] = useState([]);
  // const [y, sety] = useState([]);

  const y = dateArray.map((item) => [new Date(item.date), item.totalSale]);
  // console.log(y);
  const options = {
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val.toFixed(2)}`;
      },
      offsetY: -20,
      style: {
        fontSize: '11px',
        colors: ['#304758'],
      },
    },
    markers: {
      size: 0,
    },
    title: {
      text: 'Sales By Date($)',
      align: 'left',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: format === 'bar' ? 1 : 0,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return `$${val.toFixed(0)}`;
        },
      },
      title: {
        text: 'Total Sale',
      },
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return `$${val.toFixed(2)}`;
        },
      },
    },
  };

  const series = [
    {
      name: 'Total Sale',
      data: y,
    },
  ];

  const msSaleTable = () => {
    const newArray = dateArray.map((item) => {
      return {
        Id: item.machineName,
        Description: item.machineDescription,
        'Total Sale': item.totalSale,
        'Total Tax': item.totalTax,
        'Total Cost Price': item.costTotal,
        'Total Profit': parseFloat((item.totalSale - item.costTotal).toFixed(2)),
        'Total Transaction': item.totalTrans,
      };
    });

    // console.log(newArray);

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      useTextFile: false,
      filename: 'Machine Sales Report',
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(newArray);
  };

  const columns = [
    {
      title: 'Date',
      field: 'date',
    },
    {
      title: 'Total Sale',
      field: 'totalSale',
      render: (rowData) => `${fCurrency(rowData.totalSale)}`,
    },
    {
      title: 'Total Tax',
      field: 'totalTax',
      render: (rowData) => `${fCurrency(rowData.totalTax)}`,
    },
    {
      title: 'Total Transactions',
      field: 'totalTrans',
      render: (rowData) => `${rowData?.order?.length}`,
    },
  ];

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={dateArray}
        title={<MyNewTitle variant="h6" />}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100, 200],
        }}
        detailPanel={(rowData) => {
          // console.log(rowData);
          return (
            <TableContainer component={Paper} style={{ backgroundColor: '#EBEBE8' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell align="right">Machine ID</TableCell>
                    <TableCell align="right">Machine Desc</TableCell>
                    <TableCell align="right">Tax</TableCell>
                    <TableCell align="right">Sale Amount</TableCell>
                    <TableCell align="right">Email Receipt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData?.order?.map((prod, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {prod.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {moment(prod.createdAt)?.format('lll')}
                      </TableCell>
                      <TableCell align="right">{prod.machineInfo?.name}</TableCell>
                      <TableCell align="right">{prod.machineInfo?.description}</TableCell>
                      <TableCell align="right">{fCurrency(prod.tax)}</TableCell>
                      <TableCell align="right">{fCurrency(prod.total)}</TableCell>
                      <TableCell align="right">{prod.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
        // actions={[
        //   {
        //     tooltip: 'Download csv',
        //     isFreeAction: true,
        //     icon: CloudDownload,
        //     onClick: (evt, data) => {
        //       msSaleTable();
        //     },
        //   },
        // ]}
      />
      <div className="mt-5"></div>

      <FormControlLabel
        control={
          <Switch
            checked={format === 'bar'}
            onChange={() => {
              if (format === 'bar') {
                setFormat('area');
              } else {
                setFormat('bar');
              }
            }}
          />
        }
        label="Switch Chart"
      />
      {format === 'bar' && <Chart options={options} series={series} type="bar" height={350} />}
      {format === 'area' && <Chart options={options} series={series} type="area" height={350} />}
    </div>
  );
}

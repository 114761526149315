import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Swal from 'sweetalert2';

import { Modal, Row, Col } from 'react-bootstrap';

export default function ViewCashModal(props) {
  const { data, ...rest } = props;

  const cashInfo = data?.cashInfo;
  // console.log('cashinfo', cashInfo);
  const symbol = data?.currency?.symbol;
  // console.log('cashInfo', cashInfo?.bills_stock);

  return (
    <Modal className="modal-w" {...rest} centered size="">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cash Information(
          {<span>Currency:{data?.currency?.code}</span>})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div
          className="d-flex flex-column"
          style={{
            fontSize: '25px',
          }}
        >
          {cashInfo?.bills_stock?.map((e, i) => {
            return (
              <div key={i}>
                <div style={{ width: '50%', display: 'inline-block', padding: '20px' }}>
                  Bill:
                  {symbol}
                  {e.amount}
                </div>
                <span>Stock:{e.stock}</span>
              </div>
            );
          })}

          {cashInfo?.cash?.map((e, i) => {
            console.log('cashInfo', cashInfo);
            const storage = e.storage === 'RouteFail' ? 'Reject' : e.storage;
            let storageSetting = '';
            if (e.storageSetting === 'PS') {
              storageSetting = 'Storage';
            } else if (e.storageSetting === 'CB') {
              storageSetting = 'Cashbox';
            } else if (e.storageSetting === 'Fail') {
              storageSetting = 'Reject';
            }

            return (
              <div
                className="d-flex align-items-center mb-5"
                key={i}
                style={{
                  fontSize: '18px',
                }}
              >
                <div style={{ width: '17%' }}>
                  Bill:{symbol}
                  {e.value}
                </div>
                <div style={{ width: '18%' }}>Stock:{e.count}</div>
                <div style={{ width: '35%' }}>Storage:{storageSetting}</div>
                {/* <div style={{ width: '40%' }}>Storage(current_state):{storage}</div> */}
                <div style={{ width: '25%' }}>PayoutLimit:{e.payoutLimit}</div>
              </div>
            );
          })}

          {cashInfo?.credit && (
            <div style={{ padding: '20px' }}>
              <span>Credit:{cashInfo?.credit}</span>
            </div>
          )}

          {cashInfo?.max_change && (
            <div style={{ padding: '20px' }}>
              <span>Max Change:{cashInfo?.max_change}</span>
            </div>
          )}

          {cashInfo?.coins_total_stock && (
            <div style={{ padding: '20px' }}>
              <span>Coins Total Stock:{cashInfo?.coins_total_stock}</span>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useForm, Controller } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import Swal from 'sweetalert2';

import { Modal, Row, Col } from 'react-bootstrap';

import request from '../../services/Request';

export default function EditUserModal(props) {
  const [viewButton, setviewButton] = useState('Check All View');
  const { data, setReflesh, reflesh, ...rest } = props;
  const defaultValues = {
    access: '0',
    view_machine: true,
    edit_machine: false,
    view_prod: true,
    edit_prod: false,
    view_cate: true,
    edit_cate: false,
    view_brand: true,
    edit_brand: false,
    view_receipt: true,
    edit_receipt: false,
    view_ss: true,
    edit_ss: false,
    edit_group: false,
    edit_user: false,
    view_plano: true,
    edit_plano: false,
    view_promo: true,
    edit_promo: false,
    view_report: true,
    view_trans: true,
    stocking: false,
    write: false,
  };
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const checkAllView = () => {
    if (viewButton === 'Check All View') {
      setValue('view_machine', true);
      setValue('view_prod', true);
      setValue('view_cate', true);
      setValue('view_brand', true);
      setValue('view_receipt', true);
      setValue('view_ss', true);
      setValue('view_plano', true);
      setValue('view_promo', true);
      setValue('view_report', true);
      setValue('view_trans', true);
      setviewButton('Uncheck All View');
    } else {
      setValue('view_machine', false);
      setValue('view_prod', false);
      setValue('view_cate', false);
      setValue('view_brand', false);
      setValue('view_receipt', false);
      setValue('view_ss', false);
      setValue('view_plano', false);
      setValue('view_promo', false);
      setValue('view_report', false);
      setValue('view_trans', false);

      setValue('edit_machine', false);
      setValue('edit_prod', false);
      setValue('edit_cate', false);
      setValue('edit_brand', false);
      setValue('edit_receipt', false);
      setValue('edit_ss', false);
      setValue('edit_group', false);
      setValue('edit_user', false);
      setValue('edit_plano', false);
      setValue('edit_promo', false);
      setviewButton('Check All View');
    }
  };

  const myOnchange = (field, e) => {
    const name = field.substring(4);
    if (field.includes('view')) {
      const view = field;
      const edit = 'edit' + name;
      if (!e.target.checked) {
        setValue(view, e.target.checked);
        setValue(edit, e.target.checked);
      } else {
        setValue(view, e.target.checked);
      }
    } else {
      const edit = field;
      const view = 'view' + name;
      if (e.target.checked) {
        setValue(view, e.target.checked);
        setValue(edit, e.target.checked);
      } else {
        setValue(edit, e.target.checked);
      }
    }
    if (field === 'write') {
      if (e.target.checked) {
        setValue('write', e.target.checked);
        setValue('stocking', e.target.checked);
      } else {
        setValue('write', e.target.checked);
      }
    }
    if (field === 'stocking') {
      if (!e.target.checked) {
        setValue('write', e.target.checked);
        setValue('stocking', e.target.checked);
      } else {
        setValue('stocking', e.target.checked);
      }
    }
  };

  useEffect(() => {
    if (props.show && data) {
      reset({
        role: data.role,
        access: data.fullAccess ? '1' : '0',
        view_machine: data.limits.view_machine,
        edit_machine: data.limits.edit_machine,
        view_prod: data.limits.view_prod,
        edit_prod: data.limits.edit_prod,
        view_cate: data.limits.view_cate,
        edit_cate: data.limits.edit_cate,
        view_brand: data.limits.view_brand,
        edit_brand: data.limits.edit_brand,
        view_receipt: data.limits.view_receipt,
        edit_receipt: data.limits.edit_receipt,
        view_ss: data.limits.view_ss,
        edit_ss: data.limits.edit_ss,
        edit_group: data.limits.edit_group,
        edit_user: data.limits.edit_user,
        view_plano: data.limits.view_plano,
        edit_plano: data.limits.edit_plano,
        view_promo: data.limits.view_promo,
        edit_promo: data.limits.edit_promo,
        view_report: data.limits.view_report,
        view_trans: data.limits.view_trans,
        stocking: data.stocking ? true : false,
        write: data.stocking_write ? true : false,
      });
    }
  }, [props.show]);

  const watchAll = watch();

  const onSubmit = async (data) => {
    // console.log(data);
    const access = data.access === '1' ? true : false;
    let limits = {
      view_machine: data.view_machine,
      edit_machine: data.edit_machine,
      view_prod: data.view_prod,
      edit_prod: data.edit_prod,
      view_cate: data.view_cate,
      edit_cate: data.edit_cate,
      view_brand: data.view_brand,
      edit_brand: data.edit_brand,
      view_receipt: data.view_receipt,
      edit_receipt: data.edit_receipt,
      view_ss: data.view_ss,
      edit_ss: data.edit_ss,
      edit_group: data.edit_group,
      edit_user: false,
      view_plano: data.view_plano,
      edit_plano: data.edit_plano,
      view_promo: data.view_promo,
      edit_promo: data.edit_promo,
      view_report: data.view_report,
      view_trans: data.view_trans,
    };
    if (access) {
      limits = {
        view_machine: true,
        edit_machine: true,
        view_prod: true,
        edit_prod: true,
        view_cate: true,
        edit_cate: true,
        view_brand: true,
        edit_brand: true,
        view_receipt: true,
        edit_receipt: true,
        view_ss: true,
        edit_ss: true,
        edit_group: true,
        edit_user: true,
        view_plano: true,
        edit_plano: true,
        view_promo: true,
        edit_promo: true,
        view_report: true,
        view_trans: true,
      };
    }
    await request({
      method: 'patch',
      url: '/users/limits',
      data: {
        id: props.data._id,
        fullAccess: access,
        limits: limits,
        stocking: data.stocking,
        stocking_write: data.write,
      },
    }).then((res) => {
      Swal.fire({
        text: `The user has been successfully updated`,
        icon: 'success',
      }).then(() => {
        reflesh ? setReflesh(false) : setReflesh(true);
        props.onHide();
      });
    });
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit user's access level</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form onSubmit={handleSubmit(onSubmit)} noValidate style={{ textAlign: 'center' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">User Access Level</FormLabel>
                <Controller
                  control={control}
                  name="access"
                  render={({ field }) => (
                    <RadioGroup
                      aria-label="Role"
                      name="Role1"
                      row
                      {...field}
                      value={watchAll.access}
                    >
                      <FormControlLabel value="1" control={<Radio />} label="Full Access" />
                      <FormControlLabel value="0" control={<Radio />} label="Limited Access" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid container spacing={2} style={{ padding: '0px 40px 10px 40px' }}>
              <Grid item xs={12} className="d-flex align-items-center">
                <Button fullWidth variant="contained" color="primary" onClick={checkAllView}>
                  {viewButton}
                </Button>
              </Grid>
              <Grid item xs={6} className="d-flex align-items-center">
                <Controller
                  name="stocking"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={watchAll.stocking}
                      onChange={(e) => {
                        myOnchange(field.name, e);
                      }}
                    />
                  )}
                />
                <div>Stocking(read)</div>
              </Grid>
              <Grid item xs={6} className="d-flex align-items-center">
                <Controller
                  name="write"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={watchAll.write}
                      onChange={(e) => {
                        myOnchange(field.name, e);
                      }}
                    />
                  )}
                />
                <div>Stocking(write)</div>
              </Grid>
            </Grid>

            {watchAll.access == '0' && (
              <Grid container spacing={2} style={{ padding: '0px 40px 30px 40px' }}>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_machine"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_machine}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Machine</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_machine"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_machine}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Machine</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_prod"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_prod}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Product</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_prod"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_prod}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Product</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_cate"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_cate}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Category</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_cate"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_cate}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Category</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_brand"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_brand}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Brand</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_brand"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_brand}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Brand</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_receipt"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_receipt}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Receipt</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_receipt"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_receipt}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Receipt</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_ss"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_ss}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Screen Saver</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_ss"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_ss}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Screen Saver</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_plano"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_plano}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Planogram</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_plano"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_plano}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Planogram</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_promo"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_promo}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Promotion</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_promo"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_promo}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Promotion</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_report"
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={watchAll.view_report} />}
                  />
                  <div>View Report</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_group"
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={watchAll.edit_group} />}
                  />
                  <div>Edit Group</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_trans"
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={watchAll.view_trans} />}
                  />
                  <div>View Transaction</div>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Button fullWidth variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

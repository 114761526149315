import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import request from '../../services/Request';
import { TextField, Button, MenuItem, Divider, IconButton } from '@material-ui/core';
import { ProgressBar } from 'react-bootstrap';
import { DeleteForeverOutlined } from '@material-ui/icons';
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/userContext';
import { Controller, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, Select } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { InputAdornment } from '@material-ui/core';
import { languages, languages_noEn } from '../../staticData/languages';
import { currencies, noUScurrencies } from '../../staticData/currencies';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const DF_DETAIL = 55;
const TW_DETAIL = 110;
const TW_NAME = 28;
const DF_NAME = 34;
const MAXLENGTH = 2000;
const UPC = 20;
const TITLELENGTH = 20;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 120,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 1, 2),
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function UpdateProduct(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const { userEmail, role, referTo } = useContext(UserContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const additionPriceOn = user?.features?.additionPrice;
  const theme1 = user?.theme === '1';
  const advanced = user?.features?.advanced;
  const langs = user?.langs;
  const extra = user?.features?.extra;

  const [arrow, setArrow] = useState(false);

  const defaultValues = {
    ppRadio: 'prod',
    name: '',
    select: 'en',
    selectPrice: 'USD',
    upc: '',
    price: '',
    title1: '',
    title2: '',
    costPrice: '',
    description: '',
    pop_alert: '',
    detail: '',
    category: [],
    ingredients: '',
    keyFeatures: '',
    specification: '',
    include: '',
    allergens: '',
    brand: null,
    length: '',
    width: '',
    height: '',
    image1: null,
    image2: null,
    image3: null,
    video: null,
    autoTrans: false,
    tax_type: '0',
    externalCode: '',
  };

  const {
    handleSubmit,
    control,
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });

  let id = '';
  let userId = useLocation().pathname.split('/').slice(-1)[0];

  if (userId.includes('@')) {
    id = userId;
  } else {
    if (role === 'Admin') id = userEmail;
    else id = referTo;
  }

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [prod, setProd] = useState(null);
  const [fileNames, setFileNames] = useState({});
  const [deleteImage, setDeleteImage] = useState([]);
  const [percent, setPercent] = useState(0);
  const [Ocate, setOcate] = useState([]);
  const [langArray, setlangArray] = useState([]);
  const [enArray, setenArray] = useState([]);
  const watchAllFields = watch();

  const handleRemove = (input) => {
    setPercent(0);
    setValue(input);
    fileNames[input] = null;
  };

  useEffect(() => {
    let a1 = [];
    let a2 = [languages[0]];
    if (langs?.length > 0) {
      langs.forEach((lan) => {
        const index = languages_noEn.findIndex((e) => e.code === lan);
        if (index > -1) {
          a1.push(languages_noEn[index]);
          a2.push(languages_noEn[index]);
        }
      });
    }
    setlangArray(a1);
    setenArray(a2);
    setPercent(0);
    if (props.show) {
      request({
        method: 'get',
        url: `/product/${props.id}`,
      }).then((res) => {
        console.log('res', res.data);
        const myCate = res.data.category;
        setOcate(myCate);
        setProd(res.data);
        if (res.data.productPictures[0]) {
          fileNames.image1 = res.data.productPictures[0];
          deleteImage.push(res.data.productPictures[0]);
        }
        if (res.data.productPictures[1]) {
          fileNames.image2 = res.data.productPictures[1];
          deleteImage.push(res.data.productPictures[1]);
        }
        if (res.data.productPictures[2]) {
          fileNames.image3 = res.data.productPictures[2];
          deleteImage.push(res.data.productPictures[2]);
        }
        if (res.data.productVideo) {
          fileNames.video = res.data.productVideo;
          deleteImage.push(res.data.productVideo);
        }
        let resetObj = {
          ppRadio: res.data.prodType,
          select: 'en',
          selectPrice: 'USD',
          category: myCate,
          name: res.data.name.en,
          upc: res.data.upc,
          price: res.data.price,
          costPrice: res.data.costPrice,
          description: res.data.description.en,
          ingredients: res.data.ingredients?.en,
          title1: res.data.title1?.en,
          title2: res.data.title2?.en,
          pop_alert: res.data.pop_alert?.en,
          detail: res.data.detail?.en,
          allergens: res.data.allergens?.en,
          brand: res.data.brand?._id,
          length: res.data.dimension ? res.data.dimension.length : '2',
          width: res.data.dimension ? res.data.dimension.width : '2',
          height: res.data.dimension ? res.data.dimension.height : '2',
          autoTrans: false,
          id_familia: res.data.addition?.id_familia,
          id_subfamilia: res.data.addition?.id_subfamilia,
          subconcepto: res.data.addition?.subconcepto,
          tax_type: res.data.tax_type ? res.data.tax_type : '0',
          externalCode: res.data.externalCode,
        };

        if (res.data.additionPrice) {
          for (const property in res.data.additionPrice) {
            resetObj[property + 'Price'] = res.data.additionPrice[property];
          }
        }

        for (const property in res.data.name) {
          resetObj[property + 'Name'] = res.data.name[property];
        }
        for (const property in res.data.description) {
          resetObj[property + 'Desc'] = res.data.description[property];
        }
        for (const property in res.data.title1) {
          resetObj[property + 'Title1'] = res.data.title1[property];
        }
        for (const property in res.data.title2) {
          resetObj[property + 'Title2'] = res.data.title2[property];
        }
        for (const property in res.data.pop_alert) {
          resetObj[property + 'Popa'] = res.data.pop_alert[property];
        }
        for (const property in res.data.ingredients) {
          resetObj[property + 'Ingre'] = res.data.ingredients[property];
        }
        for (const property in res.data.allergens) {
          resetObj[property + 'Aller'] = res.data.allergens[property];
        }
        for (const property in res.data.detail) {
          resetObj[property + 'Detail'] = res.data.detail[property];
        }
        if (theme1) {
          resetObj.keyFeatures = res.data.keyFeatures?.en;
          resetObj.specification = res.data.specification?.en;
          resetObj.include = res.data.include?.en;
          for (const property in res.data.keyFeatures) {
            resetObj[property + 'Key'] = res.data.keyFeatures[property];
          }
          for (const property in res.data.specification) {
            resetObj[property + 'Spec'] = res.data.specification[property];
          }
          for (const property in res.data.include) {
            resetObj[property + 'Include'] = res.data.include[property];
          }
        }
        //show addition field
        const isEmptyAddition = [
          res.data.addition?.id_familia,
          res.data.addition?.id_subfamilia,
          res.data.addition?.subconcepto,
        ].every((e) => !e || e === '');

        if (!isEmptyAddition) {
          setArrow(true);
        }

        reset(resetObj);
      });

      request({
        method: 'get',
        url: `/categories/${id}`,
      }).then((res) => {
        setCategories(res.data);
      });

      request({
        method: 'get',
        url: `/brands/${id}`,
      }).then((res) => {
        setBrands(res.data);
      });

      return () => {
        setCategories([]);
        setBrands([]);
        setDeleteImage([]);
        setFileNames({});
        setProd([]);
      };
    }
  }, [props.show]);

  const currentSymbol = () => {
    if (
      watchAllFields?.selectPrice === 'USD' ||
      watchAllFields?.selectPrice === 'CAD' ||
      !watchAllFields?.selectPrice
    )
      return '$';
    if (watchAllFields?.selectPrice === 'EUR') return '€';
    if (watchAllFields?.selectPrice === 'GBP') return '£';
  };

  const onSubmit = (formData) => {
    if (fileNames.image1 == null && (formData.image1 == null || formData.image1.length == 0)) {
      setError('image1', { message: 'Image 1 is required' });
    } else {
      const deleteFiles = deleteImage.filter(
        (e) =>
          e != fileNames.image1 &&
          e != fileNames.image2 &&
          e != fileNames.image3 &&
          e != fileNames.video
      );

      const category = formData.category.map((e) => e._id);

      // calculate cate array symmetric differnece
      const originCate = Ocate.map((e) => e._id);
      let cateDiff = originCate
        .filter((x) => !category.includes(x))
        .concat(category.filter((x) => !originCate.includes(x)));

      const myData = new FormData();
      if (cateDiff?.length > 0) {
        myData.append('cateDiff', JSON.stringify(cateDiff));
      }
      deleteFiles.forEach((element) => {
        myData.append('deleteFiles', element);
      });

      let name = { en: formData.name };
      let description = { en: formData.description };
      let title1 = { en: formData.title1 };
      let title2 = { en: formData.title2 };
      let pop_alert = { en: formData.pop_alert };
      let detail = { en: formData.detail };
      let keyFeatures = { en: formData.keyFeatures };
      let include = { en: formData.include };
      let specification = { en: formData.specification };
      let ingredients = { en: formData.ingredients };
      let allergens = { en: formData.allergens };

      let addition = {};
      const isEmptyAddition = [
        formData.id_familia,
        formData.id_subfamilia,
        formData.subconcepto,
      ].every((e) => !e || e === '');
      if (!isEmptyAddition) {
        if (formData.id_familia) {
          addition.id_familia = formData.id_familia;
          addition.id_subfamilia = formData.id_subfamilia;
          addition.subconcepto = formData.subconcepto;
        }
        myData.append('addition', JSON.stringify(addition));
      }

      if (additionPriceOn) {
        let additionPrice = {};
        noUScurrencies.forEach((e) => {
          if (formData[e.code + 'Price'] !== '') {
            additionPrice[e.code] = parseFloat(formData[e.code + 'Price']);
            console.log(additionPrice[e.code]);
          }
        });
        if (Object.keys(additionPrice).length !== 0)
          myData.append('additionPrice', JSON.stringify(additionPrice));
      }

      langArray.forEach((e) => {
        if (formData[e.code + 'Name'] !== '') {
          name[e.code] = formData[e.code + 'Name'];
        }
        if (formData[e.code + 'Desc'] !== '') {
          description[e.code] = formData[e.code + 'Desc'];
        }
        if (formData[e.code + 'Title1'] !== '') {
          title1[e.code] = formData[e.code + 'Title1'];
        }
        if (formData[e.code + 'Title2'] !== '') {
          title2[e.code] = formData[e.code + 'Title2'];
        }
        if (formData[e.code + 'Popa'] !== '') {
          pop_alert[e.code] = formData[e.code + 'Popa'];
        }
        if (formData[e.code + 'Ingre'] !== '') {
          ingredients[e.code] = formData[e.code + 'Ingre'];
        }
        if (formData[e.code + 'Aller'] !== '') {
          allergens[e.code] = formData[e.code + 'Aller'];
        }
        if (formData[e.code + 'Detail'] !== '') {
          detail[e.code] = formData[e.code + 'Detail'];
        }
        if (theme1) {
          if (formData[e.code + 'Key'] !== '') {
            keyFeatures[e.code] = formData[e.code + 'Key'];
          }
          if (formData[e.code + 'Spec'] !== '') {
            specification[e.code] = formData[e.code + 'Spec'];
          }
          if (formData[e.code + 'Include'] !== '') {
            include[e.code] = formData[e.code + 'Include'];
          }
        }
      });
      myData.append('name', JSON.stringify(name));
      myData.append('description', JSON.stringify(description));
      myData.append('title1', JSON.stringify(title1));
      myData.append('title2', JSON.stringify(title2));
      myData.append('pop_alert', JSON.stringify(pop_alert));
      myData.append('detail', JSON.stringify(detail));

      if (theme1) {
        myData.append('keyFeatures', JSON.stringify(keyFeatures));
        myData.append('specification', JSON.stringify(specification));
        myData.append('include', JSON.stringify(include));
      }
      myData.append('ingredients', JSON.stringify(ingredients));
      myData.append('allergens', JSON.stringify(allergens));
      myData.append('tax_type', formData.tax_type);
      myData.append('externalCode', formData.externalCode);

      myData.append('referTo', id);
      myData.append('auto', formData.autoTrans);
      if (langs?.length > 0) {
        langs.forEach((e) => {
          myData.append('langs', e);
        });
      }
      myData.append('upc', formData.upc);
      myData.append('price', formData.price);
      if (formData.costPrice) {
        myData.append('costPrice', formData.costPrice);
      }
      if (category.length > 0 || category.length !== '') {
        category.forEach((e) => {
          myData.append('category', e);
        });
      } else myData.append('category', null);
      myData.append('brand', formData.brand);

      myData.append('pp', watchAllFields.ppRadio);
      if (watchAllFields.ppRadio == 'prod') {
        myData.append('length', formData.length);
        myData.append('width', formData.width);
        myData.append('height', formData.height);
      }
      if (fileNames.image1) myData.append('preImage1', fileNames.image1);
      if (fileNames.image2) myData.append('preImage2', fileNames.image2);
      if (fileNames.image3) myData.append('preImage3', fileNames.image3);
      if (fileNames.video) myData.append('preVideo', fileNames.video);

      if (formData.image1 && formData.image1.length > 0)
        myData.append('image1', formData.image1[0]);
      if (formData.image2 && formData.image2.length > 0)
        myData.append('image2', formData.image2[0]);
      if (formData.image3 && formData.image3.length > 0)
        myData.append('image3', formData.image3[0]);
      if (formData.video && formData.video.length > 0) myData.append('video', formData.video[0]);
      // for (var value of myData.values()) {
      //     console.log(value);
      // }

      request({
        method: 'put',
        url: `/products/edit/${prod._id}`,
        data: myData,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setPercent(percent);
          }
        },
      })
        .then((res) => {
          setPercent(100, () => {
            setTimeout(() => {
              setPercent(0);
            }, 1000);
          });
          Swal.fire({
            text: `New product has been successfully updated`,
            icon: 'success',
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 406) {
              setError('category', {
                message: err.response.data.message,
              });
            }
          }
        });
    }
  };

  return (
    <Modal {...rest} centered scrollable className="mt-5 myModal" size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {watchAllFields.ppRadio == 'prod' ? 'Update The Product' : 'Update The Plan'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main">
          <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={1}>
                {/* <Grid item xs={12} style={{padding: '0 8px'}}>
                <RadioGroup row name="ppRadio"
                {...register("ppRadio1", {onChange: (e) =>{ setValue('ppRadio', e.target.value)}})}
                >
                  <FormControlLabel value="prod" control={<Radio />} label="Product" checked={watchAllFields.ppRadio == 'prod'} />
                  <FormControlLabel value="plan" control={<Radio />} label="Plan" checked={watchAllFields.ppRadio == 'plan'} />
                </RadioGroup>
                </Grid> */}
                <Grid item xs={advanced ? 7 : 12}>
                  <Controller
                    render={({ field }) => (
                      <Select fullWidth defaultValue="en" {...field} variant="outlined">
                        {enArray.map((e) => {
                          return <MenuItem value={e.code}>{e.name}</MenuItem>;
                        })}
                      </Select>
                    )}
                    name="select"
                    control={control}
                  />
                </Grid>
                {advanced && (
                  <Grid
                    item
                    xs={5}
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      fontSize: '16px',
                    }}
                  >
                    <Controller
                      name="autoTrans"
                      control={control}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                    <span>Auto Translate</span>
                  </Grid>
                )}
                <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label={`Name (Max ${theme1 ? TW_NAME : DF_NAME} Characters)`}
                        variant="outlined"
                        inputProps={
                          theme1
                            ? {
                                maxLength: TW_NAME,
                              }
                            : {
                                maxLength: DF_NAME,
                              }
                        }
                        {...field}
                      />
                    )}
                    name="name"
                    control={control}
                    rules={{
                      required: 'Product Name is required',
                      validate: () => {
                        if (getValues('name').includes(',')) {
                          return 'Product Name cannot contain comma';
                        }
                      },
                    }}
                  />
                  {errors.name && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.name.message}</p>
                  )}
                </Grid>
                {langArray.map((e) => {
                  return (
                    <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                      <Controller
                        name={e.code + 'Name'}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            label={`Name (Max ${theme1 ? TW_NAME : DF_NAME} Characters)`}
                            variant="outlined"
                            inputProps={
                              theme1
                                ? {
                                    maxLength: TW_NAME,
                                  }
                                : {
                                    maxLength: DF_NAME,
                                  }
                            }
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  );
                })}

                <Grid item container spacing={1}>
                  <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label={`Details (Max ${theme1 ? TW_DETAIL : DF_DETAIL} Characters)`}
                          variant="outlined"
                          inputProps={
                            theme1
                              ? {
                                  maxLength: TW_DETAIL,
                                }
                              : {
                                  maxLength: DF_DETAIL,
                                }
                          }
                          multiline
                          maxRows={3}
                          {...field}
                        />
                      )}
                      name="detail"
                      control={control}
                    />
                  </Grid>
                  {errors.detail && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.detail.message}</p>
                  )}

                  {langArray.map((e) => {
                    return (
                      <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                        <Controller
                          name={e.code + 'Detail'}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              label={`Details (Max ${theme1 ? TW_DETAIL : DF_DETAIL} Characters)`}
                              variant="outlined"
                              inputProps={
                                theme1
                                  ? {
                                      maxLength: TW_DETAIL,
                                    }
                                  : {
                                      maxLength: DF_DETAIL,
                                    }
                              }
                              multiline
                              maxRows={3}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Description"
                        variant="outlined"
                        inputProps={{
                          maxLength: MAXLENGTH,
                        }}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        maxRows={6}
                        {...field}
                      />
                    )}
                    name="description"
                    rules={{ required: 'Description is required' }}
                    control={control}
                  />
                </Grid>
                {errors.description && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.description.message}</p>
                )}

                {langArray.map((e) => {
                  return (
                    <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                      <Controller
                        name={e.code + 'Desc'}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label="Description"
                            variant="outlined"
                            inputProps={{
                              maxLength: MAXLENGTH,
                            }}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            maxRows={6}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  );
                })}

                {watchAllFields.ppRadio == 'prod' && theme1 && (
                  <Grid item container rowSpacing={1.5} xs={12}>
                    <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label="Key Features(Separate by ; for exmaple: feature1;feature2;feature3)"
                            variant="outlined"
                            inputProps={{
                              maxLength: MAXLENGTH,
                            }}
                            multiline
                            maxRows={3}
                            {...field}
                          />
                        )}
                        name="keyFeatures"
                        control={control}
                      />
                    </Grid>
                    {errors.keyFeatures && (
                      <p style={{ color: 'red', marginLeft: '8px' }}>
                        {errors.keyFeatures.message}
                      </p>
                    )}
                    {langArray.map((e) => {
                      return (
                        <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                          <Controller
                            name={e.code + 'Key'}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label="Key Features(Separate by ; for exmaple: feature1;feature2;feature3)"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                  maxLength: MAXLENGTH,
                                }}
                                multiline
                                maxRows={3}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      );
                    })}

                    <Grid item xs={12}>
                      <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              label="Specification (Separate by ; for exmaple: feature1;feature2;feature3)"
                              variant="outlined"
                              inputProps={{
                                maxLength: MAXLENGTH,
                              }}
                              multiline
                              maxRows={3}
                              {...field}
                            />
                          )}
                          name="specification"
                          control={control}
                        />
                      </Grid>
                      {errors.specification && (
                        <p style={{ color: 'red', marginLeft: '8px' }}>
                          {errors.specification.message}
                        </p>
                      )}
                      {langArray.map((e) => {
                        return (
                          <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                            <Controller
                              name={e.code + 'Spec'}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  label="Specification (Separate by ; for exmaple: feature1;feature2;feature3)"
                                  variant="outlined"
                                  inputProps={{
                                    maxLength: MAXLENGTH,
                                  }}
                                  multiline
                                  maxRows={3}
                                  fullWidth
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>

                    <Grid item xs={12}>
                      <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              label="Includes (Separate by ; for exmaple: feature1;feature2;feature3)"
                              variant="outlined"
                              inputProps={{
                                maxLength: MAXLENGTH,
                              }}
                              multiline
                              maxRows={3}
                              {...field}
                            />
                          )}
                          name="include"
                          control={control}
                        />
                      </Grid>
                      {errors.include && (
                        <p style={{ color: 'red', marginLeft: '8px' }}>{errors.include.message}</p>
                      )}
                      {langArray.map((e) => {
                        return (
                          <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                            <Controller
                              name={e.code + 'Include'}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  label="Includes (Separate by ; for exmaple: feature1;feature2;feature3)"
                                  variant="outlined"
                                  inputProps={{
                                    maxLength: MAXLENGTH,
                                  }}
                                  multiline
                                  maxRows={3}
                                  fullWidth
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}

                {watchAllFields.ppRadio == 'prod' && (
                  <Grid item xs={12} container spacing={1}>
                    <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label="Title 1"
                            variant="outlined"
                            inputProps={{
                              maxLength: TITLELENGTH,
                            }}
                            InputLabelProps={{ shrink: true }}
                            {...field}
                          />
                        )}
                        name="title1"
                        control={control}
                      />
                    </Grid>
                    {errors.title1 && (
                      <p style={{ color: 'red', marginLeft: '8px' }}>{errors.title1.message}</p>
                    )}
                    {langArray.map((e) => {
                      return (
                        <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                          <Controller
                            name={e.code + 'Title1'}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label="Title 1"
                                variant="outlined"
                                inputProps={{
                                  maxLength: MAXLENGTH,
                                }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      );
                    })}

                    <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label={'Extra Info 1'}
                            variant="outlined"
                            inputProps={{
                              maxLength: MAXLENGTH,
                            }}
                            multiline
                            maxRows={3}
                            {...field}
                          />
                        )}
                        name="ingredients"
                        control={control}
                      />
                    </Grid>
                    {errors.ingredients && (
                      <p style={{ color: 'red', marginLeft: '8px' }}>
                        {errors.ingredients.message}
                      </p>
                    )}
                    {langArray.map((e) => {
                      return (
                        <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                          <Controller
                            name={e.code + 'Ingre'}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label={'Extra Info 1'}
                                variant="outlined"
                                inputProps={{
                                  maxLength: MAXLENGTH,
                                }}
                                multiline
                                maxRows={3}
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      );
                    })}

                    <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label="Title 2"
                            variant="outlined"
                            inputProps={{
                              maxLength: TITLELENGTH,
                            }}
                            InputLabelProps={{ shrink: true }}
                            {...field}
                          />
                        )}
                        name="title2"
                        control={control}
                      />
                    </Grid>
                    {errors.title2 && (
                      <p style={{ color: 'red', marginLeft: '8px' }}>{errors.title2.message}</p>
                    )}
                    {langArray.map((e) => {
                      return (
                        <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                          <Controller
                            name={e.code + 'Title2'}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label="Title 2"
                                variant="outlined"
                                inputProps={{
                                  maxLength: MAXLENGTH,
                                }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      );
                    })}

                    <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label={'Extra Info 2'}
                            variant="outlined"
                            inputProps={{
                              maxLength: MAXLENGTH,
                            }}
                            multiline
                            maxRows={3}
                            {...field}
                          />
                        )}
                        name="allergens"
                        control={control}
                      />
                    </Grid>
                    {errors.allergens && (
                      <p style={{ color: 'red', marginLeft: '8px' }}>{errors.allergens.message}</p>
                    )}
                    {langArray.map((e) => {
                      return (
                        <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                          <Controller
                            name={e.code + 'Aller'}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label={'Extra Info 2'}
                                variant="outlined"
                                inputProps={{
                                  maxLength: MAXLENGTH,
                                }}
                                multiline
                                maxRows={3}
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}

                <Grid item xs={12} hidden={watchAllFields.select !== 'en'}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Pop Alert"
                        variant="outlined"
                        inputProps={{
                          maxLength: MAXLENGTH,
                        }}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        maxRows={6}
                        {...field}
                      />
                    )}
                    name="pop_alert"
                    control={control}
                  />
                </Grid>
                {errors.pop_alert && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.pop_alert.message}</p>
                )}

                {langArray.map((e) => {
                  return (
                    <Grid item xs={12} hidden={watchAllFields.select !== e.code}>
                      <Controller
                        name={e.code + 'Popa'}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label="Pop Alert"
                            variant="outlined"
                            inputProps={{
                              maxLength: MAXLENGTH,
                            }}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            maxRows={6}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {additionPriceOn && (
                  <Grid item xs={12}>
                    <Controller
                      render={({ field }) => (
                        <Select
                          fullWidth
                          defaultValue="USD"
                          {...field}
                          variant="outlined"
                          disabled={!additionPriceOn}
                        >
                          {currencies.map((e) => {
                            return (
                              <MenuItem value={e.code}>{e.name + ' (' + e.code + ')'}</MenuItem>
                            );
                          })}
                        </Select>
                      )}
                      name="selectPrice"
                      control={control}
                    />
                  </Grid>
                )}

                <Grid item xs={6} hidden={watchAllFields.selectPrice !== 'USD'}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Product Price"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        inputProps={{
                          step: '0.01',
                          min: '0',
                        }}
                        {...field}
                      />
                    )}
                    name="price"
                    control={control}
                    rules={{ required: 'Price is required', min: 0 }}
                  />
                  {errors.price && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.price.message}</p>
                  )}
                </Grid>

                {additionPriceOn &&
                  noUScurrencies.map((e) => {
                    return (
                      <Grid item xs={6} hidden={watchAllFields.selectPrice !== e.code}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              label="Product Price"
                              variant="outlined"
                              type="number"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currentSymbol()}
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{
                                step: '0.01',
                                min: '0',
                              }}
                              {...field}
                            />
                          )}
                          name={e.code + 'Price'}
                          control={control}
                          rules={{ min: 0 }}
                        />
                      </Grid>
                    );
                  })}

                <Grid item xs={6} hidden={watchAllFields.selectPrice !== 'USD'}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Cost Price"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">{currentSymbol()}</InputAdornment>
                          ),
                        }}
                        inputProps={{
                          step: '0.01',
                          min: '0',
                        }}
                        {...field}
                      />
                    )}
                    name="costPrice"
                    control={control}
                  />
                  {errors.costPrice && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.costPrice.message}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="External Code"
                        variant="outlined"
                        inputProps={{
                          maxLength: UPC,
                        }}
                        {...field}
                      />
                    )}
                    name="externalCode"
                    control={control}
                  />
                  {errors.externalCode && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.externalCode.message}</p>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        disabled
                        fullWidth
                        label="UPC/SKU"
                        variant="outlined"
                        inputProps={{
                          maxLength: UPC,
                        }}
                        {...field}
                      />
                    )}
                    name="upc"
                    control={control}
                    rules={{ required: 'Upc is required' }}
                  />
                  {errors.upc && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.upc.message}</p>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name="brand"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        // value={v1}
                        // onChange={(e) => setV1(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        select
                        label="Brand"
                        fullWidth
                        variant="outlined"
                      >
                        <MenuItem value={''}>
                          <em>None</em>
                        </MenuItem>
                        {brands.length > 0 &&
                          brands.map((e) => {
                            return (
                              <MenuItem key={e._id} value={e._id}>
                                {e.name.en}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    )}
                  />
                  {errors.brand && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.brand.message}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={categories}
                        value={watchAllFields.category}
                        disableCloseOnSelect
                        onChange={(event, value) => {
                          field.onChange(value);
                        }}
                        getOptionSelected={(option, value) => option._id === value._id}
                        getOptionLabel={(option) => option.name.en}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name.en}
                          </React.Fragment>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Categories" />
                        )}
                      />
                    )}
                  />
                </Grid>
                {errors.category && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.category.message}</p>
                )}

                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <Select fullWidth defaultValue="0" {...field} variant="outlined">
                        <MenuItem value="0">Type A</MenuItem>
                        <MenuItem value="1">Type B</MenuItem>
                        <MenuItem value="2">Type C</MenuItem>
                        <MenuItem value="3">Type D</MenuItem>
                      </Select>
                    )}
                    name="tax_type"
                    control={control}
                  />
                </Grid>

                {watchAllFields.ppRadio == 'prod' && (
                  <Grid container spacing={2} item>
                    <Grid item xs={12}>
                      <p style={{ marginBottom: '-2px' }}>Dimensions (cm)</p>
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            // disabled={extra || user?.dba ? false : true}
                            fullWidth
                            label="Width"
                            variant="outlined"
                            type="number"
                            inputProps={{
                              step: '0.1',
                              min: '0',
                            }}
                            {...field}
                          />
                        )}
                        name="length"
                        rules={{ required: `Length is required` }}
                        control={control}
                      />
                      {errors.length && (
                        <p style={{ color: 'red', marginLeft: '8px' }}>{errors.length.message}</p>
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            // disabled={extra || user?.dba ? false : true}
                            fullWidth
                            label="Depth"
                            variant="outlined"
                            type="number"
                            inputProps={{
                              step: '0.1',
                              min: '0',
                            }}
                            {...field}
                          />
                        )}
                        name="width"
                        rules={{ required: 'Width is required' }}
                        control={control}
                      />
                      {errors.width && (
                        <p style={{ color: 'red', marginLeft: '8px' }}>{errors.width.message}</p>
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      <Controller
                        name="height"
                        rules={{ required: 'height is required' }}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            // disabled={extra || user?.dba ? false : true}
                            label="Height"
                            variant="outlined"
                            type="number"
                            inputProps={{
                              step: '0.1',
                              min: '0',
                            }}
                          />
                        )}
                      />
                      {errors.height && (
                        <p style={{ color: 'red', marginLeft: '8px' }}>{errors.height.message}</p>
                      )}
                    </Grid>
                  </Grid>
                )}

                {theme1 && (
                  <Grid container spacing={1} item>
                    <Grid
                      item
                      xs={12}
                      onClick={() => {
                        setArrow(!arrow);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <p style={{ marginBottom: '-2px' }}>Additional Fields (Click to show)</p>
                        {!arrow ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                      </div>
                    </Grid>
                    {arrow && (
                      <Grid container spacing={1} item>
                        <Grid item xs={4}>
                          <Controller
                            name="id_familia"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                type="number"
                                label="id_familia"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="id_subfamilia"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                type="number"
                                label="id_subfamilia"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="subconcepto"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="subconcepto"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <p style={{ marginBottom: '-2px', whiteSpace: 'pre-wrap' }}>
                    {`Files:\nAcceptable formats: jpg, png, and mp4 \nAspect ratio: 220 px x 170 px or bigger \nMax. Size: 5 MB for images 25MB for videos`}
                  </p>
                </Grid>

                <Grid item xs={12}>
                  <div className="d-flex align-items-center" style={{ height: '48px' }}>
                    <Grid item xs={3}>
                      <Button variant="contained" component="label">
                        Image 1
                        <input
                          {...register('image1', {
                            onChange: (e) => {
                              clearErrors('image1');
                              setPercent(0);
                              fileNames.image1 = null;
                              if (e.target.files.length > 0 && e.target.files[0].size > 5000000) {
                                setError('image1', {
                                  message: 'File size too big, please provide the right size.',
                                });
                              }
                            },
                          })}
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          hidden
                        />
                      </Button>
                    </Grid>
                    <Grid className="d-flex align-items-center" item xs={8}>
                      <span className="product-fileName-span">
                        {watchAllFields.image1 && watchAllFields.image1.length > 0
                          ? watchAllFields.image1[0].name
                          : fileNames.image1
                          ? fileNames.image1
                          : null}
                      </span>
                    </Grid>
                    {(fileNames.image1 ||
                      (watchAllFields.image1 && watchAllFields.image1.length > 0)) && (
                      <IconButton onClick={() => handleRemove('image1')}>
                        <DeleteForeverOutlined />
                      </IconButton>
                    )}
                  </div>
                </Grid>
                {errors.image1 && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.image1.message}</p>
                )}

                <Grid item xs={12}>
                  <div className="d-flex align-items-center" style={{ height: '48px' }}>
                    <Grid item xs={3}>
                      <Button variant="contained" component="label">
                        Image 2
                        <input
                          {...register('image2', {
                            onChange: (e) => {
                              clearErrors('image2');
                              setPercent(0);
                              fileNames.image2 = null;
                              if (e.target.files.length > 0 && e.target.files[0].size > 5000000) {
                                setError('image2', {
                                  message: 'File size too big, please provide the right size.',
                                });
                              }
                            },
                          })}
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          hidden
                        />
                      </Button>
                    </Grid>
                    <Grid className="d-flex align-items-center" item xs={8}>
                      <span className="product-fileName-span">
                        {watchAllFields.image2 && watchAllFields.image2.length > 0
                          ? watchAllFields.image2[0].name
                          : fileNames.image2
                          ? fileNames.image2
                          : null}
                      </span>
                    </Grid>
                    {(fileNames.image2 ||
                      (watchAllFields.image2 && watchAllFields.image2.length > 0)) && (
                      <IconButton onClick={() => handleRemove('image2')}>
                        <DeleteForeverOutlined />
                      </IconButton>
                    )}
                  </div>
                </Grid>
                {errors.image2 && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.image2.message}</p>
                )}

                <Grid item xs={12}>
                  <div className="d-flex align-items-center" style={{ height: '48px' }}>
                    <Grid item xs={3}>
                      <Button variant="contained" component="label">
                        Image 3
                        <input
                          {...register('image3', {
                            onChange: (e) => {
                              clearErrors('image3');
                              setPercent(0);
                              fileNames.image3 = null;
                              if (e.target.files.length > 0 && e.target.files[0].size > 5000000) {
                                setError('image3', {
                                  message: 'File size too big, please provide the right size.',
                                });
                              }
                            },
                          })}
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          hidden
                        />
                      </Button>
                    </Grid>
                    <Grid className="d-flex align-items-center" item xs={8}>
                      <span className="product-fileName-span">
                        {watchAllFields.image3 && watchAllFields.image3.length > 0
                          ? watchAllFields.image3[0].name
                          : fileNames.image3
                          ? fileNames.image3
                          : null}
                      </span>
                    </Grid>
                    {(fileNames.image3 ||
                      (watchAllFields.image3 && watchAllFields.image3.length > 0)) && (
                      <IconButton onClick={() => handleRemove('image3')}>
                        <DeleteForeverOutlined />
                      </IconButton>
                    )}
                  </div>
                </Grid>
                {errors.image3 && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.image3.message}</p>
                )}

                <Grid item xs={12}>
                  <div className="d-flex align-items-center" style={{ height: '48px' }}>
                    <Grid item xs={3}>
                      <Button variant="contained" component="label">
                        Video 1
                        <input
                          {...register('video', {
                            onChange: (e) => {
                              clearErrors('video');
                              setPercent(0);
                              fileNames.video = null;
                              if (e.target.files.length > 0 && e.target.files[0].size > 25000000) {
                                setError('video', {
                                  message: 'File size too big, please provide the right size.',
                                });
                              }
                            },
                          })}
                          type="file"
                          accept=".mp4"
                          hidden
                        />
                      </Button>
                    </Grid>
                    <Grid className="d-flex align-items-center" item xs={8}>
                      <span className="product-fileName-span">
                        {watchAllFields.video && watchAllFields.video.length > 0
                          ? watchAllFields.video[0].name
                          : fileNames.video
                          ? fileNames.video
                          : null}
                      </span>
                    </Grid>
                    {(fileNames.video ||
                      (watchAllFields.video && watchAllFields.video.length > 0)) && (
                      <IconButton onClick={() => handleRemove('video')}>
                        <DeleteForeverOutlined />
                      </IconButton>
                    )}
                  </div>
                </Grid>

                {errors.video && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.video.message}</p>
                )}
                <Grid item xs={12}>
                  {percent > 0 && <ProgressBar now={percent} active label={`${percent}%`} />}
                </Grid>
                <Grid item xs={12}>
                  <p style={{ marginBottom: '-2px' }}>
                    Only jpg, png and mp4 formats are accepted!
                  </p>
                </Grid>

                <Button
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!props.access}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

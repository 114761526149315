import update from 'immutability-helper';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { BrandCard } from './BrandCard.js';
import { ItemTypes } from './ItemTypes.js';

const style = {
  width: 'fit-content',
  marginTop: '40px',
  paddingRight: '20px',
};
export const BrandCont = memo(function Container({ data, setData }) {
  console.log();
  const [cards, setCards] = useState(data);
  const findCard = useCallback(
    (id) => {
      const card = cards.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );
  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    [findCard, cards, setCards]
  );
  useEffect(() => {
    setData(cards);
  }, [cards]);
  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
  return (
    <div ref={drop} style={style}>
      {cards.map((card) => (
        <BrandCard
          data={data}
          key={card.id}
          id={`${card.id}`}
          text={card.name}
          moveCard={moveCard}
          findCard={findCard}
        />
      ))}
    </div>
  );
});

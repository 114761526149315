import React, { useState, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { UserContext } from '../contexts/userContext';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useForm } from 'react-hook-form';
import axios from 'axios';

import './form.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const loginURL = `${process.env.REACT_APP_SERVER_API}/users/login`;

  const {
    setUserName,
    setLoginStatus,
    setRole,
    setUserEmail,
    loginStatus,
    setReferTo,
    setFullAccess,
    setUser,
  } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (data) => {
    const email = getValues('email');
    const password = getValues('password');

    await axios
      .post(
        loginURL,
        {
          password: password,
          email: email.toLowerCase(),
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        const user = res.data.result;
        const check1 =
          user.role === 'SuperAdmin' ||
          user.role === 'Admin' ||
          (user.role === 'User' && user.fullAccess)
            ? true
            : false;

        const limits = {
          edit_user: check1 || user.limits.edit_user ? true : false,
          edit_group: check1 || user.limits.edit_group ? true : false,
          view_machine: check1 || user.limits.view_machine ? true : false,
          edit_machine: check1 || user.limits.edit_machine ? true : false,
          view_plano: check1 || user.limits.view_plano ? true : false,
          edit_plano: check1 || user.limits.edit_plano ? true : false,
          view_ss: check1 || user.limits.view_ss ? true : false,
          edit_ss: check1 || user.limits.edit_ss ? true : false,
          view_prod: check1 || user.limits.view_prod ? true : false,
          edit_prod: check1 || user.limits.edit_prod ? true : false,
          view_cate: check1 || user.limits.view_cate ? true : false,
          edit_cate: check1 || user.limits.edit_cate ? true : false,
          view_brand: check1 || user.limits.view_brand ? true : false,
          edit_brand: check1 || user.limits.edit_brand ? true : false,
          view_receipt: check1 || user.limits.view_receipt ? true : false,
          edit_receipt: check1 || user.limits.edit_receipt ? true : false,
          view_promo: check1 || user.limits.view_promo ? true : false,
          edit_promo: check1 || user.limits.edit_promo ? true : false,
          view_trans: check1 || user.limits.view_trans ? true : false,
          view_report: check1 || user.limits.view_report ? true : false,
        };

        localStorage.setItem('user', JSON.stringify(res.data.result));
        localStorage.setItem('limits', JSON.stringify(limits));

        const fullName = res.data.result.firstname + ' ' + res.data.result.lastname;
        setLoginStatus(true);
        setUserName(fullName);
        setUserEmail(res.data.result.email);
        setRole(res.data.result.role);
        setReferTo(res.data.result.referTo);
        setFullAccess(res.data.result.fullAccess);
        setUser(res.data.result);
        window.location.reload();
        // window.location.reload();
        // const Toast = Swal.mixin({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   timerProgressBar: true,
        //   didOpen: (toast) => {
        //     toast.addEventListener('mouseenter', Swal.stopTimer)
        //     toast.addEventListener('mouseleave', Swal.resumeTimer)
        //   }
        // })
        // Toast.fire({
        //   icon: 'success',
        //   title: 'Signed in successfully'
        // })
      })
      .catch((err) => {
        if (err) {
          setLoginStatus(false);
          setUserName('');
          if (err.response) {
            setError('password', {
              message: err.response.data.message,
            });
          }
        }
      });
  };

  if (loginStatus === true) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              {...register('email', {
                required: 'Email name is required',
                pattern: {
                  value:
                    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                  message: 'Please enter a valid email',
                },
              })}
            />
            {errors.email && <p className="signUpErrorMessage">{errors.email.message}</p>}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...register('password', {
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must longer than 8',
                },
              })}
            />
            {errors.password && <p className="signUpErrorMessage">{errors.password.message}</p>}
            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgetpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              {/* <Grid item>
              <Link href="/signUp" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
            </Grid>
          </form>
          {/* {loginStatus &&           
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => requestAccessToken()}
          >
            Is Auth
          </Button> }
          {loginStatus &&           
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => userAutheticated()}
          >
            Is Auth
          </Button> } */}
        </div>
      </Container>
    );
  }
}

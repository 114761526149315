import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Swal from 'sweetalert2';
import { FormLabel } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import request from '../../services/Request';
import '../user/form.css';
import { Modal } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const updateAdress = '/machines/updateAdress';

export default function AddressModal(props) {
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});
  const classes = useStyles();
  const { data, user, ...rest } = props;

  const defaultValues = {
    location: data[0].address?.location ? data[0].address?.location : '',
    street: data[0].address?.street ? data[0].address?.street : '',
    city: data[0].address?.city ? data[0].address?.city : '',
    province: data[0].address?.province ? data[0].address?.province : '',
    postal_code: data[0].address?.postal_code ? data[0].address?.postal_code : '',
    country: data[0].address?.country ? data[0].address?.country : '',
    phone: data[0].address?.phone ? data[0].address?.phone : '',
  };

  useEffect(async () => {
    reset(defaultValues);
  }, [data]);

  const onSubmit = async (formData) => {
    const address = {
      location: formData.location,
      street: formData.street,
      city: formData.city,
      province: formData.province,
      postal_code: formData.postal_code,
      country: formData.country,
      phone: formData.phone,
    };
    await request({
      method: 'post',
      url: updateAdress,
      data: {
        machineID: data.map((e) => e._id),
        address: address,
      },
    }).then((res) => {
      Swal.fire({
        text: `Your changes has been updated!`,
        icon: 'success',
      }).then(() => {
        props.reflesh ? props.setReflesh(false) : props.setReflesh(true);
        props.onHide();
      });
    });
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.2rem' }}>
          Update Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlignLast: 'center' }}>
                  <FormLabel component="legend">Address</FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Location (optional)"
                    name="location"
                    inputProps={{
                      maxLength: '100',
                    }}
                    {...register('location', {})}
                  />
                  {errors.location && (
                    <p className="signUpErrorMessage">{errors.location.message}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Street"
                    name="street"
                    inputProps={{
                      maxLength: '100',
                    }}
                    {...register('street', {})}
                  />
                  {errors.street && <p className="signUpErrorMessage">{errors.street.message}</p>}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="City"
                    name="city"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('city', {})}
                  />
                  {errors.city && <p className="signUpErrorMessage">{errors.city.message}</p>}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Province/State(Abbr)"
                    name="province"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('province', {})}
                  />
                  {errors.city && <p className="signUpErrorMessage">{errors.city.message}</p>}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Postal/Zip Code"
                    name="postal_code"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('postal_code', {})}
                  />
                  {errors.postal_code && (
                    <p className="signUpErrorMessage">{errors.postal_code.message}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Country"
                    name="country"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('country', {})}
                  />
                  {errors.country && <p className="signUpErrorMessage">{errors.country.message}</p>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('phone', {})}
                  />
                  {errors.phone && <p className="signUpErrorMessage">{errors.phone.message}</p>}
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Update
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

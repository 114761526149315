import './card.css';
import { useState } from 'react';
import DescCard from './descCard';
import UpdateProduct from './UpdateProduct';
import TrashBinModal from './TrashBinModal';
import ProductInfo from '../dashboard/ProductInfo';
import { userLimits } from '../dashboard/uitls/userLimits';
import { imageOnError } from '../../services/method/methods';

function ProductCard(props) {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [informationShow, setInformationShow] = useState(false);

  const { superAdmin, access } = userLimits('', '', 'Products', 'edit_prod');

  const backgroundStyle = {
    backgroundColor: props.prodType == 'prod' ? '#3f51b5' : '#ffc107',
    color: 'white',
  };

  const cursorStyle = {
    cursor: 'pointer',
  };

  const style =
    window.location.pathname.includes('/dashboard') ||
    window.location.pathname.includes('/brand') ||
    window.location.pathname.includes('/viewmymachines') ||
    window.location.pathname.includes('/viewmyusers');
  //calculate price
  let discountedPrice = 0;
  if (props.discount !== 0) {
    discountedPrice =
      props.percentage === true
        ? props.priceD * (1 - props.discount)
        : props.priceD - props.discount;
  }

  if (style) {
    return (
      <div
        id={`product-${props.id}`}
        className="col-xs-6 col-sm-6 col-md-6 col-lg-4 mb-5 cardBorder"
      >
        {access && (
          <div>
            <button
              onClick={(e) => {
                setShow(true);
                e.stopPropagation();
              }}
              className="delete_btn"
            >
              <span className="iconify" data-icon="mdi-trash-can" data-inline="false"></span>
            </button>
          </div>
        )}
        <UpdateProduct
          access={access}
          id={props.id}
          name={props.name}
          upc={props.upc}
          description={props.description}
          category={props.category}
          price={props.price}
          video={props.video}
          brand={props.brand}
          quantity={props.quantity}
          referTo={props.referTo}
          show={updateShow}
          onHide={() => setUpdateShow(false)}
        />
        <TrashBinModal
          id={props.id}
          name={props.name}
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
        <div style={cursorStyle} onClick={() => setInformationShow(true)} className="cardBorder">
          <div style={{ width: '100%', height: '200px' }}>
            <img
              loading="lazy"
              onError={imageOnError}
              style={{ maxWidth: '95%', maxHeight: '95%' }}
              alt="product"
              src={`${process.env.REACT_APP_SERVER_API}/pictures/${props.image[0]}/${props.referTo}`}
            />
          </div>
          <div className="card-body prodbody" style={backgroundStyle}>
            <button
              onClick={(e) => {
                setUpdateShow(true);
                e.stopPropagation();
              }}
              className="edit_btn"
            >
              <span className="iconify" data-icon="akar-icons:edit" data-inline="false"></span>
            </button>
            <h5 className="card-title d-flex flex-column justify-content-center">
              <div className="showh5">{props.name.en}</div>
            </h5>
            <h5 className="card-text d-flex justify-content-center">{props.price}</h5>
            {superAdmin && (
              <h6>
                Refer to{' '}
                <span
                  style={{
                    color: 'yellow',
                  }}
                >
                  {props.referTo}
                </span>
              </h6>
            )}
          </div>
        </div>
        <ProductInfo
          id={props.id}
          name={props.name}
          description={props.description}
          category={props.category}
          price={props.price}
          image={props.image}
          brand={props.brand}
          video={props.video}
          quantity={props.quantity}
          referTo={props.referTo}
          show={informationShow}
          onHide={() => setInformationShow(false)}
        />
      </div>
    );
  } else {
    return (
      <div
        id={`product-${props.id}`}
        className="col-xs-6 col-sm-6 col-md-6 col-lg-4 mb-5 cardBorder"
      >
        <button onClick={() => setModalShow(true)}>
          <div className="cardBorder">
            <div style={{ width: '100%', height: '200px' }}>
              <img
                loading="lazy"
                onError={imageOnError}
                style={{ maxWidth: '95%', maxHeight: '95%' }}
                alt="product"
                // src={`${process.env.REACT_APP_SERVER_API}/pictures/${props.image[0]}/${props.referTo}`}
              />
            </div>
            <div className="card-body prodbody">
              <h5 className="card-title d-flex justify-content-center">{props.name}</h5>
              {!props.discount ? (
                <h5 className="card-text cardtext d-flex justify-content-center">{props.price}</h5>
              ) : (
                <h5
                  className="card-text cardtext d-flex justify-content-center"
                  style={{ textDecoration: 'line-through' }}
                >
                  {props.price}
                </h5>
              )}
              {discountedPrice !== 0 && (
                <h5 className="card-text cardtext d-flex justify-content-center">
                  {'$ ' + discountedPrice}
                </h5>
              )}
              {props.buyX && (
                <h5 className="card-text cardtext d-flex justify-content-center">
                  {'Buy ' + props.buyX + ' Get'}
                </h5>
              )}
              {props.discount !== 0 && props.percentage === true && (
                <h5 className="card-text cardtext d-flex justify-content-center">
                  {props.discount * 100 + '% off!'}
                </h5>
              )}
              {props.discount !== 0 && props.percentage === false && (
                <h5 className="card-text cardtext d-flex justify-content-center">
                  {'$' + props.discount + ' off!'}
                </h5>
              )}
            </div>
          </div>
        </button>
        <DescCard
          show={modalShow}
          onHide={() => setModalShow(false)}
          stock={props.stock}
          id={props.id}
          key={props.id}
          description={props.description}
          name={props.name}
          price={props.price}
          priceD={props.priceD}
          discount={props.discount}
          image={props.image}
          category={props.category}
          add={props.add}
          alterquantity={props.alterquantity}
          cart={props.cart}
          percentage={props.percentage}
          discountedPrice={discountedPrice}
          buyX={props.buyX}
        />
      </div>
    );
  }
}

export default ProductCard;

import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import request from '../../services/Request';
import { Grid } from '@material-ui/core';
import TotalRev from './report/Cards/TotalRev';
import UnitSold from './report/Cards/UnitSold';
import TotalTrans from './report/Cards/TotalTrans';

// Generate Sales Data
// function createData(time, amount) {
//   return { time, amount };
// }

// const data = [
//   createData('00:00', 0),
//   createData('03:00', 300),
//   createData('06:00', 600),
//   createData('09:00', 800),
//   createData('12:00', 1500),
//   createData('15:00', 2000),
//   createData('18:00', 2400),
//   createData('21:00', 2400),
//   createData('24:00', undefined),
// ];

export default function Chart(props) {
  const useremail = JSON.parse(localStorage.getItem('user')).email;
  const { id, view_trans } = props;
  const [data, setData] = useState([0, 0, 0]);

  const date = new Date();
  const today = date.setUTCHours(23, 59, 59, 999);
  const newD = new Date(today);
  const yesterday = newD.setDate(newD.getDate() - 1);
  const dayBefore = newD.setDate(newD.getDate() - 1);
  const finalDate = newD.setDate(newD.getDate() - 1);

  // console.log('today', new Date(today));
  // console.log('yesterday', new Date(yesterday));
  // console.log('dayBefore', new Date(dayBefore));
  // console.log('finalDate', new Date(finalDate));

  useEffect(() => {
    const run = async () => {
      if (view_trans) {
        await request({
          method: 'post',
          url: `/orders/getRecentData`,
          data: {
            email: useremail,
            referTo: id,
            startDate: new Date(finalDate),
            endDate: new Date(today),
          },
        }).then((res) => {
          let pToday = 0;
          let pYesterday = 0;
          let pBefore = 0;
          res.data?.forEach((order) => {
            const orderDate = new Date(order.createdAt);
            if (orderDate > new Date(yesterday)) {
              pToday += order.total;
            } else if (orderDate > new Date(dayBefore)) {
              pYesterday += order.total;
            } else {
              pBefore += order.total;
            }
          });

          console.log('pBefore', pBefore);

          setData([pToday.toFixed(2), pYesterday.toFixed(2), pBefore.toFixed(2)]);
          // setData();
        });
      }
    };
    run();
    return () => {
      setData([]);
    };
  }, [id]);

  // let newOrder = []
  const theme = useTheme();

  return (
    <Grid container item spacing={4} justifyContent={'space-evenly'} className="mt-3 mb-3">
      <Grid item xs={12} sm={6} md={4}>
        <UnitSold title="Today" unit={data[0]} sign={true} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TotalRev title="Yesterday" rev={data[1]} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TotalTrans title="Day Before" trans={data[2]} sign={true} />
      </Grid>
    </Grid>
  );
}

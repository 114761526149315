import { Grid } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { makenum } from '../../../services/randomCharGenerator';
import request from '../../../services/Request';
import '../../../../node_modules/react-quill/dist/quill.snow.css';
import './receipt.css';
import parse from 'html-react-parser';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/en-ca';
import { Thank, Receipt, DateS, Order, Machine, Item, Price, Tax, Discount } from './emailTrans';

export default function EmailPreview(props) {
  const id = props.match.params.id;
  const mid = props.match.params.mid;
  const [date, setDate] = useState(moment(Date()).format('LLLL'));
  const [custom1, setcustom1] = useState('');
  const [custom2, setcustom2] = useState('');
  const [machine, setMachine] = useState(null);
  const [watchAllFields, setwatch] = useState({
    name: '',
    email: '',
    phone: '',
    custom1: '',
    custom2: '',
    company: '',
    country: '',
    street: '',
    city: '',
    province: '',
    postal_code: '',
    website: '',
    user: '',
    password: '',
    port: '',
    host: '',
    language: 'en',
  });
  const [i1, seti1] = useState('https://cdn.logo.com/hotlink-ok/logo-social.png');

  // const watchAllFields = watch();

  useEffect(() => {
    request({
      method: 'get',
      url: '/machines/edit/' + mid,
    }).then((res) => {
      setMachine(res.data.address);
    }, []);

    request({
      method: 'get',
      url: '/receipt/edit/' + id,
    }).then((res) => {
      // console.log(res);
      setwatch({
        name: res.data.name ? res.data.name : '',
        email: res.data.email ? res.data.email : '',
        custom1: res.data.custom1 ? res.data.custom1 : '',
        custom2: res.data.custom2 ? res.data.custom2 : '',
        company: res.data.company ? res.data.company : '',
        country: res.data.country ? res.data.country : '',
        street: res.data.street ? res.data.street : '',
        city: res.data.city ? res.data.city : '',
        province: res.data.province ? res.data.province : '',
        postal_code: res.data.postal_code ? res.data.postal_code : '',
        website: res.data.website ? res.data.website : '',
        logo: res.data.logo ? res.data.logo : '',
        language: res.data.language ? res.data.language : 'en',
      });
      seti1(`${process.env.REACT_APP_SERVER_API}/pictures/${res.data.logo}/${res.data.referTo}`);
      setcustom1(res.data.custom1);
      setcustom2(res.data.custom2);

      if (res.data.language === 'en') {
        moment.locale('en-ca');
      } else {
        moment.locale(res.data.language);
      }
      setDate(moment(Date()).format('LLLL'));
    });
    setDate(moment(new Date()).format('LLLL'));
    return () => {
      setcustom1(null);
      setcustom2(null);
      setwatch(null);
      setMachine(null);
    };
  }, []);

  return (
    <form style={{ width: '100%', margin: '20px 40px' }}>
      <Grid container columnSpacing={4}>
        <Grid container item xs={6} style={{ padding: '20px 40px' }}>
          <div id="my-receipt" style={{ width: '100%' }}>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Email Receipt</title>
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html:
                  '\n      body {\n        margin: 0;\n        padding: 0;\n        background-color: #f6f9fc;\n      }\n      table {\n        border-spacing: 0;\n      }\n      td {\n        padding: 0;\n      }\n      img {\n        border: 0;\n      }\n      .wrapper {\n        width: 100%;\n        table-layout: fixed;\n        background-color: #f6f9fc;\n        padding-bottom:20px;\n        padding-top:20px;\n      }\n      .webkit {\n        padding: 3.237% 6.258%;\n        background-color: #E8E8E8;\n        border: 2px solid #E8E8E8;\n        border-radius: 25px;\n        font-family: Arial, Helvetica, sans-serif;\n      }\n      .outer {\n        Margin: 0 auto;\n        width: 100%;\n        max-width: 600px;\n        border-spacing: 0;\n        line-height: normal;\n      }\n      .info{\n        margin-top: 10px;\n      }\n      hr {\n        margin-top: 10px;\n        margin-bottom: 15px;\n        width: 100%;\n        height: 3px;\n        border: none;\n        background:#787878;\n      }\n      .logo-img{\n         max-height: 100%; \n          max-width: 100%;\n      }\n\n      @media screen and (max-width: 600px) {\n      }\n      @media screen and (max-width: 400px) {\n      }\n    ',
              }}
            />
            <center className="wrapper">
              <div className="webkit" style={{ boxSizing: 'content-box', width: '490px' }}>
                <table className="outer" align="center">
                  <tbody>
                    <tr>
                      <td>
                        <table width="100%" style={{ borderSpacing: 0, float: 'left' }}>
                          <tbody>
                            <tr>
                              <td style={{ float: 'left', width: '50%', height: '120px' }}>
                                <img className="logo-img" src={i1} alt="Logo" title="Logo" />
                              </td>
                              <td
                                style={{
                                  float: 'right',
                                  fontSize: '2.4rem',
                                  fontWeight: 'bold',
                                }}
                              >
                                {Thank[watchAllFields.language]}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <table className="outer" align="center" style={{ marginBottom: '10px' }}>
                  <tbody>
                    <tr>
                      <td style={{ fontSize: '1.8rem', fontWeight: 400 }}>
                        {' '}
                        {Receipt[watchAllFields.language]}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="outer" align="center" style={{ marginBottom: '40px' }}>
                  <tbody>
                    <tr>
                      <td>
                        <span style={{ fontSize: 'large', fontWeight: 700 }}>
                          {DateS[watchAllFields.language]}:{' '}
                          <span style={{ fontWeight: 'normal' }}>{date}</span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span style={{ fontSize: 'large', fontWeight: 700 }}>
                          {Order[watchAllFields.language]}:{' '}
                          <span style={{ fontWeight: 'normal' }}>{makenum(6)}</span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span style={{ fontSize: 'large', fontWeight: 700 }}>
                          {Machine[watchAllFields.language]}ID:{' '}
                          <span style={{ fontWeight: 'normal' }}>{makenum(6)}</span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <table
                  className="outer"
                  align="center"
                  style={{ borderSpacing: '5px 10px', borderCollapse: 'separate' }}
                >
                  <tbody>
                    <tr style={{ fontWeight: 'bold', width: '100%' }}>
                      <td style={{ width: '83%' }}> {Item[watchAllFields.language]}</td>
                      <td style={{ width: '17%', textAlign: 'right' }}>
                        {' '}
                        {Price[watchAllFields.language]}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '83%' }}>Sample 1</td>
                      <td
                        style={{
                          width: '17%',
                          textAlign: 'right',
                          textDecoration: 'line-through',
                        }}
                      >
                        $350.00
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '83%', paddingLeft: '30px' }}>
                        {Discount[watchAllFields.language]}
                      </td>
                      <td style={{ width: '17%', textAlign: 'right' }}>$250.00</td>
                    </tr>
                    <tr>
                      <td style={{ width: '83%' }}>Sample 2</td>
                      <td style={{ width: '17%', textAlign: 'right' }}>$149.99</td>
                    </tr>
                    <tr>
                      <td style={{ width: '83%' }}>Sample 3</td>
                      <td style={{ width: '17%', textAlign: 'right' }}>$199.99</td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="outer"
                  align="center"
                  style={{ borderCollapse: 'separate', borderSpacing: '5px 5px' }}
                >
                  <tbody>
                    <tr>
                      <td style={{ width: '61%' }} />
                      <td style={{ width: '5%', textAlign: 'right' }} />
                      <td style={{ width: '17%', textAlign: 'right' }}>
                        <span style={{ fontWeight: 'bold' }}>Subtotal</span>
                      </td>
                      <td style={{ width: '17%', textAlign: 'right' }}>$599.98</td>
                    </tr>
                    <tr>
                      <td style={{ width: '61%' }} />
                      <td style={{ width: '5%', textAlign: 'right' }} />
                      <td style={{ width: '17%', textAlign: 'right' }}>
                        <span style={{ fontWeight: 'bold' }}> {Tax[watchAllFields.language]}</span>
                      </td>
                      <td style={{ width: '17%', textAlign: 'right' }}>$78.00</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <table
                  className="outer"
                  align="center"
                  style={{ borderCollapse: 'separate', borderSpacing: '5px 0px' }}
                >
                  <tbody>
                    <tr>
                      <td style={{ width: '61%' }} />
                      <td style={{ width: '5%', textAlign: 'right' }} />
                      <td style={{ width: '17%', textAlign: 'right' }}>
                        <span style={{ fontWeight: 'bold' }}>Total</span>
                      </td>
                      <td style={{ width: '17%', textAlign: 'right' }}>$677.98</td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="outer info"
                  style={{ borderCollapse: 'separate', borderSpacing: '0 15px' }}
                >
                  <tbody>
                    {custom1 != '' && custom1 != '<p><br></p>' && (
                      <tr>
                        <td style={{ textAlign: 'center' }}>{parse(custom1)}</td>
                      </tr>
                    )}
                    {custom2 != '' && custom2 != '<p><br></p>' && (
                      <tr>
                        <td style={{ textAlign: 'center' }}>{parse(custom2)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table
                  className="outer"
                  style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 5px',
                    marginTop: '15px',
                  }}
                >
                  <tbody>
                    {machine?.location && machine?.location !== '' && (
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          <strong>{machine?.location}</strong>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        <strong>{watchAllFields.company}</strong>
                      </td>
                    </tr>
                    {machine?.street && machine?.street !== '' && (
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          <span>{machine?.street}</span>
                        </td>
                      </tr>
                    )}
                    {((machine?.city && machine?.city !== '') ||
                      (machine?.province && machine?.province !== '') ||
                      (machine?.postal_code && machine?.postal_code !== '')) && (
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          {machine?.city && machine?.city !== '' && <span>{machine?.city}, </span>}
                          {machine?.province && machine?.province !== '' && (
                            <span>{machine?.province}, </span>
                          )}
                          {machine?.postal_code && machine?.postal_code !== '' && (
                            <span>{machine?.postal_code} </span>
                          )}
                        </td>
                      </tr>
                    )}
                    {machine?.country && machine?.country !== '' && (
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          <span>{machine?.country}</span>
                        </td>
                      </tr>
                    )}
                    {watchAllFields.website != '' && (
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          <a href={watchAllFields.website}>
                            <span>{watchAllFields.website}</span>
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </center>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

// material
import MaterialTable from 'material-table';
import { CloudDownload } from '@material-ui/icons';
import { Email } from '@material-ui/icons';
import request from '../../../services/Request';
import Swal from 'sweetalert2';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import { fShortenNumber } from './utils/formatNumber';
import { fCurrency } from './utils/formatNumber';
import { useEffect } from 'react';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

export default function MaSaleTable(props) {
  const { endDate, startDate, mcSale, machinesName } = props;

  const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
    <Typography
      variant={variant}
      style={{ overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '20px' }}
    >
      {`Machine Sales Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
    </Typography>
  );

  const msSaleTable = () => {
    const newArray = mcSale.map((item) => {
      return {
        Id: item.machineName,
        Description: item.machineDescription,
        'Total Sale': item.totalSale,
        'Total Tax': item.totalTax,
        'Total Cost Price': item.costTotal,
        'Total Profit': parseFloat((item.totalSale - item.costTotal).toFixed(2)),
        'Total Transaction': item.totalTrans,
      };
    });

    // console.log(newArray);

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      useTextFile: false,
      filename: 'Machine Sales Report',
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(newArray);
  };

  const columns = [
    {
      title: 'Machine ID',
      field: 'machineName',
    },
    { title: 'Description', field: 'machineDescription' },
    {
      title: 'Total Sale',
      field: 'totalSale',
      render: (rowData) => `${fCurrency(rowData.totalSale)}`,
    },
    // { title: 'Cost Price', field: 'costPrice',render: (rowData) => `$${(rowData.costPrice / rowData.quantity)}` },
    {
      title: 'Total Tax',
      field: 'totalTax',
      render: (rowData) => `${fCurrency(rowData.totalTax)}`,
    },
    {
      title: 'Total Cost Price',
      field: 'costTotal',
      render: (rowData) => `${fCurrency(rowData.costTotal)}`,
    },
    {
      title: 'Total Profit',
      render: (rowData) => `${fCurrency(rowData.totalSale - rowData.costTotal)}`,
    },
    {
      title: 'Total Transaction',
      field: 'totalTrans',
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={mcSale}
      title={<MyNewTitle variant="h6" />}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100, 200],
      }}
      actions={[
        {
          tooltip: 'Download csv',
          isFreeAction: true,
          icon: CloudDownload,
          onClick: (evt, data) => {
            msSaleTable();
          },
        },
      ]}
    />
  );
}

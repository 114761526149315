import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../frontend/contexts/userContext';

// import { requestAccessToken, userAutheticated } from "./authentication";
// import axios from 'axios';

// const authURL = "http://localhost:8080/api/isUserAuth";

// const PrivateRoute = ({ component: Component, ...rest }) => {

//   const { loginStatus, setLoginStatus } = useContext(UserContext);

// const [auth, setAuth] = useState(false);
// const [isTokenValidated, setIsTokenValidated] = useState(false);

// useEffect(() => {

//   async function fetchAPI() {

//     await requestAccessToken();
//     let token = localStorage.getItem("token");
//     if (token) {
//       await axios.get(authURL, {
//         headers: {
//           'auth-token': localStorage.getItem("token"),
//         }
//       })
//       .then((res) => {
//         setLoginStatus(true);
//       })
//       .catch((err) => {
//         setLoginStatus(false);
//         localStorage.removeItem("token");
//       })
//       .then(() => setIsTokenValidated(true));
//     } else {
//        setIsTokenValidated(true); // in case there is no token
//     }
// }

// fetchAPI();
// }, [])

//  if (isTokenValidated) return <div> token is valid</div>;

//     return (<Route {...rest}
//       render={(props) => {
//         return loginStatus ? <Component {...props} /> : <Redirect to="/signin" />
//       }} />)
// }

// export default PrivateRoute;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loginStatus, role, setLoginStatus } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        return loginStatus ? <Component {...props} /> : <Redirect to="/signin" />;
      }}
    />
  );
};

export default PrivateRoute;

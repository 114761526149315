import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2';

import { Modal, Row, Col } from 'react-bootstrap';

import request from '../../services/Request';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 200,
    maxWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ReceiptModal(props) {
  const classes = useStyles();

  const getSSURL = `/receiptByEmail/`;
  const updatSSURL = `/machines/updateEmail`;
  const { rowData, showAdmin, ...rest } = props;

  const [ss, setSS] = useState([]);
  const [ssid, setSsid] = useState('');
  const [ssErr, setssErr] = useState('');

  useEffect(() => {
    let isCancelled = false;
    const belongTo =
      rowData && rowData.length > 0 ? (rowData[0].belongTo ? rowData[0].belongTo : '') : '';
    if (belongTo != '') {
      setSsid(rowData && rowData.length > 0 ? (rowData[0].receipt ? rowData[0].receipt : '') : '');
      setssErr('');
      //getAllEmployees
      async function getSS() {
        request({
          method: 'get',
          url: getSSURL + belongTo,
        }).then((res) => {
          if (!isCancelled) {
            setSS(res.data);
          }
        });
      }

      getSS();

      return () => {
        isCancelled = true;
      };
    }
  }, [rowData]);

  const onSubmit = async (data) => {
    let finalID = ssid;
    if (ssid === 'None') finalID = '';
    if (showAdmin) {
      request({
        method: 'patch',
        url: updatSSURL,
        data: {
          id: finalID,
          machineID: rowData.map((e) => e._id),
        },
      })
        .then((res) => {
          setssErr('');
          Swal.fire({
            text: `The receipt template has been selected`,
            icon: 'success',
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              setssErr(err.response.data.message);
            }
          }
        });
    } else {
      Swal.fire({
        text: `You have no permission to change the receipt template`,
        icon: 'error',
      });
    }
  };

  const handleChange = async (event) => {
    setssErr('');
    setSsid(event.target.value);
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Select Your Receipt Template</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container>
          <Row>
            <Col xs={7}>
              <FormControl className={classes.formControl}>
                <InputLabel>Select Receipt Template</InputLabel>
                <Select fullWidth value={ssid} onChange={handleChange}>
                  <MenuItem key={1} value={'None'}>
                    None
                  </MenuItem>
                  {ss.map((m) => {
                    return (
                      <MenuItem key={m._id} value={m._id}>
                        {m.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {ssErr != '' && <p style={{ color: 'red' }}>{ssErr}</p>}
            </Col>
            <Col xs={5}>
              <Button
                style={{ marginTop: '12px' }}
                onClick={() => {
                  // window.open(`/screenSaverPreview/${ssid}`, "width=1080,height=1920")
                  window.open(
                    `/emailPreview/${ssid}/${rowData[0]?._id}`,
                    'mywin',
                    'width=1080,height=1920'
                  );
                }}
                fullWidth
                disabled={ssid == '' || ssid == 'None'}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Preview
              </Button>
            </Col>
          </Row>
          <Container component="main">
            <CssBaseline />
          </Container>
          <Button
            fullWidth
            onClick={onSubmit}
            disabled={ssid == ''}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Select
          </Button>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import { LoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import mapStyle from './mapStyle';
import { useParams } from 'react-router-dom';
import request from '../../../services/Request';

const Google_Key = process.env.REACT_APP_GOOGLE_MAP_API;

const containerStyle = {
  width: '99vw',
  height: '100vh',
};

const center = {
  lat: 43.653225,
  lng: -79.383186,
};

export default function MyMap(props) {
  const [selectedMachine, setSelectedMachine] = useState(null);
  let { machines, option, showFull } = useParams();
  let [ips, setIps] = useState([]);
  let [noips, setNoips] = useState([]);

  useEffect(() => {
    if (option !== '62dad7639bb0910a90b5720') {
      request({
        method: 'get',
        url: `/machines/getMyMachinesForMap/${machines}/${option}/${process.env.REACT_APP_ENV1}}`,
      }).then((res) => {
        let ip = [];
        let nonip = [];
        res.data.forEach((mc) => {
          mc.status = new Date().getTime() - new Date(mc?.active) <= 90000 ? true : false;
          if (mc.address_by_ip?.ip) {
            ip.push(mc);
          } else {
            nonip.push(mc);
          }
        });
        setIps(ip);
        setNoips(nonip);
        console.log('ips', ip);
        console.log('nonip', nonip);
      });
    }
    if (option === '62dad7639bb0910a90b5720') {
      request({
        method: 'get',
        url: `/machines/getMyMachinesForMap/whatever/all/${process.env.REACT_APP_ENV1}`,
      }).then((res) => {
        let ip = [];
        let nonip = [];
        res.data.forEach((mc) => {
          mc.status = new Date().getTime() - new Date(mc?.active) <= 90000 ? true : false;
          if (mc.address_by_ip?.ip) {
            ip.push(mc);
          } else {
            nonip.push(mc);
          }
        });
        setIps(ip);
        setNoips(nonip);
        console.log('ips', ip);
        console.log('nonip', nonip);
      });
    }
  }, []);

  return (
    <div style={{ width: '100vw', height: '100vh', marginTop: '50px' }}>
      <LoadScript googleMapsApiKey={Google_Key}>
        <GoogleMap
          options={{
            styles: mapStyle,
            clickableIcons: false,
          }}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
        >
          {ips?.length > 0 &&
            ips.map((machine) => (
              <Marker
                label={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  text: machine.name,
                }}
                // icon="https://illustoon.com/photo/dl/7251.png"
                key={machine._id}
                position={{
                  lat: machine.address_by_ip?.latitude,
                  lng: machine.address_by_ip?.longitude,
                }}
                onClick={() => {
                  setSelectedMachine(machine);
                }}
              />
            ))}
          <></>

          {selectedMachine && (
            <InfoWindow
              position={{
                lat: selectedMachine?.address_by_ip?.latitude,
                lng: selectedMachine?.address_by_ip?.longitude,
              }}
              onCloseClick={() => {
                setSelectedMachine(null);
              }}
            >
              <div>
                <h2>{selectedMachine.name}</h2>
                <p>Model: {selectedMachine.model}</p>
                <p>Description: {selectedMachine.description}</p>
                {showFull === 'deleteThisForSharing' && (
                  <p>IP: {selectedMachine.address_by_ip.ip}</p>
                )}

                <p>Country: {selectedMachine.address_by_ip.country_name}</p>
                <p>Region: {selectedMachine.address_by_ip.region_name}</p>
                <p>City: {selectedMachine.address_by_ip.city}</p>
                {showFull === 'deleteThisForSharing' && <p>BelongTo: {selectedMachine.belongTo}</p>}

                <p>
                  Status:{' '}
                  {selectedMachine.status ? (
                    <StopRoundedIcon style={{ color: 'green' }} />
                  ) : (
                    <StopRoundedIcon style={{ color: 'red' }} />
                  )}
                </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2';
import { FormLabel } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import request from '../../services/Request';
import '../user/form.css';
import { Modal } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const updateAlert = '/machines/updateEmailAlert';

export default function AlertModal(props) {
  const {
    reset,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({});
  const classes = useStyles();
  const { data, user, ...rest } = props;
  const showSecondEmail = user.fullAccess && user.dba;

  const defaultValues = {
    alertEmail: data[0].alertEmail ? data[0].alertEmail : '',
    alertEmail2: data[0].alertEmail2 ? data[0].alertEmail2 : '',
  };

  useEffect(async () => {
    reset(defaultValues);
  }, [data]);

  const onSubmit = async (formData) => {
    if (formData.alertEmail.split(',').length > 3) {
      setError('alertEmail', {
        message: 'Maximum 3 email address',
      });
    } else {
      if (showSecondEmail) {
        if (formData.alertEmail2.split(',').length > 3) {
          setError('alertEmail2', {
            message: 'Maximum 3 email address',
          });
        }
      }

      await request({
        method: 'post',
        url: updateAlert,
        data: {
          machineID: data.map((e) => e._id),
          alertEmail: formData.alertEmail.trim(),
          alertEmail2: formData?.alertEmail2?.trim(),
        },
      }).then((res) => {
        Swal.fire({
          text: `Your changes has been updated!`,
          icon: 'success',
        }).then(() => {
          props.reflesh ? props.setReflesh(false) : props.setReflesh(true);
          props.onHide();
        });
      });
    }
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.2rem' }}>
          Update Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlignLast: 'center' }}>
                  <FormLabel component="legend">
                    Email address for alert (To use more than one email address, separate the
                    addresses with comma, up to 3 email adresses. Example:
                    johndoe@ae.com,william@ba.com,smith.peter@olk.com)
                  </FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Email"
                    {...register('alertEmail', {})}
                  />
                  {errors.alertEmail && (
                    <p className="signUpErrorMessage">{errors.alertEmail.message}</p>
                  )}
                </Grid>
                {showSecondEmail && (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Hidden Email"
                      {...register('alertEmail2', {})}
                    />
                    {errors.alertEmail && (
                      <p className="signUpErrorMessage">{errors.alertEmail2.message}</p>
                    )}
                  </Grid>
                )}
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Update
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

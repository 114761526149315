import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import { FormControl } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Modal } from 'react-bootstrap';

import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CreateGroup(props) {
  const classes = useStyles();

  const {
    reset,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (formData) => {
    let codePromoArray = [];
    let codeStringArray = [];
    let codeOneTimeArray = [];
    let codeOneTimeStringArray = [];
    const codePromo = JSON.parse(localStorage.getItem('codePromo'));
    const oneTime = JSON.parse(localStorage.getItem('oneTime'));
    if (codePromo) {
      codePromoArray = codePromoArray.concat(codePromo);
      codeStringArray = codePromo.map((e) => e.code);
    }
    if (oneTime) {
      codeOneTimeArray = codeOneTimeArray.concat(oneTime);
      codeOneTimeStringArray = oneTime.map((e) => e.code);
    }
    const promoData = JSON.parse(localStorage.getItem('promo'));
    let isFound = false;
    let isApplied = false;
    promoData.forEach((e) => {
      if (e.code === formData.code) {
        isFound = true;
        //check is code already used
        if (
          codeStringArray.indexOf(formData.code) === -1 &&
          codeOneTimeStringArray.indexOf(formData.code) === -1
        ) {
          if (e.promoType === 'generalWithCode') {
            isApplied = true;
            codePromoArray.push(e);
            localStorage.setItem('codePromo', JSON.stringify(codePromoArray));
            window.location.reload();
          }
          if (e.promoType === 'oneTime') {
            isApplied = true;
            e.product = { all: false, id: { _id: props.productId, name: props.name } };
            codeOneTimeArray.push(e);
            localStorage.setItem('oneTime', JSON.stringify(codeOneTimeArray));
            window.location.reload();
          }
        }
      }
    });
    if (!isFound) {
      setError('code', {
        message: 'the promotion code is not valid',
      });
    } else if (!isApplied) {
      setError('code', {
        message: 'the promotion code has already applied',
      });
    }
  };
  const codePromo = JSON.parse(localStorage.getItem('codePromo'));
  const oneTime = JSON.parse(localStorage.getItem('oneTime'));

  return (
    <Modal className="modal-w" {...props} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Promotion Code</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main">
          <CssBaseline />
          {(codePromo || oneTime) && <p>Active Promotions: </p>}
          {codePromo &&
            codePromo.map((e) =>
              e.product.all ? (
                <p>
                  {e.percentage ? e.amount * 100 + `% off` : '$' + e.amount + ' off'}
                  {` for All Product`}
                </p>
              ) : (
                <p>
                  {e.percentage ? e.amount * 100 + `% off` : '$' + e.amount + ' off'}
                  {` for ${e.product.id.name}`}
                </p>
              )
            )}
          {oneTime &&
            oneTime.map((e) =>
              e.product.all ? (
                <p>
                  {e.percentage ? e.amount * 100 + `% off` : '$' + e.amount + ' off'}
                  {` for All Product (One Time)`}
                </p>
              ) : (
                <p>
                  {e.percentage ? e.amount * 100 + `% off` : '$' + e.amount + ' off'}
                  {` for ${e.product.id.name} (One Time)`}
                </p>
              )
            )}
          {/* {codePromo && (codePromo.product.all ? 
      <p>{`Active Promotion: `}{codePromo.percentage ? codePromo.amount*100+`% off`: '$'+ codePromo.amount + ' off'}{` for All Product`}</p> : 
      <p>{`Active Promotion: `}{codePromo.percentage ? codePromo.amount+`% off`: '$'+ codePromo.amount + ' off'}{` for ${codePromo.product.id.name}`}</p>)} */}

          <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="code"
                    control={control}
                    rules={{ required: 'Code is required' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Promo Code"
                        variant="outlined"
                        required
                        fullWidth
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.code && <p className="signUpErrorMessage">{errors.code.message}</p>}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

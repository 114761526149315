// material
import MaterialTable from 'material-table';

export default function CateTable(props) {
  const { data, machinesName } = props;

  // const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
  //   <Typography variant={variant} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //     {`Product Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
  //   </Typography>
  // );

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Category Page',
      field: 'detail',
    },
  ];

  return (
    <MaterialTable
      title={'Categories: ' + machinesName}
      columns={columns}
      data={data}
      options={{
        actionsColumnIndex: -1,
        pageSize: 5,
        paging: true,
        search: false,
      }}
    />
  );
}

import React from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import './ImageSlider.css';

const ImageSlider = ({ slides, prevSlide, nextSlide, index, video, referTo }) => {
  //if no image, return null
  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  console.log(slides);

  return (
    <div className="d-flex justify-content-center align-items-center mb-3 mt-4">
      <KeyboardArrowLeftIcon fontSize="large" className="left-arrow" onClick={prevSlide} />

      <div className="sliderContainer">
        {slides.map((slide, i) => {
          return (
            <div
              className={`${
                index === i ? 'activeImg' : 'img'
              } imgContainer justify-content-center align-items-center d-flex `}
              key={i}
              style={{ width: '120px', height: '80px' }}
            >
              <img
                src={`${process.env.REACT_APP_SERVER_API}/pictures/${slide}/${referTo}`}
                alt={i}
                key={i}
                style={{ maxHeight: '90%', maxWidth: '95%' }}
              />
            </div>
          );
        })}
        {video && (
          <div className="imgContainer">
            <video className={index === slides.length ? 'activeImg' : 'img'}>
              <source
                src={`${process.env.REACT_APP_SERVER_API}/videos/${video}/${referTo}`}
                type="video/mp4"
              />
            </video>
          </div>
        )}
      </div>
      <KeyboardArrowRightIcon fontSize="large" className="right-arrow" onClick={nextSlide} />
    </div>
  );
};

export default ImageSlider;

// material
import { Grid, Container } from '@mui/material';
// components
// import Page from './Page';

import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext';
import MachineSelect from '../report/MachineSelect';
import React, { useEffect, useState } from 'react';
import request from '../../../services/Request';

import LoadingIcon from '../../items/buttons/LoadingIcon';

// ----------------------------------------------------------------------
import { userLimits } from '../uitls/userLimits';
import MaterialTable from 'material-table';
import { localDateRange } from '../../../services/method/methods';
import moment from 'moment';
const getGroupsURL = `/groupsAndMachinesForReport/`;
const getAlerts = `/logs/getKioskLog`;

export default function KioskLog(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  const NotBothAdmin = user.role == 'SuperAdmin' || user.role === 'BoxAdmin' ? true : false;
  var date = new Date();
  const [machines, setMachines] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [radio, setRadio] = useState('a');
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [machinesName, setmachinesName] = useState('All Machines');

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_kiosk') ? localStorage.getItem('table_view_kiosk') : 20
  );

  useEffect(() => {
    localStorage.setItem('table_view_kiosk', pagination);
  }, [pagination]);

  const { userEmail, setFiltered, filterd } = useContext(UserContext);
  const { id, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'view_report'
  );

  const columns = [
    {
      title: 'Time',
      field: 'localTime',
      render: (rowData) => moment.utc(new Date(rowData.localTime)).format('lll'),
    },
    {
      title: 'Machine',
      field: 'machineID.name',
    },
    {
      title: 'Type',
      field: 'type',
    },
    {
      title: 'Text',
      field: 'code1',
      render: (rowData) => rowData.code1 + (rowData.motor ? `Motor:` + rowData.motor : ''),
    },
    {
      title: 'Error Code',
      field: 'code2',
    },
    {
      title: 'Message',
      field: 'code3',
    },
    {
      title: 'User',
      field: 'user.email',
      render: (rowData) => {
        if (rowData.user) return rowData?.user?.firstname + ' ' + rowData?.user?.lastname;
        else return '';
      },
    },
  ];

  useEffect(() => {
    let isCancelled = false;
    if (!NotBothAdmin) {
      async function getGroups() {
        await request({
          method: 'get',
          url: !props.match.params.group && superAdmin ? getGroupsURL : getGroupsURL + userEmail,
        }).then((res) => {
          res.data.machines.forEach((element) => {
            element.product?.forEach((e) => {
              e.id.name = e.id.name.en;
            });
          });
          if (!isCancelled) {
            setGroups(res.data.groups);
            setMachines(res.data.machines);
            setFiltered(res.data.machines);
          }
        });
      }
      getGroups();
    } else {
      request({
        method: 'get',
        url: '/machines/getMyMachines',
      }).then((res) => {
        if (!isCancelled) {
          // console.log(res.data);
          res.data?.forEach((element) => {
            if (element?.status_info === 'Out Of Service') {
              element.oos = 'NO';
            }
            element.online = 'false';
          });

          // setLoading(false);
          setMachines(res.data);
        }
      });
    }

    return () => {
      setMachines([]);
      setGroups([]);
      setLoading(false);
      isCancelled = true;
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleChange = async (event) => {
    let filteredArray = [];
    setLoading(false);
    let myIDs = 0;
    if (radio == 'a') {
      setFiltered(machines);
      myIDs = machines.map((e) => e._id).join(',');
      setmachinesName('All Machines');
      filteredArray = [...machines];
    }
    if (radio == 'g') {
      const myArray = groupV.map((e) => e.machineID);
      var merged = [].concat.apply([], myArray);
      merged = merged.filter((e, i, self) => i === self.findIndex((t) => t._id == e._id));
      // const filteredArray = machines.filter((e, i) => i == merged.findIndex((t)=>(t._id == e._id)));
      machines.forEach((e) => {
        merged.findIndex((t) => {
          if (t._id == e._id) {
            filteredArray.push(e);
          }
        });
      });
      setFiltered(filteredArray);
      myIDs = filteredArray.map((e) => e._id).join(',');
      setmachinesName(`Groups: ${groupV.map((e) => e.name).join(', ')} `);
    }
    if (radio == 'm') {
      setFiltered(groupV);
      filteredArray = [...groupV];
      myIDs = groupV.map((e) => e._id).join(',');

      setmachinesName(`Machines: ${groupV.map((e) => e.name).join(', ')} `);
    }
    console.log('myIDs', id);
    const { startDateString, endDateString } = localDateRange(startDate, endDate);
    await request({
      method: 'post',
      url: getAlerts,
      data: {
        machineID: myIDs,
        id: id,
        startDate: startDateString,
        endDate: endDateString,
      },
    }).then((res) => {
      console.log('KioskLog', res.data);
      let filteredArray = res.data.filter(
        (e) => e.code1
        // != 'Slot number is not found when try to checkout'
      );
      // setData(res.data);
      setData(filteredArray);
      setLoading(true);
    });
  };

  return (
    <div>
      <Container maxWidth="xl" sx={{ pt: 5 }}>
        {!loading && <LoadingIcon />}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MachineSelect
              filterd={filterd}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              startDate={startDate}
              endDate={endDate}
              setRadio={setRadio}
              setgroupV={setgroupV}
              radio={radio}
              groupV={groupV}
              groups={groups}
              machines={machines}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Container>
      <MaterialTable
        onChangeRowsPerPage={setPagination}
        style={{ marginTop: '40px' }}
        columns={columns}
        data={data}
        title={''}
        options={{
          actionsColumnIndex: -1,
          pageSize: parseInt(pagination),
          pageSizeOptions: [10, 20, 50, 100, 200],
        }}
      />
    </div>
  );
}

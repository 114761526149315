import React, { useEffect, useState, useContext } from 'react';
import { PlanoContext } from './PlanoContext';
import { useDrag } from 'react-dnd';
import Swal from 'sweetalert2';
import { COLOROrigin, COLORS1, COLORS2 } from './colorVariable';

function roundUp(num, precision) {
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
}

const getMaxHeight = (array) => {
  let mh = 0;
  array.forEach((e) => {
    if (e.product) {
      if (mh < e.product.dimension.height) {
        mh = e.product.dimension.height;
      }
    }
  });
  return mh;
};

function Droped({
  prod,
  url,
  width,
  setBoard,
  lines,
  setLines,
  index,
  setFloors,
  floors,
  floorIndexHeight,
  floorNumber,
  floor,
}) {
  const { error, setError, plano, uploadProd, setUploadProd, setDragProd } =
    useContext(PlanoContext);
  const [didDrop, setDidDrop] = useState(false);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'image',
    item: { prod: prod },
    end: (item, monitor) => {
      const itemLane = parseFloat(roundUp(item.prod.dimension.length / plano.laneLength, 1));
      // const itemLane = parseFloat((item.prod.dimension.length / plano.laneLength).toFixed(1));
      console.log('itemLane', itemLane);
      console.log('item', item);
      if (monitor.didDrop()) {
        setDidDrop(false);
        if (
          index !== 0 &&
          lines[index - 1].disable == 0 &&
          itemLane % 1 !== 0 &&
          lines[index - 1].product2
        ) {
          lines[index - 1].occupied = lines[index - 1].occupied - (itemLane % 1);
          lines[index - 1].occupied = Math.round(lines[index - 1].occupied * 10) / 10;
          lines[index - 1].color = lines[index - 1].occupied > 0 ? COLORS2 : COLORS1;
          lines[index - 1].product2 = null;
          for (let i = 0; i < itemLane - 1; i++) {
            lines[index + i].product = null;
            lines[index + i].occupied = 0;
            lines[index + i].color = COLOROrigin;
          }
        } else {
          for (let i = 0; i < itemLane; i++) {
            if (lines[index + i].disable == 0) {
              lines[index + i].occupied = lines[index + i].occupied - (itemLane % 1);
              lines[index + i].occupied = Math.round(lines[index + i].occupied * 10) / 10;
              lines[index + i].color = lines[index + i].occupied > 0 ? COLORS2 : COLORS1;
            } else {
              lines[index + i].occupied = 0;
              lines[index + i].color = COLOROrigin;
            }
            lines[index + i].product = null;
            lines[index + i].length = 0;
          }
        }
        setLines([...lines]);
        setBoard([]);
        floors[floorNumber - floorIndexHeight - 1].maxHeight = getMaxHeight(lines);
        setFloors([...floors]);
        lines[index].maxStock = 0;
        lines[index].motor = 1;
        lines[index].step = null;

        let myIndex = uploadProd.findIndex((e) =>
          e.pos.includes(floor + 'F ' + parseInt(index + 1) + 'L')
        );
        console.log(floor + 'F ' + parseInt(index + 1) + 'L');
        setDragProd('');
        // console.log(uploadProd);
        // console.log('myIndex', myIndex);
        if (myIndex > -1) {
          uploadProd.splice(myIndex, 1);
          // setUploadProd([...uploadProd]);
        }
        // console.log(uploadProd);
        if (!uploadProd[myIndex]) {
        }
      } else {
        setDidDrop(true);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    setDragProd('');
    if (error != '' && didDrop) {
      Swal.fire(`Can't place the product here`, error, 'error');
      setError('');
      setDidDrop(false);
    }
  }, [didDrop]);

  useEffect(() => {
    if (isDragging) setDragProd(lines[index]?.product?.name ? lines[index]?.product?.name : '');
  }, [isDragging]);

  return (
    <div className="d-flex justify-content-center" ref={drag} style={{ position: 'absolute' }}>
      {lines[index].maxStock > 0 && lines[index].length > 0 && (
        <div
          style={{ position: 'absolute', top: '112px', zIndex: '1000' }}
        >{`Max:${lines[index].maxStock}`}</div>
      )}
      {/* {isDragging && (
        <div
          style={{ position: 'absolute', top: '-50px', zIndex: '1000' }}
        >{`${lines[index]?.product?.name}`}</div>
      )} */}
      <img
        // onClick={()=>{console.log(floor+'F '+ parseInt(index + 1) + 'L')}}
        style={{
          border: isDragging ? '5px solid pink' : '0px',
          marginTop: width === '50px' && '35px',
        }}
        src={url}
        width={width ? width : '150px'}
        height={width !== '50px' ? '100px' : '50px'}
      />
    </div>
  );
}

export default Droped;

import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import request from '../../services/Request';

export default function TrashBinModal(props) {
  if (props.catename) {
    return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to delete category: {props.catename.en}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              request({
                method: 'delete',
                url: `${process.env.REACT_APP_SERVER_API}/categories/delete/${props.id}`,
              })
                .then(() => {
                  props.onHide();
                  Swal.fire({
                    text: `The category has been successfully deleted: ${props.catename.en}`,
                    icon: 'success',
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((err) => {
                  if (err.response?.data?.message) {
                    Swal.fire('', err.response?.data?.message, 'error');
                  }
                  props.onHide();
                });
            }}
          >
            YES
          </Button>
          <Button onClick={props.onHide}>NO</Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to delete product: {props.name.en}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              request({
                method: 'delete',
                url: `${process.env.REACT_APP_SERVER_API}/products/delete/${props.id}`,
              })
                .then(() => {
                  props.onHide();
                  Swal.fire({
                    text: `The product has been successfully deleted: ${props.name.en}`,
                    icon: 'success',
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((err) => {
                  if (err.response?.data?.message) {
                    Swal.fire('', err.response?.data?.message, 'error');
                  }
                  props.onHide();
                });
            }}
          >
            YES
          </Button>
          <Button onClick={props.onHide}>NO</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

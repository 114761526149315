import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import request from '../../../services/Request';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import Swal from 'sweetalert2';
import { userLimits } from '../uitls/userLimits';
import { MenuItem, Select } from '@mui/material';
import { useForm } from 'react-hook-form';
import { TextField, Button, Grid, FormLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(-5.6),
    marginRight: theme.spacing(40),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ViewMagexInvoice(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  const dbaUser = user.dba;
  const classes = useStyles();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});
  let history = useHistory();
  const [data, setData] = useState([]);
  const [reflesh, setReflesh] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [select, setSelect] = useState(null);
  const [rowdata, setrowdata] = useState(null);

  const defaultValues = {
    service: '',
    description: '',
    unit_price: '',
  };

  useEffect(async () => {
    reset(defaultValues);
  }, [data]);

  const onSubmit = async (formData) => {
    if (rowdata) {
      request({
        method: 'post',
        url: `/magex/editInvoiceField/` + rowdata._id,
        data: {
          service: formData.service,
          description: formData.description,
          unit_price: formData.unit_price,
        },
      })
        .then(() => {
          Swal.fire({
            title: 'Success',
            text: 'Field has been successfully updated',
            timer: 2000,
          });
          setshow(false);
          setReflesh(!reflesh);
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text: 'Error to update the field',
            timer: 2000,
          });
        });
    } else {
      request({
        method: 'post',
        url: `/magex/createInvoiceField`,
        data: {
          service: formData.service,
          description: formData.description,
          unit_price: formData.unit_price,
        },
      })
        .then(() => {
          Swal.fire({
            title: 'Success',
            text: 'Field has been successfully created',
            timer: 2000,
          });
          setshow(false);
          setReflesh(!reflesh);
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text: 'Error to create the field',
            timer: 2000,
          });
        });
    }
  };

  const access = user?.role === 'SuperAdmin' || (user?.role === 'MagexAdmin' && user?.fullAccess);

  useEffect(() => {
    let isCancelled = false;

    //getAllEmployees
    async function getAllEmployees() {
      await request({
        method: 'get',
        url: `/magex/getAllInvoiceFields`,
      }).then((res) => {
        if (!isCancelled) {
          // console.log('res.data', res.data);
          setData(res.data);
          setIsLoading(true);
        }
      });
    }

    getAllEmployees();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  const deleteUser = async (id, referTo) => {
    await request({
      method: 'delete',
      url: `magex/deleteInvoiceField/` + id,
    }).then(() => {
      Swal.fire({
        text: `The Field has been successfully deleted`,
        icon: 'success',
      });
      reflesh ? setReflesh(false) : setReflesh(true);
    });
  };

  const add = () => {
    reset(defaultValues);
    setrowdata(null);
  };

  const columns = [
    { title: 'Service', field: 'service' },
    { title: 'Description', field: 'description' },

    {
      title: 'Unit Price',
      field: 'unit_price',
      type: 'numeric',
    },
  ];

  return (
    <div>
      {!isloading && <LoadingIcon />}
      <div style={{ maxWidth: '100%', marginBottom: '100px', textAlign: 'center', margin: 'auto' }}>
        <div
          style={{
            width: '100%',
            marginTop: '30px',
            marginBottom: '20px',
            textAlign: 'center',
            padding: '20px 20px',
          }}
        >
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2} className="mb-3" tyle={{ textAlign: 'center' }}>
              <Grid item xs={12} style={{ textAlignLast: 'center' }}>
                <FormLabel component="legend">Add Invoice Field</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Serivce"
                  InputLabelProps={{ shrink: true }}
                  name="service"
                  inputProps={{
                    maxLength: '100',
                  }}
                  {...register('service', {})}
                />
                {errors.service && <p className="signUpErrorMessage">{errors.service.message}</p>}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Description"
                  name="description"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  {...register('description', {})}
                />
                {errors.description && (
                  <p className="signUpErrorMessage">{errors.description.message}</p>
                )}
              </Grid>
              <Grid item xs={1}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Unit Price"
                  name="unit_price"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    maxLength: '100',
                  }}
                  {...register('unit_price', {})}
                />
                {errors.unit_price && (
                  <p className="signUpErrorMessage">{errors.unit_price.message}</p>
                )}
              </Grid>
            </Grid>
            {rowdata && (
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={add}
                style={{ marginRight: '20px' }}
              >
                Add New
              </Button>
            )}

            <Button type="submit" variant="contained" color="primary" className={classes.submit}>
              SUbmite
            </Button>
          </form>
        </div>

        <div>
          <MaterialTable
            columns={columns}
            data={data}
            title={'Magex Invoice'}
            options={{
              paging: true,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
            }}
            localization={{ body: { editRow: { deleteText: 'Are you sure to delete this user' } } }}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Edit Info',
                onClick: (event, rowdata) => {
                  reset({
                    service: rowdata.service,
                    description: rowdata.description,
                    unit_price: rowdata.unit_price,
                  });
                  setrowdata(rowdata);
                },
              },
            ]}
            editable={
              !access
                ? {}
                : {
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          deleteUser(oldData._id);
                          resolve();
                        }, 600);
                      }),
                  }
            }
          />
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Alert } from 'react-bootstrap';

export default function cartAlert(props) {
  return (
    <>
      <Alert show={props.show} variant="light">
        <Alert.Heading>The item has been successfully added to your cart!</Alert.Heading>
      </Alert>
    </>
  );
}

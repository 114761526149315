import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router';
import Link from '@material-ui/core/Link';

import { useForm } from 'react-hook-form';
import axios from 'axios';
import './form.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword(props) {
  const classes = useStyles();
  const token = props.match.params.token;
  const resetPasswordURL = `${process.env.REACT_APP_SERVER_API}/users/resetPassword`;
  const checkTokenURL = `${process.env.REACT_APP_SERVER_API}/users/checkToken`;

  const defaultValues = {
    role: 'User',
  };

  const {
    register,
    watch,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const [show, setShow] = useState(true);
  const password = useRef({});
  password.current = watch('newPassword', '');

  let history = useHistory();

  useEffect(() => {
    axios
      .post(checkTokenURL, {
        token: token,
      })
      .then((res) => {
        setShow(true);
      })
      .catch((err) => {
        if (err) {
          setShow(false);
        }
      });
  }, []);

  const onSubmit = async (data) => {
    axios
      .put(resetPasswordURL, {
        resetLink: token,
        newPassword: data.newPassword2,
      })
      .then((res) => {
        alert('You password has been reset, now you can login with your new password');
        history.push('/dashboard');
      })
      .catch((err) => {
        if (err) {
          if (err.response.data)
            setError('newPassword2', {
              message: err.response.data.message,
            });
        }
      });
  };

  if (show) {
    return (
      <div style={{ display: 'flex' }}>
        {/* <Menu title="Change My Password"/> */}
        <Container style={{ marginTop: '19px' }} component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="newPassword"
                    label="New Password"
                    name="newPassword"
                    type="password"
                    autoComplete="newPassword"
                    {...register('newPassword', {
                      required: 'New Password is required',
                      minLength: {
                        value: 8,
                        message: 'Password must longer than 8',
                      },
                    })}
                  />
                  {errors.newPassword && (
                    <p className="signUpErrorMessage">{errors.newPassword.message}</p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="newPassword2"
                    label="Enter password again"
                    name="newPassword2"
                    type="password"
                    autoComplete="newPassword2"
                    {...register('newPassword2', {
                      required: 'Re-Enter Password is required',
                      minLength: {
                        value: 8,
                        message: 'Password must longer than 8',
                      },
                      validate: (value) =>
                        value === password.current || 'The new passwords do not match',
                    })}
                  />
                  {errors.newPassword2 && (
                    <p className="signUpErrorMessage">{errors.newPassword2.message}</p>
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Change My Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgetpassword" variant="body2">
                    Resend the reset link
                  </Link>
                </Grid>
                {/* <Grid item>
              <Link href="/signUp" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    );
  } else {
    return <h1>This is invalid link, Your token is incorrect or expired!</h1>;
  }
}

import ProductCard from '../items/productCard';
import BackGroundTag from '../items/backGroundTag';
import ToggleButtons from '../items/buttons/ToggleButtons';
import request from '../../services/Request';
import MaterialTable from 'material-table';
import UpdateProduct from '../items/UpdateProduct';
import { userLimits } from '../dashboard/uitls/userLimits';
import Swal from 'sweetalert2';
import { TextField } from '@material-ui/core';

import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/userContext';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CloudDownload } from '@material-ui/icons';
import { ExportToCsv } from 'export-to-csv';

function ManageProducts(props) {
  const { access } = userLimits('', '', 'Products', 'edit_prod');
  const { userEmail, role, referTo } = useContext(UserContext);
  const userId = props.user;
  const id = role === 'Admin' || role === 'SuperAdmin' ? (userId ? userId : userEmail) : referTo;
  const getAllProdURL = `${process.env.REACT_APP_SERVER_API}/products/`;

  const [prods, setProds] = useState([]);
  const [view, setView] = useState('module');
  const [rowD, setRowD] = useState({});
  const [updateShow, setUpdateShow] = useState(false);
  const [input, setInput] = useState('');

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_prod') ? localStorage.getItem('table_view_prod') : 20
  );

  useEffect(() => {
    localStorage.setItem('table_view_prod', pagination);
  }, [pagination]);

  function setList(event) {
    setView('list');
  }
  function setModule(event) {
    setView('module');
  }

  const downloadProducts = () => {
    // console.log(prods);
    // return console.log(prods);
    const result = prods.map((e) => {
      let newCate = e.category.map((e) => e?.name?.en);
      let cate = newCate.join(', ');

      let image1 = '';
      let image2 = '';
      let image3 = '';
      let video = '';
      if (e.productPictures?.length > 0) {
        image1 = `${process.env.REACT_APP_SERVER_API}/image/${e.referTo}/${e.productPictures[0]}`;
      }
      if (e.productPictures?.length > 1) {
        image2 = `${process.env.REACT_APP_SERVER_API}/image/${e.referTo}/${e.productPictures[1]}`;
      }
      if (e.productPictures?.length > 2) {
        image3 = `${process.env.REACT_APP_SERVER_API}/image/${e.referTo}/${e.productPictures[2]}/`;
      }
      if (e.productVideo) {
        video = `${process.env.REACT_APP_SERVER_API}/video/${e.referTo}/${e.productVideo}`;
      }
      return {
        name: e.name?.en,
        upc: `${e.upc}`,
        description: e.description?.en,
        brand: e.brand?.name?.en ? e.brand?.name?.en : '',
        category: cate,
        price: e.price,
        costPrice: e.costPrice ? e.costPrice : '',
        width: e.dimension?.length,
        height: e.dimension?.height,
        depth: e.dimension?.width,
        image1: image1,
        image2: image2,
        image3: image3,
        video: video,
      };
    });
    console.log('result', result);

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      useTextFile: false,
      filename: 'Sales Report CSV(products report per machine per day)',
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    console.log(csvExporter, 'csvExporter');
    csvExporter.generateCsv(result);
  };

  const columns = [
    { title: 'Name', field: 'name.en' },
    { title: 'upc', field: 'upc' },
    {
      title: 'price',
      field: 'price',
      render: (rowData) => '$' + rowData.price.toFixed(2),
    },
  ];

  useEffect(() => {
    request({
      method: 'get',
      url: role === 'SuperAdmin' && !userId ? getAllProdURL : getAllProdURL + id,
    })
      .then((res) => {
        console.log(res.data);
        setProds(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      setProds([]);
    };
  }, []);

  const filteredProduct = prods.filter((prod) => prod.category === props.catename);

  let result;

  if (!props.catename) result = [...prods];
  else result = [...filteredProduct];

  if (input.length > 0) {
    result = result.filter(
      (product) =>
        product.name.en?.toLowerCase().includes(input.toLowerCase()) ||
        product.upc?.toLowerCase().includes(input.toLowerCase())
    );
  }

  const duplicateProduct = async (rowData) => {
    const { value: upc } = await Swal.fire({
      title: 'Enter the new UPC/SKU',
      input: 'text',
      inputPlaceholder: 'Enter the new UPC/SKU',
    });
    if (upc) {
      Swal.fire({
        title: `Please Wait for response`,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await request({
        method: 'post',
        url: '/products/duplicate/' + rowData?._id,
        data: {
          upc: upc,
          referTo: id,
        },
      })
        .then(() => {
          Swal.fire({
            icon: 'success',
            text: `Successfully duplicate the product`,
          }).then(() => window.location.reload());
        })
        .catch((res) => {
          Swal.fire({
            icon: 'error',
            title: `Failed to duplicate the product`,
            text: res?.response?.data?.message,
          });
        });
    }
  };

  const products = result.map((prod) => {
    return (
      <ProductCard
        prodType={prod.prodType}
        key={prod._id}
        id={prod._id}
        price={`$ ${parseFloat(prod.price).toFixed(2)}`}
        upc={prod.upc}
        name={prod.name}
        image={prod.productPictures}
        video={prod?.productVideo}
        description={prod.description}
        category={prod.category}
        brand={prod.brand}
        quantity={prod.quantity}
        referTo={prod.referTo}
      />
    );
  });

  function productStructure() {
    // if (result.length === 0) {
    //   return (
    //     <div
    //       style={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         height: '90%',
    //       }}
    //     >
    //       <div className="container">
    //         <h2 className="mt-4 mb-2">No products to display</h2>
    //       </div>
    //     </div>
    //   );
    // } else {
    return (
      <div style={{ backgroundColor: 'white' }}>
        <BackGroundTag text={props.catename} />
        {role === 'SuperAdmin' && userId && <h2 className="mt-4 mb-2">Products of: {userId}</h2>}
        <ToggleButtons setList={setList} setModule={setModule} view={view} />
        <div className="container">
          {view === 'module' && (
            <div className="mt-4">
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                onChange={(e) => {
                  setInput(e.target.value);
                }}
              />
              <div className="row items-container mt-4">{products}</div>
            </div>
          )}
        </div>
        <div className="mt-5">
          {view === 'list' && (
            <MaterialTable
              onChangeRowsPerPage={setPagination}
              columns={columns}
              data={result}
              title={'Products Table'}
              actions={[
                {
                  isFreeAction: true,
                  tooltip: 'Download all products',
                  icon: CloudDownload,
                  onClick: (event) => {
                    downloadProducts();
                  },
                },
                () => ({
                  icon: 'edit',
                  tooltip: 'Edit Product',
                  onClick: (event, rowData) => {
                    setRowD(rowData);
                    setUpdateShow(true);
                  },
                }),
                () => ({
                  icon: FileCopyIcon,
                  tooltip: 'Duplicate the product',
                  onClick: (event, rowData) => {
                    duplicateProduct(rowData);
                  },
                }),
                () => ({
                  icon: 'delete',
                  tooltip: 'Delete Product',
                  onClick: (event, rowData) => {
                    Swal.fire({
                      title: 'Are you sure?',
                      text: "You won't be able to revert this!",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        request({
                          method: 'delete',
                          url: `${process.env.REACT_APP_SERVER_API}/products/delete/${rowData._id}`,
                        })
                          .then(() => {
                            Swal.fire({
                              text: `The product has been successfully deleted: ${rowData.name.en}`,
                              icon: 'success',
                            });
                            setTimeout(() => {
                              window.location.reload();
                            }, 1000);
                          })
                          .catch((err) => {
                            if (err.response?.data?.message) {
                              Swal.fire('', err.response?.data?.message, 'error');
                            }
                          });
                      }
                    });
                  },
                }),
              ]}
              options={{
                pageSize: parseInt(pagination),
                pageSizeOptions: [20, 50, 100, 200],
                actionsColumnIndex: -1,
              }}
            />
          )}
        </div>
        <UpdateProduct
          access={access}
          id={rowD._id}
          name={rowD.name}
          upc={rowD.upc}
          description={rowD.description}
          category={rowD.category}
          price={rowD.price}
          video={rowD.video}
          brand={rowD.brand}
          quantity={rowD.quantity}
          referTo={rowD.referTo}
          show={updateShow}
          onHide={() => setUpdateShow(false)}
        />
      </div>
    );
    // }
  }
  return <>{productStructure()}</>;
}

export default ManageProducts;

import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import request from '../../services/Request';
import { Button } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/userContext';
import Grid from '@mui/material/Grid';
import Container from '@material-ui/core/Container';
import Papa from 'papaparse';

export default function ImportProducts(props) {
  const { ...rest } = props;
  const { userEmail, role, referTo } = useContext(UserContext);

  let id = '';
  let userId = useLocation().pathname.split('/').slice(-1)[0];

  if (userId.includes('@')) {
    id = userId;
  } else {
    if (role === 'Admin') id = userEmail;
    else id = referTo;
  }

  const [prod, setProd] = useState([]);
  const [fileName, setFileName] = useState('');
  const [image, setImage] = useState([]);

  useEffect(() => {
    if (props.show) {
      setImage([]);
      setProd([]);
      setFileName('');
    }
  }, [props.show]);

  const downloadTemplate = () => {
    window.open(`${process.env.REACT_APP_SERVER_API}/download/importProducts/ImportProducts`);
  };

  const downloadTemplateImage = () => {
    window.open(
      `${process.env.REACT_APP_SERVER_API}/download/importProducts/ImportProductImageExCode`
    );
  };

  const downloadTemplateImageUPC = () => {
    window.open(
      `${process.env.REACT_APP_SERVER_API}/download/importProducts/ImportProductImageUPC`
    );
  };

  const submit = () => {
    console.log('prod', prod);
    Swal.fire({
      title: `Please Wait for response`,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    request({
      method: 'post',
      url: `/products/importProducts`,
      data: {
        prod: prod,
        referTo: id,
      },
    })
      .then((res) => {
        Swal.fire({
          icon: 'success',
          text: `Your products have been imported successfully.`,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          text: `Something Wrong during the import.`,
        });
      });
  };

  const submitImage = () => {
    Swal.fire({
      title: `Please Wait for response`,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    request({
      method: 'post',
      url: `/products/importProductsImage`,
      data: {
        prod: image,
        referTo: id,
      },
    })
      .then((res) => {
        Swal.fire({
          icon: 'success',
          text: `Your products have been imported successfully.`,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          text: `Something Wrong during the import.`,
        });
      });
  };

  const importCode = (evt) => {
    if (!evt.target.files) {
      return;
    }
    const file = evt.target.files[0];
    try {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          const data = results.data;
          let go = true;

          console.log('data', data);

          data.forEach((e) => {
            if (e.name === '' || e.sku === '') {
              go = false;
              return Swal.fire({
                text: `Name and SKU should not be none`,
                icon: 'error',
              });
            }
            console.log('e.name?.length', e.name?.length);
            if (e.name?.length > 33) {
              go = false;
              return Swal.fire({
                text: `Characters of name should be less than 34 characters`,
                icon: 'error',
              });
            }
            if (e.category?.length > 30 || e.brand?.length > 30) {
              go = false;
              return Swal.fire({
                text: 'Characters of category/brand should be less than 31 characters',
                icon: 'error',
              });
            }
            e.dimension = {
              length: parseFloat(e.width),
              width: parseFloat(e.depth),
              height: parseFloat(e.height),
            };
            e.price = parseFloat(e.price);
            e.costPrice = parseFloat(e.costPrice);
            if (e.brand === '') e.brand = null;
            console.log('e.dimension', e.dimension);
            if (
              isNaN(e.dimension.length) ||
              isNaN(e.dimension.width) ||
              isNaN(e.dimension.height) ||
              isNaN(e.price) ||
              isNaN(e.costPrice)
            ) {
              go = false;
              return Swal.fire({
                text: `price/costPrice/length/width/height is not a number`,
                icon: 'error',
              });
            }
            e.name = { en: e.name };
            e.description = { en: e.description };
            e.ingredients = { en: e.extraInfo1 };
            e.allergens = { en: e.extraInfo2 };
            e.title1 = { en: e.title1 };
            e.title2 = { en: e.title2 };
            const cate = e.category === '' ? [] : e.category.split(';');
            cate.forEach((ca) => {
              ca = ca.trim();
            });
            e.externalCode = e.externalCode ? e.externalCode.trim() : null;
            console.log(cate);
            e.category = cate;
          });
          console.log(data);
          evt.target.value = null;
          if (go) {
            // console.log('data', data);
            setFileName(file.name);
            setProd(data);
            Swal.fire({
              text: `Ready for submit`,
              icon: 'success',
            });
          }
        },
      });
    } catch (err) {
      evt.target.value = null;
      console.log(err);
      return Swal.fire({
        text: `Something wrong`,
        icon: 'error',
      });
    }
  };

  const importImage = (evt) => {
    if (!evt.target.files) {
      return;
    }
    const file = evt.target.files[0];
    try {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          const data = results.data;
          let go = true;

          const checkExtensionImage = (e) => {
            return (
              e !== 'jpg' &&
              e !== 'jpeg' &&
              e !== 'png' &&
              e !== 'JPG' &&
              e !== 'JEPG' &&
              e !== 'PNG'
            );
          };
          console.log('data', data);
          data.forEach((e) => {
            console.log('e.Image1', e.Image1);
            if (!e.UPC && !e.externalCode) {
              go = false;
              return Swal.fire({
                text: `UPC/external code should not be none`,
                icon: 'error',
              });
            }
            if (!e.Image1 || e.Image1 === '') {
              go = false;
              return Swal.fire({
                text: `Image1 should not be none`,
                icon: 'error',
              });
            }
            if (e.Image1) {
              const extention1 = e.Image1.split('.').pop();
              if (checkExtensionImage(extention1)) {
                go = false;
                return Swal.fire({
                  text: `Image1 extension is not correct`,
                  icon: 'error',
                });
              }
            }

            if (e.Image2) {
              const extention1 = e.Image2.split('.').pop();
              if (checkExtensionImage(extention1)) {
                go = false;
                return Swal.fire({
                  text: `Image2 extension is not correct`,
                  icon: 'error',
                });
              }
            }

            if (e.Image3) {
              const extention1 = e.Image3.split('.').pop();
              if (checkExtensionImage(extention1)) {
                go = false;
                return Swal.fire({
                  text: `Image3 extension is not correct`,
                  icon: 'error',
                });
              }
            }

            if (e.Video) {
              const extention1 = e.Video.split('.').pop();
              if (extention1 !== 'mp4' && extention1 !== 'MP4') {
                go = false;
                return Swal.fire({
                  text: `Video extension is not correct`,
                  icon: 'error',
                });
              }
            }
          });

          if (go) {
            // console.log('data', data);
            setFileName(file.name);
            setImage(data);
            Swal.fire({
              text: `Ready for submit`,
              icon: 'success',
            });
          }
        },
      });
    } catch (err) {
      evt.target.value = null;
      console.log(err);
      return Swal.fire({
        text: `Something wrong`,
        icon: 'error',
      });
    }
  };

  return (
    <Modal {...rest} centered scrollable className="mt-5 myModal" size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Import Product</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                component="label"
                variant="contained"
                color="primary"
                style={{ marginRight: '35px' }}
                onClick={downloadTemplate}
              >
                Download a template
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                component="label"
                variant="contained"
                color="primary"
                style={{ marginRight: '35px' }}
              >
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={(e) => {
                    importCode(e);
                  }}
                />
                IMPORT Products by a CSV
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                component="label"
                variant="contained"
                color="primary"
                style={{ marginRight: '35px' }}
                onClick={downloadTemplateImage}
              >
                Download image template(external code)
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                component="label"
                variant="contained"
                color="primary"
                style={{ marginRight: '35px' }}
                onClick={downloadTemplateImageUPC}
              >
                Download image template(upc)
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                // disabled
                component="label"
                variant="contained"
                color="primary"
                style={{ marginRight: '35px' }}
              >
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={(e) => {
                    importImage(e);
                  }}
                />
                IMPORT image by a CSV
              </Button>
            </Grid>

            {fileName !== '' && (
              <Grid item xs={12}>
                <div>Your File Name: {fileName}</div>
              </Grid>
            )}

            {prod.length > 0 && (
              <Grid item xs={12}>
                <Button
                  onClick={submit}
                  component="label"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '35px' }}
                >
                  Submit
                </Button>
              </Grid>
            )}

            {image.length > 0 && (
              <Grid item xs={12}>
                <Button
                  onClick={submitImage}
                  component="label"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '35px' }}
                >
                  Submit Image Upload
                </Button>
              </Grid>
            )}
          </Grid>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

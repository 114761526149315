import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import { FormControl } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import request from '../../../services/Request';

import { useForm, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CreateGroup(props) {
  const classes = useStyles();

  const createGroupURL = `/groups/create`;
  const updateGroupURL = `/groups/update/`;
  const getMyMachineURL = `/machines/getMyMachines/`;
  const getAllEmployeesURL = `/users/getAllEmployees/`;

  const title = props.update === 'false' ? 'Create a Group' : 'Update a Group';
  const button = props.update === 'false' ? 'Create the Group' : 'Update the Group';

  const {
    checkedvalues,
    setcheckedvalues,
    checkedvalues2,
    setcheckedvalues2,
    name,
    groupid,
    reflesh,
    setreflesh,
    id,
    setname,
    ...rest
  } = props;

  const [machine, setMachine] = useState([]);
  const [employees, setEmployees] = useState([]);

  const defaultValues = {
    Name: name,
    machineID: checkedvalues,
    userID: checkedvalues2,
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  function handleSelect(checkedId) {
    const newIds = checkedvalues?.includes(checkedId)
      ? checkedvalues?.filter((name) => name !== checkedId)
      : [...(checkedvalues ?? []), checkedId];
    setcheckedvalues(newIds);
    return newIds;
  }

  function handleSelect2(checkedId) {
    const newIds = checkedvalues2?.includes(checkedId)
      ? checkedvalues2?.filter((name) => name !== checkedId)
      : [...(checkedvalues2 ?? []), checkedId];
    setcheckedvalues2(newIds);
    return newIds;
  }

  useEffect(() => {
    reset(defaultValues);
    let isCancelled = false;
    //getAllEmployees

    async function getMyMachines() {
      await request({
        method: 'get',
        url: getMyMachineURL + id,
      }).then((res) => {
        if (!isCancelled) {
          setMachine(res.data);
        }
      });
    }

    async function getMyUser() {
      await request({
        method: 'get',
        url: getAllEmployeesURL + id,
      }).then((res) => {
        if (!isCancelled) {
          setEmployees(res.data);
        }
      });
    }

    getMyMachines();
    getMyUser();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  const onSubmit = async (data) => {
    console.log(data);
    if (props.update === 'false') {
      await request({
        method: 'post',
        url: createGroupURL,
        data: {
          name: data.Name,
          machineID: data.machineID,
          userID: data.userID,
          referTo: id,
        },
      }).then((res) => {
        props.onHide();
        props.setreflesh(reflesh ? false : true);
        Swal.fire({
          text: `New group has been successfully created: ${data.Name}`,
          icon: 'success',
        });
      });
    }
    if (props.update === 'true') {
      await request({
        method: 'patch',
        url: updateGroupURL + groupid,
        data: {
          name: data.Name,
          machineID: data.machineID,
          userID: data.userID,
          referTo: id,
        },
      }).then((res) => {
        props.onHide();
        props.setreflesh(reflesh ? false : true);
        Swal.fire({
          text: `New group has been successfully updated: ${data.Name}`,
          icon: 'success',
        });
      });
    }
  };

  return (
    <Modal className="modal-w" {...rest} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main">
          <CssBaseline />
          <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="Name"
                    control={control}
                    rules={{ required: 'Name is required' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Name"
                        variant="outlined"
                        required
                        fullWidth
                        defaultValue={name}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.Name && <p className="signUpErrorMessage">{errors.Name.message}</p>}
                </Grid>
                <Grid item xs={12}>
                  {/* select machines */}
                  <FormControl className={classes.formControl}>
                    <FormLabel component="legend">Select machines to this group</FormLabel>
                    <Controller
                      name="machineID"
                      rules={{ required: 'Machine is required' }}
                      render={({ field }) =>
                        machine.map((item, index) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => field.onChange(handleSelect(item._id))}
                                checked={checkedvalues.includes(item._id)}
                              />
                            }
                            key={item._id}
                            label={item.name + ' - ' + item.description}
                          />
                        ))
                      }
                      control={control}
                    />
                  </FormControl>
                </Grid>
                {errors.machineID && (
                  <p className="signUpErrorMessage">{errors.machineID.message}</p>
                )}
                <Grid item xs={12}>
                  {/* select users */}
                  <FormControl className={classes.formControl}>
                    <FormLabel component="legend">Select users to this group</FormLabel>
                    <Controller
                      name="userID"
                      render={({ field }) =>
                        employees.map((item, index) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() => field.onChange(handleSelect2(item._id))}
                                checked={checkedvalues2.includes(item._id)}
                              />
                            }
                            key={item._id}
                            label={item.lastname + ' ' + item.firstname + '(' + item.email + ')'}
                          />
                        ))
                      }
                      control={control}
                    />
                  </FormControl>
                </Grid>
                {errors.userID && <p className="signUpErrorMessage">{errors.userID.message}</p>}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {button}
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

import { Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(-5.6),
    marginRight: theme.spacing(43),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function BackButton(props) {
  const classes = useStyles();
  const history = useHistory();

  const goBack = async () => {
    history.goBack();
  };

  return (
    <div className={classes.paper}>
      {props.handle ? (
        <Button variant="contained" color="primary" onClick={props.handle}>
          Back
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={goBack}>
          Back
        </Button>
      )}
    </div>
  );
}

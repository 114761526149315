import React from 'react';

const Preket = ({ data, referTo, select }) => {
  let total = [];
  let reMake = [];
  let array = [];
  let f = 1;
  //deep copy
  const newData = JSON.parse(JSON.stringify(data));
  console.log('newData', newData);
  newData.forEach((e, index) => {
    e.shortage = e.max_stock - e.current_stock;

    const a = JSON.parse(JSON.stringify(e));
    const idx = total.findIndex((x) => x.id?.upc === a.id?.upc);
    if (idx > -1) {
      total[idx].shortage += a.shortage;
      total[idx].current_stock += a.current_stock;
      total[idx].max_stock += a.max_stock;
    } else {
      total.push(a);
    }

    if (f === e.floor && index !== data.length - 1) {
      array.push(e);
    } else {
      if (index === data.length - 1) {
        array.push(e);
        reMake.push(array);
      } else {
        reMake.push(array);
        array = [];
        array.push(e);
        ++f;
      }
    }
  });

  if (select?.name) {
    return (
      <div>
        <div
          id="pkToPrint2"
          style={{
            paddingTop: '15px',
            paddingBottom: '100px',
            width: '794px',
            height: '1123px',
            margin: 'auto',
          }}
        >
          <div style={{ fontSize: total.length <= 25 ? '35px' : '15px', marginBottom: '15px' }}>
            {select.name}
            {select.description ? `(${select.description})` : ''}
          </div>
          {/* <div style={{ paddingLeft: '20px', fontSize: '20x' }}> */}
          <div style={{ fontSize: '20x' }}>
            {total.length <= 17
              ? total.map((e) => {
                  return (
                    <div
                      style={{
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        textAlign: 'start',
                        marginLeft: '20px',
                      }}
                    >
                      <div
                        style={{
                          height: '60px',
                          width: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          style={{ maxHeight: '80%', maxWidth: '80%', marginRight: '14px' }}
                          alt="pdimg"
                          src={`${process.env.REACT_APP_SERVER_API}/pictures/${e.id?.productPictures[0]}/${referTo}`}
                        ></img>
                      </div>
                      <div>
                        <div style={{ width: '100px', display: 'inline-block' }}>
                          UPC: {e.id.upc}
                        </div>
                        <div style={{ width: '300px', display: 'inline-block' }}>
                          Name: {e.id.name?.en}
                        </div>
                        <div style={{ width: '100px', display: 'inline-block' }}>
                          Shortage: {e.shortage}
                        </div>
                        <div style={{ width: '70px', display: 'inline-block' }}>
                          Max: {e.max_stock}
                        </div>
                        <div style={{ width: '80px', display: 'inline-block' }}>
                          Current: {e.current_stock}
                        </div>
                      </div>
                    </div>
                  );
                })
              : total.map((e) => {
                  return (
                    <div
                      style={{
                        height: total.length <= 25 ? '30px' : '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        textAlign: 'start',
                        marginLeft: '20px',
                        fontSize: '10px !important',
                      }}
                    >
                      <div
                        style={{
                          height: total.length <= 25 ? '30px' : '15px',
                          width: total.length <= 25 ? '30px' : '15px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          style={{ maxHeight: '98%', maxWidth: '98%', marginRight: '14px' }}
                          alt="pdimg"
                          src={`${process.env.REACT_APP_SERVER_API}/pictures/${e.id?.productPictures[0]}/${referTo}`}
                        ></img>
                      </div>
                      <div className="d-flex">
                        <div style={{ width: '100px', display: 'inline-block' }}>
                          UPC: {e.id.upc}
                        </div>
                        <div style={{ width: '300px', display: 'inline-block' }}>
                          Name: {e.id.name?.en}
                        </div>
                        <div style={{ width: '100px', display: 'inline-block' }}>
                          Shortage: {e.shortage}
                        </div>
                        <div style={{ width: '70px', display: 'inline-block' }}>
                          Max: {e.max_stock}
                        </div>
                        <div style={{ width: '80px', display: 'inline-block' }}>
                          Current: {e.current_stock}
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        <div
          id="pkToPrint"
          style={{
            paddingTop: '70px',
            paddingBottom: '100px',
            width: '794px',
            height: '1123px',
            margin: 'auto',
          }}
        >
          <div style={{ width: '90%', margin: 'auto' }}>
            {reMake.map((floor, i) => {
              return (
                <div
                  key={i}
                  style={{
                    background: 'lightblue',
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {floor.map((slot) => {
                    return (
                      <div
                        style={{ height: '120px', paddingLeft: '1px', paddingRight: '1px' }}
                        key={slot.idx}
                      >
                        <div
                          style={{
                            height: '80px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            style={{ maxWidth: '80%', maxHeight: '80%' }}
                            alt="pdimg"
                            src={`${process.env.REACT_APP_SERVER_API}/pictures/${slot.id?.productPictures[0]}/${referTo}`}
                          ></img>
                        </div>
                        <div style={{ fontSize: '10px' }}> {slot.id?.upc}</div>
                        <div style={{ fontSize: '11px' }}> {`${slot.idx} (${slot.shortage})`}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default Preket;

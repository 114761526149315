import './App.css';
import { Switch, Route, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import ScrollToTopButton from './frontend/items/buttons/ScrollToTopButton';
import SignIn from './frontend/user/SignIn';
import { UserContext } from './frontend/contexts/userContext';
import Dashboard from './frontend/dashboard/Dashboard';
import ForgetPassword from './frontend/user/ForgetPassword';
import ResetPassword from './frontend/user/ResetPassword';
import NotFound from './frontend/404/NotFound';
import PlanoPage from './planogram/PlanoPage';
import EmailPreview from './frontend/dashboard/email/EmailPreview';
import Logout from './frontend/user/Logout';
import ScreenSaverPreview from './frontend/machines/ScreenSaverPreview';
import TCNplanoPage from './TCNplanogram/TCNplanoPage';

import PrivateRoute from './services/PrivateRoute';
import MyMap from './frontend/dashboard/googlemap/Map';

function App() {
  const location = useLocation();
  //restore user information from localstorage
  // localStorage.clear();
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  let initialState = { login: false, userName: '', role: '', email: '' };
  if (loggedInUser) {
    initialState.login = true;
    initialState.userName = loggedInUser.firstname;
    initialState.role = loggedInUser.role;
    initialState.email = loggedInUser.email;
    initialState.referTo = loggedInUser.referTo;
    initialState.fullAccess = loggedInUser.fullAccess;
    initialState.limits = loggedInUser.limits;
  }

  //for multi machine operation
  const [filterd, setFiltered] = useState([]);

  //user state
  const [loginStatus, setLoginStatus] = useState(initialState.login);
  const [user, setUser] = useState(initialState);
  const [userName, setUserName] = useState(initialState.userName);
  const [role, setRole] = useState(initialState.role);
  const [userEmail, setUserEmail] = useState(initialState.email);
  const [referTo, setReferTo] = useState(initialState.referTo);
  const [fullAccess, setFullAccess] = useState(initialState.fullAccess);
  //for checkout and cart
  const [testMode, setTestMode] = useState(false);

  const [reRender, setReRender] = useState(false);

  return (
    <div className="App">
      <UserContext.Provider
        value={{
          loginStatus,
          setLoginStatus,
          userName,
          user,
          setUser,
          setUserName,
          role,
          setRole,
          userEmail,
          setUserEmail,
          referTo,
          setReferTo,
          fullAccess,
          setFullAccess,
          reRender,
          setReRender,
          setFiltered,
          filterd,
          setTestMode,
          testMode,
        }}
      >
        <Switch location={location} key={location.pathname}>
          <PrivateRoute exact path="/" component={Dashboard} />
          <Route exact path="/plano" component={PlanoPage} />
          <Route exact path="/plano/:id/:prodid" component={PlanoPage} />
          <Route exact path="/TCNplano/:id/:prodid" component={TCNplanoPage} />
          <Route exact path="/INSplano/:id/:prodid" component={TCNplanoPage} />
          <Route exact path="/DELplano/:id/:prodid" component={TCNplanoPage} />
          <Route exact path="/showMachineAddress/:machines/:option/:showFull" component={MyMap} />
          <Route exact path="/showMachineAddress/:machines/:option" component={MyMap} />
          <Route exact path="/signIn" component={SignIn} />
          <Route exact path="/emailPreview/:id/:mid" component={EmailPreview} />
          <Route exact path="/forgetpassword" component={ForgetPassword} />
          <Route path="/resetpassword/:token" component={ResetPassword} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/logout" component={Logout} />
          <PrivateRoute path="/screenSaverPreview/:id" component={ScreenSaverPreview} />
          <Route component={NotFound} />
        </Switch>
      </UserContext.Provider>

      <ScrollToTopButton />
    </div>
  );
}

export default App;

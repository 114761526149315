import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useForm, Controller } from 'react-hook-form';
import {
  FormControl,
  Grid,
  FormLabel,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  TextField,
  Checkbox,
  MenuItem,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Swal from 'sweetalert2';
import { themes } from '../../staticData/theme';
import { languages_noEn } from '../../staticData/languages';

import { Modal, Row, Col } from 'react-bootstrap';

import request from '../../services/Request';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EditUserModal(props) {
  const { data, setReflesh, reflesh, ...rest } = props;
  const defaultValues = {
    additionPrice: false,
    disablePromo: false,
    disableMR: false,
    extra: false,
    temperature: false,
    disableAudio: false,
    disableAlert: false,
    advanced: false,
    theme: '0',
    langs: [],
    thirdPartyLink: '',
  };
  const { handleSubmit, reset, watch, control, register } = useForm({ defaultValues });

  const watchAll = watch();

  useEffect(() => {
    if (props.show && data) {
      console.log(data);
      let langArray = [];
      if (data.langs.length > 0) {
        data.langs.forEach((lan) => {
          const index = languages_noEn.findIndex((e) => e.code === lan);
          if (index > -1) {
            langArray.push(languages_noEn[index]);
          }
        });
      }
      reset({
        additionPrice: data.features?.additionPrice,
        disablePromo: data.features?.disablePromo,
        disableMR: data.features?.disableMR,
        extra: data.features?.extra,
        inspire: data.features?.inspire,
        temperature: data.features?.temperature,
        disableAudio: data.features?.disableAudio,
        disableAlert: data.features?.disableAlert,
        advanced: data.features?.advanced,
        theme: data.theme,
        langs: data.langs ? langArray : [],
        thirdPartyLink: data.thirdPartyLink,
      });
    }
  }, [props.show]);

  const onSubmit = async (data) => {
    const langs = data.langs.map((e) => e.code);
    const features = {
      additionPrice: data.additionPrice,
      disablePromo: data.disablePromo,
      disableMR: data.disableMR,
      extra: data.extra,
      inspire: data.inspire,
      temperature: data.temperature,
      disableAudio: data.disableAudio,
      disableAlert: data.disableAlert,
      advanced: data.advanced,
    };

    await request({
      method: 'patch',
      url: '/users/features',
      data: {
        id: props.data._id,
        features: features,
        thirdPartyLink: data.thirdPartyLink,
        theme: data.theme,
        langs: langs,
      },
    }).then((res) => {
      Swal.fire({
        text: `The user has been successfully updated`,
        icon: 'success',
      }).then(() => {
        reflesh ? setReflesh(false) : setReflesh(true);
        props.onHide();
      });
    });
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Admin's Features</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ textAlign: 'center', padding: '0 40px' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Admin's Features</FormLabel>
              </FormControl>
            </Grid>
            <Grid container item xs={12} spacing={2} style={{ textAlign: 'left' }}>
              <Grid item xs={6}>
                <Controller
                  name="additionPrice"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={watchAll.additionPrice} />}
                />
                <span>Addition Price</span>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="disablePromo"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={watchAll.disablePromo} />}
                />
                <span>Disable Promo</span>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="advanced"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={watchAll.advanced} />}
                />
                <span>Auto Translation</span>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="disableAlert"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={watchAll.disableAlert} />}
                />
                <span>Disable Alert &amp; Msg</span>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="disableMR"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={watchAll.disableMR} />}
                />
                <span>Disable Mk Report</span>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="disableAudio"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={watchAll.disableAudio} />}
                />
                <span>Disable Audio</span>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="extra"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={watchAll.extra} />}
                />
                <span>Extra</span>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="inspire"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={watchAll.inspire} />}
                />
                <span>Inspire</span>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="temperature"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={watchAll.temperature} />}
                />
                <span>Temperature</span>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">Themes</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <Select fullWidth defaultValue={watchAll.theme} {...field} variant="outlined">
                    {themes.map((e) => {
                      return <MenuItem value={e.code}>{e.name}</MenuItem>;
                    })}
                  </Select>
                )}
                name="theme"
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Third Party Link"
                name="thirdPartyLink"
                {...register('thirdPartyLink', {})}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel component="legend">Languages</FormLabel>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="langs"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    options={languages_noEn}
                    value={watchAll.langs}
                    disableCloseOnSelect
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                    getOptionSelected={(option, value) => option.code === value.code}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Languages" />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Button variant="contained" color="primary" type="submit" className="mt-3">
            Submit
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

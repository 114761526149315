// material
import { Grid, Container } from '@mui/material';
// components
// import Page from './Page';
import Average from './Cards/Average';
import AverageRev from './Cards/AverageRevenueTrans';
import TransUnit from './Cards/AveUnitTrans';
import TotalRev from './Cards/TotalRev';
import TotalTrans from './Cards/TotalTrans';
import UnitSold from './Cards/UnitSold';
import React, { useEffect, useState } from 'react';
import request from '../../../services/Request';
import ReportTable from './ReportTable';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import TableUPC from './TableUPC';
import { useWindowDimension } from '../../../services/method/useWindowsDimension';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Button } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { localDateRange } from '../../../services/method/methods';

// ----------------------------------------------------------------------
import { userLimits } from '../uitls/userLimits';

const getProdURL = `/products/`;
const getOrderByUPC = `/getOrderByProductID/`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  buttom: {
    width: 80,
    height: 40,
    marginTop: 15,
  },
}));

export default function ReportByUPC(props) {
  var date = new Date();
  const classes = useStyles();
  const [width, height] = useWindowDimension();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [done, setdone] = useState(false);

  const [rev, setRev] = useState(0);
  const [trans, setTrans] = useState(0);
  const [unit, setUnit] = useState(0);
  const [soldProd, setSoldProd] = useState([]);
  const [prodInfo, setProdInfo] = useState(null);
  const [machinesName, setmachinesName] = useState('');

  const [selectP, setSelectP] = useState({});
  const [products, setProducts] = useState([]);
  const [profit, setProfit] = useState(0);

  const { _id, id, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'view_report'
  );

  useEffect(() => {
    async function getProducts() {
      await request({
        method: 'get',
        url:
          props.match.params.group && superAdmin
            ? getProdURL + props.match.params.id + '/report'
            : getProdURL + _id + '/report',
      }).then((res) => {
        setProducts(res.data);
      });
    }
    getProducts();
    return () => {
      setProducts([]);
      setLoading(false);
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleChange = async () => {
    setLoading(false);
    // console.log(selectP?._id);
    const { startDateString, endDateString } = localDateRange(startDate, endDate);
    await request({
      method: 'get',
      url: getOrderByUPC + selectP?._id + '/' + startDateString + '/' + endDateString,
    }).then((res) => {
      setdone(true);
      let myRev = 0;
      let costTotal = 0;
      let myUnit = 0;
      let totaltrans = 0;
      const tableUPC = [];
      console.log('all Data', res.data);
      res.data.forEach((e) => {
        const beforeTax = e.total - e.tax;
        const taxp = parseFloat(((e.total - beforeTax) / beforeTax).toFixed(2));
        // console.log('tax', taxp);
        let allFailedTrans = false;

        e.products.forEach((prod) => {
          //change
          // prod.product.name = prod.productInfo?.name;
          if (!prod.err) {
            //ignore error Item
            if (selectP.upc === prod.productInfo.upc) {
              costTotal += prod.productInfo.costPrice ? prod.productInfo.costPrice : 0;
              // myRev += prod.tax_amount + prod.soldPrice;
              myRev += prod.soldPrice * (1 + taxp);
              myUnit += prod.quantity;
              // console.log(tableUPC);
              allFailedTrans = true;
              if (tableUPC.length === 0) {
                tableUPC.push({
                  name: e.machineInfo?.name,
                  description: e.machineInfo?.description,
                  quantity: prod.quantity,
                  soldPrice: prod.soldPrice,
                  costPrice: prod.productInfo.costPrice,
                  totalPrice: parseFloat((prod.soldPrice * (1 + taxp)).toFixed(2)),
                });
              } else {
                const index = tableUPC.findIndex((x) => x.name === e.machineInfo?.name);
                if (index === -1) {
                  tableUPC.push({
                    name: e.machineInfo?.name,
                    description: e.machineInfo?.description,
                    quantity: prod.quantity,
                    soldPrice: prod.soldPrice,
                    costPrice: prod.productInfo.costPrice,
                    totalPrice: parseFloat((prod.soldPrice * (1 + taxp)).toFixed(2)),
                  });
                } else {
                  tableUPC[index].quantity += prod.quantity;
                  tableUPC[index].soldPrice += prod.soldPrice;
                  tableUPC[index].totalPrice += parseFloat(
                    (prod.soldPrice * (1 + taxp)).toFixed(2)
                  );
                }
              }
            }
          }
        });
        if (allFailedTrans) {
          totaltrans += 1;
        }
      });
      const myprofit = myRev - costTotal;
      setProfit(myprofit);
      if (tableUPC.length > 0) {
        tableUPC.sort(function (a, b) {
          return b.quantity - a.quantity;
        });
      }
      console.log(tableUPC);
      setTrans(totaltrans);
      setRev(myRev);
      setUnit(myUnit);
      setOrder(res.data);
      setSoldProd(tableUPC);
      setmachinesName(selectP.name.en);
      setLoading(true);
    });
  };

  return (
    <Container maxWidth="xl" sx={{ pt: 5 }}>
      {!loading && <LoadingIcon />}
      <Grid container spacing={3}>
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <Grid xs={4} item container justifyContent="flex-start">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.formControl}
                value={startDate}
                onChange={(e) => handleStartDate(e)}
                label="Select start date"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-start"
              />
              <KeyboardDatePicker
                className={classes.formControl}
                value={endDate}
                onChange={(e) => handleEndDate(e)}
                label="Select end date"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-end"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4} style={{ marginLeft: '15px' }}>
            <Autocomplete
              value={selectP}
              fullWidth
              id="addToMachine"
              options={products}
              onChange={(event, value) => {
                setSelectP(value);
              }}
              getOptionLabel={(option) => (option?.upc ? `${option.upc} (${option.name?.en})` : '')}
              renderInput={(params) => (
                <TextField {...params} label="Select a Product" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={1} style={{ marginLeft: '15px' }}>
            <Button
              style={{ height: '40px' }}
              disabled={!selectP?.upc}
              onClick={() => {
                handleChange();
              }}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Grid>
        </Grid>

        {order.length > 0 && (
          <Grid container item spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <TotalRev rev={rev} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TotalTrans trans={trans} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <UnitSold unit={unit} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TotalRev rev={profit} title={'Gross Profit'} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}></Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AverageRev rev={rev} trans={trans} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TransUnit trans={trans} unit={unit} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}></Grid>

            <Grid item xs={12}>
              <ReportTable
                machinesName={machinesName}
                endDate={endDate}
                startDate={startDate}
                order={order}
              />
            </Grid>
            <Grid item xs={12}>
              <TableUPC
                soldProd={soldProd}
                machinesName={machinesName}
                endDate={endDate}
                startDate={startDate}
              />
            </Grid>
          </Grid>
        )}

        {order.length === 0 && done && (
          <div className="report-no-data">No Data Found On Your Selected Dates/Machines</div>
        )}
      </Grid>
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import axios from 'axios';

import './form.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  let history = useHistory();
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    counter > 0 && show && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter, show]);

  const resetPassWordURL = `${process.env.REACT_APP_SERVER_API}/users/forgotPassword`;

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (data) => {
    const email = getValues('email');
    setShow(true);
    setDisable(true);
    await axios
      .put(resetPassWordURL, {
        email: email,
      })
      .then((res) => {
        setTimeout(() => {
          return history.push('/signin');
        }, 5000);
      })
      .catch((err) => {
        if (err) {
          if (err.response.data)
            setError('email', {
              message: err.response.data.message,
            });
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset My Password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            {...register('email', {
              required: 'Email name is required',
              pattern: {
                value:
                  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                message: 'Please enter a valid email',
              },
            })}
          />
          {errors.email && <p className="signUpErrorMessage">{errors.email.message}</p>}
          <Button
            disabled={disable}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset My Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link style={{ float: 'left' }} href="/signin" variant="body2">
                sign In
              </Link>
            </Grid>
            {/* <Grid item>
              <Link style={{float:'right'}} href="/signUp" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
          </Grid>
        </form>
      </div>
      {show && <h5 style={{ marginTop: '30px' }}> The reset link has been sent to you email!</h5>}
      {show && (
        <h5 style={{ marginTop: '10px' }}> Redirect page to sign in in {counter} seconds</h5>
      )}
    </Container>
  );
}

import { Modal } from 'react-bootstrap';
import '../../items/formFields.css';
import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import request from '../../../services/Request';
import { ProgressBar } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { DeleteForeverOutlined } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const SIZELIMIT = 20000000;

export default function AddAudio(props) {
  const { _id, user } = props;
  const classes = useStyles();
  const [name, setName] = useState('');
  const [counter, setCounter] = useState('5');
  const [e1, setE1] = useState('');
  const [e2, setE2] = useState('');
  const [percent, setPercent] = useState(0);
  const [formData, setFormData] = useState(new FormData());

  const [fileNames, setFileNames] = useState({});

  const defaultValues = {
    name: '',
    start: '',
    cart: '',
    checkout: '',
    dispense: '',
    doorOpen: '',
    doorClose: '',
  };

  const {
    handleSubmit,
    control,
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = useForm({ defaultValues });

  const watchAllFields = watch();

  const handleRemove = (input) => {
    setPercent(0);
    setValue(input);
    fileNames[input] = null;
  };

  const onSubmit = (formData) => {
    const myData = new FormData();
    // if (fileNames.start) myData.append('startAudio', fileNames.start);
    // if (fileNames.cart) myData.append('cartAudio', fileNames.cart);
    // if (fileNames.checkout) myData.append('checkoutAudio', fileNames.checkout);
    // if (fileNames.dispense) myData.append('dispenseAudio', fileNames.dispense);
    // if (fileNames.doorOpen) myData.append('doorOpenAudio', fileNames.doorOpen);
    // if (fileNames.doorClose) myData.append('doorCloseAudio', fileNames.doorClose);
    myData.append('name', formData.name);
    myData.append('referTo', _id);
    if (formData.start && formData.start.length > 0) myData.append('start', formData.start[0]);
    if (formData.cart && formData.cart.length > 0) myData.append('cart', formData.cart[0]);
    if (formData.checkout && formData.checkout.length > 0)
      myData.append('checkout', formData.checkout[0]);
    if (formData.dispense && formData.dispense.length > 0)
      myData.append('dispense', formData.dispense[0]);
    if (formData.doorOpen && formData.doorOpen.length > 0)
      myData.append('doorOpen', formData.doorOpen[0]);
    if (formData.doorClose && formData.doorClose.length > 0)
      myData.append('doorClose', formData.doorClose[0]);

    request({
      method: 'post',
      url: `/audio/create`,
      data: myData,
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setPercent(percent);
        }
      },
    })
      .then((res) => {
        setPercent(100, () => {
          setTimeout(() => {
            setPercent(0);
          }, 1000);
        });
        Swal.fire({
          text: `New product has been successfully updated`,
          icon: 'success',
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 406) {
            setError('category', {
              message: err.response.data.message,
            });
          }
        }
      });
  };

  return (
    <Modal className="modal-w" {...props} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add New Audio</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField label="Name" variant="outlined" required {...field} />
                )}
              />
            </Grid>
            {errors.name && (
              <p style={{ color: 'red', marginLeft: '8px' }}>{errors.name.message}</p>
            )}
            <Grid item xs={12}>
              <Grid item xs={12}>
                <div>Touch The Screen To Start A Transaction</div>
              </Grid>
              <div className="d-flex align-items-center" style={{ height: '48px' }}>
                <Grid item xs={4}>
                  <Button variant="contained" component="label">
                    UPLOAD
                    <input
                      {...register('start', {
                        onChange: (e) => {
                          clearErrors('start');
                          setPercent(0);
                          fileNames.start = null;
                          if (e.target.files.length > 0 && e.target.files[0].size > SIZELIMIT) {
                            setError('start', {
                              message: 'File size too big, please provide the right size.',
                            });
                          }
                        },
                      })}
                      type="file"
                      accept=".wav"
                      hidden
                    />
                  </Button>
                </Grid>
                <Grid className="d-flex align-items-center" item xs={8}>
                  <span className="product-fileName-span">
                    {watchAllFields.start && watchAllFields.start.length > 0
                      ? watchAllFields.start[0].name
                      : fileNames.start
                      ? fileNames.start
                      : null}
                  </span>
                </Grid>
                {(fileNames.start || (watchAllFields.start && watchAllFields.start.length > 0)) && (
                  <IconButton onClick={() => handleRemove('start')}>
                    <DeleteForeverOutlined />
                  </IconButton>
                )}
              </div>
            </Grid>
            {errors.start && (
              <p style={{ color: 'red', marginLeft: '8px' }}>{errors.start.message}</p>
            )}

            {user?.features?.extra && (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <div>Open Shopping Cart</div>
                </Grid>
                <div className="d-flex align-items-center" style={{ height: '48px' }}>
                  <Grid item xs={4}>
                    <Button variant="contained" component="label">
                      UPLOAD
                      <input
                        {...register('cart', {
                          onChange: (e) => {
                            clearErrors('cart');
                            setPercent(0);
                            fileNames.cart = null;
                            if (e.target.files.length > 0 && e.target.files[0].size > SIZELIMIT) {
                              setError('cart', {
                                message: 'File size too big, please provide the right size.',
                              });
                            }
                          },
                        })}
                        type="file"
                        accept=".wav"
                        hidden
                      />
                    </Button>
                  </Grid>
                  <Grid className="d-flex align-items-center" item xs={8}>
                    <span className="product-fileName-span">
                      {watchAllFields.cart && watchAllFields.cart.length > 0
                        ? watchAllFields.cart[0].name
                        : fileNames.cart
                        ? fileNames.cart
                        : null}
                    </span>
                  </Grid>
                  {(fileNames.cart || (watchAllFields.cart && watchAllFields.cart.length > 0)) && (
                    <IconButton onClick={() => handleRemove('cart')}>
                      <DeleteForeverOutlined />
                    </IconButton>
                  )}
                </div>
              </Grid>
            )}

            {errors.cart && (
              <p style={{ color: 'red', marginLeft: '8px' }}>{errors.cart.message}</p>
            )}

            <Grid item xs={12}>
              <Grid item xs={12}>
                <div>Tap On Check Out</div>
              </Grid>
              <div className="d-flex align-items-center" style={{ height: '48px' }}>
                <Grid item xs={4}>
                  <Button variant="contained" component="label">
                    UPLOAD
                    <input
                      {...register('checkout', {
                        onChange: (e) => {
                          clearErrors('checkout');
                          setPercent(0);
                          fileNames.checkout = null;
                          if (e.target.files.length > 0 && e.target.files[0].size > SIZELIMIT) {
                            setError('checkout', {
                              message: 'File size too big, please provide the right size.',
                            });
                          }
                        },
                      })}
                      type="file"
                      accept=".wav"
                      hidden
                    />
                  </Button>
                </Grid>
                <Grid className="d-flex align-items-center" item xs={8}>
                  <span className="product-fileName-span">
                    {watchAllFields.checkout && watchAllFields.checkout.length > 0
                      ? watchAllFields.checkout[0].name
                      : fileNames.checkout
                      ? fileNames.checkout
                      : null}
                  </span>
                </Grid>
                {(fileNames.checkout ||
                  (watchAllFields.checkout && watchAllFields.checkout.length > 0)) && (
                  <IconButton onClick={() => handleRemove('checkout')}>
                    <DeleteForeverOutlined />
                  </IconButton>
                )}
              </div>
            </Grid>
            {errors.checkout && (
              <p style={{ color: 'red', marginLeft: '8px' }}>{errors.checkout.message}</p>
            )}

            <Grid item xs={12}>
              <Grid item xs={12}>
                <div>During Dispense</div>
              </Grid>
              <div className="d-flex align-items-center" style={{ height: '48px' }}>
                <Grid item xs={4}>
                  <Button variant="contained" component="label">
                    UPLOAD
                    <input
                      {...register('dispense', {
                        onChange: (e) => {
                          clearErrors('dispense');
                          setPercent(0);
                          fileNames.dispense = null;
                          if (e.target.files.length > 0 && e.target.files[0].size > SIZELIMIT) {
                            setError('dispense', {
                              message: 'File size too big, please provide the right size.',
                            });
                          }
                        },
                      })}
                      type="file"
                      accept=".wav"
                      hidden
                    />
                  </Button>
                </Grid>
                <Grid className="d-flex align-items-center" item xs={8}>
                  <span className="product-fileName-span">
                    {watchAllFields.dispense && watchAllFields.dispense.length > 0
                      ? watchAllFields.dispense[0].name
                      : fileNames.dispense
                      ? fileNames.dispense
                      : null}
                  </span>
                </Grid>
                {(fileNames.dispense ||
                  (watchAllFields.dispense && watchAllFields.dispense.length > 0)) && (
                  <IconButton onClick={() => handleRemove('dispense')}>
                    <DeleteForeverOutlined />
                  </IconButton>
                )}
              </div>
            </Grid>
            {errors.dispense && (
              <p style={{ color: 'red', marginLeft: '8px' }}>{errors.dispense.message}</p>
            )}

            <Grid item xs={12}>
              <Grid item xs={12}>
                <div>Door Opens</div>
              </Grid>
              <div className="d-flex align-items-center" style={{ height: '48px' }}>
                <Grid item xs={4}>
                  <Button variant="contained" component="label">
                    UPLOAD
                    <input
                      {...register('doorOpen', {
                        onChange: (e) => {
                          clearErrors('doorOpen');
                          setPercent(0);
                          fileNames.doorOpen = null;
                          if (e.target.files.length > 0 && e.target.files[0].size > SIZELIMIT) {
                            setError('doorOpen', {
                              message: 'File size too big, please provide the right size.',
                            });
                          }
                        },
                      })}
                      type="file"
                      accept=".wav"
                      hidden
                    />
                  </Button>
                </Grid>
                <Grid className="d-flex align-items-center" item xs={8}>
                  <span className="product-fileName-span">
                    {watchAllFields.doorOpen && watchAllFields.doorOpen.length > 0
                      ? watchAllFields.doorOpen[0].name
                      : fileNames.doorOpen
                      ? fileNames.doorOpen
                      : null}
                  </span>
                </Grid>
                {(fileNames.doorOpen ||
                  (watchAllFields.doorOpen && watchAllFields.doorOpen.length > 0)) && (
                  <IconButton onClick={() => handleRemove('doorOpen')}>
                    <DeleteForeverOutlined />
                  </IconButton>
                )}
              </div>
            </Grid>
            {errors.doorOpen && (
              <p style={{ color: 'red', marginLeft: '8px' }}>{errors.doorOpen.message}</p>
            )}

            <Grid item xs={12}>
              <Grid item xs={12}>
                <div>After Door Closes</div>
              </Grid>
              <div className="d-flex align-items-center" style={{ height: '48px' }}>
                <Grid item xs={4}>
                  <Button variant="contained" component="label">
                    UPLOAD
                    <input
                      {...register('doorClose', {
                        onChange: (e) => {
                          clearErrors('doorClose');
                          setPercent(0);
                          fileNames.doorClose = null;
                          if (e.target.files.length > 0 && e.target.files[0].size > SIZELIMIT) {
                            setError('doorClose', {
                              message: 'File size too big, please provide the right size.',
                            });
                          }
                        },
                      })}
                      type="file"
                      accept=".wav"
                      hidden
                    />
                  </Button>
                </Grid>
                <Grid className="d-flex align-items-center" item xs={8}>
                  <span className="product-fileName-span">
                    {watchAllFields.doorClose && watchAllFields.doorClose.length > 0
                      ? watchAllFields.doorClose[0].name
                      : fileNames.doorClose
                      ? fileNames.doorClose
                      : null}
                  </span>
                </Grid>
                {(fileNames.doorClose ||
                  (watchAllFields.doorClose && watchAllFields.doorClose.length > 0)) && (
                  <IconButton onClick={() => handleRemove('doorClose')}>
                    <DeleteForeverOutlined />
                  </IconButton>
                )}
              </div>
            </Grid>
            {errors.doorClose && (
              <p style={{ color: 'red', marginLeft: '8px' }}>{errors.doorClose.message}</p>
            )}
          </Grid>

          <div style={{ display: 'inline-block' }}></div>

          <p className="signUpErrorMessage" style={{ marginLeft: '10px' }}>
            {e1 != '' && e1}
          </p>

          <p style={{ marginLeft: '10px' }} className="signUpErrorMessage">
            {e2 != '' && e2}
          </p>
          {percent > 0 && (
            <ProgressBar
              style={{ marginLeft: '9px', marginTop: '10px' }}
              now={percent}
              active
              label={`${percent}%`}
            />
          )}
          <p style={{ marginLeft: '10px', marginTop: '10px' }}>
            Only wav format is accepted! Files size can not be exceed 20MB.
          </p>
          <Button className={classes.submit} variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';

export default function Logout(props) {
  const { setLoginStatus } = useContext(UserContext);

  const history = useHistory();
  useEffect(() => {
    setLoginStatus(false);
    history.push('/signIn');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('MagexApp.cart');
    localStorage.removeItem('machineName');
    localStorage.removeItem('reRender');
    localStorage.removeItem('limits');
  }, []);

  return null;
}

import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/userContext';
import request from '../../services/Request';
import MaterialTable from 'material-table';
import BackButton from '../items/buttons/BackButton';
import LoadingIcon from '../items/buttons/LoadingIcon';
import Swal from 'sweetalert2';
import AddPlan from './AddPlan';
import { userLimits } from '../dashboard/uitls/userLimits';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ProductPositionModal from './ArrangeProduct/ProductPositionModal';
import { CloudDownload } from '@material-ui/icons';
import { ExportToCsv } from 'export-to-csv';

const addProdToMachineURL = `/machines/updateProduct`;
const getMachineURL = `/machines/getMachineProductOverPlano`;

export default function AddProductToMachine(props) {
  const { filterd } = useContext(UserContext);
  const { id, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    '',
    'edit_machine'
  );
  const user = JSON.parse(localStorage.getItem('user'));
  const additionPriceOn = user?.features?.additionPrice;
  const theme = user?.theme;
  const machineID = props.match.params.id != 'all' ? props.match.params.id : null;

  // const [allProds, setAllProds] = useState([]);
  const [finalProd, setFinalProd] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reflesh, setReflesh] = useState(false);
  const [title, setTitle] = useState('');
  const [all, setAll] = useState(true);
  const tableRef = React.useRef();

  const [rowData, setRowData] = useState([]);
  const [show, setshow] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [arrangeBrand, setArrangeBrand] = useState([]);
  const [dPlan, setdPlan] = useState(false);
  const [showOnce, setShowOnce] = useState(true);

  const downloadProductCsv = async () => {
    console.log('finalProd', finalProd);
    let newArray = [];
    finalProd.forEach((element) => {
      const newObj = {};
      newObj.name = element.id.name;
      newObj.upc = element.id.upc;
      newObj.price = element.id.price;
      newObj.modified_price = element.nPrice ? element.nPrice : '';
      newObj.category = element.category;
      newObj.best_seller = element.bestSeller;
      newArray.push(newObj);
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Machine_Products',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Machine_Products',
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(newArray);
  };

  useEffect(() => {
    // console.log(filterd[0]);
    const filterdID = filterd.map((e) => e._id);
    const getMachine = async () => {
      await request({
        method: 'post',
        url: getMachineURL,
        data: {
          machineID: filterdID,
        },
      }).then((res) => {
        console.log('edit products', res.data);
        let myP = [...res.data[0]];
        myP?.forEach((e) => {
          e.id.name = e.id.name.en;
        });
        let finalProd = [];

        myP.forEach((prod) => {
          //setcategory
          let category = '';
          prod.id?.category.forEach((element, i) => {
            category += element.name?.en;
            if (i < prod.id?.category?.length - 1) {
              category += ', ';
            }
          });
          prod.category = category;

          const index = res.data[1]?.findIndex((e) => e.id === prod.id?._id);
          const index2 = res.data[2]?.findIndex((e) => e.id === prod.id?._id);
          const index3 = res.data[3]?.findIndex((e) => e === prod.id?._id);
          const index4 = res.data[4]?.findIndex((e) => e === prod.id?._id);

          // combind price
          if (index > -1) {
            prod.nPrice = res.data[1][index].price;
          }

          if (index2 > -1) {
            prod.plans = res.data[2][index2].plan;
          } else {
            prod.plans = null;
          }

          if (index3 > -1) {
            prod.bestSeller = true;
          } else {
            prod.bestSeller = false;
          }

          //hide product
          if (index4 > -1) {
            prod.hide = true;
          } else {
            prod.hide = false;
          }

          //change
          if (finalProd.length === 0) {
            finalProd.push({
              id: prod.id,
              plans: prod.plans,
              nPrice: prod.nPrice,
              bestSeller: prod.bestSeller,
              category: prod.category,
              hide: prod.hide,
              // current_stock: prod.current_stock,
            });
          } else {
            if (prod.id) {
              const index = finalProd.findIndex((x) => x.id?._id === prod.id?._id);
              if (index === -1) {
                const addP = {
                  id: prod.id,
                  plans: prod.plans,
                  nPrice: prod.nPrice,
                  bestSeller: prod.bestSeller,
                  category: prod.category,
                  hide: prod.hide,
                  // current_stock: prod.current_stock,
                };
                finalProd.push(addP);
              } else {
                // finalProd[index].current_stock += prod.current_stock;
              }
            }
          }
        });
        // console.log(myP);
        // setAllProds(myP);
        finalProd.sort(function (a, b) {
          if (a.bestSeller > b.bestSeller) {
            return -1;
          }
          if (a.bestSeller < b.bestSeller) {
            return 1;
          }
          return res.data[3].indexOf(a.id._id) - res.data[3].indexOf(b.id._id);
        });
        setFinalProd(finalProd);
        setLoading(true);
        // idArray = res.data.product.map(e=>e.id)
        // mc = res.data;
      });
    };
    getMachine();

    let string = '';
    filterd.forEach((e) => {
      string += e.name + ', ';
    });
    string = string.substring(0, string.length - 2);
    setTitle(string);

    return () => {
      // setAllProds([]);
      setFinalProd([]);
    };
  }, [reflesh]);

  const updateProduct = async (data) => {
    console.log(finalProd);
    const prodPrice = [];
    const prodPlan = [];
    const prodBs = [];
    const prodHide = [];
    let bsCount = 0;
    let hideCount = 0;
    data.forEach((prod) => {
      if (prod.nPrice) {
        prodPrice.push({ id: prod.id._id, price: prod.nPrice ? prod.nPrice : prod.id.price });
      }
      if (prod.bestSeller) {
        prodBs.push(prod.id._id);
        bsCount++;
      }

      if (prod.plans) {
        prodPlan.push({
          id: prod.id._id,
          plan: prod.plans,
        });
      }
      if (prod.hide) {
        prodHide.push(prod.id._id);
        hideCount++;
      }
    });
    console.log(prodHide, 'prodHide');

    await request({
      method: 'post',
      url: addProdToMachineURL,
      data: {
        machineID: machineID ? new Array(machineID) : filterd.map((e) => e._id),
        product: prodPrice.length > 0 ? prodPrice : null,
        plan: prodPlan.length > 0 || dPlan ? prodPlan : null,
        bs: prodBs.length > 0 || bsCount === 0 ? prodBs : null,
        hide: prodHide.length > 0 || hideCount === 0 ? prodHide : null,
      },
    }).then((res) => {
      Swal.fire({
        text: `The product has been successfully updated`,
        icon: 'success',
        target: document.getElementById('swal2-container'),
      }).then((e) => {
        reflesh ? setReflesh(false) : setReflesh(true);
      });
    });
  };

  const updateProd = async (id, price) => {
    // const index = allProds.findIndex((e) => e.id._id == id);
    // if (index > -1) allProds[index].nPrice = price;
    // console.log(id);
    let newArray = Object.values(id);
    newArray = newArray.map((e) => e.newData);

    newArray.forEach((changes) => {
      //edit origin array
      // allProds.forEach((element) => {
      //   if (element.id._id === changes.id._id) {
      //     element.nPrice = changes.nPrice;
      //     element.bestSeller = changes.bestSeller;
      //   }
      // });
      //edit filtered array
      finalProd.forEach((element) => {
        if (element.id._id === changes.id._id) {
          element.nPrice = changes.nPrice;
          element.bestSeller = changes.bestSeller;
          element.hide = changes.hide;
        }
      });
    });
  };

  const addPlan = async (data) => {
    setRowData(data);
    setshow(true);
  };

  const handleSubmit = async (data) => {
    const prodsb = [];

    arrangeBrand.forEach((prod) => {
      prodsb.push(prod.id._id);
    });
    // console.log(prodsb);
    await request({
      method: 'post',
      url: addProdToMachineURL,
      data: {
        machineID: machineID ? new Array(machineID) : filterd.map((e) => e._id),
        bs: prodsb,
      },
    }).then((e) => {
      setShowBrand(false);
      Swal.fire('', 'Position saved', 'success').then((e) => {
        reflesh ? setReflesh(false) : setReflesh(true);
      });
    });
  };

  const arrangeProduct = async (data) => {
    let newBrand = [...finalProd];
    newBrand = newBrand.filter((e) => {
      e._id = e.id._id;
      if (e.bestSeller) {
        return e;
      }
    });
    setArrangeBrand(newBrand);
    setShowBrand(true);
  };

  const selectAll = async (data) => {
    data.forEach((changes) => {
      finalProd.forEach((element) => {
        if (element.id._id === changes.id._id) {
          element.bestSeller = true;
        }
      });
    });
    setFinalProd([...finalProd]);

    Swal.fire({
      text: `Please disable edit mode(if you are in edit mode) to see the changes`,
      icon: 'warning',
    });
  };

  const unSelectAll = async (data) => {
    data.forEach((changes) => {
      finalProd.forEach((element) => {
        if (element.id._id === changes.id._id) {
          element.bestSeller = false;
        }
      });
    });
    setFinalProd([...finalProd]);

    Swal.fire({
      text: `Please disable edit mode(if you are in edit mode) to see the changes`,
      icon: 'warning',
    });
  };

  const deletePlan = async (data) => {
    // const index = allProds.findIndex((e) => e.id?._id === data.id?._id);
    const index2 = finalProd.findIndex((e) => e.id?._id === data?.id._id);
    // if (index > -1) {
    //   allProds[index].plans = null;
    // }
    if (index2 > -1) {
      finalProd[index2].plans = null;
      console.log('22222');
    }
    console.log(finalProd);
    setFinalProd([...finalProd]);
    setdPlan(true);
  };

  const columns = [
    {
      title: 'Best Seller',
      field: 'bestSeller',
      type: 'boolean',
    },
    {
      title: 'Disable',
      field: 'hide',
      type: 'boolean',
    },
    {
      title: 'Picture',
      field: 'id.productPicture',
      editable: 'never',
      render: (rowData) => (
        <img
          src={`${process.env.REACT_APP_SERVER_API}/pictures/${rowData.id.productPictures[0]}/${id}`}
          style={{ width: 200 }}
        />
      ),
    },
    { title: 'Product Name', editable: 'never', field: 'id.name' },
    { title: 'UPC/SKU', editable: 'never', field: 'id.upc' },
    {
      title: 'Category',
      field: 'category',
      editable: 'never',
    },
    {
      title: 'Plan',
      editable: 'never',
      field: 'plans',
      render: (rowData) =>
        rowData.plans &&
        rowData.plans.plan.map((e) => {
          return <p>{e.name}</p>;
        }),
    },

    {
      title: 'Price',
      field: 'nPrice',
      type: 'numeric',
      render: (rowData) => (rowData.nPrice ? `$${rowData.nPrice}` : `$${rowData.id.price}`),
      editable: additionPriceOn ? 'never' : 'always',
    },
  ];

  return (
    <div>
      <h4
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '80%',
        }}
      >
        {' '}
        Update Products for the Machines: {title}{' '}
      </h4>
      {!loading ? (
        <LoadingIcon />
      ) : (
        <div style={{ maxWidth: '100%' }}>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={finalProd}
            title=""
            editable={
              access && {
                onBulkUpdate: (changes) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      updateProd(changes);
                      resolve();
                    }, 1000);
                  }),
              }
            }
            actions={[
              access && {
                tooltip: 'Select All Best Seller',
                isFreeAction: true,
                icon: CheckBoxIcon,
                onClick: (evt, data) => {
                  selectAll(tableRef.current.dataManager?.searchedData);
                },
              },
              access && {
                tooltip: 'uncheck All Best Seller',
                isFreeAction: true,
                icon: CancelIcon,
                onClick: (evt, data) => {
                  unSelectAll(tableRef.current.dataManager?.searchedData);
                },
              },
              access && {
                // }, //   }), //     fillAllProduct(data); //   onClick: ((evt, data) => { //   icon: AddCircleOutlineSharpIcon, //   isFreeAction:true, //   tooltip: 'Fill all products', // {
                tooltip: 'Save My changes',
                isFreeAction: true,
                icon: 'save',
                onClick: (evt, data) => {
                  updateProduct(finalProd);
                },
              },
              !additionPriceOn && {
                icon: RestartAltIcon,
                tooltip: 'Reset Price',
                isFreeAction: true,
                onClick: (event) => {
                  Swal.fire({
                    title: 'Are you sure to reset all price?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, reset it!',
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await request({
                        method: 'post',
                        url: addProdToMachineURL,
                        data: {
                          machineID: machineID ? new Array(machineID) : filterd.map((e) => e._id),
                          product: [],
                        },
                      }).then((e) => {
                        Swal.fire(
                          'Price reset!',
                          'All products is reset to default price.',
                          'success'
                        ).then((e) => {
                          reflesh ? setReflesh(false) : setReflesh(true);
                        });
                      });
                    }
                  });
                },
              },
              access && {
                tooltip: 'Arrange Products',
                isFreeAction: true,
                icon: LowPriorityIcon,
                onClick: (evt, data) => {
                  arrangeProduct(finalProd);
                },
              },
              {
                tooltip: 'Add/Edit a plan',
                icon: 'add',
                onClick: (evt, data) => {
                  addPlan(data);
                },
              },
              (rowData) => ({
                tooltip: 'delete a plan',
                icon: 'delete',
                onClick: (evt, data) => {
                  deletePlan(data);
                },
                hidden: !rowData.plans,
              }),
              {
                isFreeAction: true,
                tooltip: 'Download CSV',
                icon: CloudDownload,
                onClick: (evt, data) => {
                  downloadProductCsv(finalProd);
                },
              },

              // {
              //   tooltip: 'Fill the products',
              //   position: 'row',
              //   icon: AddCircleOutlineSharpIcon,
              //   onClick: ((evt, data) => {
              //     console.log(data)
              //     fillOneProduct(data);
              //   }),
              // },
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
          <BackButton />
          {/* <SaveChangeButton title="Save Changes" handle={saveChange}/> */}
        </div>
      )}
      <AddPlan
        show={show}
        reflesh={+reflesh}
        setReflesh={setReflesh}
        data={rowData}
        // allProds={allProds}
        finalProd={finalProd}
        id={id}
        onHide={() => setshow(false)}
      />
      <ProductPositionModal
        show={showBrand}
        brand={arrangeBrand}
        setBrand={setArrangeBrand}
        onHide={() => setShowBrand(false)}
        handleSubmit={handleSubmit}
        referTo={filterd[0].belongTo}
      />
    </div>
  );
}

// material
import MaterialTable from 'material-table';
import { CloudDownload } from '@material-ui/icons';
import { Email } from '@material-ui/icons';
import request from '../../../services/Request';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { MTableToolbar } from 'material-table';

export default function ProdTable(props) {
  const { prodInfo } = props;

  const [data, setData] = useState(prodInfo ? prodInfo.nprod : []);

  useEffect(() => {
    setData(prodInfo ? prodInfo.nprod : []);
  }, [prodInfo]);

  const columns = [
    // { title: 'Product Name', field: 'name', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '40%'} },
    // { title: 'Quantity Sold', field: 'quantity' },
    // { title: 'Regular Price', field: 'price', render: rowData => `$${rowData.price}` },
    // { title: 'Sale Amount', field: 'totalSale', render: rowData => `$${rowData.totalSale}` },
    {
      field: 'id.name',
      title: 'Name',
      cellStyle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '50%',
      },
    },
    { field: 'id.upc', title: 'UPC/SKU' },
    { field: 'id.price', title: 'Price', render: (rowData) => `$${rowData.id.price.toFixed(2)}` },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      title="Products not sold"
      components={{
        Toolbar: (props) => (
          <div style={{ height: '43px' }}>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
      options={{
        search: false,
        actionsColumnIndex: -1,
        pageSize: 3,
      }}
    />
  );
}

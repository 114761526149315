// material
import { Card, CardHeader, Box } from '@mui/material';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { CloudDownload } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { Email } from '@material-ui/icons';
import request from '../../../services/Request';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { useState, useEffect } from 'react';

export default function ReportTable(props) {
  const { order, startDate, endDate, machinesName, id } = props;

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_report1') ? localStorage.getItem('table_view_report1') : 10
  );
  const [filteredData, setFilteredData] = useState([]); // State to hold filtered data

  useEffect(() => {
    localStorage.setItem('table_view_report1', pagination);
  }, [pagination]);

  const columns = [
    {
      title: 'Order ID',
      field: 'id',
    },
    {
      title: 'Order Date',
      field: 'createdAt',
      render: (rowData) => {
        let date = new Date(rowData.createdAt);
        //show keep old trans date
        if (rowData.realTime) {
          date = moment.utc(date).format('lll');
        } else {
          date = moment(date).format('lll');
        }
        return date;
      },
    },
    { title: 'Machine ID', field: 'machineInfo.name' },
    { title: 'Machine Desc', field: 'machineInfo.description' },
    { title: 'Currency', field: 'currency' },
    { title: 'Tax', field: 'tax', render: (rowData) => `${rowData.tax.toFixed(2)}` },
    { title: 'Sale Amount', field: 'total', render: (rowData) => `${rowData.total.toFixed(2)}` },
    {
      title: 'Email Receipt',
      field: 'email',
      render: (rowData) => {
        if (rowData.email) {
          if (rowData.isCheck) {
            return <span style={{ color: 'blue' }}>{rowData.email}</span>;
          } else {
            return `${rowData.email}`;
          }
        } else {
          return null;
        }
      },
    },
    { title: 'Code Used', field: 'otc' },
    {
      title: 'Payment Type',
      field: 'custom',
      render: (rowData) =>
        `${
          rowData.payment_type === 'HF'
            ? rowData.custom
            : rowData.payment_type
            ? rowData.payment_type
            : ''
        }`,
    },
    {
      title: 'Status',
      field: 'status',
    },
  ];

  const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
    <Typography variant={variant} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {`Transaction Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
    </Typography>
  );

  const handleSearchChange = (searchText) => {
    // Perform filtering operation on data based on searchText
    const filteredData = order.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase().trim())
      )
    );
    // Update filteredData state
    console.log('filteredData', filteredData);
    setFilteredData(filteredData);
  };

  return (
    <MaterialTable
      onChangeRowsPerPage={setPagination}
      columns={columns}
      data={order}
      onSearchChange={handleSearchChange}
      title={<MyNewTitle variant="h6" />}
      detailPanel={(rowData) => {
        // console.log(rowData);
        return (
          <TableContainer component={Paper} style={{ backgroundColor: '#EBEBE8' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>UPC/SKU</TableCell>
                  <TableCell align="right">Discount</TableCell>
                  <TableCell align="right">Promotion Used</TableCell>
                  <TableCell align="right">Code Sent</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Cost Price(unit)</TableCell>
                  <TableCell align="right">Cost Price(total)</TableCell>
                  <TableCell align="right">Unit Price</TableCell>
                  <TableCell align="right">Sub Total without Tax</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.products.map(
                  (prod, i) =>
                    prod.productInfo && (
                      <TableRow key={i} style={prod.err ? { backgroundColor: '#fea7a5' } : {}}>
                        <TableCell component="th" scope="row">
                          {prod.productInfo.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {prod.productInfo.upc}
                        </TableCell>
                        <TableCell align="right">{'' + prod.discount.toFixed(2)}</TableCell>
                        {prod.promoType ? (
                          <TableCell align="right">
                            {prod.codeEntered} ({prod.promoType})
                          </TableCell>
                        ) : prod.promoInfo ? (
                          <TableCell align="right">
                            {prod.promoInfo.title} ({prod.promoInfo.promoType})
                          </TableCell>
                        ) : (
                          <TableCell align="right">No Promotion</TableCell>
                        )}
                        {prod.code ? (
                          <TableCell align="right">{prod.code}</TableCell>
                        ) : (
                          <TableCell align="right">No Code</TableCell>
                        )}
                        <TableCell align="right">{prod.quantity}</TableCell>
                        <TableCell align="right">{prod.productInfo?.costPrice}</TableCell>
                        <TableCell align="right">
                          {(prod.productInfo?.costPrice * prod.quantity).toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          {(prod.soldPrice / prod.quantity).toFixed(2)}
                        </TableCell>
                        <TableCell align="right">{prod.soldPrice.toFixed(2)}</TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
      options={{
        actionsColumnIndex: -1,
        pageSize: parseInt(pagination),
        pageSizeOptions: [5, 10, 20, 50, 100, 200],
      }}
      actions={[
        {
          tooltip: 'Download CSV(Per Trans)',
          isFreeAction: true,
          icon: CloudDownload,
          onClick: (evt, rowData) => {
            console.log('rowData', evt);
            console.log('rd', rowData);
            let length = 0;
            let downloaddata = filteredData?.length === 0 ? [...order] : [...filteredData];
            const filteredArray = downloaddata.filter((e) => e.products.some((e) => !e.err));

            let outPutArray = filteredArray.map((e) => {
              if (e.products.length > length) length = e.products.length;
              let date = new Date(e.createdAt);
              let date1 = new Date(e.createdAt);
              if (e.realTime) {
                date = moment.utc(date).format('lll');
                date1 = moment.utc(date).format('MM/DD/YYYY');
              } else {
                date = moment(date).format('lll');
                date1 = moment(date).format('MM/DD/YYYY');
              }
              date = date.replaceAll(',', ' ');
              //replace description too, because it may contain comma
              if (e.machineInfo.description) {
                e.machineInfo.description = e.machineInfo.description.replaceAll(',', ';');
              }
              return {
                Order_ID: e.id,
                Order_Date: date,
                Order_Date_Only: date1,
                Machine_ID: e.machineInfo.name,
                Machine_Desc: e.machineInfo.description,
                currency: e.currency,
                Tax: e.tax,
                Sale_Amount: e.total,
                Products: e.products,
                Email: e.email,
                One_Time_Code: e.otc,
                Subscripted: e.isCheck,
                payment_type: e.payment_type,
              };
            });

            let csv = 'data:text/csv;charset=utf-8,';

            let header = `Order_ID,Order_Date,Order_Date_Only,Machine_ID,Machine_Desc,Currency,Tax,Sale_Amount,Email,Email_Subscribed,Code_Used,Payment_Type,`;

            for (var i = 1; i <= length; i++) {
              header += `Product${i} Name,Product${i} UPC/SKU,Product${i} Cost Price(unit),Product${i} Cost Price(total),Product${i} Unit Price, Product${i} Quantity,Product${i} Sub Total without Tax,Product${i} Tax,Product${i} Promotion Used,Product${i} Code Sent,Product${i} Discount, `;
            }

            let values = outPutArray
              .map((o) => {
                let r = '';
                r +=
                  o.Order_ID +
                  ',' +
                  o.Order_Date +
                  ',' +
                  o.Order_Date_Only +
                  ',' +
                  o.Machine_ID +
                  ',' +
                  o.Machine_Desc +
                  ',' +
                  o.currency +
                  ',' +
                  o.Tax +
                  ',' +
                  o.Sale_Amount +
                  ',';
                if (o.Email) r += o.Email + ',';
                else r += ' ,';

                if (o.Subscripted) r += o.Subscripted + ',';
                else r += ' ,';

                if (o.One_Time_Code) r += o.One_Time_Code + ',';
                else r += ' ,';

                if (o.payment_type) r += o.payment_type + ',';
                else r += ' ,';

                o.Products.forEach((element, idx) => {
                  if (!element.err) {
                    let i = idx + 1;
                    if (element.productInfo) {
                      const name = element.productInfo?.name?.replaceAll(',', ';');

                      r += name + ',';
                      r += element.productInfo.upc + ',';
                      r += element.productInfo.costPrice + ',';
                      r += element.quantity + ',';
                      r += element.productInfo.price + ',';
                    } else {
                      r += ' ,';
                      r += ' ,';
                      r += ' ,';
                      r += ' ,';
                    }

                    r += element.quantity + ',' + element.soldPrice + ',';
                    if (element.tax_amount) {
                      r += element.tax_amount + ',';
                    } else {
                      r += 0 + ',';
                    }
                    if (element.promoInfo)
                      r += element.promoInfo.title + '(' + element.promoInfo.promoType + ')' + ',';
                    else r += ' ,';
                    if (element.code) r += element.code + ',';
                    else r += ' ,';
                    r += element.discount + ' ,';
                  }
                });
                return r;
              })
              .join('\n');
            csv += header + '\n' + values;
            var encodedUri = encodeURI(csv);
            var link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'my_transaction_data.csv');
            document.body.appendChild(link); // Required for FF

            link.click();
          },
        },
        {
          tooltip: 'Download CSV(Per Item)',
          isFreeAction: true,
          icon: CloudDownload,
          onClick: (evt, data) => {
            let downloaddata = filteredData?.length === 0 ? [...order] : [...filteredData];
            const filteredArray = downloaddata;
            let length = 0;
            let outPutArray = [];
            filteredArray?.forEach((e) => {
              let date = new Date(e.createdAt);
              let date1 = new Date(e.createdAt);
              if (e.realTime) {
                date = moment.utc(date).format('lll');
                date1 = moment.utc(date).format('MM/DD/YYYY');
              } else {
                date = moment(date).format('lll');
                date1 = moment(date).format('MM/DD/YYYY');
              }
              date = date.replace(',', ' ');
              // console.log(order);
              for (var i = 0; i < e.products?.length; i++) {
                const thisData = {
                  Order_ID: e.id,
                  Order_Date: date,
                  Order_Date_Only: date1,
                  Machine_ID: e.machineInfo.name,
                  Machine_Desc: e.machineInfo.description,
                  Currency: e.currency,
                  Tax: e.tax,
                  Sale_Amount: e.total,
                  Email: e.email ? e.email : '',
                  Email_Subscribed: e.isCheck ? e.isCheck : '',
                  Code_Used: e.otc ? e.otc : '',
                  Payment_Type: e.payment_type ? e.payment_type : '',
                  Product_Name: e.products[i].productInfo?.name,
                  Product_UPC_SKU: e.products[i].productInfo?.upc,
                  Product_Cost_Price: e.products[i].productInfo?.costPrice,
                  Product_Unit_Price: e.products[i].productInfo?.price,
                  Product_Quantity: e.products[i].quantity,
                  Product_Sub_Total_Without_Tax: e.products[i].soldPrice,
                  Product_Tax: e.products[i].tax_amount ? e.products[i].tax_amount : 0,

                  Product_Promotion: e.products[i].promo
                    ? e.products[i].promo?.title + '(' + e.products[i].promo?.promoType + ')'
                    : '',
                  Product_Code_Sent: e.products[i].code ? e.products[i].code : '',
                  Product_Discount: e.products[i].discount,
                };

                thisData.Email_Subscribed = e.isCheck ? e.isCheck : '';

                if (!e.products[i].err) {
                  outPutArray.push(thisData);
                }
              }
            });

            const options = {
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              useTextFile: false,
              filename: 'Sales Report CSV(per item)',
              useKeysAsHeaders: true,
            };
            console.log('outPutArray', outPutArray);
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(outPutArray);
          },
        },
        {
          tooltip: 'Send Email Receipt',
          icon: Email,
          onClick: async (event, rowData) => {
            const { value: email } = await Swal.fire({
              title: 'Enter the email address',
              input: 'text',
              inputPlaceholder: 'Enter the email ("delete" for deleting email address)',
            });
            if (email) {
              Swal.fire({
                title: `Please Wait for response`,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
              console.log('rowData', rowData);
              await request({
                method: 'post',
                url: '/receipt/manual',
                data: {
                  email: email,
                  orderID: rowData.id,
                },
              })
                .then(() => {
                  Swal.fire({
                    icon: 'success',
                    text: `The receipt has been send.`,
                  });
                })
                .catch(() => {
                  Swal.fire({
                    icon: 'error',
                    text: `Fail to send the receipt`,
                  });
                });
            }
          },
        },
        // {
        //   tooltip: 'Send to my email',
        //   isFreeAction: true,
        //   icon: Email,
        //   onClick: async (evt, data) => {
        //     //redundent code
        //     let length = 0;
        //     const outPutArray = order.map((e) => {
        //       if (e.products.length > length) length = e.products.length;
        //       let date = new Date(e.createdAt);
        //       if (e.realTime) {
        //         date = moment.utc(date).format('lll');
        //       } else {
        //         date = moment(date).format('lll');
        //       }
        //       date = date.replace(',', ' ');

        //       return {
        //         Order_ID: e._id,
        //         Order_Date: date,
        //         Machine_ID: e.machineID.name,
        //         Machine_Desc: e.machineID.description,
        //         currency: e.currency,
        //         Tax: e.tax,
        //         Sale_Amount: e.total,
        //         Products: e.products,
        //       };
        //     });
        //     let csv = '';
        //     let header = `Order_ID,Order_Date,Machine_ID,Machine_Desc,currency,Tax,Sale_Amount,`;
        //     for (var i = 1; i <= length; i++) {
        //       header += `Product${i} Name,Product${i} Cost Price(unit),Product${i} Cost Price(total),Product${i} Unit Price, Product${i} Quantity,Product${i} Sub Total without Tax,Product${i} Promotion Used,Product${i} Discount, `;
        //     }

        //     let values = outPutArray
        //       .map((o) => {
        //         let r = '';
        //         r +=
        //           o.Order_ID +
        //           ',' +
        //           o.Order_Date +
        //           ',' +
        //           o.Machine_ID +
        //           ',' +
        //           o.Machine_Desc +
        //           ',' +
        //           o.currency +
        //           ',' +
        //           o.Tax +
        //           ',' +
        //           o.Sale_Amount +
        //           ',';
        //         o.Products.forEach((element, idx) => {
        //           let i = idx + 1;
        //           if (element.product) {
        //             r += element.product.name + ',';
        //             r += element.product.costPrice + ',';
        //             r += element.quantity + ',';
        //             r += element.product.price + ',';
        //           } else {
        //             r += ' ,';
        //             r += ' ,';
        //             r += ' ,';
        //             r += ' ,';
        //           }

        //           r += element.quantity + ',' + element.soldPrice + ',';
        //           if (element.promo)
        //             r += element.promo.code + '(' + element.promo.promoType + ')' + ',';
        //           else r += ' ,';
        //           r += element.discount + ' ,';
        //         });
        //         return r;
        //       })
        //       .join('\n');
        //     csv += header + '\n' + values;

        //     const { value: email } = await Swal.fire({
        //       title: 'Enter your email address',
        //       input: 'email',
        //       inputPlaceholder: 'Enter your email address',
        //     });
        //     if (email) {
        //       await request({
        //         method: 'post',
        //         url: '/email/sendcsv',
        //         data: {
        //           email: email,
        //           csv: csv,
        //         },
        //       }).then((res) => {
        //         Swal.fire(`The table has been send to your email as csv file.`);
        //       });
        //     }
        //   },
        // },
      ]}
    />
  );
}

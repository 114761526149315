// material
import { Grid, Container } from '@mui/material';
// components
// import Page from './Page';

import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext';
import MachineSelect from '../report/MachineSelect';
import React, { useEffect, useState } from 'react';
import request from '../../../services/Request';

import LoadingIcon from '../../items/buttons/LoadingIcon';

// ----------------------------------------------------------------------
import { userLimits } from '../uitls/userLimits';
import MaterialTable from 'material-table';
import { localDateRange } from '../../../services/method/methods';
import moment from 'moment';
const getGroupsURL = `/groupsAndMachinesForReport/`;
const getAlerts = `/logs/getEvent`;

export default function AlertEmail(props) {
  var date = new Date();
  const [machines, setMachines] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [radio, setRadio] = useState('a');
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_alertEmail')
      ? localStorage.getItem('table_view_alertEmail')
      : 20
  );

  useEffect(() => {
    localStorage.setItem('table_view_alertEmail', pagination);
  }, [pagination]);

  const [machinesName, setmachinesName] = useState('All Machines');

  const { userEmail, setFiltered, filterd } = useContext(UserContext);
  const { id, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'view_report'
  );

  const columns = [
    {
      title: 'Time',
      field: 'dateTime',
      render: (rowData) => moment.utc(new Date(rowData.dateTime)).format('lll'),
    },
    {
      title: 'Machine',
      field: 'machineId',
    },
    {
      title: 'Description',
      field: 'customID.text',
      render: (rowData) => {
        let text = rowData.customID?.text;
        text = text?.replace('[[[STOCK]]]', rowData?.motor[0]);
        text = text?.replace('[[[FLOOR]]]', rowData?.motor[1]);
        text = text?.replace('[[[LANE]]]', rowData?.motor[2]);
        return <span style={{ color: rowData.customID?.color }}> {text} </span>;
      },
    },
    {
      title: 'Code',
      field: 'description',
      render: (rowData) => rowData.code1 + '/' + rowData.code2,
    },
    {
      title: 'Motor',
      field: 'motor',
    },
  ];

  useEffect(() => {
    let isCancelled = false;

    async function getGroups() {
      await request({
        method: 'get',
        url: !props.match.params.group && superAdmin ? getGroupsURL : getGroupsURL + userEmail,
      }).then((res) => {
        res.data.machines.forEach((element) => {
          element.product?.forEach((e) => {
            e.id.name = e.id.name.en;
          });
        });
        if (!isCancelled) {
          setGroups(res.data.groups);
          setMachines(res.data.machines);
          setFiltered(res.data.machines);
        }
      });
    }
    getGroups();

    return () => {
      setMachines([]);
      setGroups([]);
      setLoading(false);
      isCancelled = true;
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleChange = async (event) => {
    let filteredArray = [];
    setLoading(false);
    let myIDs = 0;
    if (radio == 'a') {
      setFiltered(machines);
      myIDs = machines.map((e) => e._id).join(',');
      setmachinesName('All Machines');
      filteredArray = [...machines];
    }
    if (radio == 'g') {
      const myArray = groupV.map((e) => e.machineID);
      var merged = [].concat.apply([], myArray);
      merged = merged.filter((e, i, self) => i === self.findIndex((t) => t._id == e._id));
      // const filteredArray = machines.filter((e, i) => i == merged.findIndex((t)=>(t._id == e._id)));
      machines.forEach((e) => {
        merged.findIndex((t) => {
          if (t._id == e._id) {
            filteredArray.push(e);
          }
        });
      });
      setFiltered(filteredArray);
      myIDs = filteredArray.map((e) => e._id).join(',');
      setmachinesName(`Groups: ${groupV.map((e) => e.name).join(', ')} `);
    }
    if (radio == 'm') {
      setFiltered(groupV);
      filteredArray = [...groupV];
      myIDs = groupV.map((e) => e._id).join(',');
      setmachinesName(`Machines: ${groupV.map((e) => e.name).join(', ')} `);
    }

    const { startDateString, endDateString } = localDateRange(startDate, endDate);

    await request({
      method: 'post',
      url: getAlerts,
      data: {
        machineID: myIDs,
        id: id,
        startDate: startDateString,
        endDate: endDateString,
      },
    }).then((res) => {
      let filterAlert = res.data;
      console.log('res.data', res.data);
      if (id === 'adrian.pittaway@msccruises.com') {
        filterAlert = res.data.filter((e) => e?.customID?.code !== '1171500');
      }
      setData(filterAlert);
      setLoading(true);
    });
  };

  return (
    <div>
      <Container maxWidth="xl" sx={{ pt: 5 }}>
        {!loading && <LoadingIcon />}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MachineSelect
              filterd={filterd}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              startDate={startDate}
              endDate={endDate}
              setRadio={setRadio}
              setgroupV={setgroupV}
              radio={radio}
              groupV={groupV}
              groups={groups}
              machines={machines}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Container>
      <MaterialTable
        onChangeRowsPerPage={setPagination}
        style={{ marginTop: '40px' }}
        columns={columns}
        data={data}
        title={''}
        options={{
          pageSize: parseInt(pagination),
          pageSizeOptions: [10, 20, 50, 100, 200],
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
}

import './card.css';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import { useState, useContext } from 'react';
import TrashBinModal from './TrashBinModal';

function CategoryCard({ id, catename, imagename, image, machineID, referTo, linkTo }) {
  const linkToURL = linkTo ? `${linkTo}/${referTo}/${catename}` : `${catename}/${machineID}`;
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const { role, fullAccess } = useContext(UserContext);
  const showAdmin =
    role === 'SuperAdmin' || role === 'Admin' || (role === 'User' && fullAccess) ? true : false;

  const style =
    window.location.pathname.includes('/dashboard') ||
    window.location.pathname.includes('/brand') ||
    window.location.pathname.includes('/viewmymachines') ||
    window.location.pathname.includes('/viewmyusers');
  window.location.pathname.includes('/dashboard/categories');

  const backgroundStyle = {
    backgroundColor: '#3f51b5',
    color: 'white',
  };

  function isDashboard() {
    if (showAdmin) {
      return (
        <div>
          <button onClick={(e) => setShow(true)} className="delete_btn">
            <span className="iconify" data-icon="mdi-trash-can" data-inline="false"></span>
          </button>
          <TrashBinModal id={id} catename={catename} show={show} onHide={() => setShow(false)} />
          <button onClick={() => setModalShow(true)} className="edit_btn">
            <span className="iconify" data-icon="akar-icons:edit" data-inline="false"></span>
          </button>
        </div>
      );
    }
  }

  return (
    <div id={`category-${id}`} className="col-xs-6 col-sm-6 col-md-4 mb-5 cardBorder">
      <div className="card cardBorder">
        <div style={{ textDecoration: 'none' }}>
          <img className="imgBorder" width="300px" height="200px" src={image} alt={imagename} />
          <div className="card-body catebody" style={style ? backgroundStyle : null}>
            <h5 className="card-title d-flex justify-content-center">{catename}</h5>
            {role === 'SuperAdmin' &&
              window.location.pathname.includes('/dashboard/manage-all-categories') && (
                <h6>
                  Refer to{' '}
                  <span
                    style={{
                      color: 'yellow',
                    }}
                  >
                    {referTo}
                  </span>
                </h6>
              )}
          </div>
        </div>
        {isDashboard()}
      </div>
    </div>
  );
}

export default CategoryCard;

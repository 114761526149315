import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import {
  FormControlLabel,
  RadioGroup,
  Grid,
  Radio,
  TextField,
  Checkbox,
  Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// ----------------------------------------------------------------------
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Title from '../Title';
import { useHistory } from 'react-router';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import request from '../../../services/Request';
import moment from 'moment';
import Swal from 'sweetalert2';
import MaterialTable from 'material-table';
import { CloudDownload } from '@material-ui/icons';
import { ExportToCsv } from 'export-to-csv';
import invoiceTemplate from './invoiceTemplate';
import tmctemplate from './tmcMagex';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const getAllEmployeesURL = `/magexuser/viewAllMagexUsers`;

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function MagexSales(props) {
  const history = useHistory();
  const classes = useStyles();
  var date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [groupV, setgroupV] = useState([]);
  const [groups, setGroups] = useState([]);
  const [ms, setMS] = useState([]);
  const [os, setOS] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [totalMachines, setTotalMachines] = useState(0);

  const [op, setop] = useState();

  const [serviceFee, setServiceFee] = useState(0);

  const [totalSales, setTotalSales] = useState(0);
  const [totalMonth, setTotalMonth] = useState(0);

  const [invoiceNO, setInvoiceNO] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [pdf, setPdf] = useState(null);
  const [enableDownLoad, setEnableDownload] = useState(false);

  function monthsBetween(start, end) {
    const startDate = moment(start);
    const endDate = moment(end);

    // Calculate the difference in months
    const diffMonths = endDate.diff(startDate, 'months', true);

    // Round up to the nearest complete month
    const completeMonths = Math.ceil(diffMonths);

    return completeMonths;
  }

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  useEffect(() => {
    let isCancelled = false;

    //getAllEmployees
    async function getAllEmployees() {
      setIsLoading(true);
      await request({
        method: 'get',
        url: getAllEmployeesURL,
      })
        .then((res) => {
          if (!isCancelled) {
            console.log('allusers', res.data);
            setGroups(res.data);
            setIsLoading(false);
          }
        })
        .catch(() => {
          Swal.fire({ text: 'Request Failed' });
          setIsLoading(false);
        });
    }

    getAllEmployees();

    return () => {
      isCancelled = true;
    };
  }, []);

  // useEffect(() => {
  //   let allMachines = [];
  //   let isCancelled = false;

  //   async function getGroups() {
  //     await request({
  //       method: 'get',
  //       url:
  //     }).then((res) => {
  //       if (!isCancelled) {
  //         setGroups(res.data.groups);
  //         setMachines(res.data.machines);
  //         setLoading(true);
  //         allMachines = res.data.machines.map((e) => e._id);
  //       }
  //     });
  //   }

  //   getGroups();

  //   return () => {
  //     isCancelled = true;
  //   };
  // }, []);

  const sendInvoice = () => {
    if (!groupV?.email) {
      return Swal.fire({
        text: 'No Email found for this user',
        timer: 2000,
      });
    }
    const std = moment(new Date(startDate)).format('YYYY-MM-DD');
    const edt = moment(new Date(endDate)).format('YYYY-MM-DD');
    request({
      method: 'post',
      url: '/magex/sendMagexInvoice',
      data: {
        users: [groupV],
        startDate: std,
        endDate: edt,
        invoiceNO: invoiceNO,
      },
    })
      .then((res) => {
        Swal.fire({
          text: 'Invoice Sent',
        });
      })
      .catch((err) => {
        Swal.fire({
          text: 'Send Invoice Failed',
        });
      });
  };

  const showInvoice = () => {
    //get total price of service fee from groupV's service. service array
    let machineString = '';
    filtered.forEach((mc, index) => {
      machineString += mc.machineId;
      if (index !== filtered.length - 1) {
        machineString += index === filtered.length - 2 ? ' & ' : ', ';
      }
    });

    const taxRate = groupV.tax ? parseFloat(groupV?.tax / 100) : 0;

    const invoiceData = {
      mcNO: filtered?.length,
      taxRate: taxRate,
      operator: groupV,
      serviceFee: serviceFee,
      license_fee: groupV.license_fee,
      invoiceNO: invoiceNO,
      machineString: machineString,
      startDate: moment(new Date(startDate)).format('YYYY/MM/DD'),
      endDate: moment(new Date(endDate)).format('YYYY/MM/DD'),
    };
    document.getElementById('invoicetpmagex').innerHTML = invoiceTemplate(invoiceData);
    document.getElementById('tmc_magexinvoice').innerHTML = tmctemplate(invoiceData);
    setEnableDownload(true);
    // //open new window
    // // Open a new window
    // var newWindow = window.open('', '_blank');

    // // Write the content of the div into the new window
    // newWindow.document.write(document.getElementById('download-invoice-magex-content').innerHTML);
    // newWindow.document.write('</body></html>');

    // // Close the document to ensure proper rendering
    // newWindow.document.close();
  };

  const columns = [
    { title: 'Machine ID', field: 'machineId' },
    { title: 'Sales', field: 'totalPrice' },
    { title: 'Tax', field: 'totalTax' },
    { title: 'Items sold', field: 'totalItems' },
  ];

  const handleChange = async () => {
    // console.log('data', groupV);
    setEnableDownload(false);
    const std = moment(new Date(startDate)).format('YYYY-MM-DD');
    const edt = moment(new Date(endDate)).format('YYYY-MM-DD');

    //get machine list from magex
    let magexmachinelist = [];
    try {
      magexmachinelist = await request({
        method: 'post',
        url: '/magex/magexmachinelist',
        data: {
          md5: groupV?.md5,
        },
      });
    } catch (err) {
      Swal.fire({
        text: 'Get Data Failed',
      });
    }
    let machineList = magexmachinelist?.data?.machines;
    console.log('machineList', machineList);
    if (!machineList || machineList.length === 0) return;

    machineList.forEach((element) => {
      element.totalItems = 0;
      element.totalPrice = 0;
      element.totalTax = 0;
      element.machineId = element.serial;
    });

    const magexUrl = '/getMagexSales';
    setIsLoading(true);
    request({
      method: 'post',
      url: magexUrl,
      data: {
        md5: groupV?.md5,
        startDate: std,
        endDate: edt,
      },
    })
      .then((res) => {
        if (res.data?.length > 0) {
          const machineStats = [];
          console.log('res.data', res.data);
          setOS(res.data);
          res.data?.forEach((item) => {
            const machineId = item.machine;
            // Check if this machine's statistics exist in machineStats
            const index = machineList.findIndex((stat) => stat.serial == machineId);
            // if (index === -1) {
            //   // If not exist, add new entry
            //   machineStats.push({
            //     machineId: machineId,
            //     totalPrice: item.price,
            //     totalTax: item.tax,
            //     totalItems: 1,
            //   });
            //   // console.log('machineStats', machineStats);
            // } else {

            if (index === -1) return;
            machineList[index].totalItems++;
            machineList[index].operator = groupV?.operator;
            // If exist, update existing entry
            machineList[index].totalPrice += item.price;
            machineList[index].totalTax += item.tax;

            machineList[index].totalPrice = parseFloat(machineList[index].totalPrice.toFixed(2));
            machineList[index].totalTax = parseFloat(machineList[index].totalTax.toFixed(2));
            // }
          });
          //calculate the final price
          let sf = 0;
          let ts = 0;

          // console.log('groupV', groupV);
          let tolerance = groupV?.tolerance ? groupV?.tolerance : 0;
          let license_fee = groupV.license_fee ? groupV.license_fee : 0;

          console.log('machineList', machineList);

          //filter the machines with tolerance
          if (tolerance !== -1) {
            const filtered = machineList.filter((mc) => {
              if (tolerance < mc.totalPrice) {
                return mc;
              }
            });
            setFiltered(filtered);
          }

          filtered.forEach((mc) => {
            ts += mc.totalPrice;
            if (tolerance !== -1) {
              sf += license_fee;
              // console.log('license_fee', sf);
            } else {
              if (tolerance < mc.totalPrice) {
                sf += license_fee;
              }
            }
          });

          const totalMonth = monthsBetween(startDate, endDate);
          setTotalMonth(totalMonth);
          sf = sf * totalMonth;

          ts = parseFloat(ts.toFixed(2));
          sf = parseFloat(sf.toFixed(2));
          setTotalSales(ts);
          setServiceFee(sf);
          setMS(machineList);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          text: 'Get Data Failed',
        });
        setIsLoading(false);
      });
  };

  const DownloadInvoice = () => {
    var opt = {
      margin: 0.1,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    const input = document.getElementById('download-invoice-magex-content');
    if (input) {
      html2pdf().from(input).set(opt).save();
    }
    // setIsLoading(true);
    // request({
    //   method: 'post',
    //   url: '/magex/downloadInvoice',
    //   responseType: 'blob',
    //   data: {
    //     startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
    //     endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
    //     users: [groupV],
    //     invoiceNO: invoiceNO,
    //   },
    // })
    //   .then(async (res) => {
    //     const pdfData = await res.data;
    //     setPdf(pdfData);
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     Swal.fire({
    //       title: 'Download Invoice Failed',
    //       text: err,
    //     });
    //     setIsLoading(false);
    //   });
  };

  useEffect(() => {
    if (pdf) {
      const url = window.URL.createObjectURL(new Blob([pdf]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      link.click();
    }
  }, [pdf]);

  return (
    <React.Fragment>
      {loading && <LoadingIcon />}
      <diiv>
        <Grid container style={{ paddingLeft: '40px', marginTop: '30px' }} alignItems="center">
          <Grid xs={4} item container justifyContent="flex-start">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.formControl}
                value={startDate}
                onChange={(e) => handleStartDate(e)}
                label="Select start date"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-start"
              />
              <KeyboardDatePicker
                className={classes.formControl}
                value={endDate}
                onChange={(e) => handleEndDate(e)}
                label="Select end date"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-end"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            xs={4}
            item
            container
            justifyContent="flex-start"
            alignContent="center"
            alignItems="center"
          >
            <Autocomplete
              value={groupV}
              options={groups}
              onChange={(event, value) => {
                setgroupV(value);
              }}
              getOptionSelected={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option?.name}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Customers" />
              )}
            />
          </Grid>
          <Grid item xs={1} style={{ marginLeft: '15px' }}>
            {!props.compare && (
              <Button
                style={{ height: '40px' }}
                onClick={handleChange}
                disabled={!groupV?.name}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
        <div className="mt-5">
          <div className="d-flex mb-4 ml-4 ">
            <div
              style={{
                fontSize: '2rem',
                marginRight: '40px',
              }}
            >
              Service Fee: $ {serviceFee}
            </div>
            <div
              style={{
                fontSize: '2rem',
              }}
            >
              Total Sales: $ {totalSales}
            </div>
          </div>
          <div className="d-flex mb-4 ml-4">
            <div
              style={{
                fontSize: '1rem',
                marginRight: '40px',
              }}
            >
              Tolerance: ${groupV?.tolerance}
            </div>
            <div
              style={{
                fontSize: '1rem',
                marginRight: '40px',
              }}
            >
              License Fee: ${groupV?.license_fee}
            </div>
            <div
              style={{
                fontSize: '1rem',
                marginRight: '40px',
              }}
            >
              Months: {totalMonth}
            </div>
            <div
              style={{
                fontSize: '1rem',
                marginRight: '40px',
              }}
            >
              Machine Num: {filtered.length}
            </div>
          </div>
          <div>
            <MaterialTable
              title="Machine Statistics"
              columns={columns}
              data={ms}
              actions={[
                {
                  tooltip: 'Download cvs',
                  isFreeAction: true,
                  icon: CloudDownload,
                  onClick: (evt, rowData) => {
                    let outPutArray = [...ms];
                    outPutArray = outPutArray.map((item) => {
                      return {
                        Machine: item.machineId,
                        Sales: item.totalPrice,
                        Tax: item.totalTax,
                        'Items Sold': item.totalItems,
                      };
                    });
                    const options = {
                      fieldSeparator: ',',
                      quoteStrings: '"',
                      decimalSeparator: '.',
                      useTextFile: false,
                      filename: 'Sales Report CSV(per item)',
                      useKeysAsHeaders: true,
                    };
                    console.log('outPutArray', outPutArray);
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(outPutArray);
                  },
                },
              ]}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
              }}
            />
          </div>
          <div className="mt-5">
            <Button
              style={{ height: '40px' }}
              onClick={showInvoice}
              disabled={!groupV?.name}
              variant="contained"
              color="primary"
            >
              Get Invoice
            </Button>
            <Button
              style={{ height: '40px', marginLeft: '40px' }}
              onClick={DownloadInvoice}
              disabled={!enableDownLoad}
              variant="contained"
              color="primary"
            >
              Download Invoice
            </Button>
            <Button
              style={{ height: '40px', marginLeft: '40px' }}
              onClick={sendInvoice}
              disabled={true}
              variant="contained"
              color="primary"
            >
              Send Invoice
            </Button>

            <div
              style={{
                width: '100%',
                marginLeft: '50px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div className="mb-5 mt-5">
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Invoice Number"
                  value={invoiceNO}
                  onChange={(e) => {
                    setInvoiceNO(e.target.value);
                  }}
                />
              </div>
            </div>
            <div id="download-invoice-magex-content">
              <div id="invoicetpmagex"></div>
              <div id="tmc_magexinvoice"></div>
            </div>
          </div>
        </div>
      </diiv>
    </React.Fragment>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/userContext';
import request from '../../../services/Request';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';

import { Grid, Container } from '@material-ui/core';

import MachineSelect from '../../dashboard/report/MachineSelect';
import { userLimits } from '../../dashboard/uitls/userLimits';
import moment from 'moment';
import { localDateRange } from '../../../services/method/methods';

const getOrderByEmail = `/credit/cashTrans`;
const getAllOrder = `/allOrders/`;
const getGroupsURL = `/groupsAndMachines/`;

Date.prototype.toShortFormat = function () {
  let monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let day = this.getDate();

  let monthIndex = this.getMonth();
  let monthName = monthNames[monthIndex];

  let year = this.getFullYear();

  return `${day}-${monthName}-${year}`;
};

export default function CashTrans(props) {
  const { userEmail, filterd, setFiltered } = useContext(UserContext);

  //start at first date of this month
  var date = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [order, setOrder] = useState([]);
  const [machines, setMachines] = useState([]);
  const [machineName, setMachineName] = useState('All Machines');
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [radio, setRadio] = useState('a');
  const user = JSON.parse(localStorage.getItem('user'));
  const thirdParty = user.thirdPartyLink;

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_cash') ? localStorage.getItem('table_view_cash') : 20
  );

  useEffect(() => {
    localStorage.setItem('table_view_cash', pagination);
  }, [pagination]);

  // console.log(user);

  const { id, tableTitle, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Transactions',
    'view_trans'
  );

  useEffect(() => {
    let allMachines = [];
    let isCancelled = false;

    async function getGroups() {
      await request({
        method: 'get',
        url: !props.match.params.group && superAdmin ? getGroupsURL : getGroupsURL + userEmail,
      }).then((res) => {
        if (!isCancelled) {
          setGroups(res.data.groups);
          setMachines(res.data.machines);
          setLoading(true);
          allMachines = res.data.machines.map((e) => e._id);
        }
      });
    }

    getGroups();

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleSearch = async (event) => {
    // console.log('startDateString', new Date(startDateString));
    // console.log('endDateString', new Date(endDateString));
    let filteredArray = [];
    setLoading(false);
    let myIDs = 0;
    if (radio == 'a') {
      setFiltered(machines);
      myIDs = machines.map((e) => e._id).join(',');
      setMachineName('All Machines');
      filteredArray = [...machines];
    }
    if (radio == 'g') {
      const myArray = groupV.map((e) => e.machineID);
      var merged = [].concat.apply([], myArray);
      merged = merged.filter((e, i, self) => i === self.findIndex((t) => t._id == e._id));
      // const filteredArray = machines.filter((e, i) => i == merged.findIndex((t)=>(t._id == e._id)));
      machines.forEach((e) => {
        merged.findIndex((t) => {
          if (t._id == e._id) {
            filteredArray.push(e);
          }
        });
      });
      setFiltered(filteredArray);
      myIDs = filteredArray.map((e) => e._id).join(',');
      setMachineName(`Groups: ${groupV.map((e) => e.name).join(', ')} `);
    }
    if (radio == 'm') {
      setFiltered(groupV);
      filteredArray = [...groupV];
      myIDs = groupV.map((e) => e._id).join(',');
      setMachineName(`Machines: ${groupV.map((e) => e.name).join(', ')} `);
    }
    // console.log(myIDs);
    // console.log(startDateString)
    // console.log(endDateString)
    if (myIDs.length > 0) {
      const { startDateString, endDateString } = localDateRange(startDate, endDate);
      await request({
        method: 'post',
        url:
          !props.match.params.group && superAdmin
            ? getAllOrder + myIDs + '/' + startDateString + '/' + endDateString
            : getOrderByEmail,
        data: {
          start: startDateString,
          end: endDateString,
          ids: myIDs,
          id: id,
        },
      }).then(async (res) => {
        console.log('res.data', res.data);
        setOrder(res.data);
        setLoading(true);
      });
    } else {
      setLoading(true);
    }
  };
  let columns = [
    {
      title: 'ID',
      field: 'cashID',
    },
    {
      title: 'Date',
      field: 'local_time',
      render: (rowData) => {
        let date = new Date(rowData.local_time);
        date = moment.utc(date).format('lll');
        return date;
      },
    },
    { title: 'Machine ID', field: 'machineID.name' },
    {
      title: 'Total Amount Charged($)',
      field: 'total_amount',
      render: (rowData) => {
        if (rowData?.total_amount) {
          return rowData.total_amount;
        } else return 'Cash Back';
      },
    },
    {
      title: 'Cash In($)',
      field: 'cashIn',
      render: (rowData) => {
        return rowData?.cashIn.join(', ');
      },
    },
    {
      title: 'Cash Out($)',
      field: 'cashOut',
      render: (rowData) => {
        return rowData?.cashOut.join(', ');
      },
    },
    { title: 'Credit Amount($)', field: 'amount' },
    {
      title: 'Credit Note($)',
      field: 'credit',
      render: (rowData) => {
        return rowData.credit ? 'True' : 'False';
      },
    },
  ];

  return (
    <div>
      {!loading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <Container maxWidth="xl" style={{ marginTop: '5px', marginBottom: '5px', padding: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MachineSelect
                filterd={filterd}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                startDate={startDate}
                endDate={endDate}
                setRadio={setRadio}
                setgroupV={setgroupV}
                radio={radio}
                groupV={groupV}
                groups={groups}
                machines={machines}
                handleChange={handleSearch}
              />
            </Grid>
          </Grid>
        </Container>
        {/* </Box> */}
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          title={'Cash Transctions'}
          columns={columns}
          data={order}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100, 200],
            rowStyle: (rowData) => {
              if (rowData?.credit) {
                return {
                  backgroundColor: '#DEADDD',
                };
              }
              if (!rowData.total_amount) {
                return {
                  backgroundColor: 'grey',
                };
              }
            },
          }}
          actions={[]}
        />
        <BackButton />
      </div>
    </div>
  );
}

import React, { useEffect } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import request from '../../services/Request';
import Title from './Title';
import { useHistory } from 'react-router';
import LoadingIcon from '../items/buttons/LoadingIcon';
import moment from 'moment';

// Generate Order Data
// function createData(id, date, name, shipTo, paymentMethod, amount) {
//   return { id, date, name, shipTo, paymentMethod, amount };
// }

// const rows = [
//   createData(0, '16 Mar, 2019', 'Elvis Presley', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
//   createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
//   createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
//   createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
//   createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
// ];

const getOrderByEmailURL = '/orders/getNewestTransaction/';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Orders(props) {
  const { id, loading, setLoading, order, setOrder, view_trans } = props;
  const history = useHistory();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user')).email;

  useEffect(() => {
    if (view_trans) {
      setLoading(true);
      let isCancelled = false;
      if (id) {
        async function getOrderByEmail() {
          await request({
            method: 'get',
            url: getOrderByEmailURL + user + '/10',
          }).then((res) => {
            if (!isCancelled) {
              console.log(res.data);
              setOrder(res.data);
              setLoading(false);
            }
          });
        }

        getOrderByEmail();
      }

      return () => {
        isCancelled = true;
      };
    }
  }, [props.id]);
  return (
    <React.Fragment>
      {loading && LoadingIcon}
      <Title>Newest 10 transactions</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Kiosk</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Sale Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.map((item) => {
            let date = new Date(item.createdAt);
            if (item.realTime) {
              date = moment.utc(date).format('lll');
            } else {
              date = moment(date).format('lll');
            }
            return (
              <TableRow key={item._id}>
                <TableCell>{date}</TableCell>
                <TableCell>{item.machineID?.name}</TableCell>
                <TableCell>{item.machineID?.description}</TableCell>
                <TableCell>{item.payment_type}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell align="right">${item.total}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link
          color="primary"
          href="#"
          onClick={() => {
            history.push('/dashboard/order');
          }}
        >
          See more transactions
        </Link>
      </div>
    </React.Fragment>
  );
}

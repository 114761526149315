import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import AddCategory from './addCategory';
import Swal from 'sweetalert2';
import request from '../../../services/Request';
import { userLimits } from '../uitls/userLimits';
import { imageOnError } from '../../../services/method/methods';

const getCatesURL = `/categories/`;
const deleteCatesURL = `/categories/delete/`;

export default function Category(props) {
  const { _id, tableTitle, superAdmin, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Categories',
    'edit_cate'
  );
  const [reflesh, setReflesh] = useState(false);
  const [cates, setCates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [id, setID] = useState('');
  const [update, setUpdate] = useState(false);
  const [name, setName] = useState('');
  const [picture, setPicture] = useState('');
  const [limit, setLimit] = useState(0);

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_cate') ? localStorage.getItem('table_view_cate') : 5
  );

  useEffect(() => {
    localStorage.setItem('table_view_cate', pagination);
  }, [pagination]);

  useEffect(() => {
    let isCancelled = false;

    async function getMycates() {
      await request({
        method: 'get',
        url: !props.match.params.group && superAdmin ? getCatesURL : getCatesURL + _id,
      }).then((res) => {
        if (!isCancelled) {
          console.log(res.data);
          setCates(res.data);
          setLoading(true);
        }
      });
    }
    getMycates();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  const deleteCate = async (id) => {
    setLoading(false);
    await request({
      method: 'delete',
      url: deleteCatesURL + id,
    }).then(() => {
      setLoading(true);
    });
    reflesh ? setReflesh(false) : setReflesh(true);
  };

  const columns = [
    {
      title: 'Picture',
      field: 'picture',
      render: (rowData) =>
        rowData.categoryPicture != '' ? (
          <img
            src={`${process.env.REACT_APP_SERVER_API}/pictures/${rowData.categoryPicture}/${_id}`}
            style={{ maxWidth: 200 }}
            onError={imageOnError}
          />
        ) : (
          'No image'
        ),
    },
    { title: 'Category Name', field: 'name.en' },
  ];
  return (
    <div>
      {!loading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={cates}
          title={tableTitle}
          editable={
            access && {
              onRowDelete: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    deleteCate(newData._id);
                    Swal.fire({
                      text: `A category has been successfully deleted: ${newData.name.en}`,
                      icon: 'success',
                    });
                    resolve();
                  }, 1000);
                }),
            }
          }
          actions={[
            access && {
              icon: 'add',
              tooltip: 'Add Category',
              isFreeAction: true,
              onClick: (event) => {
                setUpdate(false);
                setShow(true);
              },
            },
            access && {
              icon: 'edit',
              tooltip: 'Edit Category',
              onClick: (event, rowData) => {
                setID(rowData._id);
                setName(rowData.name);
                setPicture(rowData.categoryPicture);
                setUpdate(true);
                setShow(true);
                setLimit(rowData.limit);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        <BackButton />
        <AddCategory
          referTo={_id}
          id={id}
          show={show}
          name={name}
          reflesh={reflesh}
          setReflesh={setReflesh}
          update={update}
          picture={picture}
          limit={limit}
          onHide={() => setShow(false)}
        />
      </div>
    </div>
  );
}

import { useDrag } from 'react-dnd';
import React, { useState, useEffect, useContext } from 'react';
import { PlanoContext } from './PlanoContext';
import Swal from 'sweetalert2';
import DimensionChangeModal from './DimensionChangeModal';
import { Button } from '@material-ui/core';

function ProductCard({ prod, url, TCN, i, setProds, newProds }) {
  // const user = JSON.parse(localStorage.getItem('user'));
  const [didDrop, setDidDrop] = useState(false);
  const [show, setShow] = useState(false);
  const { prods, error, setError } = useContext(PlanoContext);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'image',
      item: { prod: prod },
      end: (item, monitor) => {
        console.log('item', item);
        if (!monitor.didDrop()) {
          setDidDrop(true);
        } else {
          setDidDrop(false);
        }
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [prod]
  );

  useEffect(() => {
    // console.log('prod', prod);
    if (error != '' && didDrop) {
      Swal.fire(`Can't place the product here`, error, 'error');
      setError('');
      setDidDrop(false);
    }
  }, [didDrop]);

  if (prod.empty) {
    return (
      <div className="card flex-row prodCard-plano" style={{ marginTop: '0px', borderRadius: '0' }}>
        <div className="d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ width: '150px', height: '120px', display: 'block' }}
          >
            <img
              // height='120px'
              loading="lazy"
              ref={drag}
              style={{
                border: isDragging ? '5px solid pink' : '0px',
                maxWidth: '100%',
                maxHeight: '100%',
              }}
              src={prod.productPictures[0]}
              alt="i"
            />
          </div>
          <div
            className="card-body d-flex"
            style={{ padding: '10px 1px 10px 1px', display: 'flex', flexDirection: 'column' }}
          >
            <h6 className="card-title" style={{ marginBottom: '5px' }}>
              Empty Slot
            </h6>
            <p className="card-text" style={{ marginBottom: '5px' }}>
              Width: {prod.dimension.length} cm
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card flex-row prodCard-plano" style={{ marginTop: '0px', borderRadius: '0' }}>
      <div className="d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: '150px', height: '120px', display: 'block' }}
        >
          <img
            loading="lazy"
            // height='120px'
            ref={drag}
            style={{
              border: isDragging ? '5px solid pink' : '0px',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
            src={url}
            alt="i"
          />
        </div>
        <div
          className="card-body d-flex"
          style={{ padding: '10px 1px 10px 1px', display: 'flex', flexDirection: 'column' }}
        >
          <h6 className="card-title" style={{ marginBottom: '5px' }}>
            {prod.name}
          </h6>
          <p className="card-text" style={{ marginBottom: '5px' }}>
            ${prod.price}
          </p>
          {!TCN ? (
            <div>
              <p className="card-text" style={{ marginBottom: '5px' }}>
                Lanes required: {prod.dimension.lane} lanes
              </p>
              <p className="card-text" style={{ marginBottom: '5px' }}>
                Depth required: {prod.dimension.step} steps
              </p>
              <p className="card-text" style={{ marginBottom: '5px' }}>
                Vertical slot required: {prod.dimension.height} slots
              </p>

              <div style={{ width: '65%', margin: 'auto' }} className="card-text">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Edit Dimensions
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <p className="card-text" style={{ marginBottom: '5px' }}>
                Width: {prod.dimension.length} cm
              </p>
              <p className="card-text" style={{ marginBottom: '5px' }}>
                Depth: {prod.dimension.width} cm
              </p>
              <p className="card-text" style={{ marginBottom: '5px' }}>
                Vertical slot required: {prod.dimension.height}
              </p>
            </div>
          )}
        </div>
      </div>
      <DimensionChangeModal
        newProds={newProds}
        setProds={setProds}
        prod={prod}
        show={show}
        onHide={() => setShow(false)}
        prods={prods}
        i={i}
      />
    </div>
  );
}

export default ProductCard;

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import Swal from 'sweetalert2';
import request from '../../../services/Request';
import AddReceipt from './AddReceipt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { userLimits } from '../uitls/userLimits';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default function EmailReceipt(props) {
  const { _id, tableTitle, superAdmin, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Email receipt',
    'edit_receipt'
  );

  const [reflesh, setReflesh] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [update, setupdate] = useState(false);
  const [notnew, setnotnew] = useState(false);
  const [rowdata, setrowdata] = useState('');

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_email') ? localStorage.getItem('table_view_email') : 5
  );

  useEffect(() => {
    localStorage.setItem('table_view_email', pagination);
  }, [pagination]);

  useEffect(() => {
    let isCancelled = false;

    async function getER() {
      await request({
        method: 'get',
        url:
          !props.match.params.group && superAdmin ? '/receiptByEmail/' : '/receiptByEmail/' + _id,
      }).then((res) => {
        if (!isCancelled) {
          setData(res.data);
          setLoading(true);
        }
      });
    }
    getER();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  const handleDelete = async (id, newData) => {
    await request({
      method: 'delete',
      url: '/receipt/delete/' + id,
    }).then(() => {
      Swal.fire({
        text: `A receipt template has been deleted: ${newData.name}`,
        icon: 'success',
      }).then(() => {
        reflesh ? setReflesh(false) : setReflesh(true);
      });
    });
  };

  const duplicateReceipt = (rowData) => {
    setLoading(false);
    request({
      method: 'post',
      url: '/receipt/duplicate/' + rowData?._id,
    })
      .then(() => {
        Swal.fire('Success', 'Successfully dumplicated the receipt', 'success');
        setReflesh(!reflesh);
        setLoading(true);
      })
      .catch(() => {
        Swal.fire('Fail', 'Fail to dumplicated the receipt', 'fail');
        setReflesh(!reflesh);
        setLoading(true);
      });
  };

  const columns = [
    {
      title: 'Logo Image',
      field: 'logo',
      render: (rowData) =>
        rowData.logo != '' ? (
          <img
            src={`${process.env.REACT_APP_SERVER_API}/pictures/${rowData.logo}/${_id}`}
            style={{ maxWidth: 200 }}
          />
        ) : (
          'No image'
        ),
    },
    { title: 'Template Name', field: 'name' },
  ];
  return (
    <div>
      {!loading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={data}
          title={tableTitle}
          editable={
            access && {
              onRowDelete: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    handleDelete(newData._id, newData);
                    resolve();
                  }, 1000);
                }),
            }
          }
          actions={[
            access && {
              icon: 'add',
              tooltip: 'Create New One',
              isFreeAction: true,
              onClick: (event) => {
                setupdate(false);
                setnotnew(false);
                setShow(true);
              },
            },
            access && {
              icon: 'edit',
              tooltip: 'Edit/Show Template',
              onClick: (event, rowData) => {
                setupdate(true);
                setnotnew(true);
                setrowdata(rowData);
                setShow(true);
              },
            },
            access && {
              icon: FileCopyIcon,
              tooltip: 'Duplicate the receipt',
              onClick: (event, rowData) => {
                duplicateReceipt(rowData);
              },
            },
            access && {
              icon: ForwardToInboxIcon,
              tooltip: 'Send My Testing Receipt',
              onClick: async (event, rowData) => {
                const { value: email } = await Swal.fire({
                  title: 'Enter the email address',
                  input: 'email',
                  inputPlaceholder: 'Enter the email address',
                });
                if (email) {
                  Swal.fire({
                    title: `Please Wait for response`,
                    didOpen: () => {
                      Swal.showLoading();
                    },
                  });
                  await request({
                    method: 'post',
                    url: '/receipt/send/' + rowData._id,
                    data: {
                      email: email,
                      receipt: rowData._id,
                    },
                  })
                    .then(() => {
                      Swal.fire({
                        icon: 'success',
                        text: `The receipt template has been send to your email.`,
                      });
                    })
                    .catch(() => {
                      Swal.fire({
                        icon: 'error',
                        text: `The receipt template fail to send, Please check your smtp setting.`,
                      });
                    });
                }
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        <BackButton />
        {
          <AddReceipt
            reflesh={reflesh}
            setReflesh={setReflesh}
            data={rowdata}
            update={update}
            notnew={notnew}
            referTo={_id}
            show={show}
            onHide={() => {
              setShow(false);
            }}
          />
        }
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import Line from './Line';
import { TextField } from '@material-ui/core';
import Swal from 'sweetalert2';

import './Plano.css';
export default function Floor({
  lineNumber,
  setHeights,
  floor,
  floors,
  setFloors,
  height,
  floorNumber,
  heights,
  plano,
  i,
}) {
  //second index 0 is for checking capacity

  const [myheight, setheight] = useState(0);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    let initialLine = [];
    let myheight;

    if (floors[floor - 1].lines.length > 0) {
      initialLine = floors[floor - 1].lines;
      myheight = floors[floor - 1].px;
    } else {
      floors[floor - 1].px = height * 20 - heights[floorNumber - floor] * 20 - 150;
      floors[floor - 1].maxHeight = 0;
      myheight = height * 20 - heights[floorNumber - floor] * 20 - 150;
      console.log('aaaaaaaaaaaa');
      for (let i = 1; i <= lineNumber; i++) {
        console.log('2234234234234');
        initialLine.push({
          occupied: 0,
          length: 0,
          disable: 1,
          color: 'lightBlue',
          maxStock: 0,
          motor: 1,
        });
      }
      floors[floor - 1].lines = initialLine.slice();
    }

    initialLine[0].heights = heights;
    setLines(initialLine);
    setheight(myheight);
  }, [myheight]);

  const errorMessage = (h) => {
    Swal.fire('', `There are products required ${h} floors `, 'error');
  };

  //i dont know why useDrop(in line.js) can not receive updated heights, only can received updated lines, so I push heights value into lines then pass to useDrop

  let LinesComponents;
  LinesComponents = lines.map((i, index) => {
    return (
      <Line
        plano={plano}
        height={height}
        floorIndexHeight={floorNumber - floor}
        floorNumber={floorNumber}
        setHeights={setHeights}
        heights={heights}
        floors={floors}
        setFloors={setFloors}
        lines={lines}
        width={i.length}
        setLines={setLines}
        key={index}
        index={index}
        lineNumber={lineNumber}
        floor={floor}
      />
    );
  });

  return (
    <div className="d-flex justify-content-center">
      <div
        id={`floor-${floorNumber - floor}`}
        className="row items-container plano-floor"
        style={{
          top:
            // height*20 - heights[floorNumber-floor]*20 - 150 > height*20 - heights[floorNumber-floor-1]*20 - 150 ?
            `${myheight}px`,
          //  : `${height*20 - heights[floorNumber-floor-1]*20 - 150 + 150}px`
        }}
      >
        {LinesComponents}
        <div>
          {/* <div className="d-flex flex-column justify-content-center" style={{marginTop:'60px', cursor:'pointer'}}>
              <KeyboardArrowUp />
              <KeyboardArrowDown />
            </div> */}
          <div style={{ marginTop: '98px' }}>
            <TextField
              label="vertical slot"
              value={heights[floorNumber - floor]}
              onChange={(e) => {
                const plus = e.target.value > heights[floorNumber - floor] ? true : false;
                let go = false;
                const minHeight = 5;
                const prev = floorNumber - floor - 1;
                const next =
                  floorNumber - floor + 1 >= floorNumber - floor + 1
                    ? floorNumber - floor + 1
                    : floorNumber % (floorNumber - floor + 1);
                // const notEmpty = lines.some(e=>e.occupied != 0);
                // if (notEmpty) {
                //   Swal.fire(
                //     '',
                //     `Please remove the products first before adjust the floor.`,
                //     'error'
                //   )
                // }
                //lowest floor
                if (floorNumber - floor == floorNumber - 1) {
                  if (
                    parseFloat(e.target.value) + minHeight <= heights[prev] &&
                    parseFloat(e.target.value) >= 0
                  ) {
                    go = true;
                  }
                  if (
                    heights[floorNumber - floor - 1] - parseFloat(e.target.value) <
                    floors[floor - 1].maxHeight
                  ) {
                    go = false;
                    errorMessage(floors[floor - 1].maxHeight);
                  }
                } else if (floorNumber - floor == 0) {
                  // console.log(heights[floorNumber-floor] - heights[floorNumber-floor+1])
                  // console.log(floors[floorNumber-floor+1])\
                  if (
                    parseFloat(e.target.value) + minHeight <= height &&
                    parseFloat(e.target.value) - minHeight >= heights[next]
                  ) {
                    go = true;
                  }
                  if (
                    parseFloat(e.target.value) - heights[floorNumber - floor + 1] <
                    floors[floor - 2].maxHeight
                  ) {
                    go = false;
                    errorMessage(floors[floor - 2].maxHeight);
                  }
                  if (height - parseFloat(e.target.value) < floors[floor - 1].maxHeight) {
                    go = false;
                    errorMessage(floors[floor - 1].maxHeight);
                  }
                } else {
                  if (
                    parseFloat(e.target.value) - minHeight >= heights[next] &&
                    parseFloat(e.target.value) + minHeight <= heights[prev]
                  ) {
                    go = true;
                  }
                  if (
                    parseFloat(e.target.value) - heights[floorNumber - floor + 1] <
                    floors[floor - 2].maxHeight
                  ) {
                    go = false;
                    errorMessage(floors[floor - 2].maxHeight);
                  }
                  if (
                    heights[floorNumber - floor - 1] - parseFloat(e.target.value) <
                    floors[floor - 1].maxHeight
                  ) {
                    go = false;
                    errorMessage(floors[floor - 1].maxHeight);
                  }
                }
                if (go) {
                  // console.log(heights)
                  // console.log(floor)
                  // const myh = [...heights];
                  // myh[floorNumber-floor] = parseFloat(e.target.value);

                  // setHeights([...myh])
                  heights[floorNumber - floor] = parseInt(e.target.value);
                  // console.log(heights)
                  setHeights([...heights]);
                  const mydiv = document.getElementById(`floor-${floorNumber - floor}`);
                  const nextDiv = document.getElementById(`floor-${next}`);
                  const preDiv = document.getElementById(`floor-${prev}`);
                  const thispx = parseFloat(
                    mydiv.style.top.substring(0, mydiv.style.top.length - 2)
                  );
                  // console.log(thispx)
                  // console.log(next)
                  // console.log(height*20 - heights[floorNumber-floor]*20 - 150)
                  //lowest floor
                  if (floorNumber - floor == floorNumber - 1) {
                    // const prevpx = parseFloat(preDiv.style.top.substring(0, preDiv.style.top.length - 2));
                    const prevpx = floors[floorNumber - 1 - prev].px;
                    if (
                      height * 20 - heights[floorNumber - floor] * 20 - 150 - 160 >= prevpx &&
                      parseFloat(e.target.value) >= 0
                    ) {
                      // mydiv.style.top = `${height*20 - heights[floorNumber-floor]*20 - 150}px`;
                      floors[floor - 1].px = height * 20 - heights[floorNumber - floor] * 20 - 150;
                      setheight(height * 20 - heights[floorNumber - floor] * 20 - 150);
                      // setFloors([...floors])
                    } else {
                      if (heights[floorNumber - floor] + 8 >= heights[floorNumber - floor - 1]) {
                        floors[floor - 1].px = prevpx + 160;
                        setheight(prevpx + 160);
                      }
                    }
                  } else if (floorNumber - floor == 0) {
                    const nextPx = floors[floorNumber - 1 - next].px;
                    if (
                      height * 20 - heights[floorNumber - floor] * 20 - 150 + 160 <= nextPx &&
                      parseFloat(e.target.value) - minHeight >= heights[next]
                    ) {
                      // mydiv.style.top = `${height*20 - heights[floorNumber-floor]*20 - 150}px`;
                      floors[floor - 1].px = height * 20 - heights[floorNumber - floor] * 20 - 150;
                      setheight(height * 20 - heights[floorNumber - floor] * 20 - 150);
                      // setFloors([...floors])
                    } else {
                      if (heights[floorNumber - floor] - 8 <= heights[floorNumber - floor + 1]) {
                        setheight(nextPx - 160);
                        floors[floor - 1].px = nextPx - 160;
                        // console.log(nextPx - 160)
                        // console.log(floors)
                      }
                    }
                  } else {
                    const prevpx = floors[floorNumber - 1 - prev].px;
                    const nextPx = floors[floorNumber - 1 - next].px;
                    // if (height*20 - heights[floorNumber-floor]*20 - 150 + 160 <= nextPx) console.log('yes1')
                    // if (height*20 - heights[floorNumber-floor]*20 - 150 - 160 >= prevpx) console.log('yes2')
                    if (
                      height * 20 - heights[floorNumber - floor] * 20 - 150 + 160 <= nextPx &&
                      height * 20 - heights[floorNumber - floor] * 20 - 150 - 160 >= prevpx
                    ) {
                      // mydiv.style.top = `${height*20 - heights[floorNumber-floor]*20}px`;
                      floors[floor - 1].px = height * 20 - heights[floorNumber - floor] * 20 - 150;
                      setheight(height * 20 - heights[floorNumber - floor] * 20 - 150);
                    } else {
                      if (heights[floorNumber - floor] + 8 >= heights[floorNumber - floor - 1]) {
                        floors[floor - 1].px = prevpx + 160;
                        setheight(prevpx + 160);
                      }
                      if (heights[floorNumber - floor] - 8 <= heights[floorNumber - floor + 1]) {
                        floors[floor - 1].px = nextPx - 160;
                        setheight(nextPx - 160);
                      }
                      // else floors[floor-1].px = nextPx - 160;
                    }
                  }
                }
              }}
              style={{ width: 95, marginLeft: '10px', zIndex: '10000' }}
              variant="outlined"
              type="number"
              inputProps={{
                min: 0,
                max: height - 5,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

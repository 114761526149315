export const currencies = [
  {
    code: 'USD',
    name: 'American Dollar',
    symbol: '$',
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    symbol: '$',
  },
  {
    code: 'EUR',
    name: 'Euro',
    symbol: '€',
  },
  {
    code: 'GBP',
    name: 'British Pound',
    symbol: '£',
  },
];

export const noUScurrencies = [
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    symbol: '$',
  },
  {
    code: 'EUR',
    name: 'Euro',
    symbol: '€',
  },
  {
    code: 'GBP',
    name: 'British Pound',
    symbol: '£',
  },
];

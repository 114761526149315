export function sortPOS(prods) {
  const sorted = prods.sort(function (a, b) {
    const array = a.pos.split(' ');
    a.lane = array[1].substring(0, array[1].length - 1);
    const a2 = b.pos.split(' ');
    b.lane = a2[1].substring(0, a2[1].length - 1);
    if (a.pos[0] > b.pos[0]) {
      return 1;
    }
    if (a.pos[0] < b.pos[0]) {
      return -1;
    }
    return a.lane - b.lane;
  });
  return sorted;
}

export function localDateRange(start, end, noReset) {
  // let offset = new Date().getTimezoneOffset();
  const endByDay = noReset ? new Date(end) : new Date(end.setHours(23, 59, 59, 999));
  const startDay = noReset ? new Date(start) : new Date(start.setHours(0, 0, 0, 0));
  // console.log('startDay', startDay);
  let offsetStart = startDay.getTimezoneOffset();
  let offsetEnd = endByDay.getTimezoneOffset();
  const startDateString = new Date(
    startDay.setTime(startDay.getTime() - offsetStart * 60 * 1000)
  ).toISOString();
  const endDateString = new Date(
    endByDay.setTime(endByDay.getTime() - offsetEnd * 60 * 1000)
  ).toISOString();
  // console.log(startDateString, endDateString);
  return { startDateString: startDateString, endDateString: endDateString };
}

export const imageOnError = (ev) => {
  ev.target.src = '/Img/brokenImg.png';
};

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { CloudUpload } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BallotIcon from '@material-ui/icons/Ballot';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EmailIcon from '@material-ui/icons/Email';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import Inventory from '../orders/Inventory';
import ManageCategories from './ManageCategories';
import Category from './category/Category';
import ManageProducts from './ManageProducts';
import AddProduct from '../items/AddProduct';
import VpnKeySharpIcon from '@material-ui/icons/VpnKeySharp';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import SmtpConfiguration from './email/smtpConfiguration';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import './dashboard.css';
//APP component
import Groups from './group/Groups';
import ChangePassWord from '../user/ChangePassword';
import Order from '../orders/Order';
import ViewAllGroups from './viewAllGroups';
import ViewMyUser from './viewMyUser';
import ViewMyMachines from './viewMyMachines';
import AddCategoryToMachine from '../machines/AddCategoryToMachine';
import AddProductToMachine from '../machines/AddProductToMachine';
import Brand from './brand/brand';
import ViewMyPlano from '../../planogram/ViewMyPlano';
import Promo from './promo/promo';
import CreateGroup from '../items/PromoCode';
import CreateMachine from './createMachine';
import SignUp from '../user/SignUp';
import Logs from '../Logs/Logs';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarketingReport from './report/marketingReport/MarketingReport';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';

import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/userContext';
import { useHistory } from 'react-router';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../services/PrivateRoute';
import SuperAdminRoute from '../../services/SuperAdminRoute';
import AddBrandToMachine from '../machines/Brands/AddBrandToMachine';
import ReportByUPC from './report/ReportByUPC';
import Report from './report/Report';
import StockLog from './logs/StockLog';
import AlertEmail from './alert/AlertEmail';
import AlertCustom from './alert/AlertCustom';

import ScreenSaver from './screenSaver/ScreenSaver';
import EmailReceipt from './email/EmailReceipt';
import ImportProducts from '../items/ImportProducts';
import CustomAudio from './customAudio/CustomAudio';
import KioskLog from './alert/KioskLog';
import Temperature from './temperature/Temperature';
import { useLocation } from 'react-router-dom';
import CashTrans from './cash/CashTrans';

import DvrIcon from '@mui/icons-material/Dvr';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import CategoryIcon from '@mui/icons-material/Category';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InventoryIcon from '@mui/icons-material/Inventory';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import MoneyIcon from '@mui/icons-material/Money';
import SelectMachinesForMap from './googlemap/SelectMachinesForMap';
import ViewAllMachines from './Admin/ViewAllMachines';
import ViewHFusers from './HFuser/ViewHFuser';
import DownloadSales from './Admin/DownloadSales';
import MagexSales from './MagexSales/MagexSales';
import ViewMagexUser from './MagexSales/ViewMagexUser';
import ViewMagexInvoice from './MagexSales/MagexInvoice';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'js-cookie';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: 1001,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // height: '100vh',
    paddingBottom: '100px',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  fixedHeight2: {
    height: 280,
  },
}));

export default function Dashboard() {
  const {
    loginStatus,
    setLoginStatus,
    userName,
    setUserName,
    role,
    setRole,
    userEmail,
    setUserEmail,
    referTo,
    setReferTo,
    fullAccess,
    setFullAccess,
  } = useContext(UserContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const features = user?.features;
  const {
    edit_user,
    edit_group,
    view_machine,
    view_plano,
    view_ss,
    view_prod,
    view_cate,
    view_brand,
    view_receipt,
    view_promo,
    view_trans,
    view_report,
    edit_prod,
  } = JSON.parse(localStorage.getItem('limits'));
  // console.log(JSON.parse(localStorage.getItem('limits')));
  // const showSuperAdminAndAdmin = (role === "SuperAdmin" || role === "Admin" || role === "User" && fullAccess) ? true : false;
  const isSuperAdmin = user.role === 'SuperAdmin' ? true : false;
  const isBoxAdmin = user.role === 'BoxAdmin' ? true : false;
  const isMagexAdmin = user.role === 'SuperAdmin' || user.role === 'MagexAdmin';
  const NotBothAdmin =
    user.role == 'SuperAdmin' || user.role === 'BoxAdmin' || user.role === 'MagexAdmin'
      ? true
      : false;

  const { t } = useTranslation();

  let id = '';
  if (role === 'Admin') id = userEmail;
  else if (role === 'User') id = referTo;

  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();

  const [showSmtp, setShowSmtp] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [showImport, setImport] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState([]);

  const [currLan, setCurrLan] = useState(Cookies.get('i18next') || 'en');
  const lastParam = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('MagexApp.cart');
    localStorage.removeItem('machineName');
    localStorage.removeItem('reRender');
    localStorage.removeItem('limits');
    // localStorage.clear();
    setLoginStatus(false);
    setUserName('');
    setRole('');
    setUserEmail('');
    setReferTo('');
    setFullAccess(false);
    history.push('/signIn');
  };

  const dashboardPage = async () => {
    history.push('/dashboard');
  };

  const viewMyUsers = async () => {
    history.push('/dashboard/viewmyusers');
  };

  const viewAllAdmin = async () => {
    history.push('/dashboard/viewallgroups');
  };

  const configureSMTP = async () => {
    history.push('/dashboard/email/manage-smtp');
  };

  const changePassword = async () => {
    history.push('/dashboard/changePassword');
  };

  const viewMyMachines = async () => {
    // if (role === "SuperAdmin")
    //     history.push("/viewallmachines")
    // else
    history.push('/dashboard/viewmymachines');
  };

  const manageAllProducts = async () => {
    history.push('/dashboard/manage-all-products');
  };

  const manageAllCategories = async () => {
    history.push('/dashboard/manage-all-categories');
  };
  const manageBrand = async () => {
    history.push('/dashboard/brand');
  };
  const showOrders = async () => {
    history.push('/dashboard/order');
  };
  const showCash = async () => {
    history.push('/dashboard/cashTrans');
  };
  const addNewMachine = async () => {
    history.push('/dashboard/createmachine');
  };
  const group = async () => {
    history.push('/dashboard/group');
  };
  const promo = async () => {
    history.push('/dashboard/promo');
  };

  const screenSaver = async () => {
    history.push('/dashboard/screenSaver');
  };

  const customAudio = async () => {
    history.push('/dashboard/customAudio');
  };

  const emailReceipt = async () => {
    history.push('/dashboard/emailReceipt');
  };

  const viewPlano = async () => {
    history.push('/dashboard/planogram');
  };

  const viewReport = async () => {
    history.push('/dashboard/report');
  };

  const viewReportByUPC = async () => {
    history.push('/dashboard/reportByUPC');
  };

  const inventory = async () => {
    history.push('/dashboard/inventory');
  };

  const stockLog = async () => {
    history.push('/dashboard/stockLog');
  };

  const mkReport = async () => {
    history.push('/dashboard/mkReport');
  };

  const alert = async () => {
    history.push('/dashboard/alert');
  };

  const kioskLog = async () => {
    history.push('/dashboard/kioskLog');
  };

  const temperature = async () => {
    history.push('/dashboard/temperature');
  };

  const messagePersonalization = async () => {
    history.push('/dashboard/messagePersonalization');
  };

  const downloadLog = async () => {
    history.push('/dashboard/log');
  };

  const downloadSales = async () => {
    history.push('/dashboard/downloadSales');
  };

  const machinesOnMap = async () => {
    history.push('/dashboard/machinesOnMap');
  };

  const viewMachine = async () => {
    history.push('/dashboard/viewmachine');
  };

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixedHeightPaper2 = clsx(classes.paper, classes.fixedHeight2);

  const mainDashboardComponent = () => {
    return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper className={order.length == 0 ? fixedHeightPaper : fixedHeightPaper2}>
              <Chart id={id} view_trans={view_trans} />
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={order.length == 0 ? fixedHeightPaper : fixedHeightPaper2}>
              <Deposits name={userName} role={role} fullAccess={fullAccess} />
            </Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Orders
                loadind={loading}
                setLoading={setLoading}
                order={order}
                setOrder={setOrder}
                id={id}
                view_trans={view_trans}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          {window.location.pathname.includes('/dashboard/manage-all-products') && edit_prod && (
            <Button
              onClick={() => setShowAdd(true)}
              color="inherit"
              size="large"
              startIcon={<AddCircleIcon />}
            >
              Add Product
            </Button>
          )}
          {/* <FormControl style={{ width: '150px' }}>
            <InputLabel style={{ color: 'white' }}>Language</InputLabel>
            <Select
              value={currLan}
              label="Languages"
              onChange={(e) => {
                setCurrLan(e.target.value);
                i18next.changeLanguage(e.target.value);
              }}
            >
              <MenuItem value={'en'}>English</MenuItem>
              <MenuItem value={'fr'}>French</MenuItem>
              <MenuItem value={'es'}>Spanish</MenuItem>
            </Select>
          </FormControl> */}
          {user?.theme === '0' &&
            window.location.pathname.includes('/dashboard/manage-all-products') &&
            edit_prod && (
              <Button
                onClick={() => setImport(true)}
                color="inherit"
                size="large"
                startIcon={<CloudUpload />}
              >
                Import Products
              </Button>
            )}

          <AddProduct show={showAdd} onHide={() => setShowAdd(false)} />
          <ImportProducts show={showImport} onHide={() => setImport(false)} />
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {window.location.pathname === '/dashboard' && t('Dashboard')}
            {window.location.pathname === '/dashboard/manage-all-categories' && 'Categories'}
            {window.location.pathname === '/dashboard/manage-all-products' && 'Manage All Products'}
            {window.location.pathname.indexOf('/dashboard/viewmyusers') === 0 && 'Users'}
            {window.location.pathname.indexOf('/dashboard/group') === 0 && 'Groups'}
            {window.location.pathname.indexOf('/dashboard/viewmymachines') === 0 && 'Machines'}
            {window.location.pathname.indexOf('/dashboard/viewallgroups') === 0 && 'Groups'}
            {window.location.pathname.indexOf('/dashboard/planogram') === 0 && 'Planograms'}
            {window.location.pathname.indexOf('/dashboard/brand') === 0 && 'Brands'}
            {window.location.pathname.indexOf('/dashboard/order') === 0 && 'Transactions'}
            {window.location.pathname.indexOf('/dashboard/promo') === 0 && 'Promos'}
            {window.location.pathname.indexOf('/dashboard/screenSaver') === 0 && 'Screen Saver'}
            {window.location.pathname.indexOf('/dashboard/customAudio') === 0 && 'Custom Audio'}
            {window.location.pathname.indexOf('/dashboard/log') === 0 && 'Stocking Log'}
            {window.location.pathname.indexOf('/dashboard/report') === 0 && 'Reports'}
            {window.location.pathname.indexOf('/dashboard/emailReceipt') === 0 && 'Email receipt'}
            {window.location.pathname.indexOf('/dashboard/inventory') === 0 && 'Inventory'}
            {window.location.pathname.indexOf('/dashboard/stockLog') === 0 && 'Stocking Log'}
            {window.location.pathname.indexOf('/dashboard/temperature') === 0 && 'Temperature'}
            {window.location.pathname.indexOf('/dashboard/mkReport') === 0 && 'Marketing Report'}
            {window.location.pathname.indexOf('/dashboard/alert') === 0 && 'Alerts'}
            {window.location.pathname.indexOf('/dashboard/kioskLog') === 0 && 'Kiosk Log'}
            {window.location.pathname.indexOf('/dashboard/cashTrans') === 0 && 'Cash Transactions'}
            {window.location.pathname.indexOf('/dashboard/messagePersonalization') === 0 &&
              'Message Personalization'}
            {window.location.pathname.indexOf('/dashboard/magexsales') === 0 && 'Magex Sales'}
            {window.location.pathname.indexOf('/dashboard/magexusers') === 0 && 'View Magex User'}
            {window.location.pathname.indexOf('/dashboard/magexinvoice') === 0 &&
              'View Magex Invoice'}
          </Typography>
          {/* <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Tooltip title="Exit">
            <IconButton color="inherit" aria-label="Exit" onClick={logout}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        style={{ zIndex: 1000 }}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          {/* <div
            style={{ margin: 'auto', fontWeight: 'bold' }}
          >{`${user.firstname} (${user.groupCode})`}</div> */}
          <div
            style={{ margin: 'auto', fontWeight: 'bold' }}
          >{`${user.firstname} ${user.lastname}`}</div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem
              button
              onClick={dashboardPage}
              className={lastParam === 'dashboard' ? 'dashboardtag' : ''}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <Divider />
            <List>
              {!NotBothAdmin && (
                <ListItem
                  button
                  onClick={viewMyUsers}
                  disabled={!edit_user}
                  className={lastParam === 'viewmyusers' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              )}

              {/* {(id === '1234@gmail.com' || isSuperAdmin) && (
                <ListItem
                  button
                  className={lastParam === 'viewHFusers' ? 'dashboardtag' : ''}
                  onClick={() => {
                    history.push('/dashboard/viewHFusers');
                  }}
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="HFusers" />
                </ListItem>
              )} */}

              {!NotBothAdmin && (
                <ListItem
                  button
                  onClick={group}
                  disabled={!edit_group}
                  className={lastParam === 'group' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <PeopleOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Groups" />
                </ListItem>
              )}
              {isSuperAdmin && (
                <ListItem
                  button
                  onClick={viewAllAdmin}
                  className={lastParam === 'viewallgroups' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="All Groups" />
                </ListItem>
              )}
              <Divider />
            </List>
            {!NotBothAdmin && view_machine && (
              <ListItem
                button
                onClick={viewMyMachines}
                className={lastParam === 'viewmymachines' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <DesktopWindowsIcon />
                </ListItemIcon>
                <ListItemText primary="Machines" />
              </ListItem>
            )}
            {!NotBothAdmin && view_plano && (
              <ListItem
                button
                onClick={viewPlano}
                className={lastParam === 'planogram' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <DvrIcon />
                </ListItemIcon>
                <ListItemText primary="Planograms" />
              </ListItem>
            )}
            {!NotBothAdmin && view_ss && (
              <ListItem
                button
                onClick={screenSaver}
                className={lastParam === 'screenSaver' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <OndemandVideoIcon />
                </ListItemIcon>
                <ListItemText primary="Screen Saver" />
              </ListItem>
            )}
            {!NotBothAdmin && view_ss && !features.disableAudio && (
              <ListItem
                button
                onClick={customAudio}
                className={lastParam === 'customAudio' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <LibraryMusicIcon />
                </ListItemIcon>
                <ListItemText primary="Custom Audio" />
              </ListItem>
            )}
            {!NotBothAdmin && view_prod && (
              <ListItem
                button
                onClick={manageAllProducts}
                className={lastParam === 'manage-all-products' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <BallotIcon />
                </ListItemIcon>
                <ListItemText primary="Products" />
              </ListItem>
            )}
            {!NotBothAdmin && view_cate && (
              <ListItem
                button
                onClick={manageAllCategories}
                className={lastParam === 'manage-all-categories' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="Categories" />
              </ListItem>
            )}
            {!NotBothAdmin && view_brand && (
              <ListItem
                button
                onClick={manageBrand}
                className={lastParam === 'brand' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <BrandingWatermarkIcon />
                </ListItemIcon>
                <ListItemText primary="Brands" />
              </ListItem>
            )}
            {!NotBothAdmin && view_receipt && (
              <ListItem
                button
                onClick={emailReceipt}
                className={lastParam === 'emailReceipt' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <MailOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Email Receipt" />
              </ListItem>
            )}
            {!NotBothAdmin && view_promo && !features?.disablePromo && (
              <ListItem
                button
                onClick={promo}
                className={lastParam === 'promo' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <MoneyOffIcon />
                </ListItemIcon>
                <ListItemText primary="Promotions" />
              </ListItem>
            )}
            {!NotBothAdmin && (
              <div>
                <Divider />

                <ListItem
                  button
                  onClick={viewReport}
                  disabled={!view_report}
                  className={lastParam === 'report' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItem>
                <ListItem
                  button
                  onClick={viewReportByUPC}
                  disabled={!view_report}
                  className={lastParam === 'reportByUPC' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports(UPC/SKU)" />
                </ListItem>
                <ListItem
                  button
                  onClick={showOrders}
                  disabled={!view_trans}
                  className={lastParam === 'order' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText primary="Transactions" />
                </ListItem>
                <ListItem
                  button
                  onClick={showCash}
                  disabled={!view_trans}
                  className={lastParam === 'cash' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <MoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cash Transactions" />
                </ListItem>
                <ListItem
                  button
                  onClick={inventory}
                  disabled={!view_report}
                  className={lastParam === 'inventory' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inventory" />
                </ListItem>

                <ListItem
                  button
                  onClick={stockLog}
                  disabled={!view_report}
                  className={lastParam === 'stockLog' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <Inventory2OutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Stocking Log" />
                </ListItem>
                {!features?.disableMR && (
                  <ListItem
                    button
                    onClick={mkReport}
                    disabled={!view_report}
                    className={lastParam === 'mkReport' ? 'dashboardtag' : ''}
                  >
                    <ListItemIcon>
                      <AutoGraphOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Marketing Reports" />
                  </ListItem>
                )}

                {features?.extra && (
                  <ListItem
                    button
                    onClick={kioskLog}
                    disabled={!view_report}
                    className={lastParam === 'kioskLog' ? 'dashboardtag' : ''}
                  >
                    <ListItemIcon>
                      <AnalyticsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Kiosk Log" />
                  </ListItem>
                )}

                {!features?.disableAlert && (
                  <div>
                    <Divider />
                    <ListItem
                      button
                      onClick={alert}
                      disabled={!view_report}
                      className={lastParam === 'alert' ? 'dashboardtag' : ''}
                    >
                      <ListItemIcon>
                        <WarningAmberOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Alert" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={messagePersonalization}
                      disabled={!view_report}
                      className={lastParam === 'messagePersonalization' ? 'dashboardtag' : ''}
                    >
                      <ListItemIcon>
                        <DisplaySettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Msg Personalization" />
                    </ListItem>
                  </div>
                )}

                {features?.temperature && (
                  <ListItem
                    button
                    onClick={temperature}
                    disabled={!view_report}
                    className={lastParam === 'temperature' ? 'dashboardtag' : ''}
                  >
                    <ListItemIcon>
                      <DeviceThermostatIcon />
                    </ListItemIcon>
                    <ListItemText primary="Temperature" />
                  </ListItem>
                )}

                <Divider />
              </div>
            )}
            <List>
              <ListItem
                button
                onClick={changePassword}
                className={lastParam === 'changePassword' ? 'dashboardtag' : ''}
              >
                <ListItemIcon>
                  <VpnKeySharpIcon />
                </ListItemIcon>
                <ListItemText primary="Password & Email" />
              </ListItem>
            </List>
            <Divider />
            {/* {onlyAdmin && 
      <div>
          <Divider />
          <List>
          <ListItem button onClick={downloadLog}>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary="Download Logs" />
          </ListItem>
          </List>
          <Divider />
      </div>
    } */}
            {role === 'SuperAdmin' && (
              <>
                <List>
                  <ListItem
                    button
                    onClick={configureSMTP}
                    className={lastParam === 'manage-smtp' ? 'dashboardtag' : ''}
                  >
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary="SMTP Configuration" />
                  </ListItem>
                </List>
                <Divider />

                <List>
                  <ListItem
                    button
                    onClick={addNewMachine}
                    className={lastParam === 'createmachine' ? 'dashboardtag' : ''}
                  >
                    <ListItemIcon>
                      <AddCircleRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Add New Machines" />
                  </ListItem>
                </List>

                <List>
                  <ListItem
                    button
                    onClick={machinesOnMap}
                    className={lastParam === 'machinesOnMap' ? 'dashboardtag' : ''}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Machines on Map" />
                  </ListItem>
                </List>

                <Divider />
              </>
            )}

            {isSuperAdmin && (
              <div>
                <List>
                  <ListItem
                    button
                    onClick={downloadSales}
                    className={lastParam === 'downloadSales' ? 'dashboardtag' : ''}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Download Sales By Machine" />
                  </ListItem>
                </List>
                <Divider />
              </div>
            )}
            {NotBothAdmin && user.role !== 'MagexAdmin' && (
              <List>
                <ListItem
                  button
                  onClick={viewMachine}
                  className={lastParam === 'viewmachine' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="View Machines" />
                </ListItem>

                <ListItem
                  button
                  onClick={kioskLog}
                  disabled={!view_report}
                  className={lastParam === 'kioskLog' ? 'dashboardtag' : ''}
                >
                  <ListItemIcon>
                    <AnalyticsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Kiosk Log" />
                </ListItem>
              </List>
            )}

            {(id === '1234@gmail.com' || isMagexAdmin) && (
              <List>
                <ListItem
                  button
                  className={lastParam === 'magexusers' ? 'dashboardtag' : ''}
                  onClick={() => {
                    history.push('/dashboard/magexusers');
                  }}
                >
                  <ListItemIcon>
                    <AnalyticsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Magex Users" />
                </ListItem>
                <ListItem
                  button
                  className={lastParam === 'magexsales' ? 'dashboardtag' : ''}
                  onClick={() => {
                    history.push('/dashboard/magexsales');
                  }}
                >
                  <ListItemIcon>
                    <AnalyticsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Magex Sales" />
                </ListItem>
                <ListItem
                  button
                  className={lastParam === 'magexinvoice' ? 'dashboardtag' : ''}
                  onClick={() => {
                    history.push('/dashboard/magexinvoice');
                  }}
                >
                  <ListItemIcon>
                    <AnalyticsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Magex Invoice" />
                </ListItem>
              </List>
            )}

            <List>
              <ListItem button onClick={logout}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </ListItem>
            </List>
            <Divider />

            <Divider />
          </div>
        </List>
      </Drawer>
      <div id="swal2-container" className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route exact path={`${path}/`}>
            {mainDashboardComponent()}
          </Route>
          <PrivateRoute exact path={`${path}/group`} component={Groups}></PrivateRoute>
          <PrivateRoute exact path={`${path}/group/:id/:group`} component={Groups}></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/changePassword`}
            component={ChangePassWord}
          ></PrivateRoute>
          <PrivateRoute exact path={`${path}/order`} component={Order}></PrivateRoute>
          <PrivateRoute exact path={`${path}/order/:id/:group`} component={Order}></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/viewallgroups`}
            component={ViewAllGroups}
          ></PrivateRoute>
          <PrivateRoute exact path={`${path}/viewmyusers`} component={ViewMyUser}></PrivateRoute>
          <PrivateRoute exact path={`${path}/viewHFusers`} component={ViewHFusers}></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/viewmyusers/:id/:group/:_id`}
            component={ViewMyUser}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/viewmymachines`}
            component={ViewMyMachines}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/viewmymachines/:id/:group`}
            component={ViewMyMachines}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/addCategories/:id`}
            component={AddCategoryToMachine}
          ></PrivateRoute>
          {/* <PrivateRoute exact path={`${path}/addCategories`} component={AddCategoryToMachine}></PrivateRoute > */}
          <PrivateRoute
            exact
            path={`${path}/addBrands/:id`}
            component={AddBrandToMachine}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/addProducts/:id`}
            component={AddProductToMachine}
          ></PrivateRoute>
          <PrivateRoute exact path={`${path}/brand`} component={Brand}></PrivateRoute>
          <PrivateRoute exact path={`${path}/brand/:id/:group`} component={Brand}></PrivateRoute>
          <PrivateRoute exact path={`${path}/emailReceipt`} component={EmailReceipt}></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/emailReceipt/:id/:group`}
            component={EmailReceipt}
          ></PrivateRoute>
          <Route exact path={`${path}/planogram`} component={ViewMyPlano} />
          <Route exact path={`${path}/planogram/:id/:group`} component={ViewMyPlano} />
          <PrivateRoute exact path={`${path}/promo`} component={Promo}></PrivateRoute>
          <PrivateRoute exact path={`${path}/promo/:id/:group`} component={Promo}></PrivateRoute>
          <PrivateRoute exact path={`${path}/createGroup`} component={CreateGroup}></PrivateRoute>
          <SuperAdminRoute
            exact
            path={`${path}/createmachine`}
            component={CreateMachine}
          ></SuperAdminRoute>
          <PrivateRoute exact path={`${path}/signUp`} component={SignUp} />
          <PrivateRoute exact path={`${path}/signUp/:id/:_id`} component={SignUp} />
          <PrivateRoute exact path={`${path}/log`} component={Logs} />
          <PrivateRoute exact path={`${path}/log/:id`} component={Logs} />
          <PrivateRoute exact path={`${path}/screenSaver`} component={ScreenSaver}></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/screenSaver/:id/:group`}
            component={ScreenSaver}
          ></PrivateRoute>
          <PrivateRoute exact path={`${path}/customAudio`} component={CustomAudio}></PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/customAudio/:id/:group`}
            component={CustomAudio}
          ></PrivateRoute>
          <Route path={`${path}/manage-all-products`}>
            <ManageProducts />
          </Route>
          <Route exact path={`${path}/manage-all-categories`} component={Category}></Route>
          <Route exact path={`${path}/manage-all-categories/:id/:group`} component={Category} />
          <Route exact path={`${path}/report`} component={Report}></Route>
          <Route exact path={`${path}/report/:id/:group`} component={Report} />
          <Route exact path={`${path}/mkReport`} component={MarketingReport}></Route>
          <Route exact path={`${path}/mkReport/:id/:group`} component={MarketingReport} />
          <Route exact path={`${path}/reportByUPC`} component={ReportByUPC}></Route>
          <Route exact path={`${path}/reportByUPC/:id/:group`} component={ReportByUPC} />
          <Route exact path={`${path}/inventory`} component={Inventory}></Route>
          <Route exact path={`${path}/inventory/:id/:group`} component={Inventory} />
          <Route exact path={`${path}/cashTrans`} component={CashTrans}></Route>
          <Route exact path={`${path}/cashTrans/:id/:group`} component={CashTrans} />
          <Route exact path={`${path}/stockLog`} component={StockLog}></Route>
          <Route exact path={`${path}/stockLog/:id/:group`} component={StockLog} />
          <Route exact path={`${path}/temperature`} component={Temperature}></Route>
          <Route exact path={`${path}/temperature/:id/:group`} component={Temperature} />
          <Route exact path={`${path}/alert`} component={AlertEmail}></Route>
          <Route exact path={`${path}/alert/:id/:group`} component={AlertEmail} />
          <Route exact path={`${path}/kioskLog`} component={KioskLog}></Route>
          <Route exact path={`${path}/kioskLog/:id/:group`} component={KioskLog} />
          <Route exact path={`${path}/messagePersonalization`} component={AlertCustom}></Route>
          <Route exact path={`${path}/downloadSales`} component={DownloadSales}></Route>
          <Route exact path={`${path}/messagePersonalization/:id/:group`} component={AlertCustom} />
          <SuperAdminRoute path={`${path}/email/manage-smtp`}>
            <SmtpConfiguration show={showSmtp} onHide={() => setShowSmtp(false)} />
          </SuperAdminRoute>
          <SuperAdminRoute path={`${path}/machinesOnMap`}>
            <SelectMachinesForMap />
          </SuperAdminRoute>
          <SuperAdminRoute path={`${path}/viewmachine`} component={ViewAllMachines} />
          <Route exact path={`${path}/magexsales`} component={MagexSales} />
          <Route exact path={`${path}/magexusers`} component={ViewMagexUser} />
          <Route exact path={`${path}/magexinvoice`} component={ViewMagexInvoice} />

          <SuperAdminRoute exact path={`${path}/categories/:user`} component={ManageCategories} />
          <SuperAdminRoute
            exact
            path={`${path}/categories/:user/:category`}
            component={(props) => (
              <ManageProducts
                catename={props.match.params.category}
                user={props.match.params.user}
              />
            )}
          />
          <SuperAdminRoute
            exact
            path={`${path}/products/:user`}
            component={(props) => <ManageProducts user={props.match.params.user} />}
          />
        </Switch>
      </div>
    </div>
  );
}

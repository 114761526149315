import { Modal } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { Button } from 'react-bootstrap';

export default function QRpage(props) {
  const { show, onHide, qrcode } = props;

  const downloadIMG = () => {
    const canvas = document.getElementById('qr-gen');
    console.log(canvas);
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrcode}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    props.onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Your QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-content-center">
          <div className="mt-3 mb-3 d-flex flex-column align-content-center flex-wrap">
            <QRCode id="qr-gen" value={qrcode} level={'H'} size={290} includeMargin={true} />
          </div>
          <div className="mt-3 d-flex flex-column align-content-start">
            <Button className="mb-3" variant="primary" onClick={downloadIMG}>
              Download My QR Code
            </Button>
            {/* <Button className="mb-3" variant="primary" onClick={downloadPDF}>Download pdf</Button> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import Swal from 'sweetalert2';

import { Modal, Row, Col } from 'react-bootstrap';

import request from '../services/Request';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 200,
    maxWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PropertyModal(props) {
  const classes = useStyles();

  const getPlanoByEmailURL = `/plano/getPlanoByEmail/`;
  const updateMachineURL = `/machines/updatePlano`;
  const {
    index,
    lineNumber,
    handleChange,
    disable,
    lines,
    setLines,
    plano,
    uploadProd,
    setUploadProd,
    floor,
    ...rest
  } = props;

  const [motor, setMotor] = useState('n');

  const showInfo = () => {
    console.log(lines);
  };

  const changeMotor = (event) => {
    console.log(parseInt(event.target.value));
    let myIndex = uploadProd.findIndex((e) =>
      e.pos.includes(floor + 'F ' + parseInt(index + 1) + 'L')
    );
    if (myIndex > -1) {
      uploadProd[myIndex].motor = parseInt(event.target.value);
    }
    lines[index].motor = parseInt(event.target.value);
    setLines([...lines]);
    // setUploadProd([...uploadProd]);werwqer
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body" onClick={() => {}}>
        <Grid item xs={12} style={{ fontSize: '20px', marginBottom: '10px' }}>
          {lines[index].product?.name}
        </Grid>

        <Grid item xs={12} onClick={showInfo}>
          {index != 0 && index != lineNumber - 1 && (
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{ fontSize: '0.8rem' }}>
                Disable/Enable Lanes
              </FormLabel>
              <RadioGroup
                row
                aria-label="percentage"
                style={{ justifyContent: 'space-around' }}
                value={disable}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Enable"
                  onChange={handleChange}
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Disable"
                  onChange={handleChange}
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        {lines[index].product && lines[index].length > 0 && (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{ fontSize: '0.8rem' }}>
                Motor
              </FormLabel>
              <RadioGroup
                row
                aria-label="percentage"
                style={{ justifyContent: 'space-around' }}
                value={lines[index].motor}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Normal"
                  onChange={(e) => changeMotor(e)}
                />
                <FormControlLabel
                  value={10}
                  control={<Radio />}
                  label="Big"
                  onChange={(e) => changeMotor(e)}
                />
                <FormControlLabel
                  value={8}
                  control={<Radio />}
                  label="Small"
                  onChange={(e) => changeMotor(e)}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}

        <Grid>
          {lines[index].product && lines[index].length > 0 && (
            <TextField
              type="number"
              label="Step"
              variant="outlined"
              InputProps={{ inputProps: { min: 1, max: 12 } }}
              fullWidth
              value={lines[index].step}
              onChange={(e) => {
                let myIndex = uploadProd.findIndex((e) =>
                  e.pos.includes(floor + 'F ' + parseInt(index + 1) + 'L')
                );
                if (myIndex > -1) {
                  uploadProd[myIndex].step = parseInt(e.target.value);
                  uploadProd[myIndex].maxStock = Math.trunc(plano.step_num / e.target.value);
                  uploadProd[myIndex].max_stock = Math.trunc(plano.step_num / e.target.value);
                }
                // for (let i = 0; i < lines[index].length; i++) {
                lines[index].step = parseInt(e.target.value);
                lines[index].maxStock = Math.trunc(plano.step_num / e.target.value);
                // }
                // setUploadProd([...uploadProd]);
                setLines([...lines]);
              }}
            />
          )}
        </Grid>
        <Container component="main">
          <CssBaseline />
        </Container>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            props.onHide();
          }}
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormGroup,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { UserContext } from '../contexts/userContext';
import { v4 as uuidv4 } from 'uuid';
import { themes } from '../../staticData/theme';
import Swal from 'sweetalert2';
import { languages_noEn } from '../../staticData/languages';

import { Autocomplete } from '@material-ui/lab';
import { useForm, Controller } from 'react-hook-form';
import request from '../../services/Request';
import './form.css';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();

  const { role, userEmail, referTo } = useContext(UserContext);

  const createUserURL =
    role === 'SuperAdmin' && !props.match.params.id ? `/users/createAdmin` : `/users/create`;

  const getUserByID = `/users/edit/`;

  const isSuperAdmin = props.match.params.id ? true : false;
  const hideLimits = role === 'SuperAdmin' && !props.match.params.id ? true : false;
  const disableAdmin = role === 'Admin' || role === 'User' || props.match.params.id;
  const value = props.match.params.id || role === 'Admin' || role === 'User' ? 'User' : 'Admin';
  let _id = props.match.params.id ? props.match.params.id : userEmail;
  if (role === 'User') _id = referTo;
  const user = JSON.parse(localStorage.getItem('user'));
  const dbaUser = user.dba;
  const langs = user?.langs;

  const features = user?.features;
  const theme = user?.theme;
  const thirdPartyLink = user.thirdPartyLink;
  const [viewButton, setviewButton] = useState('Check All View');

  const defaultValues = {
    role: value,
    access: '0',
    hidden: '0',
    view_machine: true,
    edit_machine: false,
    view_prod: true,
    edit_prod: false,
    view_cate: true,
    edit_cate: false,
    view_brand: true,
    edit_brand: false,
    view_receipt: true,
    edit_receipt: false,
    view_ss: true,
    edit_ss: false,
    edit_group: false,
    edit_user: false,
    view_plano: true,
    edit_plano: false,
    view_promo: true,
    edit_promo: false,
    view_report: true,
    view_trans: true,
    stocking: false,
    write: false,
    theme: theme,
    additionPrice: features?.additionPrice ? features.additionPrice : false,
    disablePromo: features?.disablePromo ? features.disablePromo : false,
    disableAlert: features?.disableAlert ? features.disableAlert : false,
    temperature: features?.temperature ? features.temperature : false,
    disableMR: features?.disableMR ? features.disableMR : false,
    disableAudio: features?.disableAudio ? features.disableAudio : false,
    extra: features?.extra ? features.extra : false,
    advanced: features?.advanced ? features.advanced : false,
    langs: langs ? langs : [],
  };

  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const watchAll = watch();

  const checkAllView = () => {
    if (viewButton === 'Check All View') {
      setValue('view_machine', true);
      setValue('view_prod', true);
      setValue('view_cate', true);
      setValue('view_brand', true);
      setValue('view_receipt', true);
      setValue('view_ss', true);
      setValue('view_plano', true);
      setValue('view_promo', true);
      setValue('view_report', true);
      setValue('view_trans', true);
      setviewButton('Uncheck All View');
    } else {
      setValue('view_machine', false);
      setValue('view_prod', false);
      setValue('view_cate', false);
      setValue('view_brand', false);
      setValue('view_receipt', false);
      setValue('view_ss', false);
      setValue('view_plano', false);
      setValue('view_promo', false);
      setValue('view_report', false);
      setValue('view_trans', false);

      setValue('edit_machine', false);
      setValue('edit_prod', false);
      setValue('edit_cate', false);
      setValue('edit_brand', false);
      setValue('edit_receipt', false);
      setValue('edit_ss', false);
      setValue('edit_group', false);
      setValue('edit_user', false);
      setValue('edit_plano', false);
      setValue('edit_promo', false);
      setviewButton('Check All View');
    }
  };

  useEffect(() => {
    if (isSuperAdmin && role === 'SuperAdmin') {
      request({
        method: 'get',
        url: getUserByID + props.match.params._id,
      }).then((res) => {
        setValue('additionPrice', res.data?.features?.additionPrice);
        setValue('disablePromo', res.data?.features?.disablePromo);
        setValue('disableMR', res.data?.features?.disableMR);
        setValue('extra', res.data?.features?.extra);
        setValue('temperature', res.data?.features?.temperature);
        setValue('disableAudio', res.data?.features?.disableAudio);
        setValue('disableAlert', res.data?.features?.disableAlert);
        setValue('advanced', res.data?.features?.advanced);
        setValue('langs', res.data?.langs);
      });
    }
  }, []);

  const myOnchange = (field, e) => {
    const name = field.substring(4);
    if (field.includes('view')) {
      const view = field;
      const edit = 'edit' + name;
      if (!e.target.checked) {
        setValue(view, e.target.checked);
        setValue(edit, e.target.checked);
      } else {
        setValue(view, e.target.checked);
      }
    } else {
      const edit = field;
      const view = 'view' + name;
      if (e.target.checked) {
        setValue(view, e.target.checked);
        setValue(edit, e.target.checked);
      } else {
        setValue(edit, e.target.checked);
      }
    }
    if (field === 'write') {
      if (e.target.checked) {
        setValue('write', e.target.checked);
        setValue('stocking', e.target.checked);
      } else {
        setValue('write', e.target.checked);
      }
    }
    if (field === 'stocking') {
      if (!e.target.checked) {
        setValue('write', e.target.checked);
        setValue('stocking', e.target.checked);
      } else {
        setValue('stocking', e.target.checked);
      }
    }
  };

  const onSubmit = async (data) => {
    const access = data.access === '1' ? true : false;
    const hidden = data.hidden === '1' ? true : false;
    const email2 = data.email2;
    let features = {
      additionPrice: data.additionPrice,
      disablePromo: data.disablePromo,
      disableMR: data.disableMR,
      extra: data.extra,
      temperature: data.temperature,
      disableAudio: data.disableAudio,
      disableAlert: data.disableAlert,
      advanced: data.advanced,
    };
    let limits = {
      view_machine: data.view_machine,
      edit_machine: data.edit_machine,
      view_prod: data.view_prod,
      edit_prod: data.edit_prod,
      view_cate: data.view_cate,
      edit_cate: data.edit_cate,
      view_brand: data.view_brand,
      edit_brand: data.edit_brand,
      view_receipt: data.view_receipt,
      edit_receipt: data.edit_receipt,
      view_ss: data.view_ss,
      edit_ss: data.edit_ss,
      edit_group: data.edit_group,
      edit_user: data.edit_user,
      view_plano: data.view_plano,
      edit_plano: data.edit_plano,
      view_promo: data.view_promo,
      edit_promo: data.edit_promo,
      view_report: data.view_report,
      view_trans: data.view_trans,
    };
    if (access || hideLimits) {
      limits = {
        view_machine: true,
        edit_machine: true,
        view_prod: true,
        edit_prod: true,
        view_cate: true,
        edit_cate: true,
        view_brand: true,
        edit_brand: true,
        view_receipt: true,
        edit_receipt: true,
        view_ss: true,
        edit_ss: true,
        edit_group: true,
        edit_user: true,
        view_plano: true,
        edit_plano: true,
        view_promo: true,
        edit_promo: true,
        view_report: true,
        view_trans: true,
      };
    }
    const password = uuidv4().substr(0, 8);
    const finalLang = data.role === 'Admin' ? data.langs.map((e) => e.code) : data.langs;
    const email = data.email.trim().toLowerCase();
    await request({
      method: 'post',
      url: createUserURL,
      data: {
        groupname: data.groupName,
        groupCode: data.groupCode,
        firstname: data.firstName,
        lastname: data.lastName,
        password: password,
        email: email,
        role: data.role,
        addedToAdmin: _id,
        fullAccess: access,
        limits: limits,
        features: features,
        theme: data.theme,
        stocking: data.stocking,
        stocking_write: data.write,
        langs: finalLang,
        dba: hidden,
        email2: email2,
        thirdPartyLink: thirdPartyLink,
      },
    })
      .then((res) => {
        Swal.fire({
          text: `The account has been successfully created`,
          icon: 'success',
        }).then(() => {
          window.history.back();
        });
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.data.code === 1) {
            setError('email', {
              message: err.response.data.message,
            });
          }
          if (err.response.data.code === 2) {
            setError('groupCode', {
              message: err.response.data.message,
            });
          }
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs" style={{ height: '120vh' }}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create an account
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            {!isSuperAdmin && role === 'SuperAdmin' && (
              <Grid item xs={12}>
                <TextField
                  autoComplete="gName"
                  name="groupName"
                  variant="outlined"
                  required
                  fullWidth
                  id="groupName"
                  label="Group Name"
                  autoFocus
                  {...register('groupName', { required: 'group name is required' })}
                />
                {errors.groupName && (
                  <p className="signUpErrorMessage">{errors.groupName.message}</p>
                )}
              </Grid>
            )}
            {!isSuperAdmin && role === 'SuperAdmin' && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Group Code"
                  {...register('groupCode', { required: 'group code is required' })}
                />
                {errors.groupCode && (
                  <p className="signUpErrorMessage">{errors.groupCode.message}</p>
                )}
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                variant="outlined"
                required
                fullWidth
                label="First Name"
                {...register('firstName', { required: 'First name is required' })}
              />
              {errors.firstName && <p className="signUpErrorMessage">{errors.firstName.message}</p>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Last Name"
                autoComplete="lname"
                {...register('lastName', { required: 'Last name is required' })}
              />
              {errors.lastName && <p className="signUpErrorMessage">{errors.lastName.message}</p>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Email Address"
                autoComplete="email"
                {...register('email', {
                  required: 'Email name is required',
                  pattern: {
                    value:
                      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                    message: 'Please enter a valid email',
                  },
                })}
              />
              {errors.email && <p className="signUpErrorMessage">{errors.email.message}</p>}
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                {...register('password', { required: "Password is required" , minLength: {
                  value: 8,
                  message: "Password must longer than 8"
                }})}
              />
              {errors.password && <p className="signUpErrorMessage">{errors.password.message}</p>}
            </Grid> */}
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Create an account for </FormLabel>
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <RadioGroup aria-label="Role" name="Role1" row {...field}>
                      <FormControlLabel
                        value="User"
                        disabled={!disableAdmin}
                        control={<Radio />}
                        label="User"
                      />
                      {role === 'SuperAdmin' && (
                        <FormControlLabel
                          value="Admin"
                          disabled={disableAdmin}
                          control={<Radio />}
                          label="Admin"
                        />
                      )}

                      {role === 'SuperAdmin' && (
                        <FormControlLabel
                          value="MagexAdmin"
                          // disabled={disableAdmin}
                          control={<Radio />}
                          label="MagexAdmin"
                        />
                      )}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            {dbaUser && (
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Type of user</FormLabel>
                  <Controller
                    control={control}
                    name="hidden"
                    render={({ field }) => (
                      <RadioGroup aria-label="Role" name="Role1" row {...field}>
                        <FormControlLabel value="0" control={<Radio />} label="Normal" />
                        <FormControlLabel value="1" control={<Radio />} label="Hidden" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            {/* {(watchAll?.hidden === '1' || role === 'SuperAdmin') && ( */}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                // required
                fullWidth
                label="Email for receive password"
                autoComplete="email"
                {...register('email2', {
                  // required: 'Email2 is required',
                  pattern: {
                    value:
                      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                    message: 'Please enter a valid email',
                  },
                })}
              />
              {errors.email2 && <p className="signUpErrorMessage">{errors.email2.message}</p>}
            </Grid>
            {/* )} */}

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">User Access Level</FormLabel>
                <Controller
                  control={control}
                  name="access"
                  render={({ field }) => (
                    <RadioGroup aria-label="Role" name="Role1" row {...field}>
                      <FormControlLabel value="1" control={<Radio />} label="Full Access" />
                      <FormControlLabel value="0" control={<Radio />} label="Limited Access" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

            {role !== 'SuperAdmin' && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={checkAllView}
                className={classes.submit}
              >
                {viewButton}
              </Button>
            )}

            {!hideLimits && (
              <Grid container spacing={2} className="mb-2">
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="stocking"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.stocking}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Stocking(read)</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="write"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.write}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Stocking(write)</div>
                </Grid>
              </Grid>
            )}

            {!hideLimits && watchAll.access == '0' && (
              <Grid container spacing={2}>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_machine"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_machine}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Machine</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_machine"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_machine}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Machine</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_prod"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_prod}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Product</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_prod"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_prod}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Product</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_cate"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_cate}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Category</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_cate"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_cate}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Category</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_brand"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_brand}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Brand</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_brand"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_brand}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Brand</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_receipt"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_receipt}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Receipt</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_receipt"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_receipt}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Receipt</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_ss"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_ss}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Screen Saver</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_ss"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_ss}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Screen Saver</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_plano"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_plano}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Planogram</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_plano"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_plano}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Planogram</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_promo"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.view_promo}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>View Promotion</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_promo"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={watchAll.edit_promo}
                        onChange={(e) => {
                          myOnchange(field.name, e);
                        }}
                      />
                    )}
                  />
                  <div>Edit Promotion</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_report"
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={watchAll.view_report} />}
                  />
                  <div>View Report</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="edit_group"
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={watchAll.edit_group} />}
                  />
                  <div>Edit Group</div>
                </Grid>
                <Grid item xs={6} className="d-flex align-items-center">
                  <Controller
                    name="view_trans"
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={watchAll.view_trans} />}
                  />
                  <div>View Transaction</div>
                </Grid>
              </Grid>
            )}
            {!isSuperAdmin && role === 'SuperAdmin' && (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Features</FormLabel>
                  </FormControl>
                </Grid>
                <Grid container item xs={12} spacing={2} style={{ textAlign: 'left' }}>
                  <Grid item xs={6}>
                    <Controller
                      name="additionPrice"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={watchAll.additionPrice} />
                      )}
                    />
                    <span>Addition Price</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="disablePromo"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={watchAll.disablePromo} />
                      )}
                    />
                    <span>Disable Promo</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="advanced"
                      control={control}
                      render={({ field }) => <Checkbox {...field} checked={watchAll.advanced} />}
                    />
                    <span>Auto Translation</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="disableAlert"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={watchAll.disableAlert} />
                      )}
                    />
                    <span>Disable Alert &amp; Msg</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="disableMR"
                      control={control}
                      render={({ field }) => <Checkbox {...field} checked={watchAll.disableMR} />}
                    />
                    <span>Disable Mk Report</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="disableAudio"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={watchAll.disableAudio} />
                      )}
                    />
                    <span>Disable Audio</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="extra"
                      control={control}
                      render={({ field }) => <Checkbox {...field} checked={watchAll.extra} />}
                    />
                    <span>Extra</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="temperature"
                      control={control}
                      render={({ field }) => <Checkbox {...field} checked={watchAll.temperature} />}
                    />
                    <span>Temperature</span>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '20px' }}>
                  <FormLabel component="legend">Themes</FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <Select fullWidth defaultValue="0" {...field} variant="outlined">
                        {themes.map((e) => {
                          return <MenuItem value={e.code}>{e.name}</MenuItem>;
                        })}
                      </Select>
                    )}
                    name="theme"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <FormLabel component="legend">Languages</FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="langs"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={languages_noEn}
                        value={watchAll.langs}
                        disableCloseOnSelect
                        onChange={(event, value) => {
                          field.onChange(value);
                        }}
                        getOptionSelected={(option, value) => option.code === value.code}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Languages" />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Create the account
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/signIn" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

const tmctemplate = (data) => {
  return `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Terms and Conditions</title>
    <style>
      .container1-invoice {
        max-width: 842px;
        margin: 0 auto;
        text-align: center;
        padding: 0 10px;
      }
      .terms-invoice {
        font-size: 10px;
        text-align: start;
        margin-bottom: 15px;
      }
      .left-invoice {
        float: left;
        width: 48%;
      }
      .right-invoice {
        float: right;
        width: 48%;
      }
      .mg-2-invoice {
        margin-top: 25px;
        margin-bottom: 30px;
      }
      .mbb-2 {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .mr-5 {
        margin-right: 3rem;
      }
    </style>
  </head>
  <body>
    <div class="container1-invoice">
      <div class="mbb-2">TERMS AND CONDITIONS</div>
      <div class="terms-invoice">
        <div class="left-invoice">
          <div>
            <strong>1. Entire Agreement:</strong> These terms and conditions constitute the entire
            agreement between Alps Innovations Group Inc. (“Alps Innovations Group Inc.) and the
            company named on the attached Order Form (“Customer”) (collectively, the “Agreement”).
            This Agreement supersedes previous expectations, understandings, communications,
            representations, proposals or agreements, whether verbal or written, between the
            parties. This Agreement prevails where it conflicts with the terms of the Customer’s
            order.
            <!-- Remaining content goes here -->
          </div>
          <div>
            <strong>2. Products and Payment:</strong> Alps Innovations Group Inc. will have the
            Product listed on the attached Order Form (“Product”) manufactured upon receipt of the
            deposit from the Customer. Payment in full is required prior to shipment of the Product.
            The customer acknowledges that the Products are custom built; the product will begin to
            be built as soon as the deposit has been received, therefore all deposits and payments
            are non-refundable.
          </div>
          <div>
            <strong>3. Delayed Receipt of Goods by Customer:</strong> If the Customer is not ready
            to accept delivery at the end of a four-week period after the customer has been informed
            the kiosk is complete and ready to be shipped, Alps Innovations Group Inc. will charge
            storage fees in the amount of $500 per month per kiosk for the first 6 months. After
            such time, unless other arrangements have been decided, the customer will forfeit their
            deposit.
          </div>
          <div>
            <strong>4. Software:</strong> Portions of the Product are being licensed to the Customer
            for a quarterly license fee, which must be paid quarterly in advance. The customer
            understands that the access to the software is an integral part of any Alps Innovations
            Group Inc. kiosk solution. The license fees must be paid in a timely basis for continued
            service. Non-payment will result in the removal of software access rights. Customer
            acknowledges the Software is the Intellectual Property of Alps Innovations Inc and may
            not be copied, sold, traded, reverse engineered or infringed upon in any manner.
          </div>
          <div>
            <strong>5. Default:</strong> If the Customer defaults on its obligations to Alps
            Innovations Group Inc., Alps Innovations Group Inc. may declare the entire balance, if
            any, owing by the Customer to be immediately due and payable and may, without prejudice
            to its other rights, enter upon the premises where the Products are located, without
            liability for trespass or damages, and take possession and remove the Product as Alps
            Innovations personal property. Following repossession, Alps Innovations Group Inc. may,
            without notice, offer the Product at a public or private sale at which Alps Innovations
            Group Inc. may be a purchaser. The Customer will be liable to pay the balance owing for
            the Product after proceed of a sale, less Alps Innovations Group Inc. repossession
            costs, including but limited to reasonable legal fees. No delay or omission in the
            exercise of any remedy available to Alps Innovations Group Inc. will be deemed to be a
            waiver of, or affect Alps Innovations right to exercise, its remedies. Alps Innovations
            Group Inc. may record its security interest in the Product to protect its interest in
            the Product.
          </div>
          <div>
            <strong>6. Product Acceptance:</strong> Upon receipt, the Customer is deemed to have
            accepted the Product as ordered. The Product is shipped F.O.B. from Alps Innovations
            Group Inc., or the manufacturing location.
          </div>
          <div>
            <strong>7. Warranty:</strong> The products outlined have a one-year depot warrantee on
            parts and labour. The warranty is extended to the customer original purchaser of
            equipment. Any labour performed at “depot” (Alps Innovations Group offices), during the
            warrantee period, will be free of charge. In the event the customer requires replacement
            parts, Alps Innovations Group Inc. will ship the parts to the customer. The customer is
            required to ship back the damaged parts to Alps Innovations Group Inc. within 7 days at
            their own cost. If the shipment is not received within this period, the customer will be
            invoiced in full for the parts. Note: Each party will be responsible for one-way
            shipping. Alps Innovations Group Inc., sole liability and Client’s exclusive remedy with
            respect to breach of the foregoing Warranties will be limited to error correction.
          </div>
          <div>
            <strong>8. Warranty Exclusions:</strong> This agreement does not cover the replacement
            of parts and labor due to but not limited to vandalism, theft, fire, and flood or by
            customer's negligence. The Customer is responsible for obtaining insurance, as it deems
            appropriate to protect it from this type of loss. Warranty will be voided if any person
            tampers with the equipment without approval from Alps Innovations Group Inc. At all time
            during warranty period, the customer covenants that it will keep reasonable care of the
            equipment as described by Alps Innovations Group Inc. or using Manufacturers manuals.
          </div>
          <div>
            <strong>9. Assignment:</strong> If the kiosk is fully paid and there no other
            outstanding balances owing to Alps Innovations Group Inc., the Customer may sell his/her
            kiosk to a third party. In such instances, assuming that the product is in fair
            condition, Alps Innovations Group Inc. will charge a transfer
            and an administration fees
            to the third-party. It is the responsibility of the transferring client to make the purchasing
          </div>
        </div>
        <div class="right-invoice">
          <div>
          client  aware of the Assignment fee. No transfers or sale can be completed
            without Alps Innovations Group Inc. knowledge of the sale or receipt of the transfer
            fee. Warrantee is not transferable accept by written authorization from Alps Innovation
            Group Inc.
            <div><strong>10. Support:</strong> Alps Innovations Group Inc. provides online, phone and
            video support. In the event that a part needs replacement, an Alps Innovations Group
            Inc. representative will guide the operator to fix the issue remotely. It is the
            responsibility of the operator to perform such tasks; or have a qualified person the
            ability to follow instructions. If the issue cannot be fixed, and is an Alps Innovations
            Group Inc. issue, Alps Innovations Group Inc. will dispatch a technician or use a third
            party company to resolve the issue.
            <div>
          </div>
          <div>
            <strong>11. Limitation of Liability:</strong> Alps Innovations Group Inc. and its
            Manufacturer shall not be liable for, or the Customer have a remedy against Alps
            Innovations Group Inc. or its Manufacturer for the recovery of, special, indirect,
            consequential or incidental damages or other commercial or economic loss including lost
            profits and savings, whether or not reasonably foreseeable or whether Alps Innovations
            Group Inc. was advised of the damages. Alps Innovations Group Inc. sole obligation and
            maximum liability is limited to error correction.
          </div>
          <div>
            <strong>12. Limited Remedies:</strong> If the Software, Hardware fails to operate as
            warranted during the Warranty Period, Client shall notify Alps Innovations Group Inc.
            and provide Alps Innovations Group Inc. with written information that details the
            nonconformity that is reproducible by Alps Innovations Group Inc. (“Issue”). Alps
            Innovations Group Inc. will use reasonable efforts to promptly correct the Issue. In the
            event that Alps Innovations Group Inc. is unable to correct a particular issue or Issue
            occurring during the Warranty Period within 60 days, Alps Innovations Group Inc. shall
            have the right to have such Hardware or Software replaced or upgraded. The foregoing
            obligations of Alps Innovations Group Inc. and its Manufacturer shall be Alps
            Innovations Group Inc. and its Manufacturers sole liability, and Client’s exclusive
            remedy with respect to issue of the foregoing Warranties will be limited to error
            correction.
          </div>
          <div>
            <strong>13. Indemnity:</strong> The Customer will indemnify Alps Innovations Group Inc.
            and its Manufacturer against all claims, losses, and liability and damages sustained or
            incurred by Alps Innovations Group Inc. or its Manufacturer: (a) as a result of any act
            or omission of the Customer in the performance of its obligation to Alps Innovations
            Group Inc., or (b) arising from the exercise by Alps Innovations Group Inc. of its
            rights hereunder.
          </div>
          <div>
            <strong>14. Acknowledgment:</strong> The Customer acknowledges that the limitations on
            Alps Innovations Group Inc., and its Manufacturer liability are integral to the price
            charged to the Customer for the Product. The customer also acknowledges that the use of
            Alps Innovations Group Inc., information, products and services should be based on their
            own due diligence. The customer further agrees that Alps Innovations Group Inc. is not
            liable for any success or failure of the customer’s business, and its financial losses
            or gains are the sole responsibility of the customer.
          </div>
          <div>
            <strong>15. Severability:</strong> If a court of competent jurisdiction declares a
            provision of this Agreement invalid or unenforceable, the provision will be severed and
            the validity or enforceability of the other provisions will not be affected or impaired.
          </div>
          <div>
            <strong>16. Jurisdiction:</strong> This Agreement shall be governed by and construed in
            accordance with the laws of the Province of Ontario without giving effect to principles
            of conflicts of laws. Legal action arising pursuant to this Agreement shall be filed in
            the courts of the Province of Ontario. The Parties waive any right to a jury trial. The
            customer has read, and accepts the above terms and conditions.
          </div>
          <div>
            <div class="mg-2-invoice">
              <strong>___________________________________________________________________</strong><br />
              <strong>Acceptance of the Terms and Conditions:</strong>
            </div>
            <div class="mg-2-invoice">
              <strong>___________________________________________________________________</strong><br />
              <strong>Name and Title of Authorized Signing Authority:</strong>
            </div>
            <div class="mg-2-invoice">
              <strong>___________________________________________________________________</strong><br />
              <strong>Company Name:</strong>
            </div>
            <div class="d-flex">
              <span class="mr-5">
                <strong>__________________________</strong><br />
                <strong>Order Invoice Number:</strong>
              </span>
              <span class="">
                <strong>__________________________</strong><br />
                <strong>Date Signed:</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
`;
};

export default tmctemplate;

// material
import MaterialTable from 'material-table';

export default function BannerTable(props) {
  const { data, machinesName } = props;

  // const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
  //   <Typography variant={variant} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //     {`Product Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
  //   </Typography>
  // );

  const columns = [
    {
      title: 'Screen Saver Title',
      field: 'ssTitle',
    },
    {
      title: 'Image File Name',
      field: 'file',
    },
    {
      title: 'Clicked',
      field: 'count',
    },
    {
      title: 'Conversion',
      field: 'conv',
    },
  ];

  return (
    <MaterialTable
      title={'Banners: ' + machinesName}
      columns={columns}
      data={data}
      // title={<MyNewTitle variant="h6" />}
      options={{
        actionsColumnIndex: -1,
        pageSize: 5,
      }}
    />
  );
}

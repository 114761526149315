// material
import { Grid, Container } from '@mui/material';
// components
// import Page from './Page';
import React, { useEffect, useState } from 'react';
import request from '../../../../services/Request';
import LoadingIcon from '../../../items/buttons/LoadingIcon';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Button, Divider } from '@material-ui/core';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/userContext';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { localDateRange } from '../../../../services/method/methods';
import ProdTable from './prodTable';
import CateTable from './CateTable';
import BrandTable from './BrandTable';
import BannerTable from './BannerTable';
import BasicTable from './BasicTable';
import MachineSelect from '../MachineSelect';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// ----------------------------------------------------------------------
import { userLimits } from '../../uitls/userLimits';
import BasicChart from './chart/BasicChart';
import ProductsChart from './chart/ProductsChart';
import CatesChart from './chart/CatesChart';
import BrandChart from './chart/BrandChart';
import BannerChart from './chart/BannerChart';
import TotalTrans from '../Cards/TotalTrans';
import UnitSold from '../Cards/UnitSold';

const getReport = `/logs/getMarketingReport`;
const getGroupsURL = `/groupsAndMachinesForReport/`;

export default function MarketingReport(props) {
  var date = new Date();
  const [bc, setBc] = useState(false);
  const [pd, setPD] = useState(false);
  const [ca, setCA] = useState(false);
  const [bd, setBD] = useState(false);
  const [bn, setBN] = useState(false);
  const [compare, setCompare] = useState(false);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [radio, setRadio] = useState('a');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [groups2, setGroups2] = useState([]);
  const [groupV2, setgroupV2] = useState([]);
  const [radio2, setRadio2] = useState('a');
  const [startDate2, setStartDate2] = useState(new Date());
  const [endDate2, setEndDate2] = useState(new Date());
  const [filterd2, setFiltered2] = useState(new Date());
  const [data2, setData2] = useState([]);

  const [machinesName, setmachinesName] = useState('All Machines');
  const [machinesName2, setmachinesName2] = useState('All Machines');

  const [machines, setMachines] = useState([]);

  const [data, setData] = useState([]);
  const { userEmail, setFiltered, filterd } = useContext(UserContext);

  const { _id, id, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'view_report'
  );

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };
  const handleStartDate2 = (e) => {
    setStartDate2(e);
  };

  const handleEndDate2 = (e) => {
    setEndDate2(e);
  };

  const handleBC = () => {
    setBc(!bc);
  };

  const handlePD = () => {
    setPD(!pd);
  };

  const handleCA = () => {
    setCA(!ca);
  };
  const handleBD = () => {
    setBD(!bd);
  };
  const handleBN = () => {
    setBN(!bn);
  };

  const handleCompare = () => {
    setCompare(!compare);
  };

  useEffect(() => {
    let isCancelled = false;

    async function getGroups() {
      await request({
        method: 'get',
        url: !props.match.params.group && superAdmin ? getGroupsURL : getGroupsURL + userEmail,
      }).then((res) => {
        res.data.machines.forEach((element) => {
          element.product?.forEach((e) => {
            e.id.name = e.id.name.en;
          });
        });
        if (!isCancelled) {
          setGroups(res.data.groups);
          setMachines(res.data.machines);
          setFiltered(res.data.machines);
        }
      });
    }
    getGroups();

    return () => {
      setMachines([]);
      setGroups([]);
      setLoading(false);
      isCancelled = true;
    };
  }, []);

  const sendRequest = async (myIDs, startDate, endDate, setload, number) => {
    const { startDateString, endDateString } = localDateRange(startDate, endDate);
    console.log(endDateString);

    await request({
      method: 'post',
      url: getReport,
      data: {
        start: startDateString,
        end: endDateString,
        ids: myIDs,
      },
    }).then((res) => {
      console.log('res.data', res.data);
      const prods = [];
      const cates = [];
      const brands = [];
      const banners = [];
      const language = {
        en: 0,
        fr: 0,
        es: 0,
      };
      let totalSession = 0;
      let all_brand = 0;
      let all_prod = 0;
      let cart_aband = 0;
      let cart_page = 0;
      let faq = 0;
      let payment = 0;
      let session_com = 0;
      let session_start = 0;
      res.data.forEach((e) => {
        all_brand += e.all_brand;
        all_prod += e.all_prod;
        cart_aband += e.cart_aband;
        cart_page += e.cart_page;
        faq += e.faq;
        payment += e.payment;
        session_com += e.session_com;
        session_start += e.session_start;
        //session time
        e?.session_time.forEach((e) => {
          totalSession += e;
        });
        //language
        if (e.language?.en) {
          language.en += e.language?.en;
        }
        if (e.language?.fr) {
          language.fr += e.language?.fr;
        }
        if (e.language?.es) {
          language.es += e.language?.es;
        }
        //prod
        e.prod.forEach((prod) => {
          const index = prods.findIndex((e) => e._id === prod._id);
          if (index > -1) {
            prods[index].detail += prod.detail;
            prods[index].add_to_cart += prod.add_to_cart;
            if (prod.includes) prods[index].includes += prod.includes;
            if (prod.specs) prods[index].specs += prod.specs;
            prods[index].conv += prod.conv;
          } else {
            if (!prod.includes) {
              prod.includes = 0;
            }
            if (!prod.specs) {
              prod.specs = 0;
            }
            prods.push(prod);
          }
        });
        //cate
        e.cate.forEach((cate) => {
          const index = cates.findIndex((e) => e._id === cate._id);
          if (index > -1) {
            cates[index].detail += cate.detail;
          } else {
            cates.push(cate);
          }
        });
        //brand
        e.brand.forEach((brand) => {
          const index = brands.findIndex((e) => e._id === brand._id);
          if (index > -1) {
            brands[index].detail += brand.detail;
          } else {
            brands.push(brand);
          }
        });
        //banner
        e.banner.forEach((banner) => {
          const index = banners.findIndex((e) => e.file === banner.file);
          if (index > -1) {
            banners[index].count += banner.count;
            banners[index].conv += banner.conv;
          } else {
            banners.push(banner);
          }
        });
      });
      setLoading(true);
      const obj = {
        prod: prods,
        cate: cates,
        brand: brands,
        banner: banners,
        all_brand: all_brand,
        all_prod: all_prod,
        cart_aband: cart_aband,
        cart_page: cart_page,
        faq: faq,
        payment: payment,
        session_com: session_com,
        session_start: session_start,
        language: language,
        aveSession: Math.round(totalSession / session_start),
      };
      console.log('prods', obj);
      if (number === 1) setData(obj);
      else setData2(obj);
    });
  };

  const handleChange = async () => {
    console.log('data', data);
    let filteredArray = [];
    setLoading(false);
    let myIDs = [];
    if (radio == 'a') {
      setFiltered(machines);
      myIDs = machines.map((e) => e._id).join(',');
      setmachinesName('All Machines');
      filteredArray = [...machines];
    }
    if (radio == 'g') {
      const myArray = groupV.map((e) => e.machineID);
      var merged = [].concat.apply([], myArray);
      merged = merged.filter((e, i, self) => i === self.findIndex((t) => t._id == e._id));
      // const filteredArray = machines.filter((e, i) => i == merged.findIndex((t)=>(t._id == e._id)));
      machines.forEach((e) => {
        merged.findIndex((t) => {
          if (t._id == e._id) {
            filteredArray.push(e);
          }
        });
      });
      setFiltered(filteredArray);
      myIDs = filteredArray.map((e) => e._id).join(',');
      setmachinesName(`Groups: ${groupV.map((e) => e.name).join(', ')}`);
    }
    if (radio == 'm') {
      setFiltered(groupV);
      filteredArray = [...groupV];
      myIDs = groupV.map((e) => e._id).join(',');
      setmachinesName(`Machines: ${groupV.map((e) => e.name).join(', ')}`);
    }
    if (myIDs.length > 0) {
      sendRequest(myIDs, startDate, endDate, !compare, 1);
    }
    // // console.log(selectP?._id);
    if (compare) {
      let filteredArray = [];
      setLoading(false);
      let myIDs = [];
      if (radio2 == 'a') {
        setFiltered2(machines);
        myIDs = machines.map((e) => e._id).join(',');
        setmachinesName2('All Machines');
        filteredArray = [...machines];
      }
      if (radio2 == 'g') {
        const myArray = groupV2.map((e) => e.machineID);
        var merged = [].concat.apply([], myArray);
        merged = merged.filter((e, i, self) => i === self.findIndex((t) => t._id == e._id));
        // const filteredArray = machines.filter((e, i) => i == merged.findIndex((t)=>(t._id == e._id)));
        machines.forEach((e) => {
          merged.findIndex((t) => {
            if (t._id == e._id) {
              filteredArray.push(e);
            }
          });
        });
        setFiltered2(filteredArray);
        setmachinesName2(`Groups: ${groupV2.map((e) => e.name).join(', ')}`);
        myIDs = filteredArray.map((e) => e._id).join(',');
      }
      if (radio == 'm') {
        setFiltered2(groupV);
        filteredArray = [...groupV2];
        myIDs = groupV2.map((e) => e._id).join(',');
        setmachinesName2(`Machines: ${groupV2.map((e) => e.name).join(', ')}`);
      }
      if (myIDs.length > 0) {
        sendRequest(myIDs, startDate2, endDate2, compare, 2);
      }
    }
  };

  return (
    <Container maxWidth="xl" sx={{ pt: 5 }}>
      {!loading && <LoadingIcon />}
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <MachineSelect
            filterd={filterd}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            startDate={startDate}
            endDate={endDate}
            setRadio={setRadio}
            setgroupV={setgroupV}
            radio={radio}
            groupV={groupV}
            groups={groups}
            machines={machines}
            handleChange={handleChange}
            compare={compare}
          />
          {compare && <hr style={{ height: '1px', width: '100%' }} />}
          {compare && (
            <MachineSelect
              radio2={radio}
              doubleCheck={true}
              filterd={filterd2}
              handleStartDate={handleStartDate2}
              handleEndDate={handleEndDate2}
              startDate={startDate2}
              endDate={endDate2}
              setRadio={setRadio2}
              setgroupV={setgroupV2}
              radio={radio2}
              groupV={groupV2}
              groupV2={groupV}
              groups={groups2}
              machines={machines}
              handleChange={handleChange}
              compare={!compare}
            />
          )}
          <Button
            style={{ height: '40px', marginTop: '10px' }}
            variant="contained"
            color="primary"
            onClick={handleCompare}
          >
            {compare ? 'Cancel Compare' : 'Compare'}
          </Button>
        </Grid>
      </Grid>

      {data.session_start &&
        (compare ? (
          <Grid container item spacing={3} className="mt-5">
            <Grid container item spacing={3} justifyContent={'space-evenly'}>
              <Grid item xs={12} sm={6} md={3}>
                <UnitSold unit={data?.session_start} title="1st Total Sessions" />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TotalTrans trans={data?.aveSession} title="1st Ave Session Time (seconds)" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <UnitSold unit={data2?.session_start} title="2nd Total Sessions" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TotalTrans trans={data2?.aveSession} title="2nd Ave Session Time (seconds)" />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={bc} onChange={handleBC} />}
                label="Show Chart"
              />
              {bc ? (
                <BasicChart data={data} machinesName={machinesName} />
              ) : (
                <BasicTable data={data} machinesName={machinesName} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={bc} onChange={handleBC} />}
                label="Show Chart"
              />
              {bc ? (
                <BasicChart data={data2} machinesName={machinesName2} />
              ) : (
                <BasicTable data={data2} machinesName={machinesName2} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={pd} onChange={handlePD} />}
                label="Show Chart"
              />
              {pd ? (
                <ProductsChart data={data.prod} machinesName={machinesName} type={1} />
              ) : (
                <ProdTable data={data.prod} machinesName={machinesName} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={pd} onChange={handlePD} />}
                label="Show Chart"
              />
              {pd ? (
                <ProductsChart data={data2.prod} machinesName={machinesName2} type={1} />
              ) : (
                <ProdTable data={data2.prod} machinesName={machinesName2} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={ca} onChange={handleCA} />}
                label="Show Chart"
              />
              {ca ? (
                <CatesChart data={data.cate} machinesName={machinesName} />
              ) : (
                <CateTable data={data.cate} machinesName={machinesName} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={ca} onChange={handleCA} />}
                label="Show Chart"
              />
              {ca ? (
                <CatesChart data={data2.cate} machinesName={machinesName2} />
              ) : (
                <CateTable data={data2.cate} machinesName={machinesName2} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={bd} onChange={handleBD} />}
                label="Show Chart"
              />
              {bd ? (
                <BrandChart data={data.brand} machinesName={machinesName} />
              ) : (
                <BrandTable data={data.brand} machinesName={machinesName} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={bd} onChange={handleBD} />}
                label="Show Chart"
              />
              {bd ? (
                <BrandChart data={data2.brand} machinesName={machinesName2} />
              ) : (
                <BrandTable data={data2.brand} machinesName={machinesName2} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={bn} onChange={handleBN} />}
                label="Show Chart"
              />
              {bn ? (
                <BannerChart data={data.banner} machinesName={machinesName} type={1} />
              ) : (
                <BannerTable data={data.banner} machinesName={machinesName} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={bn} onChange={handleBN} />}
                label="Show Chart"
              />
              {bn ? (
                <BannerChart data={data2.banner} machinesName={machinesName2} type={1} />
              ) : (
                <BannerTable data={data2.banner} machinesName={machinesName2} />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container item spacing={3} className="mt-5">
            <Grid container item spacing={3} justifyContent={'space-evenly'}>
              <Grid item xs={12} sm={6} md={3}>
                <UnitSold unit={data?.session_start} title="Total Sessions" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TotalTrans trans={data?.aveSession} title="Ave Session Time(seconds)" />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={bc} onChange={handleBC} />}
                label="Show Chart"
              />
              {bc ? (
                <BasicChart data={data} machinesName={machinesName} />
              ) : (
                <BasicTable data={data} machinesName={machinesName} />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={pd} onChange={handlePD} />}
                label="Show Chart"
              />
              {pd ? (
                <ProductsChart data={data.prod} machinesName={machinesName} />
              ) : (
                <ProdTable data={data.prod} machinesName={machinesName} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={ca} onChange={handleCA} />}
                label="Show Chart"
              />
              {ca ? (
                <CatesChart data={data.cate} machinesName={machinesName} />
              ) : (
                <CateTable data={data.cate} machinesName={machinesName} />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch checked={bd} onChange={handleBD} />}
                label="Show Chart"
              />
              {bd ? (
                <BrandChart data={data.brand} machinesName={machinesName} />
              ) : (
                <BrandTable data={data.brand} machinesName={machinesName} />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={bn} onChange={handleBN} />}
                label="Show Chart"
              />
              {bn ? (
                <BannerChart data={data.banner} machinesName={machinesName} />
              ) : (
                <BannerTable data={data.banner} machinesName={machinesName} />
              )}
            </Grid>
          </Grid>
        ))}
    </Container>
  );
}

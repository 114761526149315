import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { CssBaseline } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { makenum } from '../../../services/randomCharGenerator';
import request from '../../../services/Request';
import ReactQuill from 'react-quill';
import '../../../../node_modules/react-quill/dist/quill.snow.css';
import './receipt.css';
import parse from 'html-react-parser';
import { languages } from '../../../staticData/languages';
import Swal from 'sweetalert2';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/en-ca';
import { Thank, Receipt, DateS, Order, Machine, Item, Price, Tax, Discount } from './emailTrans';
import { Checkbox } from '@material-ui/core';

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { header: '3' }, { header: '4' }, { header: '5' }],
    ['bold', 'italic', 'underline'],
    ['link'],
    ['clean'],
    [{ color: [] }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const formats = ['header', 'color', 'bold', 'italic', 'underline', 'link'];

export default function AddReceipt(props) {
  const theme1 = JSON.parse(localStorage.getItem('user'))?.theme === '1';
  const { show, onHide, referTo, data, update, reflesh, setReflesh } = props;
  const [date, setDate] = useState(moment(Date()).format('LLLL'));
  const [custom1, setcustom1] = useState('');
  const [custom2, setcustom2] = useState('');
  const [i1, seti1] = useState('https://cdn.logo.com/hotlink-ok/logo-social.png');
  const [logo, setlogo] = useState(null);

  const [iname, setiname] = useState(
    'Please provide image with aspect ratio between 120 × 120（1:1） to 252 * 120 （2.1:1'
  );

  const defaultValues = update
    ? {
        select: data?.language ? data.language : 'en',
        name: data ? data.name : '',
        email: data ? data.email : '',
        phone: data ? data.phone : '',
        custom1: data ? data.custom1 : '',
        custom2: data ? data.custom2 : '',
        company: data ? data.company : '',
        subject: data ? data.subject : '',
        // country: data ? data.country : '',
        // street: data ? data.street : '',
        // city: data ? data.city : '',
        // province: data ? data.province : '',
        // postal_code: data ? data.postal_code : '',
        website: data ? data.website : '',
        user: data && data.setting ? data.setting.user : '',
        password: data && data.setting ? data.setting.password : '',
        port: data && data.setting ? data.setting.port : '',
        host: data && data.setting ? data.setting.host : '',
        option: data ? data.option : '0',
        hideTax: data?.hideTax ? true : false,
      }
    : {
        select: 'en',
        name: '',
        email: '',
        phone: '',
        subject: '',
        custom1: '',
        custom2: '',
        company: '',
        // country: '',
        // street: '',
        // city: '',
        // province: '',
        // postal_code: '',
        website: '',
        user: '',
        password: '',
        port: '',
        host: '',
        option: '0',
        hideTax: false,
      };
  const {
    handleSubmit,
    control,
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });

  const watchAllFields = watch();

  const imageHandler = (e) => {
    //prevent crash

    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          seti1(reader.result);
          setlogo(e.target.files[0]);
          setiname(e.target.files[0].name);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (watchAllFields.select === 'en') {
      moment.locale('en-ca');
    } else {
      moment.locale(watchAllFields.select);
    }
    setDate(moment(Date()).format('LLLL'));
  }, [watchAllFields.select]);

  const onSubmit = async (formData) => {
    const myData = new FormData();
    myData.append('language', formData.select);
    myData.append('name', formData.name);
    myData.append('option', formData.option);
    myData.append('company', formData.company);
    myData.append('subject', formData.subject);
    // myData.append('country', formData.country);
    // myData.append('city', formData.city);
    // myData.append('street', formData.street);
    // myData.append('province', formData.province);
    // myData.append('postal_code', formData.postal_code);
    myData.append('website', formData.website);
    myData.append('user', formData.user);
    myData.append('password', formData.password);
    myData.append('port', formData.port);
    myData.append('host', formData.host);
    myData.append('custom1', custom1);
    myData.append('custom2', custom2);
    myData.append('referTo', referTo);
    myData.append('logo', logo);
    myData.append('hideTax', formData.hideTax);

    if (update) {
      await request({
        method: 'put',
        url: '/receipt/edit/' + data._id,
        data: myData,
      }).then((res) => {
        Swal.fire({
          text: `The receipt template has been successfully updated`,
          icon: 'success',
        }).then(() => {
          props.onHide();
          reflesh ? setReflesh(false) : setReflesh(true);
        });
      });
    } else {
      await request({
        method: 'post',
        url: '/receipt/create',
        data: myData,
      }).then((res) => {
        Swal.fire({
          text: `New receipt template has been successfully created`,
          icon: 'success',
        }).then(() => {
          props.onHide();
          reflesh ? setReflesh(false) : setReflesh(true);
        });
      });
    }
  };

  useEffect(() => {
    reset(defaultValues);
    setDate(moment(new Date()).format('LLLL'));
    if (update) {
      setcustom1(data.custom1);
      setcustom2(data.custom2);
      seti1(
        data.logo != ''
          ? `${process.env.REACT_APP_SERVER_API}/pictures/${data.logo}/${referTo}`
          : 'https://cdn.logo.com/hotlink-ok/logo-social.png'
      );
    } else {
      setcustom1('');
      setcustom2('');
      setlogo(null);
      seti1('https://cdn.logo.com/hotlink-ok/logo-social.png');
      setiname(
        'Please provide image with aspect ratio between 1:1(at least 120×120 or bigger) to 2.1:1(252×120 or bigger)'
      );
    }
  }, [show, update]);

  const handleC1 = (e) => {
    setcustom1(e);
  };

  const handleC2 = (e) => {
    setcustom2(e);
  };

  // const handleSend = async () => {
  //   const htmlStr = document.getElementById('my-receipt').outerHTML;
  //   console.log(htmlStr);
  // props.onHide();
  // const { value: email } = await Swal.fire({
  //   title: 'Enter the email address',
  //   input: 'email',
  //   inputPlaceholder: 'Enter the email address',
  // });
  // if (email) {
  //   await request({
  //     method: 'post',
  //     url: '/receipt/send/' + data._id,
  //     data: {
  //       htmlStr: htmlStr,
  //       email: email,
  //       user: watchAllFields.user,
  //       password: watchAllFields.password,
  //       port: watchAllFields.port,
  //       host: watchAllFields.host,
  //     },
  //   }).then((res) => {
  //     Swal.fire(`The template has been send to your email`);
  //   });
  // }
  // };
  return (
    <Modal show={show} dialogClassName="modal-90w" onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Create New Email Receipt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CssBaseline />
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', margin: '20px 40px' }}>
          <Grid container columnSpacing={4}>
            <Grid
              container
              item
              rowSpacing={2}
              columnSpacing={4}
              xs={6}
              style={{ padding: '20px 40px' }}
            >
              <Grid
                item
                xs={12}
                style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}
              >
                Select Language
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <Select fullWidth defaultValue="en" {...field} variant="outlined">
                      {languages.map((e) => {
                        return <MenuItem value={e.code}>{e.name}</MenuItem>;
                      })}
                    </Select>
                  )}
                  name="select"
                  control={control}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}
              >
                Basic Information
              </Grid>
              <Grid item xs={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Template Name"
                      variant="outlined"
                      inputProps={{
                        maxLength: '36',
                      }}
                      {...field}
                    />
                  )}
                  name="name"
                  rules={{ required: 'name is required' }}
                  control={control}
                />
                {errors.name && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.name.message}</p>
                )}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Company Name"
                      variant="outlined"
                      inputProps={{
                        maxLength: '36',
                      }}
                      {...field}
                    />
                  )}
                  name="company"
                  rules={{ required: 'company name is required' }}
                  control={control}
                />
                {errors.company && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.company.message}</p>
                )}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Email Subject"
                      variant="outlined"
                      inputProps={{
                        maxLength: '200',
                      }}
                      {...field}
                    />
                  )}
                  name="subject"
                  rules={{ required: 'subject name is required' }}
                  control={control}
                />
                {errors.subject && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.subject.message}</p>
                )}
              </Grid>

              {/* <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="street"
                  name="street"
                  {...register('street', {})}
                />
                {errors.street && <p className="signUpErrorMessage">{errors.street.message}</p>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="city"
                  name="city"
                  inputProps={{
                    maxLength: '15',
                  }}
                  {...register('city', {})}
                />
                {errors.city && <p className="signUpErrorMessage">{errors.city.message}</p>}
              </Grid>

              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="province(abbreviation)"
                  name="province"
                  inputProps={{
                    maxLength: '15',
                  }}
                  {...register('province', {})}
                />
                {errors.city && <p className="signUpErrorMessage">{errors.city.message}</p>}
              </Grid>

              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="postal code"
                  name="postal_code"
                  inputProps={{
                    maxLength: '8',
                  }}
                  {...register('postal_code', {})}
                />
                {errors.postal_code && (
                  <p className="signUpErrorMessage">{errors.postal_code.message}</p>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="country"
                  name="country"
                  inputProps={{
                    maxLength: '15',
                  }}
                  {...register('country', {})}
                />
                {errors.country && <p className="signUpErrorMessage">{errors.country.message}</p>}
              </Grid> */}

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Website"
                  name="website"
                  {...register('website', {})}
                />
                {errors.website && <p className="signUpErrorMessage">{errors.website.message}</p>}
              </Grid>

              <Grid item xs={6}>
                <div className="d-flex align-items-center" style={{ height: '48px' }}>
                  <Grid item xs={3}>
                    <Button variant="contained" component="label">
                      Logo
                      <input
                        // {...register('logo', {
                        //   onChange: (e) => {
                        //     imageHandler(e);
                        //     clearErrors('logo');
                        //     if (e.target.files.length > 0 && e.target.files[0].size > 5000000) {
                        //       setError('logo', {
                        //         message: 'File size too big, please provide the right size.',
                        //       });
                        //     }
                        //   },
                        // })}
                        onChange={imageHandler}
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        hidden
                      />
                    </Button>
                  </Grid>
                  <Grid className="d-flex align-items-center" item xs={8}>
                    <span className="product-fileName-span" style={{ fontSize: '12px' }}>
                      {iname}
                    </span>
                  </Grid>
                </div>
              </Grid>
              {errors.logo && (
                <p style={{ color: 'red', marginLeft: '8px' }}>{errors.logo.message}</p>
              )}

              <Grid
                item
                xs={12}
                style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}
              >
                <ReactQuill
                  placeholder="Custom1"
                  value={custom1}
                  onChange={handleC1}
                  modules={modules}
                  formats={formats}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}
              >
                <ReactQuill
                  placeholder="Custom2"
                  value={custom2}
                  onChange={handleC2}
                  modules={modules}
                  formats={formats}
                />
              </Grid>
              {theme1 && (
                <Grid item xs={12} className="d-flex align-items-center">
                  <Controller
                    name="hideTax"
                    control={control}
                    render={({ field }) => (
                      <Checkbox color="primary" {...field} checked={watchAllFields.hideTax} />
                    )}
                  />
                  <div>Hide Tax</div>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}
              >
                Email SMTP Configuration
              </Grid>
              <Grid item xs={12}>
                {/* <FormLabel component="legend">Do you want to send the receipt from own email</FormLabel> */}
                <Controller
                  control={control}
                  name="option"
                  render={({ field }) => (
                    <RadioGroup
                      row
                      {...field}
                      value={watchAllFields.option}
                      className="d-flex justify-content-around"
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Send receipt from server email"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Send receipt from my email"
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
              {watchAllFields.option == '0' && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="User Email"
                      name="user"
                      {...register('user', {
                        required: 'user email is required',
                        pattern: {
                          value:
                            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                          message: 'Please enter a valid email',
                        },
                      })}
                    />
                    {errors.user && <p className="signUpErrorMessage">{errors.user.message}</p>}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Password"
                      name="password"
                      type="password"
                      {...register('password', { required: 'password is required' })}
                    />
                    {errors.password && (
                      <p className="signUpErrorMessage">{errors.password.message}</p>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Port"
                      name="port"
                      type="number"
                      {...register('port', {
                        required: 'port is required',
                      })}
                    />
                    {errors.port && <p className="signUpErrorMessage">{errors.port.message}</p>}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Host"
                      name="host"
                      {...register('host', { required: 'host is required' })}
                    />
                    {errors.host && <p className="signUpErrorMessage">{errors.host.message}</p>}
                    {watchAllFields.host.includes('gmail') && (
                      <p style={{ fontSize: '0.8rem', marginTop: '10px' }}>
                        Don't forget turn your&nbsp;
                        <a href="https://support.google.com/accounts/answer/6010255?hl=en">
                          Less secure apps
                        </a>
                        &nbsp;on, and &nbsp;
                        <a href="https://accounts.google.com/DisplayUnlockCaptcha">
                          allow the access
                        </a>
                      </p>
                    )}
                    {watchAllFields.host.includes('office365') && (
                      <p style={{ fontSize: '0.8rem', marginTop: '10px' }}>
                        You may need to go to your&nbsp;
                        <a href="https://account.microsoft.com/security?refd=account.microsoft.com">
                          security setting
                        </a>
                        , recent activity, then trust our server(after fail to send the email).
                      </p>
                    )}
                  </Grid>
                </>
              )}

              <Grid item xs={6}>
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Grid>

              {/* <Grid item xs={6}>
                <Button variant="contained" color="primary" onClick={handleSend}>
                  Send sample to email
                </Button>
              </Grid> */}
            </Grid>
            <Grid container item xs={6} style={{ padding: '20px 40px' }}>
              <div id="my-receipt" style={{ width: '100%' }}>
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Email Receipt</title>
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\n      body {\n        margin: 0;\n        padding: 0;\n        background-color: #f6f9fc;\n      }\n      table {\n        border-spacing: 0;\n      }\n      td {\n        padding: 0;\n      }\n      img {\n        border: 0;\n      }\n      .wrapper {\n        width: 100%;\n        table-layout: fixed;\n        background-color: #f6f9fc;\n        padding-bottom:20px;\n        padding-top:20px;\n      }\n      .webkit {\n        padding: 3.237% 6.258%;\n        background-color: #E8E8E8;\n        border: 2px solid #E8E8E8;\n        border-radius: 25px;\n        font-family: Arial, Helvetica, sans-serif;\n      }\n      .outer {\n        Margin: 0 auto;\n        width: 100%;\n        max-width: 600px;\n        border-spacing: 0;\n        line-height: normal;\n      }\n      .info{\n        margin-top: 10px;\n      }\n      hr {\n        margin-top: 10px;\n        margin-bottom: 15px;\n        width: 100%;\n        height: 3px;\n        border: none;\n        background:#787878;\n      }\n      .logo-img{\n         max-height: 100%; \n          max-width: 100%;\n      }\n\n      @media screen and (max-width: 600px) {\n      }\n      @media screen and (max-width: 400px) {\n      }\n    ',
                  }}
                />
                <center className="wrapper">
                  <div className="webkit" style={{ boxSizing: 'content-box', width: '490px' }}>
                    <table className="outer" align="center">
                      <tbody>
                        <tr>
                          <td>
                            <table width="100%" style={{ borderSpacing: 0, float: 'left' }}>
                              <tbody>
                                <tr>
                                  <td style={{ float: 'left', width: '50%', height: '120px' }}>
                                    <img className="logo-img" src={i1} alt="Logo" title="Logo" />
                                  </td>
                                  <td
                                    style={{
                                      float: 'right',
                                      fontSize: '2.4rem',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {Thank[watchAllFields.select]}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <table className="outer" align="center" style={{ marginBottom: '10px' }}>
                      <tbody>
                        <tr>
                          <td style={{ fontSize: '1.8rem', fontWeight: 400 }}>
                            {' '}
                            {Receipt[watchAllFields.select]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="outer" align="center" style={{ marginBottom: '40px' }}>
                      <tbody>
                        <tr>
                          <td>
                            <span style={{ fontSize: 'large', fontWeight: 700 }}>
                              {DateS[watchAllFields.select]} :{' '}
                              <span style={{ fontWeight: 'normal' }}>{date}</span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontSize: 'large', fontWeight: 700 }}>
                              {Order[watchAllFields.select]}:{' '}
                              <span style={{ fontWeight: 'normal' }}>{makenum(6)}</span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontSize: 'large', fontWeight: 700 }}>
                              {Machine[watchAllFields.select]}:{' '}
                              <span style={{ fontWeight: 'normal' }}>{makenum(6)}</span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <table
                      className="outer"
                      align="center"
                      style={{ borderSpacing: '5px 10px', borderCollapse: 'separate' }}
                    >
                      <tbody>
                        <tr style={{ fontWeight: 'bold', width: '100%' }}>
                          <td style={{ width: '83%' }}>{Item[watchAllFields.select]}</td>
                          <td style={{ width: '17%', textAlign: 'right' }}>
                            {Price[watchAllFields.select]}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '83%' }}>Sample 1</td>
                          <td
                            style={{
                              width: '17%',
                              textAlign: 'right',
                              textDecoration: 'line-through',
                            }}
                          >
                            $350.00
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '83%', paddingLeft: '30px' }}>
                            {Discount[watchAllFields.select]}
                          </td>
                          <td style={{ width: '17%', textAlign: 'right' }}>$250.00</td>
                        </tr>
                        <tr>
                          <td style={{ width: '83%' }}>Sample 2</td>
                          <td style={{ width: '17%', textAlign: 'right' }}>$149.99</td>
                        </tr>
                        <tr>
                          <td style={{ width: '83%' }}>Sample 3</td>
                          <td style={{ width: '17%', textAlign: 'right' }}>$199.99</td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      className="outer"
                      align="center"
                      style={{ borderCollapse: 'separate', borderSpacing: '5px 5px' }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ width: '61%' }} />
                          <td style={{ width: '5%', textAlign: 'right' }} />
                          <td style={{ width: '17%', textAlign: 'right' }}>
                            <span style={{ fontWeight: 'bold' }}>Subtotal</span>
                          </td>
                          <td style={{ width: '17%', textAlign: 'right' }}>$599.98</td>
                        </tr>
                        <tr>
                          <td style={{ width: '61%' }} />
                          <td style={{ width: '5%', textAlign: 'right' }} />
                          <td style={{ width: '17%', textAlign: 'right' }}>
                            <span style={{ fontWeight: 'bold' }}>{Tax[watchAllFields.select]}</span>
                          </td>
                          <td style={{ width: '17%', textAlign: 'right' }}>$78.00</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <table
                      className="outer"
                      align="center"
                      style={{ borderCollapse: 'separate', borderSpacing: '5px 0px' }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ width: '61%' }} />
                          <td style={{ width: '5%', textAlign: 'right' }} />
                          <td style={{ width: '17%', textAlign: 'right' }}>
                            <span style={{ fontWeight: 'bold' }}>Total</span>
                          </td>
                          <td style={{ width: '17%', textAlign: 'right' }}>$677.98</td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      className="outer info"
                      style={{ borderCollapse: 'separate', borderSpacing: '0 15px' }}
                    >
                      <tbody>
                        {custom1 != '' && custom1 != '<p><br></p>' && (
                          <tr>
                            <td style={{ textAlign: 'center' }}>{parse(custom1)}</td>
                          </tr>
                        )}
                        {custom2 != '' && custom2 != '<p><br></p>' && (
                          <tr>
                            <td style={{ textAlign: 'center' }}>{parse(custom2)}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <table
                      className="outer"
                      style={{
                        borderCollapse: 'separate',
                        borderSpacing: '0 5px',
                        marginTop: '15px',
                      }}
                    >
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          <span>
                            {`(address below are samples, it will display your machine's address)`}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>
                          <strong>{`East Door`}</strong>
                        </td>
                      </tr>

                      <tbody>
                        <tr>
                          <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            <strong>{watchAllFields.company}</strong>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ textAlign: 'center' }}>
                            <span>{`135 village green square`}</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ textAlign: 'center' }}>
                            <span>{`Toronto`}, </span>
                            <span>{`ON`}, </span>
                            <span>{`M2J3A4`} </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ textAlign: 'center' }}>
                            <span>{`CANADA`}</span>
                          </td>
                        </tr>

                        {watchAllFields.website != '' && (
                          <tr>
                            <td style={{ textAlign: 'center' }}>
                              <a href={watchAllFields.website}>
                                <span>{watchAllFields.website}</span>
                              </a>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </center>
              </div>
            </Grid>
          </Grid>
        </form>
      </Modal.Body>
    </Modal>
  );
}

// material
import { Grid, Container } from '@mui/material';
// components
// import Page from './Page';

import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext';
import MachineSelect from '../report/MachineSelect';
import React, { useEffect, useState } from 'react';
import request from '../../../services/Request';

import LoadingIcon from '../../items/buttons/LoadingIcon';
import { useForm, Controller } from 'react-hook-form';

// ----------------------------------------------------------------------
import { userLimits } from '../uitls/userLimits';
import MaterialTable from 'material-table';
import AlertCustomModal from './AlertCustomModal';

const getAlertURL = `/logs/alertCustom/`;

export default function AlertCustom(props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [theData, setTheData] = useState(true);
  const [data, setData] = useState([]);
  const [reFresh, setReFresh] = useState(false);
  const { id } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'view_report'
  );

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_alert_custom')
      ? localStorage.getItem('table_view_alert_custom')
      : 20
  );

  useEffect(() => {
    localStorage.setItem('table_view_alert_custom', pagination);
  }, [pagination]);

  useEffect(() => {
    request({
      method: 'get',
      url: getAlertURL + id,
    }).then((res) => {
      setLoading(true);
      console.log(res.data);
      setData(res.data);
    });
  }, [reFresh]);

  const columns = [
    {
      title: 'Message Code',
      field: 'code',
      cellStyle: {
        width: '15%',
      },
    },
    {
      title: 'Message Text',
      field: 'text',
      cellStyle: {
        width: '70%',
      },
      render: (rowData) => <span style={{ color: rowData.color }}> {rowData.text} </span>,
    },
    {
      title: 'Email Alert',
      field: 'alert',
      cellStyle: {
        width: '15%',
      },
    },
  ];
  return (
    <div>
      {!loading && <LoadingIcon />}

      <MaterialTable
        onChangeRowsPerPage={setPagination}
        columns={columns}
        data={data}
        title={''}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit Alert',
            onClick: (event, rowData) => {
              setTheData(rowData);
              setShow(true);
            },
          },
        ]}
        options={{
          pageSize: parseInt(pagination),
          pageSizeOptions: [10, 20, 50, 100, 200],
          actionsColumnIndex: -1,
        }}
      />

      <AlertCustomModal
        data={theData}
        show={show}
        id={id}
        setReFresh={setReFresh}
        reFresh={reFresh}
        onHide={() => setShow(false)}
      />
    </div>
  );
}

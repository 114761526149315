import React, { useState, useContext, useEffect } from 'react';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import CreateGroup from './CreateGroup';
import Swal from 'sweetalert2';
import request from '../../../services/Request';
import { userLimits } from '../uitls/userLimits';

const getMyGroupsURL = `/groups/`;

export default function Groups(props) {
  const { id, tableTitle, superAdmin, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Groups',
    'edit_group'
  );

  const [update, setUpdate] = useState('false');
  const [show, setShow] = useState(false);
  const [checkedvalues, setcheckedvalues] = useState([]);
  const [checkedvalues2, setcheckedvalues2] = useState([]);
  const [reflesh, setreflesh] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setname] = useState('');
  const [groupid, setGroupID] = useState('');
  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_group') ? localStorage.getItem('table_view_group') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_group', pagination);
  }, [pagination]);

  useEffect(() => {
    let isCancelled = false;
    // getMyMachines

    async function getMyGroups() {
      await request({
        method: 'get',
        url:
          props.match.params.group && superAdmin
            ? getMyGroupsURL + props.match.params.id
            : getMyGroupsURL + id,
      }).then((res) => {
        if (!isCancelled) {
          setGroups(res.data);
          setLoading(true);
        }
      });
    }
    getMyGroups();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  const deleteGroup = async (id) => {
    await request({
      method: 'delete',
      url: getMyGroupsURL + id,
    }).then();
    reflesh ? setreflesh(false) : setreflesh(true);
  };

  const columns = [
    { title: 'Group Name', field: 'name' },
    {
      title: 'Machines In Group',
      field: 'machines',
      render: (rowData) =>
        rowData.machineID.map((e) => <p key={e._id}>{e.name + ' - ' + e.description}</p>),
    },
    {
      title: 'Users In Group',
      field: 'users',
      render: (rowData) =>
        rowData.userID.map((e) => (
          <p key={e.email}>{e.firstname + ' ' + e.lastname + '(' + e.email + ')'}</p>
        )),
    },
  ];
  return (
    <div>
      {!loading && <LoadingIcon />}
      <MaterialTable
        columns={columns}
        data={groups}
        onChangeRowsPerPage={setPagination}
        title={tableTitle}
        actions={[
          access && {
            icon: 'add',
            tooltip: 'Create A New Group',
            isFreeAction: true,
            onClick: (event) => {
              setUpdate('false');
              setcheckedvalues([]);
              setcheckedvalues2([]);
              setname('');
              setShow(true);
              reflesh ? setreflesh(false) : setreflesh(true);
            },
          },
          () =>
            access && {
              icon: 'edit',
              tooltip: 'Machine UI',
              onClick: (event, rowData) => {
                setUpdate('true');
                setcheckedvalues(rowData.machineID.map((e) => e._id));
                setcheckedvalues2(rowData.userID.map((e) => e._id));
                setname(rowData.name);
                setGroupID(rowData._id);
                setShow(true);
                reflesh ? setreflesh(false) : setreflesh(true);
              },
            },
        ]}
        editable={
          access && {
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  deleteGroup(oldData._id);
                  Swal.fire({
                    text: `A group has been successfully deleted: ${oldData.name}`,
                    icon: 'success',
                  });
                  resolve();
                }, 1000);
              }),
          }
        }
        options={{
          actionsColumnIndex: -1,
          pageSize: parseInt(pagination),
          pageSizeOptions: [10, 20, 50, 100],
        }}
      />
      <BackButton />
      <CreateGroup
        id={id}
        reflesh={+reflesh}
        checkedvalues={checkedvalues}
        setcheckedvalues={setcheckedvalues}
        checkedvalues2={checkedvalues2}
        setcheckedvalues2={setcheckedvalues2}
        setreflesh={setreflesh}
        name={name}
        setname={setname}
        groupid={groupid}
        update={update}
        show={show}
        onHide={() => setShow(false)}
      />
    </div>
  );
}

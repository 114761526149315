import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Select, MenuItem } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import request from '../../../services/Request';
import { languages, languages_noEn } from '../../../staticData/languages';

import { useForm, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CreateBrand(props) {
  const classes = useStyles();
  const extra = JSON.parse(localStorage.getItem('user'))?.feature?.extra;
  const { name, update, referTo, picture, id, reflesh, setReflesh, logo, ...rest } = props;
  const title = update ? 'Update' : 'Add New';
  const user = JSON.parse(localStorage.getItem('user'));
  const langs = user?.langs;
  const [langArray, setlangArray] = useState([]);
  const [enArray, setenArray] = useState([]);

  let defaultValues = {
    select: 'en',
    name: '',
    image: null,
  };

  const {
    reset,
    watch,
    handleSubmit,
    register,
    clearErrors,
    setError,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const watchAll = watch();

  const onSubmit = (formData) => {
    const myData = new FormData();
    let name = { en: formData.name };
    langArray.forEach((e) => {
      if (formData[e.code + 'Name'] != '') {
        name[e.code] = formData[e.code + 'Name'];
      }
    });
    myData.append('name', JSON.stringify(name));
    myData.append('referTo', referTo);
    if (formData.image && formData.image.length > 0) myData.append('picture', formData.image[0]);
    if (formData.logo && formData.logo.length > 0) myData.append('logo', formData.logo[0]);

    if (update) {
      request({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_API}/brands/edit/${id}`,
        data: myData,
      })
        .then((res) => {
          props.onHide();
          Swal.fire({
            text: `The brands has been successfully updated: ${formData.name}`,
            icon: 'success',
          }).then(() => {
            reflesh ? setReflesh(false) : setReflesh(true);
          });
        })
        .catch((err) => console.log(err));
    } else {
      request({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_API}/brands/create`,
        data: myData,
      })
        .then((res) => {
          props.onHide();
          Swal.fire({
            text: `New category has been successfully created: ${formData.name}`,
            icon: 'success',
          }).then(() => {
            reflesh ? setReflesh(false) : setReflesh(true);
          });
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    let a1 = [];
    let a2 = [languages[0]];
    if (langs?.length > 0) {
      langs.forEach((lan) => {
        const index = languages_noEn.findIndex((e) => e.code === lan);
        if (index > -1) {
          a1.push(languages_noEn[index]);
          a2.push(languages_noEn[index]);
        }
      });
    }
    setlangArray(a1);
    setenArray(a2);
    a1.forEach((e) => {
      defaultValues[e.code + 'Name'] = '';
    });

    // console.log(picture);
    if (update) {
      let resetObj = {
        select: 'en',
        name: name.en,
        image: null,
        logo: null,
      };
      for (const property in name) {
        resetObj[property + 'Name'] = name[property];
      }
      reset(resetObj);
    } else {
      reset(defaultValues);
    }
  }, [props.show]);

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title} Brand</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main">
          <CssBaseline />
          <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <Select fullWidth defaultValue="en" {...field} variant="outlined">
                        {enArray.map((e) => {
                          return <MenuItem value={e.code}>{e.name}</MenuItem>;
                        })}
                      </Select>
                    )}
                    name="select"
                    control={control}
                  />
                </Grid>

                <Grid item xs={12} hidden={watchAll.select !== 'en'}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: 'name is required' }}
                    render={({ field }) => (
                      <TextField
                        label="name"
                        variant="outlined"
                        required
                        fullWidth
                        inputProps={{
                          maxLength: '30',
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                {langArray.map((e) => {
                  return (
                    <Grid item xs={12} hidden={watchAll.select !== e.code}>
                      <Controller
                        name={e.code + 'Name'}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            label="name"
                            variant="outlined"
                            inputProps={{
                              maxLength: '30',
                            }}
                            required
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  );
                })}
                {errors.name && (
                  <p className="signUpErrorMessage" style={{ marginLeft: '10px' }}>
                    {errors.name.message}
                  </p>
                )}

                <Grid item xs={12}>
                  <div className="d-flex align-items-center" style={{ height: '48px' }}>
                    <Grid item xs={3}>
                      <Button variant="contained" component="label">
                        Image
                        <input
                          {...register('image', {
                            onChange: (e) => {
                              clearErrors('image');
                              if (e.target.files.length > 0 && e.target.files[0].size > 5000000) {
                                setError('image', {
                                  message: 'File size too big, please provide the right size.',
                                });
                              }
                            },
                          })}
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          hidden
                        />
                      </Button>
                    </Grid>
                    <Grid className="d-flex align-items-center" item xs={8}>
                      <span className="product-fileName-span">
                        {update && <div>{picture}</div>}
                        {watchAll.image && watchAll.image.length > 0 && watchAll.image[0].name}
                      </span>
                    </Grid>
                    {/* {watchAll.image && watchAll.image.length > 0 && (
                    <IconButton onClick={() => handleRemove('image')}>
                      <DeleteForeverOutlined />
                    </IconButton>
                  )} */}
                  </div>
                  {!extra ? (
                    <div className="mt-2">
                      Please provide 1080 × 600 or bigger with similar aspect ratio
                    </div>
                  ) : (
                    <div className="mt-2">
                      Please provide 660 × 320 or bigger with similar aspect ratio
                    </div>
                  )}
                </Grid>
                {errors.image && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.image.message}</p>
                )}

                {
                  !extra && user.theme === '1' && (
                    /* <div> */
                    <Grid item xs={12}>
                      <div className="d-flex align-items-center" style={{ height: '48px' }}>
                        <Grid item xs={3}>
                          <Button variant="contained" component="label">
                            LOGO
                            <input
                              {...register('logo', {
                                onChange: (e) => {
                                  clearErrors('logo');
                                  if (
                                    e.target.files.length > 0 &&
                                    e.target.files[0].size > 5000000
                                  ) {
                                    setError('logo', {
                                      message: 'File size too big, please provide the right size.',
                                    });
                                  }
                                },
                              })}
                              type="file"
                              accept=".jpg,.png,.jpeg"
                              hidden
                            />
                          </Button>
                        </Grid>
                        <Grid className="d-flex align-items-center" item xs={8}>
                          <span className="product-fileName-span">
                            {update && <div>{logo}</div>}
                            {watchAll.logo && watchAll.logo.length > 0 && watchAll.logo[0].name}
                          </span>
                        </Grid>
                      </div>
                      {/* {theme1 ? (
                      <div className="mt-2">
                        Please provide 1080 × 600 or bigger with similar aspect ratio
                      </div>
                    ) : (
                      <div className="mt-2">
                        Please provide 660 × 320 or bigger with similar aspect ratio
                      </div>
                    )} */}
                    </Grid>
                  )

                  /* </div> */
                }
                {errors.logo && (
                  <p style={{ color: 'red', marginLeft: '8px' }}>{errors.logo.message}</p>
                )}
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: '#04297A',
  backgroundColor: '#D0F2FF',
}));

// ----------------------------------------------------------------------

export default function UnitSold(props) {
  // console.log('UnitSold props', props);
  const { unit, title } = props;
  return (
    <RootStyle style={{ borderRadius: '16px', fontFamily: 'Public Sans, sans-serif' }}>
      <Typography variant="h3">{(props.sign ? '$' : '') + fShortenNumber(unit)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title ? title : 'Units Sold'}
      </Typography>
    </RootStyle>
  );
}

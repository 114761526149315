import React from 'react';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function VerticalToggleButtons(props) {
  return (
    <ToggleButtonGroup className="mt-4" value={props.view} exclusive>
      <ToggleButton value="list" aria-label="list" onClick={props.setList}>
        <ViewListIcon />
      </ToggleButton>
      <ToggleButton value="module" aria-label="module" onClick={props.setModule}>
        <ViewModuleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

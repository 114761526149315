import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PlanoContext } from './PlanoContext';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../frontend/contexts/userContext';
import request from '../services/Request';
import ProductList from './ProductList';
import { Row, Col } from 'react-bootstrap';
import LoadingIcon from '../frontend/items/buttons/LoadingIcon';
import Swal from 'sweetalert2';
import { userLimits } from '../frontend/dashboard/uitls/userLimits';

import Plano from './Plano';
import './Plano.css';

// const data=[
// {"dimention":{length:3.6,width:1,height:2},"productPictures":["APpEltkp70dn5YWiEM8so-OIP.jpg","JFegfEhugWt4sWpFE-aCT-1072762.jpg"],"_id":"60cbbd196451b78e43746051","name":"Lenovo ThinkPad E15","price":900,"description":"A sleek metallic design on top, performance underneath","category":"Computers","quantity":15,"referTo":"1234@gmail.com","updatedAt":"2021-08-01T15:44:08.463Z","brand":"Apple","productVideo":"nz0YUMmJWSxNhNOc8s-C3-NVIDIA DLSS  Max FPS Max Quality Powered By AI.mp4"},
// {"dimention":{length:3.6,width:1,height:2},"productPictures":["APpEltkp70dn5YWiEM8so-OIP.jpg","JFegfEhugWt4sWpFE-aCT-1072762.jpg"],"_id":"60cbbd196451b78e43746051","name":"Lenovo ThinkPad E15","price":900,"description":"A sleek metallic design on top, performance underneath","category":"Computers","quantity":15,"referTo":"1234@gmail.com","updatedAt":"2021-08-01T15:44:08.463Z","brand":"Apple","productVideo":"nz0YUMmJWSxNhNOc8s-C3-NVIDIA DLSS  Max FPS Max Quality Powered By AI.mp4"},
// {"dimension":{length:3.6,width:1,height:2},"productPictures":["APpEltkp70dn5YWiEM8so-OIP.jpg","JFegfEhugWt4sWpFE-aCT-1072762.jpg"],"_id":"60cbbd196451b78e43746051","name":"Lenovo ThinkPad E15","price":900,"description":"A sleek metallic design on top, performance underneath","category":"Computers","quantity":15,"referTo":"1234@gmail.com","updatedAt":"2021-08-01T15:44:08.463Z","brand":"Apple","productVideo":"nz0YUMmJWSxNhNOc8s-C3-NVIDIA DLSS  Max FPS Max Quality Powered By AI.mp4"},
// {"dimension":{length:2.5,width:2,height:2},"productPictures":["uCB3Wrk33hWnTqmD9_1oI-OIP.jpg","1nh6zA49YUwzJ3-PK1ctM-1072762.jpg"],"_id":"60ce0da372175bd367c969f7","name":"Acer Aspire 5","price":1000,"description":"The Aspire 5 packs a lot of power into the chassis with a choice of either Intel® or AMD processors, and either NVIDIA® or AMD graphics","category":"Computers","quantity":10,"referTo":"1234@gmail.com","updatedAt":"2021-07-12T23:11:49.729Z","productVideo":""},
// {"dimension":{length:2.5,width:3,height:2},"productPictures":["vx1UAXTqnry-g-baAzWj4-rtx-3060-2.jpg","ay1jrBV0F5jQvu1BlO4DK-rtx-3060.jpg"],"_id":"60ec771b89f48e4f1c878050","name":"Nvidia GeForce RTX 3060","price":550,"description":"The GeForce® RTX 3060 Ti and RTX 3060 let you take on the latest games using the power of Ampere","category":"Computers","quantity":10,"productVideo":"jIDyQ0iy3D25oF8shul5x-NVIDIA DLSS  Max FPS Max Quality Powered By AI.mp4","referTo":"1234@gmail.com","createdAt":"2021-07-12T17:08:43.573Z","updatedAt":"2021-07-13T13:40:09.518Z","__v":0,"brand":"Nvidia"},
// {"dimension":{length:2,width:2,height:2},"productPictures":["ufxHvDmCVpdK3x9XzXUVS-71SkzmfBVSL._AC_SL1200_.jpg","5lw3_nA2BXo67j3zVWQPW-818-IGwCTjL._AC_SL1500_.jpg"],"_id":"60efb85500e48e3c1c9cbaee","name":"Sennheiser Momentum 3","price":370,"description":"The New MOMENTUM Wireless delivers a superior listening experience like no other. Reproducing the balanced depth and precision of a studio recording, these headphones separate each frequency, so you hear the bass, mid-range and treble together in perfect harmony.","category":"Music","brand":"Sennheiser","quantity":5,"productVideo":"","referTo":"1234@gmail.com","createdAt":"2021-07-15T04:23:49.890Z","updatedAt":"2021-07-15T04:29:23.757Z","__v":0},
// {"dimension":{length:1.5,width:2,height:2},"productPictures":["sRehxGEKjbuUpriG1-nMU-81RxWPzy8zL._AC_SL1500_.jpg","OU-OMn7GxK3bXgkULkkFr-R.png"],"_id":"60efba4f00e48e3c1c9cbaf0","name":"Pepsi","price":4.98,"description":"The ultimate party sidekick!\nThe bold, refreshing and delicious taste of Pepsi cola","category":"Drinks","brand":"Pepsi","quantity":48,"productVideo":"","referTo":"1234@gmail.com","createdAt":"2021-07-15T04:32:15.151Z","updatedAt":"2021-07-15T21:57:00.951Z","__v":0}]

const MYHEIGHT = 2.54;

export default function PlanoPage(props) {
  const planoID = props.match.params.id;
  const id = props.match.params.prodid;
  const { access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Planograms',
    'edit_plano'
  );

  const getAllProdURL = `/products/`;
  const getPlanoURL = `/plano/`;
  const getCatesURL = `/categories/`;

  const [prods, setProds] = useState([]);
  const [plano, setplano] = useState(null);
  const [floors, setFloors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [newPlano, setNewPlano] = useState(true);
  const [cates, setCates] = useState([]);
  const [error, setError] = useState('');
  const [height, setHeight] = useState(85);
  const [laneHeight, setLaneHeight] = useState(3);
  const [laneLength, setLaneLength] = useState(11);
  const [heights, setHeights] = useState([]);
  const [display, setDisplay] = useState(true);
  const [uploadProd, setUploadProd] = useState([]);
  const [search, setSearch] = useState('');
  const [dragProd, setDragProd] = useState('');

  // useEffect(()=>{
  //   console.log(prods)
  // },[prods])

  function roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  }

  useEffect(() => {
    if (!display) {
      return window.close();
    }

    window.addEventListener('beforeunload', functionToRun);
    return () => {
      window.removeEventListener('beforeunload', functionToRun);
    };
  }, [display]);

  useEffect(() => {
    if (prods?.length > 0) {
      prods.forEach((e) => {
        e.step = Math.ceil(e.dimension.width / plano.step_depth);
        e.dimension.lane =
          parseFloat(roundUp(e.dimension.length / plano.laneLength, 1)) > 1
            ? parseFloat(roundUp(e.dimension.length / plano.laneLength, 1))
            : 1;
        e.dimension.height = Math.ceil(e.dimension.originalHeight / MYHEIGHT) + 2;
        e.dimension.step = Math.ceil(e.dimension.width / plano.step_depth);
        e.dimension.maxStock = Math.trunc(
          plano.step_num / Math.ceil(e.dimension.width / plano.step_depth)
        );
      });
    }
  }, [prods]);

  useEffect(() => {
    request({
      method: 'get',
      url: getCatesURL + id,
    }).then((res) => {
      // console.log(res.data);
      const cate = res.data;
      cate.forEach((e, i) => {
        cate[i].name = e.name.en;
      });
      setCates(cate);
    });

    request({
      method: 'get',
      url: getPlanoURL + planoID,
    }).then((res) => {
      request({
        method: 'get',
        url: getAllProdURL + id + '/plano',
      }).then((res1) => {
        let myProds = res1.data;
        myProds = myProds.filter((e) => e.dimension);
        console.log('myProds', myProds);

        myProds.forEach((e) => {
          e.step = Math.ceil(e.dimension.width / res.data.step_depth);
          if (e.dimension.length <= 1) {
            e.dimension.length = 1;
          }
          // console.log('ddd', e.dimension.length);
          // console.log('222', roundUp(e.dimension.length / res.data.laneLength, 1));
          // console.log('222', parseFloat(roundUp(e.dimension.length / res.data.laneLength, 1)));
          e.dimension.lane =
            parseFloat(roundUp(e.dimension.length / res.data.laneLength, 1)) > 1
              ? parseFloat(roundUp(e.dimension.length / res.data.laneLength, 1))
              : 1;
          e.dimension.originalHeight = e.dimension.height;
          e.dimension.height = Math.ceil(e.dimension.height / MYHEIGHT) + 2;

          e.dimension.step = Math.ceil(e.dimension.width / res.data.step_depth);
          e.dimension.maxStock = Math.trunc(
            res.data.step_num / Math.ceil(e.dimension.width / res.data.step_depth)
          );
          e.brand = e.brand?.en;
          e.category?.forEach((ele) => {
            ele.name = ele.name.en;
          });
          e.name = e.name.en;
        });
        setProds(myProds);
        // console.log(myProds)
        setLoading(true);
      });

      const myh = [];
      setplano(res.data);
      console.log(res.data);
      setNewPlano(res.data.newPlano);
      if (res.data.height) setHeight(res.data.height);
      if (res.data.laneHeight) setLaneHeight(res.data.laneHeight);
      if (res.data.laneLength) setLaneLength(res.data.laneLength);
      if (res.data.newPlano) {
        const initialFloor = [];
        for (let i = 1; i <= res.data.floorNumber; i++) {
          initialFloor.push({ lines: [] });
        }
        setFloors(initialFloor);
        myh.push(res.data.height - 13);
        for (let i = 1; i <= res.data.floorNumber - 2; i++) {
          myh.push(
            parseInt(res.data.height - 13 - ((res.data.height - 13) / res.data.floorNumber) * i)
          );
          // console.log(res.data.floorNumber)
          // console.log(myh)
        }
        setHeights(myh);
        myh.push(12);
      } else {
        setUploadProd(res.data.prods);
        setFloors(res.data.floors);
        for (let i = res.data.floorNumber; i > 0; i--) {
          myh.push(res.data.floors[i - 1].height);
        }
        setHeights(myh);
        // for (let i=1;i<=res.data.floorNumber;i++)
        // {
        //   myh.push(parseFloat((res.data.height- (res.data.height/res.data.floorNumber*i)).toFixed(1)))
        // };
        // setHeights(myh);
      }
      setLoading1(true);
    });
  }, []);

  const functionToRun = (e) => {
    e.preventDefault();
    let confirmationMessage = 'o/';
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome
  };

  const handleSave = () => {
    localStorage.setItem('reRender', false);
    const formData = [];
    floors.forEach((e, i) => {
      e.height = heights[plano.floorNumber - 1 - i];
      formData.push(e);
    });
    const FinalProduct = uploadProd.map((e) => {
      if (e.referTo) {
        return {
          id: e._id,
          pos: e.pos,
          max_stock: e.maxStock,
          current_stock: 0,
          motor: e.motor,
          step: e.step,
        };
      } else {
        return {
          id: e.id,
          pos: e.pos,
          max_stock: e.max_stock,
          current_stock: 0,
          motor: e.motor,
          step: e.step,
        };
      }
    });
    if (access) {
      setLoading1(false);
      request({
        method: 'patch',
        url: getPlanoURL + planoID,
        data: {
          floors: formData,
          prods: FinalProduct,
        },
      }).then((res) => {
        localStorage.setItem('reRender', true);
        setLoading1(true);
        Swal.fire({
          text: 'Your planogram has been saved.',
          icon: 'success',
        }).then(() => {
          setDisplay(false);
        });
      });
    } else {
      Swal.fire({
        text: 'You have no permission to update the planogram.',
        icon: 'error',
      }).then(() => {
        setDisplay(false);
      });
    }
  };

  if (!loading || !loading1) return <LoadingIcon />;
  else
    return (
      <PlanoContext.Provider
        value={{
          prods,
          error,
          setError,
          plano,
          uploadProd,
          setUploadProd,
          search,
          setSearch,
          setDragProd,
          dragProd,
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <div style={{ marginTop: '5px' }}>
            <div style={{ position: 'fixed', top: 0, zIndex: 10001 }}>
              <Row className="plano-legend">
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-item-center">
                    <div className="plano-legend-box p-l-box1"></div>
                    <p className="plano-legend-text">Available</p>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-item-center">
                    <div className="plano-legend-box p-l-box2"></div>
                    <p className="plano-legend-text">Occupied</p>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-item-center">
                    <div className="plano-legend-box p-l-box3"></div>
                    <p className="plano-legend-text">Shared Lane (not used)</p>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-item-center">
                    <div className="plano-legend-box p-l-box4"></div>
                    <p className="plano-legend-text">Shared Lane (half used)</p>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-item-center">
                    <div className="plano-legend-box p-l-box5"></div>
                    <p className="plano-legend-text">Shared Lane (fully used)</p>
                  </div>
                </Col>
              </Row>
            </div>
            <Row style={{ marginTop: '80px' }}>
              <Col xs={4} className="d-flex justify-content-center">
                <ProductList
                  search={search}
                  setSearch={setSearch}
                  setProds={setProds}
                  prods={prods}
                  laneLength={laneLength}
                  cates={cates}
                  handleSave={handleSave}
                ></ProductList>
              </Col>
              <Col xs={8} className="d-flex justify-content-center">
                <Plano
                  newPlano={newPlano}
                  plano={plano}
                  heights={heights}
                  setHeights={setHeights}
                  name={plano.name}
                  laneHeight={laneHeight}
                  height={height}
                  setNewPlano={setNewPlano}
                  floorNumber={plano.floorNumber}
                  lineNumber={plano.lineNumber}
                  floors={floors}
                  setFloors={setFloors}
                ></Plano>
              </Col>
            </Row>
          </div>
        </DndProvider>
      </PlanoContext.Provider>
    );
}

import { Grid, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import request from '../../../services/Request';
import LoadingIcon from '../../items/buttons/LoadingIcon';

import { Autocomplete } from '@material-ui/lab';
import { TextField, Button } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import Chart from 'react-apexcharts';
import { zeroPad } from '../../../services/formatNumber';

// ----------------------------------------------------------------------
import { userLimits } from '../uitls/userLimits';
import { localDateRange } from '../../../services/method/methods';

const getMachinesURL = '/machines/getMyMachines/';
const getTemp = '/temperatures/';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  buttom: {
    width: 80,
    height: 40,
    marginTop: 15,
  },
}));

export default function Temperature(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [selectP, setSelectP] = useState({ name: '' });
  const [machines, setMachines] = useState([]);
  const [x, setx] = useState([]);
  const [y, sety] = useState([]);

  const { _id, id, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'view_report'
  );

  useEffect(() => {
    async function getMachine() {
      await request({
        method: 'get',
        url:
          props.match.params.group && superAdmin
            ? getMachinesURL + props.match.params.id
            : getMachinesURL + _id,
      }).then((res) => {
        // console.log(res.data);
        setMachines(res.data);
        setLoading(true);
      });
    }
    getMachine();
    return () => {
      setMachines([]);
      setLoading(true);
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
    // setStartDateString(e.toISOString());
  };

  const changeDate = (pre) => {
    const selectDate = startDate;
    if (pre) {
      const preDate = selectDate.setDate(selectDate.getDate() - 1);
      setStartDate(new Date(preDate));
      handleChange();
    } else {
      const nextDate = selectDate.setDate(selectDate.getDate() + 1);
      setStartDate(new Date(nextDate));
      handleChange();
    }
  };

  const handleChange = async () => {
    setLoading(false);
    const { startDateString } = localDateRange(startDate, new Date());
    await request({
      method: 'get',
      url: getTemp + selectP._id + '/' + startDateString,
    }).then((res) => {
      console.log(res.data);
      let time = [];
      let temp = [];
      res.data.forEach((item) => {
        if (item.timestamp) {
          const d = new Date(item.timestamp);
          const hour = d.getUTCHours();
          const min = d.getUTCMinutes();
          time.push(`${zeroPad(hour, 2)}:${zeroPad(min, 2, true)}`);
        }
        temp.push(item.temperature1);
      });
      setx(time);
      sety(temp);
      setLoading(true);
    });
  };

  const options = {
    title: {
      text: 'Temperature1',
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
    chart: {
      id: 'basic-bar',
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: x,
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
        style: {
          fontSize: '10px',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      title: {
        text: 'Temperature °C',
        style: {
          fontSize: '18px',
          fontWeight: 'light',
        },
      },
    },
  };

  const series = [
    {
      name: 'temperature',
      data: y,
    },
  ];

  return (
    <div>
      <Container maxWidth="xl" sx={{ pt: 5 }}>
        {!loading && <LoadingIcon />}
        <Grid container spacing={3}>
          <Grid container item xs={12} alignItems="center" justifyContent="center">
            <Grid xs={3} item container justifyContent="flex-start">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.formControl}
                  value={startDate}
                  onChange={(e) => handleStartDate(e)}
                  label="Select date"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-start"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4} style={{ marginLeft: '15px' }}>
              <Autocomplete
                value={selectP}
                fullWidth
                id="addToMachine"
                options={machines}
                onChange={(event, value) => {
                  setSelectP(value);
                }}
                getOptionLabel={(option) =>
                  option.description ? `${option.name}(${option.description})` : option.name
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select a Machine" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: '15px' }}>
              <Button
                style={{ height: '40px' }}
                disabled={!selectP?.name}
                onClick={() => {
                  handleChange();
                }}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {x?.length > 0 && (
        <div>
          <div style={{ marginTop: '50px' }}>
            <Chart options={options} series={series} type="line" width="100%" />
          </div>
        </div>
      )}
      <div className={x?.length > 0 ? '' : 'mt-5'}>
        <Button
          style={{ height: '40px', float: 'left', marginLeft: '100px' }}
          disabled={!selectP?.name}
          onClick={() => {
            changeDate(true);
          }}
          variant="contained"
          color="primary"
        >
          Previous Day
        </Button>

        <Button
          style={{ height: '40px', float: 'right', marginRight: '100px' }}
          disabled={!selectP?.name}
          onClick={() => {
            changeDate();
          }}
          variant="contained"
          color="primary"
        >
          Next Day
        </Button>
      </div>
    </div>
  );
}

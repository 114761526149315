import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/userContext';
import { useHistory } from 'react-router';
import request from '../../../services/Request';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import BackButton from '../../items/buttons/BackButton';
import StopRoundedIcon from '@material-ui/icons/StopRounded';

const getAllMachinesURL = `/machines/getMyMachines`;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(-5.6),
    marginRight: theme.spacing(40),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ViewAllMachines() {
  function bytesToMegabytes(bytes) {
    return bytes / (1024 * 1024);
  }

  const [employees, setEmployees] = useState([]);

  const [isloading, setIsLoading] = useState(false);

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_allMachine')
      ? localStorage.getItem('table_view_allMachine')
      : 20
  );

  useEffect(() => {
    localStorage.setItem('table_view_allMachine', pagination);
  }, [pagination]);

  useEffect(() => {
    let isCancelled = false;

    //getAllEmployees
    async function getAllMachines() {
      await request({
        method: 'get',
        url: getAllMachinesURL,
      }).then((res) => {
        if (!isCancelled) {
          console.log(res.data);
          res.data?.forEach((element) => {
            if (element?.status_info === 'Out Of Service') {
              element.oos = 'NO';
            }
            if (element?.appStatus === 'false') {
              element.background = 'In Background';
            }
            element.online = 'false';
            if (element.active) {
              const diff = new Date().getTime() - new Date(element.active);
              if (diff <= 90000) {
                element.online = 'true';
                if (element?.status_info === 'Out Of Service' || element?.appStatus === 'false') {
                  element.online = 'oos';
                }
              }
            }
          });
          setIsLoading(true);
          setEmployees(res.data);
        }
      });
    }

    getAllMachines();

    return () => {
      isCancelled = true;
    };
  }, []);

  const columns = [
    { title: 'ID', field: 'name', editable: 'never' },
    { title: 'Description', field: 'description', editable: 'never' },
    { title: 'Belongs To', field: 'belongTo', editable: 'never' },
    { title: 'Version', field: 'version', editable: 'never' },
    {
      title: 'Free Disk',
      field: 'freedisk',
      editable: 'never',
      render: (rowData) => {
        if (rowData.freedisk) {
          return parseInt(bytesToMegabytes(rowData.freedisk)) + 'MB';
        }
      },
      customSort: (a, b) => {
        const c =
          a.freedisk === undefined || a.freedisk === null ? -Infinity : parseFloat(a.freedisk);
        const d =
          b.freedisk === undefined || b.freedisk === null ? -Infinity : parseFloat(b.freedisk);
        return c - d;
      },
    },
    // { title: 'ObjectId', field: '_id', editable: 'never' },
    {
      title: 'Status',
      field: 'online',
      editable: 'never',
      render: (rowData) => {
        if (rowData.online === 'true') {
          return (
            <div>
              <StopRoundedIcon style={{ color: 'green' }} />
            </div>
          );
        } else if (rowData.online === 'false') {
          return (
            <div>
              <StopRoundedIcon style={{ color: 'lightgrey' }} />
            </div>
          );
        } else if (rowData.online === 'oos') {
          return (
            <div>
              <StopRoundedIcon style={{ color: 'red' }} />
            </div>
          );
        }
      },
    },
    {
      title: 'In Service',
      field: 'oos',
    },
    {
      title: 'In Background',
      field: 'background',
    },
  ];

  return (
    <div>
      {!isloading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={employees}
          title={'View All Machines'}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [20, 50, 100],
          }}
        />
        <BackButton />
      </div>
    </div>
  );
}

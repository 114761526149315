// material
import {
  FormControlLabel,
  RadioGroup,
  Grid,
  Radio,
  TextField,
  Checkbox,
  Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
// ----------------------------------------------------------------------
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  buttom: {
    width: 80,
    height: 40,
    marginTop: 15,
  },
}));

Date.prototype.toShortFormat = function () {
  let monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let day = this.getDate();

  let monthIndex = this.getMonth();
  let monthName = monthNames[monthIndex];

  let year = this.getFullYear();

  return `${day}-${monthName}-${year}`;
};

export default function MachineSelect(props) {
  const classes = useStyles();
  const {
    setRadio,
    setgroupV,
    radio,
    groupV,
    groups,
    machines,
    handleChange,
    startDate,
    endDate,
    handleEndDate,
    handleStartDate,
    filterd,
    radio2,
    groupV2,
    doubleCheck,
    option,
  } = props;

  return (
    <Grid container style={{ paddingLeft: '40px' }}>
      <Grid xs={4} item container justifyContent="flex-start" alignContent="center">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.formControl}
            value={startDate}
            onChange={(e) => handleStartDate(e)}
            label="Select start date"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-start"
          />
          <KeyboardDatePicker
            className={classes.formControl}
            value={endDate}
            onChange={(e) => handleEndDate(e)}
            label="Select end date"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-end"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid xs={8} item container alignItems="center">
        <Grid lg={3} item container justifyContent="flex-start" style={{ marginRight: '20px' }}>
          {option === 'shiva' ? (
            <RadioGroup
              name="bg_radio"
              style={{ height: '100%' }}
              onChange={(e) => {
                setRadio(e.target.value);
                setgroupV([]);
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                value="a"
                control={<Radio />}
                label="TCN"
                checked={radio == 'a'}
              />

              <FormControlLabel
                style={{ margin: 0 }}
                value="g"
                control={<Radio />}
                label="Not Ready"
                checked={radio == 'g'}
              />
            </RadioGroup>
          ) : (
            <RadioGroup
              name="bg_radio"
              style={{ height: '100%' }}
              onChange={(e) => {
                setRadio(e.target.value);
                setgroupV([]);
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                value="a"
                control={<Radio />}
                label="All Machines"
                checked={radio == 'a'}
              />
              <FormControlLabel
                style={{ margin: 0 }}
                value="g"
                control={<Radio />}
                label="Groups"
                checked={radio == 'g'}
              />
              <FormControlLabel
                style={{ margin: 0 }}
                value="m"
                control={<Radio />}
                label="Machines"
                checked={radio == 'm'}
              />
            </RadioGroup>
          )}
        </Grid>
        <Grid container item xs={6} alignItems="center" justifyContent="flex-start">
          <Grid container item xs={12} alignItems="center" justifyContent="center">
            {radio == 'a' && (
              <TextField variant="outlined" label="All Machines" fullWidth disabled />
            )}

            {radio == 'g' && (
              <Autocomplete
                multiple
                value={groupV}
                options={groups}
                disableCloseOnSelect
                onChange={(event, value) => {
                  setgroupV(value);
                }}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Groups" />
                )}
              />
            )}

            {radio == 'm' && (
              <Autocomplete
                multiple
                value={groupV}
                options={machines}
                disableCloseOnSelect
                onChange={(event, value) => {
                  setgroupV(value);
                }}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.description ? `${option.name}(${option.description})` : option.name}
                  </React.Fragment>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Machines" />
                )}
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={1} style={{ marginLeft: '15px' }}>
          {!props.compare && (
            <Button
              disabled={
                doubleCheck
                  ? (groupV2.length == 0 && radio2 != 'a') || (groupV.length == 0 && radio != 'a')
                  : groupV.length == 0 && radio != 'a'
              }
              style={{ height: '40px' }}
              onClick={handleChange}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import Droped from './Droped';
import { PlanoContext } from './PlanoContext';
import Swal from 'sweetalert2';
import { COLOR1, COLOROrigin, COLORS1, COLORS2, COLORS3 } from './colorVariable';
import PropertyModal from './PropertyModal';

import './Plano.css';

export default function Line(props) {
  const {
    heights,
    floorNumber,
    lines,
    setLines,
    index,
    width,
    lineNumber,
    floor,
    floors,
    setFloors,
    floorIndexHeight,
    height,
    plano,
  } = props;

  const nextIndex = floorIndexHeight + 1 >= floorNumber ? 0 : floorIndexHeight + 1;
  const prevIndex = floorIndexHeight - 1 < 0 ? floorNumber - 1 : floorIndexHeight - 1;

  let arrayUsedForBoard = [];
  arrayUsedForBoard.push(lines[index].product);

  const { prods, error, setError, uploadProd, setUploadProd } = useContext(PlanoContext);
  const [board, setBoard] = useState(arrayUsedForBoard[0] == undefined ? [] : arrayUsedForBoard);
  const [disable, setDisable] = useState(lines[index].disable);
  const [show, setShow] = useState(false);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'image',
    drop: (item, monitor) => addImageToBoard(item.prod, monitor),
    canDrop: (item, monitor) => {
      let yes = true;
      let errorMessage = '';
      // console.log(item.prod.dimension.lane % 1);
      let prodLength =
        item.prod.dimension.lane % 1 != 0 &&
        index !== 0 &&
        lines[index - 1].disable === 0 &&
        lines[index - 1].occupied + (item.prod.dimension.lane % 1) < 1
          ? item.prod.dimension.lane - 1
          : item.prod.dimension.lane;
      prodLength =
        index + Math.trunc(item.prod.dimension.lane) < lineNumber - 1 &&
        lines[index + Math.trunc(item.prod.dimension.lane)].disable === 0
          ? item.prod.dimension.lane - 1
          : prodLength;
      // console.log(prodLength);
      for (let i = 0; i < prodLength; i++) {
        if (index + i > lineNumber - 1) {
          errorMessage = "You don't have enough lanes for this product.";
          yes = false;
        } else {
          if (lines[index + i].disable == 0) {
            yes = false;
            errorMessage = "You don't have enough lanes (before the shared lane) for this product.";
          }
          // console.log(i);
          // console.log(Math.trunc(item.prod.dimension.lane)-1);
          if (i === Math.trunc(item.prod.dimension.lane - 1)) {
            if (
              index + i + 1 < lineNumber - 1 &&
              lines[index + i + 1].disable == 0 &&
              (item.prod.dimension.lane % 1) + lines[index + i + 1].occupied > 1
            ) {
              // console.log('aaa')
              errorMessage = `There is no enough shared space on the shared lane. Please check lane's required for the products.`;
              yes = false;
            }
          }
          if (index + i <= lineNumber - 1 && lines[index + i].occupied !== 0) {
            // console.log(index+i)
            errorMessage = "You don't have enough lanes (before next product) for this product.";
            yes = false;
          }
        }
      }
      // console.log(heights[prevIndex] - heights[floorIndexHeight])
      // console.log(heights)
      // console.log(floorNumber)
      // console.log(floor)
      // console.log(lines[0].heights)
      if (floorIndexHeight == 0) {
        if (item.prod.dimension.height > height - lines[0].heights[floorIndexHeight]) {
          errorMessage = "You don't have enough height between floors for this product.";
          yes = false;
        }
      } else {
        // console.log(item.prod.dimension.height)
        if (
          item.prod.dimension.height >
          lines[0].heights[prevIndex] - lines[0].heights[floorIndexHeight]
        ) {
          errorMessage = "You don't have enough height between floors for this product.";
          yes = false;
        }
      }
      if (yes) {
        if (floors[floorNumber - floorIndexHeight - 1].maxHeight < item.prod.dimension.height) {
          floors[floorNumber - floorIndexHeight - 1].maxHeight = item.prod.dimension.height;
        }
      }
      setError(errorMessage);
      return yes;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const addImageToBoard = (newp, monitor) => {
    const p = JSON.parse(JSON.stringify(newp));
    console.log('addImageToBoard', p);
    if (p.name.en) {
      p.name = p.name.en;
    }
    let a1 = [];
    let a2 = [];
    let next = true;
    //set W
    let W = 0;
    if (p.dimension.lane > 1) {
      if (p.dimension.lane % 1 === 0) {
        W = Math.trunc(p.dimension.lane);
      } else {
        W = Math.trunc(p.dimension.lane) + 1;
      }
    } else {
      W = 1;
    }
    lines[index].step = p.dimension.step;
    //check is a disabled line before the product
    if (index !== 0 && lines[index - 1].disable == 0) {
      // console.log("cccc")
      //check is there already product
      if (lines[index - 1].occupied === 0 && p.dimension.lane % 1 !== 0) {
        //not occupied
        W = Math.trunc(p.dimension.lane);
        Swal.fire('', `${p.name} is sharing the lane with previous product.`, 'info');
        lines[index - 1].occupied = p.dimension.lane % 1;
        lines[index - 1].occupied = Math.round(lines[index - 1].occupied * 10) / 10;
        lines[index - 1].color = COLORS2;
        // console.log("bbb")
        // console.log(p.dimension.lane % 1);
        // console.log(lines[index-1].occupied);
        lines[index - 1].product2 = p;
        lines[index].length = Math.trunc(p.dimension.lane);
        for (let i = 0; i < p.dimension.lane - 1; i++) {
          lines[index + i].occupied = 1;
          lines[index + i].color = COLOR1;
          lines[index + i].product = p;
        }
        setLines([...lines]);
        next = false;
      } else {
        // console.log("aaa")
        if (p.dimension.lane % 1 !== 0 && (p.dimension.lane % 1) + lines[index - 1].occupied <= 1) {
          W = Math.trunc(p.dimension.lane);
          Swal.fire('', `${p.name} is sharing the lane with previous product.`, 'info');
          // console.log(p.dimension.lane % 1);
          // console.log(lines[index-1].occupied);
          lines[index - 1].occupied = (p.dimension.lane % 1) + lines[index - 1].occupied;
          lines[index - 1].occupied = Math.round(lines[index - 1].occupied * 10) / 10;
          lines[index - 1].color = COLORS3;
          lines[index - 1].product2 = p;
          lines[index].length = Math.trunc(p.dimension.lane);
          for (let i = 0; i < p.dimension.lane - 1; i++) {
            lines[index + i].occupied = 1;
            lines[index + i].color = COLOR1;
            lines[index + i].product = p;
          }
          setLines([...lines]);
          next = false;
        } else {
          Swal.fire(
            '',
            `${p.name} is NOT! sharing the lane with previous product. Please check the lanes required of \"${p.name}\" if you want to share the lane with previous product`,
            'warning'
          );
        }
      }
    }
    if (next) {
      // if (index + Math.trunc(p.dimension.lane) > lineNumber-1) {
      //   errorMessage = 'You have no enough lanes (before next product) for this product.';
      // }
      // else{
      console.log('p,', p);
      if (p.dimension.lane % 1 != 0 && lines[index + Math.trunc(p.dimension.lane)].disable == 0) {
        W = Math.trunc(p.dimension.lane);
        Swal.fire('', `${p.name} is sharing the lane with next product.`, 'info');
        next = false;
        lines[index].length = Math.trunc(p.dimension.lane);
        for (let i = 0; i < p.dimension.lane - 1; i++) {
          lines[index + i].occupied = 1;
          lines[index + i].color = COLOR1;
          lines[index + i].product = p;
        }
        lines[index + Math.trunc(p.dimension.lane)].color =
          lines[index + Math.trunc(p.dimension.lane)].occupied > 0 ? COLORS3 : COLORS2;
        lines[index + Math.trunc(p.dimension.lane)].occupied += p.dimension.lane % 1;
        lines[index + Math.trunc(p.dimension.lane)].product = p;
        lines[index + Math.trunc(p.dimension.lane)].occupied =
          Math.round(lines[index + Math.trunc(p.dimension.lane)].occupied * 10) / 10;
        setLines([...lines]);
      }
      // }
    }
    if (next) {
      // console.log("a");
      a1 = lines.slice(0, index + 1);
      //set capacity to occupied
      a1[index].occupied = 1;
      a1[index].color = COLOR1;
      a1[index].product = p;
      //set width
      a1[index].length =
        p.dimension.lane % 1 === 0
          ? Math.trunc(p.dimension.lane)
          : Math.trunc(p.dimension.lane) + 1;
      a2 = lines.slice(index + 1);
      //set capacity to occupied
      for (let i = 0; i < p.dimension.lane - 1; i++) {
        if (i === Math.trunc(p.dimension.lane - 1)) {
          a2[i].occupied = Math.round((p.dimension.lane - 1 - i) * 10) / 10;
          a2[i].color = COLOR1;
          a2[i].product = p;
        } else {
          a2[i].occupied = 1;
          a2[i].color = COLOR1;
          a2[i].product = p;
        }
      }
      setLines(a1.concat(a2));
    }
    lines[index].maxStock = lines[index].product.dimension.maxStock;
    const pictureList = prods.filter((prod) => p._id === prod._id);
    floors[floor - 1].floorIndex = floor - 1;
    floors[floor - 1].lines = lines.slice();
    setFloors([...floors]);
    setBoard(pictureList);
    // console.log('pictureList', pictureList);
    const myP = { ...pictureList[0] };

    myP.pos = floor + 'F ' + parseInt(index + 1) + 'L ' + W + 'W';
    if (plano.stockNum) {
      myP.pos += ' ' + plano.stockNum + 'S';
    }
    console.log(myP.pos);
    myP.maxStock = lines[index].product.dimension.maxStock;
    uploadProd.push(myP);
    // setUploadProd([...uploadProd]);
  };

  const handleChange = (event) => {
    // const px = floors.map(e=>e.px)
    // console.log(uploadProd);
    // console.log(nextIndex)
    // console.log(floorNumber)
    if (event.target.value == 0) {
      if (
        lines[index].occupied != 0 ||
        lines[index + 1].occupied != 0 ||
        lines[index - 1].occupied != 0
      ) {
        Swal.fire('', `Please remove the products first before disable the lane.`, 'error');
      } else {
        setDisable(parseInt(event.target.value));
        lines[index].disable = 0;
        lines[index].color = COLORS1;
      }
    } else {
      if (
        lines[index].occupied != 0 ||
        lines[index + 1].occupied != 0 ||
        lines[index - 1].occupied != 0
      ) {
        Swal.fire('', `Please remove the products first before enable the lane.`, 'error');
      } else {
        setDisable(parseInt(event.target.value));
        lines[index].disable = 1;
        lines[index].color = COLOROrigin;
      }
    }
    setLines([...lines]);
  };

  const handleClick = () => {
    console.log('uploadProd', uploadProd);
    setShow(true);
  };

  return (
    <div>
      <div
        onClick={handleClick}
        ref={drop}
        style={{ width: `50px`, height: '100px', backgroundColor: isOver && 'yellow' }}
        className="LineTop"
      >
        {board.map((prod, i) => {
          let referTo = prod.referTo;
          let pd = prod;
          let image = prod?._id?.name ? prod?._id?.productPictures[0] : prod?.productPictures[0];
          if (prod?._id?.name) {
            pd = prod?._id;
            prod._id.dimension = prod.dimension;
            referTo = prod?._id?.referTo;
            prod.name = prod._id.name.en;
          }

          return (
            <Droped
              url={`${process.env.REACT_APP_SERVER_API}/pictures/${image}/${referTo}`}
              heights={heights}
              setFloors={setFloors}
              floorNumber={floorNumber}
              floorIndexHeight={floorIndexHeight}
              floors={floors}
              key={i}
              prod={pd}
              setBoard={setBoard}
              lines={lines}
              index={index}
              floor={floor}
              setLines={setLines}
              width={`${width * 50}px`}
            />
          );
        })}
      </div>
      <div onClick={handleClick}>
        {index != 0 && index != lineNumber - 1 ? (
          <div>
            {/* <div style={{position:'absolute', fontSize:'0.7rem'}}>Max </div>
            <div style={{position:'absolute', fontSize:'0.7rem'}}>Max stock</div> */}
            <select
              id="plano-lane"
              value={disable}
              className="customselect"
              onChange={handleChange}
              style={{ backgroundColor: lines[index].color }}
            >
              <option value={0} className="selectDisable">
                Disable
              </option>
              <option value={1} className="selectDisable">
                Enable
              </option>
            </select>
          </div>
        ) : (
          <div
            className="customselect"
            style={{ border: '1px solid grey', backgroundColor: lines[index].color }}
          >
            {/* <option value={0} className="selectDisable">Disable</option>
            <option value={1} className="selectDisable">Enable</option> */}
          </div>
        )}
      </div>
      <p>
        {floor}
        {index.toString(16).toUpperCase()}
      </p>
      {lines[index].disable != 1 && !(lines[index].product && lines[index].product2) && (
        <p style={{ marginTop: '-120px', marginRight: '-5px' }}>{lines[index].occupied * 100}%</p>
      )}
      <PropertyModal
        plano={plano}
        handleChange={handleChange}
        show={show}
        disable={disable}
        lines={lines}
        index={index}
        uploadProd={uploadProd}
        setUploadProd={setUploadProd}
        lineNumber={lineNumber}
        floor={floor}
        setLines={setLines}
        onHide={() => setShow(false)}
      ></PropertyModal>
    </div>
  );
}

import './card.css';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap';
import AddToCardButton from './buttons/addToCardButton';
import ClearIcon from '@material-ui/icons/Clear';
import ImageSlider from './slides/ImageSlider';
import PromoCode from './PromoCode';

function DescCard(props) {
  const [stock, setStock] = useState(props.stock);
  const [current, setCurrent] = useState(0);
  const [show, setShow] = useState(false);
  const length = props.image.length;

  const scrollToImage = (event) => {
    if (document.querySelector('.activeImg') !== null) {
      document.querySelector('.activeImg').scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const decreaseStock = () => {
    if (stock > 1) setStock((stock) => stock - 1);
    else alert("Sorry, we don't have enough stock!");
  };

  useEffect(() => {
    scrollToImage();
  }, [current]);

  return (
    <div>
      <PromoCode
        show={show}
        onHide={() => setShow(false)}
        productId={props.id}
        name={props.name.en}
      />
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="ml-4 mt-3 mb-5">
            <Button className="btn btn-secondary closeButton" onClick={props.onHide}>
              <span className="mr-2">
                <ClearIcon />
              </span>{' '}
              <span className="closeText">Close</span>
            </Button>
            <Button
              className="btn btn-primary"
              style={{ marginLeft: '450px' }}
              onClick={() => setShow(true)}
            >
              Use Promo Coupon
            </Button>
          </div>
          <div className="text-center" style={{ maxWidth: '500px', maxHeight: '500px' }}>
            {/* show image */}

            {props.image.map((slide, index) => {
              return (
                <div className={index === current ? 'slideActive' : 'slide'} key={index}>
                  {index === current && (
                    <img
                      src={`${process.env.REACT_APP_SERVER_API}/products/pictures/${slide}`}
                      alt="travelImage"
                      className="img-fluid descImg img-max mb-4"
                    />
                  )}
                </div>
              );
            })}

            {/* show image */}

            <ImageSlider
              slides={props.image}
              index={current}
              nextSlide={nextSlide}
              prevSlide={prevSlide}
            />
          </div>
          <div className="card-body">
            <h5 className="card-title d-flex justify-content-center">{props.name.en}</h5>
            {!props.discount ? (
              <h5 className="card-text cardtext display-4 d-flex justify-content-center">
                {props.price}
              </h5>
            ) : (
              <h5
                className="card-text cardtext display-4 d-flex justify-content-center"
                style={{ textDecoration: 'line-through' }}
              >
                {props.price}
              </h5>
            )}
            {props.discountedPrice !== 0 && (
              <h5 className="card-text cardtext display-4 d-flex justify-content-center">
                {'$ ' + props.discountedPrice}
              </h5>
            )}
            {props.buyX && (
              <h5 className="card-text cardtext display-4 d-flex justify-content-center">
                {'Buy ' + props.buyX + ' Get'}
              </h5>
            )}
            {props.discount !== 0 && props.percentage === true && (
              <h5 className="card-text cardtext display-4 d-flex justify-content-center">
                {props.discount * 100 + '% off!'}
              </h5>
            )}
            {props.discount !== 0 && props.percentage === false && (
              <h5 className="card-text cardtext display-4 d-flex justify-content-center">
                {'$' + props.discount + ' off!'}
              </h5>
            )}
            <p className="d-flex justify-content-center">Stock Left: {stock}</p>
          </div>

          <div className="text-center">
            <AddToCardButton
              id={props.id}
              key={props.id}
              name={props.name}
              description={props.description}
              price={props.price}
              discount={props.discount}
              image={props.image}
              add={props.add}
              percentage={props.percentage}
              discountedPrice={props.discountedPrice}
              alterquantity={props.alterquantity}
              decreaseStock={decreaseStock}
              buyX={props.buyX}
              cart={props.cart}
            />
            <br />
          </div>
          <hr />
          <Container>
            <Row>
              <Col md={6} xs={12}>
                <h5>Description</h5>
                <br />
                <p>{props.description.en}</p>
              </Col>
              <Col md={6} xs={12}>
                <h5>Specifications</h5>
                <br />
                <p>{props.description.en}</p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DescCard;

import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/userContext';
import request from '../../../services/Request';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';

import { Grid, Container } from '@material-ui/core';

import MachineSelect from '../../dashboard/report/MachineSelect';
import { userLimits } from '../../dashboard/uitls/userLimits';
import moment from 'moment';
import { localDateRange } from '../../../services/method/methods';

const getOrderByEmail = `/credit/cashTrans`;
const getAllOrder = `/allOrders/`;
const getGroupsURL = `/groupsAndMachines/`;

Date.prototype.toShortFormat = function () {
  let monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let day = this.getDate();

  let monthIndex = this.getMonth();
  let monthName = monthNames[monthIndex];

  let year = this.getFullYear();

  return `${day}-${monthName}-${year}`;
};

export default function DownloadSales(props) {
  const { userEmail, filterd, setFiltered } = useContext(UserContext);

  //start at first date of this month
  var date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [order, setOrder] = useState([]);
  const [machines, setMachines] = useState([]);
  const [machineName, setMachineName] = useState('All Machines');
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [radio, setRadio] = useState('a');
  const user = JSON.parse(localStorage.getItem('user'));

  // console.log(user);

  const { id, tableTitle, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Transactions',
    'view_trans'
  );

  useEffect(() => {
    let allMachines = [];
    let isCancelled = false;

    // async function getGroups() {
    //   await request({
    //     method: 'get',
    //     url: !props.match.params.group && superAdmin ? getGroupsURL : getGroupsURL + userEmail,
    //   }).then((res) => {
    //     if (!isCancelled) {
    //       setGroups(res.data.groups);
    //       setMachines(res.data.machines);
    //       setLoading(true);
    //       allMachines = res.data.machines.map((e) => e._id);
    //     }
    //   });
    // }

    // getGroups();

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleSearch = async (event) => {
    if (radio === 'a') {
      const { startDateString, endDateString } = localDateRange(startDate, endDate);
      await request({
        method: 'post',
        url: `/downloadSalesForShiva`,
        data: {
          startDate: startDateString,
          endDate: endDateString,
          id: id,
        },
      }).then(async (res) => {
        console.log('downloadOrder', res.data);
        setOrder(res.data);
        setLoading(true);
      });
    }
  };
  let columns = [
    { title: 'Machine ID', field: 'MachineID' },
    { title: 'Sales', field: 'Sales' },
  ];

  return (
    <div>
      {/* {!loading && <LoadingIcon />} */}
      <div style={{ maxWidth: '100%' }}>
        <Container maxWidth="xl" style={{ marginTop: '5px', marginBottom: '5px', padding: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MachineSelect
                option="shiva"
                filterd={filterd}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                startDate={startDate}
                endDate={endDate}
                setRadio={setRadio}
                setgroupV={setgroupV}
                radio={radio}
                groupV={groupV}
                groups={groups}
                machines={machines}
                handleChange={handleSearch}
              />
            </Grid>
          </Grid>
        </Container>
        {/* </Box> */}
        <MaterialTable
          // onChangeRowsPerPage={setPagination}
          title={'Download Sales'}
          columns={columns}
          data={order}
          options={{
            exportButton: true,
            actionsColumnIndex: -1,
            pageSize: 100,
            pageSizeOptions: [10, 20, 50, 100, 200],
            rowStyle: (rowData) => {},
          }}
          actions={[]}
        />
        <BackButton />
      </div>
    </div>
  );
}

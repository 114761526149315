import { useEffect, useState, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../frontend/contexts/userContext';

const SuperAdminRoute = ({ component: Component, ...rest }) => {
  const { loginStatus, role } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (role === 'SuperAdmin' || role === 'BoxAdmin') && loginStatus ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        );
      }}
    />
  );
};

export default SuperAdminRoute;

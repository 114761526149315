import axios from 'axios';
import Swal from 'sweetalert2';

const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('MagexApp.cart');
  window.location.replace('/');
};

// window.addEventListener('storage', function(e) {
//   console.log(e.key);
//   if (e.key === 'token')  {
//     console.log('ssss');
//     client = axios.create({
//       baseURL: process.env.REACT_APP_SERVER_API,
//       headers: { 'auth-token': localStorage.getItem("token")}
//     });
//     console.log(client);
//   }
//  });

// const tokenURL = `${process.env.REACT_APP_SERVER_API}/users/token`;

/**
 * Request Wrapper with default success/error actions
 */

const request = async function (options) {
  let client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API,
    withCredentials: true,
  });
  // const token = localStorage.getItem('token').split(' ')[1];
  // const { exp } = jwt_decode(token);
  // const ex1 = new Date(exp * 1000);
  // const ex2 = ex1.setDate(ex1.getDate() - 3) / 1000;
  // const now = Date.now();
  // if today is 3 days before expired, get new token
  // if (now >= ex2 * 1000 && now <= exp * 1000) {
  //   await axios
  //     .post(tokenURL, {
  //       token: localStorage.getItem('token'),
  //     })
  //     .then((res) => {
  //       console.log(res.data.accessToken);
  //       localStorage.setItem('token', 'Bearer ' + res.data.accessToken);
  //       localStorage.getItem('token');
  //       window.location.reload();
  //     })
  //     .catch((err) => console.log(err));
  // }
  ///////////////////////////////////////

  const onSuccess = function (response) {
    return response;
  };

  const onError = function (error) {
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      if (error.response.status === 401) {
        Swal.fire({
          text: `Your session has been expired or you have no permission for this data, please login in again!`,
          icon: 'error',
        }).then(() => {
          logout();
        });
      }
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;

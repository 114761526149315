import React from 'react';
import { useField } from 'formik';
import { Form, Col } from 'react-bootstrap';
import './formFields.css';

export const TextField = (props) => {
  const [field, meta] = useField(props);
  return (
    <Form.Row className="mb-2">
      <Form.Group as={Col}>
        <Form.Label>
          <strong>{props.label}</strong>
        </Form.Label>
        <Form.Control {...props} {...field} isInvalid={!!meta.error} as={props.as} />
        <Form.Control.Feedback className="feedback" type="invalid" tooltip>
          {meta.error}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
};

import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2';
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  InputLabel,
  Radio,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import request from '../../../services/Request';
import { Modal } from 'react-bootstrap';
import MaterialTable from 'material-table';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const updateAlert = '/logs/updateAlertCustom';

export default function AlertCustomModal(props) {
  const {
    reset,
    handleSubmit,
    setValue,
    register,
    watch,
    setError,
    control,
    formState: { errors },
  } = useForm({});
  const classes = useStyles();
  const { data, id, setReFresh, reFresh, ...rest } = props;
  const watchAll = watch();

  useEffect(async () => {
    reset({
      code: data.code,
      text: data.text,
      alert: data.alert,
      color: data.color,
    });
  }, [data]);

  const color = ['grey', 'blue', 'red'];

  const onSubmit = async (formData) => {
    console.log(formData);
    await request({
      method: 'post',
      url: updateAlert,
      data: {
        _id: data._id,
        code: formData.code,
        text: formData.text,
        alert: formData.alert,
        color: formData.color,
        referTo: id,
      },
    }).then((res) => {
      Swal.fire({
        text: `Your changes has been updated!`,
        icon: 'success',
      }).then(() => {
        setReFresh(!reFresh);
        props.onHide();
      });
    });
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.2rem' }}>
          Update Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Code"
                    {...register('code', {})}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Message Text"
                    {...register('text', {})}
                  />
                </Grid>

                <Grid item xs={7}>
                  <Controller
                    render={({ field }) => (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Color</InputLabel>
                        <Select
                          fullWidth
                          label="Color"
                          defaultValue="grey"
                          {...field}
                          variant="outlined"
                        >
                          {color.map((e) => {
                            return <MenuItem value={e}>{e}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    )}
                    name="color"
                    control={control}
                  />
                </Grid>

                <Grid
                  item
                  xs={5}
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    fontSize: '16px',
                  }}
                >
                  <Controller
                    name="alert"
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={watchAll.alert} />}
                  />
                  <span>Email Alert</span>
                </Grid>
              </Grid>

              <Button
                style={{ marginTop: '20px' }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Update
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

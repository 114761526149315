import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useForm, Controller } from 'react-hook-form';
import {
  FormControl,
  Grid,
  FormLabel,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  TextField,
  Checkbox,
  MenuItem,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Swal from 'sweetalert2';

import { Modal } from 'react-bootstrap';

import request from '../../services/Request';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function PlaceProduct(props) {
  const { data, setReflesh, reflesh, employees, ...rest } = props;
  const defaultValues = {
    fromAdmin: data?.email,
    toAdmin: [],
    products: [],
    option: '1',
  };
  const { handleSubmit, reset, watch, control } = useForm({ defaultValues });

  const [products, setproducts] = useState([]);

  const watchAll = watch();

  useEffect(() => {
    let isCancelled = false;
    // console.log(data);
    if (props.show && data.email) {
      //download all products
      const getFromProduct = async () => {
        request({
          method: 'get',
          url: `/products/${data.email}`,
        }).then((res) => {
          if (!isCancelled) {
            const newData = [];
            res.data.forEach((element) => {
              newData.push({ upc: element.upc, _id: element._id, name: element.name.en });
            });
            console.log('newData', newData);
            setproducts(newData);
          }
        });
      };
      reset({
        fromAdmin: data?.email,
        toAdmin: [],
        products: [],
        option: '1',
      });
      getFromProduct();
    }
    return () => {
      isCancelled = true;
    };
  }, [props.show]);

  const onSubmit = async (formData) => {
    const toAdmin = formData.toAdmin?.email;
    let pd = [];

    if (toAdmin && toAdmin !== data?.email) {
      if (watchAll.option === '1') {
        pd = products?.map((e) => e._id);
      } else {
        pd = formData.products?.map((e) => e._id);
      }

      if (pd.length > 0) {
        await request({
          method: 'post',
          url: '/products/moveproducts',
          data: {
            fromAdmin: data?.email,
            toAdmin: toAdmin,
            products: pd,
          },
        }).then((res) => {
          Swal.fire({
            text: `Success`,
            icon: 'success',
          }).then(() => {
            reflesh ? setReflesh(false) : setReflesh(true);
            props.onHide();
          });
        });
      } else {
        Swal.fire({
          text: `error`,
          icon: 'error',
        });
      }
    } else {
      Swal.fire({
        text: `To Admin must be entered`,
        icon: 'error',
      });
    }
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Move Product Between Admins</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ textAlign: 'center', padding: '0 40px' }}
        >
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Admin's Features</FormLabel>
              </FormControl>
            </Grid> */}

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="From Admin"
                variant="outlined"
                value={watchAll.fromAdmin}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="toAdmin"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={employees}
                    value={watchAll.toAdmin}
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                    getOptionSelected={(option, value) => option.email === value.email}
                    getOptionLabel={(option) => option.email}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.email}
                      </React.Fragment>
                    )}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="To Admin" />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Option</FormLabel>
                <Controller
                  control={control}
                  name="option"
                  render={({ field }) => (
                    <RadioGroup name="option" row {...field} value={watchAll.option}>
                      <FormControlLabel value="1" control={<Radio />} label="All Products" />
                      <FormControlLabel value="0" control={<Radio />} label="Selected Products" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

            {watchAll.option === '0' && (
              <Grid item xs={12}>
                <Controller
                  name="products"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={products}
                      value={watchAll.products}
                      onChange={(event, value) => {
                        field.onChange(value);
                      }}
                      getOptionSelected={(option, value) => option._id === value._id}
                      getOptionLabel={(option) => option.name + '(' + option.upc + ')'}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name + '(' + option.upc + ')'}
                        </React.Fragment>
                      )}
                      fullWidth
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Select Products" />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>

          <Button variant="contained" color="primary" type="submit" className="mt-3">
            Submit
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

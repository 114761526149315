import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import BrandDiv from './BrandDiv';

import { Modal } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 200,
    maxWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ProductPositionModal(props) {
  const classes = useStyles();

  const { rowData, showAdmin, data, brand, setBrand, handleSubmit, referTo, ...rest } = props;

  const result = brand.map((prod, i) => {
    const url = `${process.env.REACT_APP_SERVER_API}/pictures/${prod.id.productPictures[0]}/${referTo}`;
    return (
      <div className="product-card" key={i}>
        <img className="card-img" src={url} alt=""></img>
        <div className="card-title card-twoline">{prod.id.name}</div>
      </div>
    );
  });

  return (
    <Modal className="modal-w" {...rest} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Arrange Your Products Position</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container>
          <h5 style={{ textAlign: 'center' }}>
            Drag and drop the list on the left to arrang your product
          </h5>
          <div className="d-flex">
            <DndProvider backend={HTML5Backend}>
              <BrandDiv data={brand} setData={setBrand} />
            </DndProvider>
            <div className="homeProduct">
              <div className="home-products">{result}</div>
            </div>
          </div>

          <Button
            fullWidth
            onClick={handleSubmit}
            // disabled={ssid == ''}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import CreateBrand from './createBrand';
import Swal from 'sweetalert2';
import request from '../../../services/Request';
import { userLimits } from '../uitls/userLimits';
import { imageOnError } from '../../../services/method/methods';

const getBrandsURL = `/brands/`;
const deleteBrandURL = `/brands/delete/`;

export default function Brand(props) {
  const { _id, tableTitle, superAdmin, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Brands',
    'edit_brand'
  );

  const user = JSON.parse(localStorage.getItem('user'));

  const [reflesh, setReflesh] = useState(false);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const [show, setShow] = useState(false);
  const [id, setID] = useState('');
  const [name, setName] = useState('');
  const [picture, setPicture] = useState('');
  const [logo, setLogo] = useState('');

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_brand') ? localStorage.getItem('table_view_brand') : 5
  );

  useEffect(() => {
    localStorage.setItem('table_view_brand', pagination);
  }, [pagination]);

  useEffect(() => {
    let isCancelled = false;

    async function getMyBrands() {
      const url = !props.match.params.group && superAdmin ? getBrandsURL : getBrandsURL + _id;
      await request({
        method: 'get',
        url: url,
      }).then((res) => {
        if (!isCancelled) {
          console.log(res.data);
          setBrands(res.data);
          setLoading(true);
        }
      });
    }
    getMyBrands();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  const deleteBrand = async (id) => {
    setLoading(false);
    await request({
      method: 'delete',
      url: deleteBrandURL + id,
    }).then(() => {
      setLoading(true);
    });
    reflesh ? setReflesh(false) : setReflesh(true);
  };

  const columns = [
    {
      title: 'Picture',
      field: 'picture',
      render: (rowData) =>
        rowData.picture != '' ? (
          <img
            src={`${process.env.REACT_APP_SERVER_API}/pictures/${rowData.picture}/${_id}`}
            style={{ maxWidth: 200 }}
            onError={imageOnError}
          />
        ) : (
          'No image'
        ),
    },

    { title: 'Brand Name', field: 'name.en' },
  ];

  if (user?.theme === '1') {
    columns.unshift({
      title: 'Logo',
      field: 'logo',
      render: (rowData) =>
        rowData.logo != '' ? (
          <img
            src={`${process.env.REACT_APP_SERVER_API}/pictures/${rowData.logo}/${_id}`}
            style={{ maxWidth: 200 }}
            onError={imageOnError}
          />
        ) : (
          'No image'
        ),
    });
  }
  return (
    <div>
      {!loading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        {/* <div className="brand-first-div">ser</div> */}
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={brands}
          title={tableTitle}
          editable={
            access && {
              onRowDelete: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    deleteBrand(newData._id);
                    Swal.fire({
                      text: `A brand has been successfully deleted: ${newData.name.en}`,
                      icon: 'success',
                    });
                    resolve();
                  }, 1000);
                }),
            }
          }
          actions={[
            access && {
              icon: 'add',
              tooltip: 'Add Brand',
              isFreeAction: true,
              onClick: (event) => {
                setUpdate(false);
                setShow(true);
              },
            },
            access && {
              icon: 'edit',
              tooltip: 'Edit Brand',
              onClick: (event, rowData) => {
                setID(rowData._id);
                setName(rowData.name);
                setPicture(rowData.picture);
                setUpdate(true);
                setShow(true);
                setLogo(rowData.logo);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        <BackButton />
        <CreateBrand
          referTo={_id}
          id={id}
          show={show}
          name={name}
          reflesh={reflesh}
          setReflesh={setReflesh}
          update={update}
          picture={picture}
          logo={logo}
          onHide={() => setShow(false)}
        />
      </div>
    </div>
  );
}

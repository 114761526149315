import React from 'react';
import { TextField } from '../../items/TextField';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import request from '../../../services/Request';
import { Modal, Button } from 'react-bootstrap';
import '../../items/formFields.css';
import Swal from 'sweetalert2';

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

export default function SmtpConfiguration({ show, onHide }) {
  const [smtpUser, setSmtpUser] = useState('');
  const [smtpPassword, setSmtpPassword] = useState('');
  const [smtpPort, setSmtpPort] = useState('');
  const [smtpHost, setSmtpHost] = useState('');
  const history = useHistory();

  const dashboard = async () => {
    history.push('/dashboard');
  };

  useEffect(() => {
    request({
      method: 'get',
      url: `/email/get-server-config-data`,
    }).then((emailConfig) => {
      setSmtpHost(emailConfig.data.host);
      setSmtpPassword(emailConfig.data.password);
      setSmtpPort(emailConfig.data.port);
      setSmtpUser(emailConfig.data.user);
    });
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        dashboard();
      }}
      centered
      scrollable
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Configure SMTP Server</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Formik
          enableReinitialize
          initialValues={{
            user: smtpUser,
            password: smtpPassword,
            port: smtpPort,
            host: smtpHost,
          }}
          validationSchema={Yup.object({
            host: Yup.string().required(),
            password: Yup.string().required(),
            port: Yup.number().required(),
            user: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            let data = new FormData();
            setSubmitting(true);
            if (
              smtpHost === values.host &&
              smtpPassword === values.password &&
              smtpPort === values.port &&
              smtpUser === values.user
            ) {
              Swal.fire({
                text: `No changes have been applied!`,
                icon: 'error',
              });
            } else {
              if (smtpHost !== values.host) data.append('host', values.host);
              if (smtpPassword !== values.password) data.append('password', values.password);
              if (smtpPort !== values.port) data.append('port', values.port);
              if (smtpUser !== values.user) data.append('user', values.user);
              request({
                method: 'post',
                url: `/email/update-server-config-data`,
                data: data,
              }).then((res) => {
                setSubmitting(false);
                Swal.fire({
                  text: 'Server configuration has been successfully updated!',
                  icon: 'success',
                });
              });
            }
          }}
        >
          {(formik) => (
            <div>
              <Form>
                <TextField label="User" name="user" type="email" as="input" />
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Type a new password"
                  as="input"
                />
                <TextField label="Port" name="port" type="number" as="input" />
                <TextField label="Host" name="host" type="text" as="input" />
                <div className="submit_btn">
                  <Button type="submit" className="btn-success">
                    {formik.isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Swal from 'sweetalert2';

import { Modal, Row, Col } from 'react-bootstrap';

import request from '../services/Request';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 200,
    maxWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PlanoModal(props) {
  const classes = useStyles();

  const getPlanoByEmailURL = `/plano/getPlanoByEmail/`;
  const updateMachineURL = `/machines/updatePlano`;
  const { rowData, showAdmin, ...rest } = props;

  const [planos, setPlanos] = useState([]);
  const [planoid, setplanoid] = useState('');
  const [planoErr, setPlanoErr] = useState('');
  const [subplano, setsubplano] = useState([]);
  const [dis, setdis] = useState(true);

  useEffect(() => {
    if (planoid != '') {
      setsubplano(
        rowData && rowData.length > 0 ? planos.filter((e) => e.referPlano == rowData[0].plano) : []
      );
    } else {
      setsubplano([]);
    }
  }, [planos]);

  useEffect(() => {
    let isCancelled = false;
    const belongTo =
      rowData && rowData.length > 0 ? (rowData[0].belongTo ? rowData[0].belongTo : '') : '';
    if (belongTo != '') {
      setplanoid(rowData && rowData.length > 0 ? (rowData[0].plano ? rowData[0].plano : '') : '');
      setPlanoErr('');
      //getAllEmployees
      async function getPlanos() {
        request({
          method: 'get',
          url: getPlanoByEmailURL + belongTo,
        }).then((res) => {
          if (!isCancelled) {
            setPlanos(res.data);
            let go = true;
            res.data.forEach((m) => {
              const mymodel =
                m.model.indexOf('(') > -1 ? m.model.slice(0, m.model.indexOf('(')).trim() : m.model;
              // console.log('rowData[0].model', rowData[0].model);
              // console.log('mymodel', mymodel);
              if (mymodel == rowData[0].model) {
                go = false;
              }
            });
            setdis(go);
          }
        });
      }

      getPlanos();

      return () => {
        setplanoid('');
        setPlanoErr('');
        setPlanos([]);
        setsubplano([]);
        setdis(true);
        isCancelled = true;
      };
    }
  }, [rowData]);

  const onSubmit = async (data) => {
    console.log('rowData', rowData);
    // console.log('planos', planos);
    // console.log(planoid);
    // console.log(planos[index].model);
    if (showAdmin) {
      request({
        method: 'patch',
        url: updateMachineURL,
        data: {
          plano: planoid,
          machineID: rowData.map((e) => e._id),
          model: rowData[0].model,
        },
      })
        .then((res) => {
          setPlanoErr('');
          Swal.fire({
            text: `The planogram has been selected`,
            icon: 'success',
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 406) {
              setPlanoErr(err.response.data.message);
            }
          }
        });
    } else {
      Swal.fire({
        text: `You have no permission to change the planogram`,
        icon: 'error',
      });
    }
  };

  const handleChange = async (event) => {
    setPlanoErr('');
    setplanoid(event.target.value);
    const subp = planos.filter((e) => e.referPlano == event.target.value);
    setsubplano([...subp]);
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Select Your Planogram</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Row>
          <Col xs={7}>
            {dis ? (
              <FormControl className={classes.formControl}>
                <InputLabel id="select-plano">No Available Planograms</InputLabel>
              </FormControl>
            ) : (
              <FormControl className={classes.formControl}>
                <InputLabel id="select-plano">Select Planogram</InputLabel>
                <Select
                  fullWidth
                  labelId="select-plano"
                  id="select-plano"
                  value={planoid}
                  disabled={dis}
                  onChange={handleChange}
                >
                  {planos.map((m) => {
                    const mymodel =
                      m.model.indexOf('(') > -1
                        ? m.model.slice(0, m.model.indexOf('(')).trim()
                        : m.model;

                    // const mymodel = m.model.slice(0, m.model.indexOf('(')).trim();
                    if (m.referPlano == null && mymodel == rowData[0].model) {
                      return (
                        <MenuItem key={m._id} value={m._id}>
                          {m.name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            )}

            {planoErr != '' && <p style={{ color: 'red' }}>{planoErr}</p>}
          </Col>
          <Col xs={5}>
            <Button
              disabled={planoid == ''}
              style={{ marginTop: '12px' }}
              onClick={() => {
                if (rowData[0].model.includes('Gamma')) {
                  window.open(`/TCNplano/${planoid}/${rowData[0].belongTo}`, 'planogram');
                } else if (rowData[0].model.includes('Inspire')) {
                  window.open(`/INSplano/${planoid}/${rowData[0].belongTo}`, 'planogram');
                } else if (rowData[0].model.includes('Delta')) {
                  window.open(`/DELplano/${planoid}/${rowData[0].belongTo}`, 'planogram');
                } else {
                  window.open(`/plano/${planoid}/${rowData[0].belongTo}`, 'planogram');
                }
              }}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Show Planogram
            </Button>
          </Col>
        </Row>
        {subplano &&
          subplano.map((e) => {
            return (
              <Row>
                <Col xs={7}>
                  <p style={{ marginTop: '20px', fontSize: '1rem' }}>{e.name}</p>
                </Col>
                <Col xs={5}>
                  <Button
                    style={{ marginTop: '12px' }}
                    onClick={() => {
                      if (rowData[0].model.includes('Gamma')) {
                        window.open(`/TCNplano/${e._id}/${rowData[0].belongTo}`, 'planogram');
                      } else if (rowData[0].model.includes('Inspire')) {
                        window.open(`/INSplano/${planoid}/${rowData[0].belongTo}`, 'planogram');
                      } else if (rowData[0].model.includes('Delta')) {
                        window.open(`/DELplano/${planoid}/${rowData[0].belongTo}`, 'planogram');
                      } else {
                        window.open(`/plano/${e._id}/${rowData[0].belongTo}`, 'planogram');
                      }
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Show Planogram
                  </Button>
                </Col>
              </Row>
            );
          })}
        <Container component="main">
          <CssBaseline />
        </Container>
        <Button
          fullWidth
          onClick={onSubmit}
          disabled={planoid == ''}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Select
        </Button>
      </Modal.Body>
    </Modal>
  );
}

// material
import { Grid, Container } from '@mui/material';
// components
// import Page from './Page';
import Average from './Cards/Average';
import AverageRev from './Cards/AverageRevenueTrans';
import TransUnit from './Cards/AveUnitTrans';
import TotalRev from './Cards/TotalRev';
import TotalTrans from './Cards/TotalTrans';
import UnitSold from './Cards/UnitSold';
import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext';
import MachineSelect from './MachineSelect';
import React, { useEffect, useState } from 'react';
import request from '../../../services/Request';
import ReportTable from './ReportTable';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import ProdTable from './ProdTable';
import TopProduct from './TopProduct';
import NotSold from './NotSold';
import { useWindowDimension } from '../../../services/method/useWindowsDimension';

// ----------------------------------------------------------------------
import { userLimits } from '../uitls/userLimits';
import { localDateRange } from '../../../services/method/methods';
import MaSaleTable from './MaSaleTable';
import DateArrayTable from './OrderDateTable';
import GroupsSalesChart from './GroupsSalesChart';

const getGroupsURL = `/groupsAndMachinesForReport/`;
const getAllOrder = `/allOrders/`;
const getOrderByEmail = `/orders`;

export default function Report(props) {
  var date = new Date();
  const [width, height] = useWindowDimension();
  const [reflesh, setReflesh] = useState(false);
  const [machines, setMachines] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [radio, setRadio] = useState('a');
  const [loading, setLoading] = useState(true);

  const [order, setOrder] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [rev, setRev] = useState(0);
  const [done, setdone] = useState(false);
  const [trans, setTrans] = useState(0);
  const [unit, setUnit] = useState(0);
  const [soldProd, setSoldProd] = useState([]);
  const [prodInfo, setProdInfo] = useState(null);
  const [mcSale, setMcSale] = useState([]);
  const [dateArray, setDateArray] = useState(0);
  const [machinesName, setmachinesName] = useState('All Machines');

  const [formatGroup, setFormatGroup] = useState([]);

  const [profit, setProfit] = useState(0);

  const { userEmail, setFiltered, filterd } = useContext(UserContext);
  const { id, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'view_report'
  );

  useEffect(() => {
    let isCancelled = false;

    async function getGroups() {
      await request({
        method: 'get',
        url: !props.match.params.group && superAdmin ? getGroupsURL : getGroupsURL + userEmail,
      }).then((res) => {
        res.data.machines.forEach((element) => {
          element.product?.forEach((e) => {
            if (e?.id) {
              e.id.name = e?.id?.name?.en;
            }
          });
        });
        if (!isCancelled) {
          console.log('groups', res.data.groups);
          setGroups(res.data.groups);
          setMachines(res.data.machines);
          setFiltered(res.data.machines);
        }
      });
    }
    getGroups();

    return () => {
      setMachines([]);
      setGroups([]);
      setLoading(false);
      isCancelled = true;
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleChange = async (event) => {
    let filteredArray = [];
    setLoading(false);
    let myIDs = 0;
    if (radio == 'a') {
      setFiltered(machines);
      myIDs = machines.map((e) => e._id).join(',');
      setmachinesName('All Machines');
      filteredArray = [...machines];
    }
    if (radio == 'g') {
      const myArray = groupV.map((e) => e.machineID);
      var merged = [].concat.apply([], myArray);
      merged = merged.filter((e, i, self) => i === self.findIndex((t) => t._id == e._id));
      // const filteredArray = machines.filter((e, i) => i == merged.findIndex((t)=>(t._id == e._id)));
      machines.forEach((e) => {
        merged.findIndex((t) => {
          if (t._id == e._id) {
            filteredArray.push(e);
          }
        });
      });
      setFiltered(filteredArray);
      myIDs = filteredArray.map((e) => e._id).join(',');
      setmachinesName(`Groups: ${groupV.map((e) => e.name).join(', ')} `);
    }
    if (radio == 'm') {
      setFiltered(groupV);
      filteredArray = [...groupV];
      myIDs = groupV.map((e) => e._id).join(',');
      setmachinesName(`Machines: ${groupV.map((e) => e.name).join(', ')} `);
    }
    if (myIDs.length > 0) {
      const { startDateString, endDateString } = localDateRange(startDate, endDate);
      // console.log('startDateString', startDateString);
      await request({
        method: 'post',
        url:
          !props.match.params.group && superAdmin
            ? getAllOrder + myIDs + '/' + startDateString + '/' + endDateString
            : getOrderByEmail,
        data: {
          start: startDateString,
          end: endDateString,
          ids: myIDs,
          id: id,
        },
      }).then(async (res) => {
        setdone(true);
        setOrder(res.data);
        console.log(res.data);
        setLoading(true);
        let myRev = 0;
        let costTotal = 0;
        let myUnit = 0;
        let prodA = [];
        let totaltrans = 0;
        let machineSales = [];
        let dateArray = [];

        res.data.forEach((e) => {
          let subCostTotal = 0;
          // console.log(e);

          //update sold price for msc
          if (e.referTo === 'adrian.pittaway@msccruises.com') {
            if (e.status === 'Failed') {
              e.status = 'Partial Failed';
            }
            let calTotal = 0;
            e.products?.forEach((pp) => {
              calTotal += pp.soldPrice;
              parseFloat(calTotal.toFixed(2));
            });
            // if (parseFloat(e.total) != parseFloat(calTotal)) {
            //   e.status = 'Partial Failed(' + e.total + ')';
            // }
            e.total = calTotal;
          }

          const beforeTax = e.total - e.tax;
          const taxp = parseFloat(((e.total - beforeTax) / beforeTax).toFixed(2));

          myRev += e.total;
          let allFailedTrans = false;

          let machineInfo = e.machineID?.name ? e.machineID : e.machineInfo;
          e.machineInfo = machineInfo;

          e.products.forEach((prod) => {
            //ignore error Item
            let pdInfo = null;
            if (prod?.product?.name?.en) {
              pdInfo = prod?.product;
              pdInfo.name = prod?.product?.name?.en;
            } else {
              pdInfo = prod?.productInfo;
            }

            // console.log('pdInfo?.name', pdInfo?.name);
            if (pdInfo?.name) {
              prod.productInfo = pdInfo;
              // prod.product.name = prod.product.name.en;
              if (!prod.err) {
                //change
                subCostTotal += pdInfo?.costPrice ? pdInfo?.costPrice : 0;
                // console.log('subCostTotal', subCostTotal);
                allFailedTrans = true;
                myUnit += prod.quantity;
                if (prodA.length === 0) {
                  prodA.push(pdInfo);
                  prodA[0].machine = [];

                  prodA[0].machine.push({
                    name: machineInfo.name,
                    quantity: prod.quantity,
                    soldPrice: prod.soldPrice,
                  });
                  prodA[0].quantity = prod.quantity;
                  prodA[0].upc = pdInfo.upc;
                  prodA[0].costPrice = pdInfo.costPrice;
                  prodA[0].totalSale = prod.soldPrice;
                } else {
                  if (pdInfo) {
                    const index = prodA.findIndex((x) => x.upc === pdInfo.upc);
                    if (index === -1) {
                      const addP = pdInfo;
                      addP.machine = [];
                      addP.machine.push({
                        name: machineInfo.name,
                        quantity: prod.quantity,
                        soldPrice: prod.soldPrice,
                      });
                      addP.upc = pdInfo.upc;
                      addP.quantity = prod.quantity;
                      addP.totalSale = prod.soldPrice;
                      prodA.push(addP);
                    } else {
                      //check the machine object include new machineID
                      const findMachine = prodA[index].machine.findIndex((x) => {
                        // console.log(x.name);
                        // console.log(e.machineID.name);

                        return x.name === machineInfo?.name;
                      });
                      // console.log(findMachine);
                      if (findMachine > -1) {
                        prodA[index].machine[findMachine].quantity += prod.quantity;
                        prodA[index].machine[findMachine].soldPrice += prod.soldPrice;
                      } else {
                        prodA[index].machine.push({
                          name: machineInfo?.name,
                          quantity: prod.quantity,
                          soldPrice: prod.soldPrice,
                        });
                      }
                      prodA[index].upc = pdInfo.upc;
                      prodA[index].quantity += prod.quantity;
                      prodA[index].totalSale =
                        Math.round((prodA[index].totalSale + prod.soldPrice) * 100) / 100;
                    }
                  }
                }
              }
            } else {
              console.log(prod, 'error item');
            }
          });

          //separate machine sales

          const findMCSale = machineSales.findIndex((x) => x.machineID === machineInfo?._id);
          if (findMCSale > -1) {
            if (allFailedTrans) {
              machineSales[findMCSale].totalTrans += 1;
            }
            machineSales[findMCSale].totalSale = parseFloat(
              (machineSales[findMCSale].totalSale + e.total).toFixed(2)
            );
            machineSales[findMCSale].totalTax = parseFloat(
              (machineSales[findMCSale].totalTax + e.tax).toFixed(2)
            );
            machineSales[findMCSale].costTotal = parseFloat(
              (machineSales[findMCSale].costTotal + subCostTotal).toFixed(2)
            );
          } else {
            machineSales.push({
              // machineID: e.machineID._id,
              group: e.machineID.group,
              machineName: machineInfo.name,
              machineDescription: machineInfo.description,
              totalSale: e.total,
              totalTax: e.tax,
              costTotal: subCostTotal,
              totalTrans: allFailedTrans ? 1 : 0,
            });
          }

          costTotal += subCostTotal;

          if (allFailedTrans) {
            totaltrans += 1;
          }

          //group date by date
          const date = e.createdAt?.split('T')[0];
          if (allFailedTrans) {
            const findDate = dateArray.findIndex((x) => x.date === date);
            if (findDate > -1) {
              dateArray[findDate].totalSale += e.total;
              dateArray[findDate].totalTax += e.tax;
              dateArray[findDate].order.push(e);
            }
            if (findDate === -1) {
              dateArray.push({
                date: date,
                totalSale: e.total,
                totalTax: e.tax,
                order: [e],
              });
            }
          }
        });
        setDateArray(dateArray);
        console.log('dateArray', dateArray);
        const myprofit = myRev - costTotal;
        // console.log('myrev', myRev);
        // console.log('costTotal', costTotal);
        machineSales = machineSales.sort((a, b) => (a.totalSale < b.totalSale ? 1 : -1));

        setMcSale(machineSales);
        console.log('machineSales', machineSales);
        //seperate group sales
        let groupSales = [...groups];

        machineSales.forEach((mc) => {
          mc?.group.forEach((gp) => {
            const findIndex = groupSales.findIndex((x) => x._id === gp);
            if (findIndex > -1) {
              if (!groupSales[findIndex].totalSale) groupSales[findIndex].totalSale = 0;
              if (!groupSales[findIndex].totalTax) groupSales[findIndex].totalTax = 0;
              if (!groupSales[findIndex].totalTrans) groupSales[findIndex].totalTrans = 0;

              groupSales[findIndex].totalSale += mc.totalSale;
              groupSales[findIndex].totalTax += mc.totalTax;
              groupSales[findIndex].totalTrans += mc.totalTrans;
            }
          });
        });
        // console.log('groupSales', groupSales);
        setFormatGroup(groupSales);

        setProfit(myprofit);

        // console.log('prodA', prodA);
        setSoldProd(prodA);

        let highest = 0;
        let hprod = null;
        prodA.forEach((element) => {
          if (element.quantity >= highest) {
            highest = element.quantity;
            hprod = element;
          }
        });
        console.log('1111', hprod);
        let p = null;
        if (hprod) {
          await request({
            method: 'get',
            url: '/product/' + 'upc/' + hprod.upc + '/' + id,
          }).then((res) => {
            // console.log(res.data);
            p = res.data;
            //change
            p.name = hprod.name;
          });
        }
        // console.log('22222', filteredArray);
        const myArray = filteredArray.map((e) => e.product);

        var merged = [].concat.apply([], myArray);
        merged = merged.filter(
          (e, i, self) => i === self.findIndex((t) => t.id && e.id && t.id._id == e.id._id)
        );
        merged = merged.filter((elem) => !prodA.find(({ upc }) => elem.id?.upc === upc));

        setProdInfo({ hprod: hprod, p: p, nprod: merged });
        setTrans(totaltrans);
        setRev(myRev);
        setUnit(myUnit);
        setLoading(true);
      });
    } else {
      setLoading(true);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ pt: 5 }}>
      {!loading && <LoadingIcon />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MachineSelect
            filterd={filterd}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            startDate={startDate}
            endDate={endDate}
            setRadio={setRadio}
            setgroupV={setgroupV}
            radio={radio}
            groupV={groupV}
            groups={groups}
            machines={machines}
            handleChange={handleChange}
          />
        </Grid>

        {order.length > 0 && done && (
          <Grid container item spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <TotalRev rev={rev} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TotalTrans trans={trans} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <UnitSold unit={unit} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TotalRev rev={profit} title={'Gross Profit'} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}></Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AverageRev rev={rev} trans={trans} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TransUnit trans={trans} unit={unit} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}></Grid>

            <Grid item xs={12}>
              <ReportTable
                machinesName={machinesName}
                endDate={endDate}
                startDate={startDate}
                order={order}
                id={id}
              />
            </Grid>

            <Grid item xl={8} lg={12}>
              <ProdTable
                soldProd={soldProd}
                prodInfo={prodInfo}
                machinesName={machinesName}
                endDate={endDate}
                startDate={startDate}
                order={order}
              />
            </Grid>
            {width > 1535 ? (
              <Grid container item xl={4} direction="column">
                <Grid item lg={12}>
                  <div style={{ marginBottom: '20px' }}>
                    <TopProduct prodInfo={prodInfo} />{' '}
                  </div>
                  <div>
                    <NotSold prodInfo={prodInfo} />{' '}
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                  <div style={{ marginBottom: '20px' }}>
                    <TopProduct prodInfo={prodInfo} />{' '}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div>
                    <NotSold prodInfo={prodInfo} />{' '}
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <MaSaleTable
                machinesName={machinesName}
                endDate={endDate}
                startDate={startDate}
                mcSale={mcSale}
              />
            </Grid>
            {dateArray?.length > 0 && (
              <Grid item xs={12}>
                <DateArrayTable
                  machinesName={machinesName}
                  endDate={endDate}
                  startDate={startDate}
                  dateArray={dateArray}
                />
              </Grid>
            )}

            {formatGroup?.length > 0 && (
              <Grid item xs={12}>
                <GroupsSalesChart
                  data={formatGroup}
                  machinesName={machinesName}
                  endDate={endDate}
                  startDate={startDate}
                />
              </Grid>
            )}

            {/* <Grid item xs={12} md={6} lg={8}>
                      <Chart1 />
                    </Grid>
          
                    <Grid item xs={12} md={6} lg={4}>
                      <PieChart />
                    </Grid> */}
          </Grid>
        )}

        {order.length === 0 && done && (
          <div className="report-no-data">No Data Found On Your Selected Dates/Machines</div>
        )}
      </Grid>
    </Container>
  );
}

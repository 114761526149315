import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Swal from 'sweetalert2';
import { useDrop } from 'react-dnd';
import TCNLane from './TCNLane';

export default function TCNFloor({
  lineNumber,
  setHeights,
  floor,
  floors,
  prods,
  setFloors,
  height,
  floorNumber,
  heights,
  newPlano,
  plano,
  trigger,
  setTrigger,
  i,
}) {
  //second index 0 is for checking capacity
  const MULTIPLE = 6.5;
  const [myheight, setheight] = useState(0);

  const [board, setBoard] = useState(newPlano ? [] : floors[floor - 1].lines);
  const [totalWidth, setTotalWidth] = useState(0);

  const emptyProduct = {
    productPictures: [
      'https://api.magexusa.com/api/pictures/GlOnLonmt29nREuLYg5VK-empty.jpg/tianlangwu001@gmail.com',
    ],
    name: 'Empty Product',
    referTo: plano.referTo,
    dimension: {
      width: plano.min_width + plano.divider,
      length: plano.min_width + plano.divider,
    },
    empty: true,
    upc: 'None',
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'image',
      drop: (item, monitor) => {
        // const { x, y } = monitor.getSourceClientOffset();
        // const myEle = document.elementFromPoint(x, y);
        // console.log(myEle);
        // if (myEle && myEle.id) {w
        //   console.log(myEle.id);

        // } else {

        if (!item.prod) {
          item.prod = emptyProduct;
        }
        // console.log('drop', item);
        // console.log(item.prod.dimension.length);
        if (totalWidth + item.prod.dimension.length <= plano.width) {
          addImageToBoard(item?.prod);
        } else {
          Swal.fire('', `No enough width on this floor`, 'error');
        }

        // }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [totalWidth]
  );

  const addImageToBoard = (item) => {
    console.log(item);
    setBoard((board) => [
      ...board,
      {
        product: item,
        length: item?.dimension?.length,
        max_stock: Math.trunc(plano?.depth / item?.dimension?.width),
      },
    ]);
  };

  useEffect(() => {
    let myheight;

    if (floors[floor - 1] && floors[floor - 1].lines.length > 0) {
      // setBoard(floors[floor - 1]);
      myheight = floors[floor - 1].px;
    } else {
      floors[floor - 1].px = height * 20 - heights[floorNumber - floor] * 20 - 150;
      floors[floor - 1].maxHeight = 0;
      myheight = height * 20 - heights[floorNumber - floor] * 20 - 150;
    }
    setheight(myheight);
  }, [myheight]);

  useEffect(() => {
    const sum = board.reduce((prev, curr) => {
      return prev + curr.length + plano.divider;
    }, 0);

    setTotalWidth(sum);
    // console.log('sum', sum);
    floors[floor - 1].lines = [...board];
    setTrigger(!trigger);
  }, [board]);

  const errorMessage = (h) => {
    Swal.fire('', `There are products required ${h} floors `, 'error');
  };

  //i dont know why useDrop(in line.js) can not receive updated heights, only can received updated lines, so I push heights value into lines then pass to useDrop

  return (
    <div className="d-flex justify-content-center">
      <div
        id={`floor-${floorNumber - floor}`}
        className="row items-container plano-floor"
        style={{
          top:
            // height*20 - heights[floorNumber-floor]*20 - 150 > height*20 - heights[floorNumber-floor-1]*20 - 150 ?
            `${myheight}px`,
          //  : `${height*20 - heights[floorNumber-floor-1]*20 - 150 + 150}px`wer
        }}
      >
        {board.length > 0 ? (
          board.map((item, index) => {
            return (
              <TCNLane
                floor={floor}
                MULTIPLE={MULTIPLE}
                key={index}
                floors={floors}
                prods={prods}
                item={item.product}
                length={item.length}
                max_stock={item.max_stock}
                index={index}
                board={board}
                setBoard={setBoard}
                totalWidth={totalWidth}
                plano={plano}
              />
            );
          })
        ) : (
          <div
            ref={drop}
            style={{ width: `${parseFloat(plano.width) * MULTIPLE}px`, background: 'lightblue' }}
          ></div>
        )}
        {board.length > 0 && (
          <div
            ref={drop}
            style={{ width: `${(plano.width - totalWidth) * MULTIPLE}px`, background: 'lightblue' }}
          ></div>
        )}

        <div>
          <div style={{ marginTop: '98px' }}>
            <TextField
              label="vertical slot"
              value={heights[floorNumber - floor]}
              onChange={(e) => {
                const plus = e.target.value > heights[floorNumber - floor] ? true : false;
                let go = false;
                const minHeight = 5;
                const prev = floorNumber - floor - 1;
                const next =
                  floorNumber - floor + 1 >= floorNumber - floor + 1
                    ? floorNumber - floor + 1
                    : floorNumber % (floorNumber - floor + 1);
                // const notEmpty = lines.some(e=>e.occupied != 0);
                // if (notEmpty) {
                //   Swal.fire(
                //     '',
                //     `Please remove the products first before adjust the floor.`,
                //     'error'
                //   )
                // }
                //lowest floor
                if (floorNumber - floor == floorNumber - 1) {
                  if (
                    parseFloat(e.target.value) + minHeight <= heights[prev] &&
                    parseFloat(e.target.value) >= 0
                  ) {
                    go = true;
                  }
                  if (
                    heights[floorNumber - floor - 1] - parseFloat(e.target.value) <
                    floors[floor - 1].maxHeight
                  ) {
                    go = false;
                    errorMessage(floors[floor - 1].maxHeight);
                  }
                } else if (floorNumber - floor == 0) {
                  // console.log(heights[floorNumber-floor] - heights[floorNumber-floor+1])
                  // console.log(floors[floorNumber-floor+1])\
                  if (
                    parseFloat(e.target.value) + minHeight <= height &&
                    parseFloat(e.target.value) - minHeight >= heights[next]
                  ) {
                    go = true;
                  }
                  if (
                    parseFloat(e.target.value) - heights[floorNumber - floor + 1] <
                    floors[floor - 2].maxHeight
                  ) {
                    go = false;
                    errorMessage(floors[floor - 2].maxHeight);
                  }
                  if (height - parseFloat(e.target.value) < floors[floor - 1].maxHeight) {
                    go = false;
                    errorMessage(floors[floor - 1].maxHeight);
                  }
                } else {
                  if (
                    parseFloat(e.target.value) - minHeight >= heights[next] &&
                    parseFloat(e.target.value) + minHeight <= heights[prev]
                  ) {
                    go = true;
                  }
                  if (
                    parseFloat(e.target.value) - heights[floorNumber - floor + 1] <
                    floors[floor - 2].maxHeight
                  ) {
                    go = false;
                    errorMessage(floors[floor - 2].maxHeight);
                  }
                  if (
                    heights[floorNumber - floor - 1] - parseFloat(e.target.value) <
                    floors[floor - 1].maxHeight
                  ) {
                    go = false;
                    errorMessage(floors[floor - 1].maxHeight);
                  }
                }
                if (go) {
                  // console.log(heights)
                  // console.log(floor)
                  // const myh = [...heights];
                  // myh[floorNumber-floor] = parseFloat(e.target.value);

                  // setHeights([...myh])
                  heights[floorNumber - floor] = parseInt(e.target.value);
                  // console.log(heights)
                  setHeights([...heights]);
                  const mydiv = document.getElementById(`floor-${floorNumber - floor}`);
                  const nextDiv = document.getElementById(`floor-${next}`);
                  const preDiv = document.getElementById(`floor-${prev}`);
                  const thispx = parseFloat(
                    mydiv.style.top.substring(0, mydiv.style.top.length - 2)
                  );
                  // console.log(thispx)
                  // console.log(next)
                  // console.log(height*20 - heights[floorNumber-floor]*20 - 150)
                  //lowest floor
                  if (floorNumber - floor == floorNumber - 1) {
                    // const prevpx = parseFloat(preDiv.style.top.substring(0, preDiv.style.top.length - 2));
                    const prevpx = floors[floorNumber - 1 - prev].px;
                    if (
                      height * 20 - heights[floorNumber - floor] * 20 - 150 - 160 >= prevpx &&
                      parseFloat(e.target.value) >= 0
                    ) {
                      // mydiv.style.top = `${height*20 - heights[floorNumber-floor]*20 - 150}px`;
                      floors[floor - 1].px = height * 20 - heights[floorNumber - floor] * 20 - 150;
                      setheight(height * 20 - heights[floorNumber - floor] * 20 - 150);
                      // setFloors([...floors])
                    } else {
                      if (heights[floorNumber - floor] + 8 >= heights[floorNumber - floor - 1]) {
                        floors[floor - 1].px = prevpx + 160;
                        setheight(prevpx + 160);
                      }
                    }
                  } else if (floorNumber - floor == 0) {
                    const nextPx = floors[floorNumber - 1 - next].px;
                    if (
                      height * 20 - heights[floorNumber - floor] * 20 - 150 + 160 <= nextPx &&
                      parseFloat(e.target.value) - minHeight >= heights[next]
                    ) {
                      // mydiv.style.top = `${height*20 - heights[floorNumber-floor]*20 - 150}px`;
                      floors[floor - 1].px = height * 20 - heights[floorNumber - floor] * 20 - 150;
                      setheight(height * 20 - heights[floorNumber - floor] * 20 - 150);
                      // setFloors([...floors])
                    } else {
                      if (heights[floorNumber - floor] - 8 <= heights[floorNumber - floor + 1]) {
                        setheight(nextPx - 160);
                        floors[floor - 1].px = nextPx - 160;
                        // console.log(nextPx - 160)
                        // console.log(floors)
                      }
                    }
                  } else {
                    const prevpx = floors[floorNumber - 1 - prev].px;
                    const nextPx = floors[floorNumber - 1 - next].px;
                    // if (height*20 - heights[floorNumber-floor]*20 - 150 + 160 <= nextPx) console.log('yes1')
                    // if (height*20 - heights[floorNumber-floor]*20 - 150 - 160 >= prevpx) console.log('yes2')
                    if (
                      height * 20 - heights[floorNumber - floor] * 20 - 150 + 160 <= nextPx &&
                      height * 20 - heights[floorNumber - floor] * 20 - 150 - 160 >= prevpx
                    ) {
                      // mydiv.style.top = `${height*20 - heights[floorNumber-floor]*20}px`;
                      floors[floor - 1].px = height * 20 - heights[floorNumber - floor] * 20 - 150;
                      setheight(height * 20 - heights[floorNumber - floor] * 20 - 150);
                    } else {
                      if (heights[floorNumber - floor] + 8 >= heights[floorNumber - floor - 1]) {
                        floors[floor - 1].px = prevpx + 160;
                        setheight(prevpx + 160);
                      }
                      if (heights[floorNumber - floor] - 8 <= heights[floorNumber - floor + 1]) {
                        floors[floor - 1].px = nextPx - 160;
                        setheight(nextPx - 160);
                      }
                      // else floors[floor-1].px = nextPx - 160;
                    }
                  }
                }
              }}
              style={{ width: 95, marginLeft: '10px', zIndex: '10000' }}
              variant="outlined"
              type="number"
              inputProps={{
                min: 0,
                max: height - 5,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

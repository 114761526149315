import React, { useState, useEffect } from 'react';
import request from '../services/Request';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import LoadingIcon from '../frontend/items/buttons/LoadingIcon';
import BackButton from '../frontend/items/buttons/BackButton';
import ComputerIcon from '@material-ui/icons/Computer';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Swal from 'sweetalert2';
import { userLimits } from '../frontend/dashboard/uitls/userLimits';
import DownloadIcon from '@mui/icons-material/Download';
import { ExportToCsv } from 'export-to-csv';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const getPlanoByEmailURL = `/plano/getPlanoByEmail/`;
const CreatePlanoURL = `/plano/create`;
const CreateTCNPlanoURL = `/TCNplano/create`;
const DeletePlanoURL = `/plano/`;
const DeleteTCNPlanoURL = `/TCNplano/`;
const UpdateNameURL = `/plano/name/`;
const getMyMachinesURL = `/machines/getMyMachines/`;

export default function ViewMyPlano(props) {
  const { id, tableTitle, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Planograms',
    'edit_plano'
  );

  const MagexModel = [
    {
      name: 'Colosseo',
      lane: 18,
      floor: 6,
      laneLength: 7.8,
      height: 85,
      slot_height: 2.54,
      step_depth: 2.4,
      step_num: 16,
    },
    {
      name: 'Big Store',
      lane: 14,
      floor: 6,
      laneLength: 7.8,
      height: 85,
      slot_height: 2.54,
      step_depth: 2.4,
      step_num: 19,
    },
    {
      name: 'Easy 14',
      lane: 14,
      floor: 6,
      laneLength: 7.8,
      height: 85,
      slot_height: 2.54,
      step_depth: 2.4,
      step_num: 16,
    },
    {
      name: 'Easy 9',
      lane: 9,
      floor: 6,
      laneLength: 7.8,
      height: 85,
      slot_height: 2.54,
      step_depth: 2.4,
      step_num: 16,
    },
    {
      name: 'Arena 9',
      lane: 9,
      floor: 6,
      laneLength: 7.8,
      height: 85,
      slot_height: 2.54,
      step_depth: 2.4,
      step_num: 19,
    },
    {
      name: 'Idea 9',
      lane: 9,
      floor: 6,
      laneLength: 7.8,
      height: 85,
      slot_height: 2.54,
      step_depth: 2.4,
      step_num: 16,
    },
    {
      name: 'Idea 6',
      lane: 6,
      floor: 6,
      laneLength: 7.8,
      height: 85,
      slot_height: 2.54,
      step_depth: 2.4,
      step_num: 16,
    },
  ];

  const theModel = [
    {
      name: 'Gamma 13',
      floor: 7,
      laneLength: 7.8,
      height: 85,
      slot_height: 2.54,
      step_depth: 2.4,
      step_num: 16,
      width: 158,
      depth: 53,
      divider: 1.7,
      min_width: 3,
      min_height: 13.5,
    },
    {
      name: 'Gamma 10',
      floor: 7,
      laneLength: 7.8,
      height: 85,
      slot_height: 2.54,
      step_depth: 2.4,
      step_num: 16,
      width: 158,
      depth: 53,
      divider: 1.7,
      min_width: 3,
      min_height: 13.5,
    },
    {
      name: 'Inspire 10',
      lane: 10,
      floor: 7,
      laneLength: 7.8,
      height: 114,
      width: 101,
      depth: 51,
      divider: 1.7,
      min_width: 3,
      min_height: 13.5,
    },
    {
      name: 'Delta 7',
      lane: 7,
      floor: 7,
      laneLength: 7.8,
      height: 121,
      width: 69,
      depth: 44,
      divider: 1.7,
      min_width: 3,
      min_height: 13.5,
    },
  ];

  const [planos, setPlanos] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [reflesh, setReflesh] = useState(false);
  const [machines, setMachines] = useState([]);
  const [forRender, setForRender] = useState(0);

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_plano') ? localStorage.getItem('table_view_plano') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_plano', pagination);
  }, [pagination]);

  const functionToRun = (e) => {
    e.preventDefault();
    reflesh ? setReflesh(false) : setReflesh(true);
    return;
  };

  useEffect(() => {
    window.addEventListener('storage', functionToRun);
    return () => {
      window.removeEventListener('storage', functionToRun);
    };
  });

  useEffect(() => {
    let isCancelled = false;

    //getAllEmployees
    async function getPlanos() {
      request({
        method: 'get',
        url: getPlanoByEmailURL + id,
      }).then((res) => {
        if (!isCancelled) {
          // console.log('planos', res.data);
          setPlanos(res.data);
          setIsLoading(true);
        }
      });
    }

    async function getMyMachines() {
      request({
        method: 'get',
        url: getMyMachinesURL + id,
      }).then((res) => {
        if (!isCancelled) {
          setMachines(res.data);
          // console.log('machines', res.data);
          setIsLoading(true);
        }
      });
    }

    getPlanos();
    getMyMachines();

    return () => {
      isCancelled = true;
    };
  }, [isloading, reflesh, forRender]);

  const deletePlano = async (rowData, referTo) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(false);
        if (
          rowData.model.includes('Gamma') ||
          rowData.model.includes('Inspire') ||
          rowData.model.includes('Delta')
        ) {
          request({
            method: 'delete',
            url: DeleteTCNPlanoURL + rowData._id,
          }).then(() => {
            Swal.fire('', `Planogram ${rowData.name} has been deleted.`, 'success');
            setIsLoading(true);
          });
        } else {
          request({
            method: 'delete',
            url: DeletePlanoURL + rowData._id,
          }).then(() => {
            Swal.fire('', `Planogram ${rowData.name} has been deleted.`, 'success');
            setIsLoading(true);
          });
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire('Cancelled', '', 'success');
      }
    });
  };

  const duplicatePlano = (rowData) => {
    if (rowData.stock === 1 && rowData.referPlano === null) {
      setIsLoading(false);
      if (
        rowData.model.includes('Gamma') ||
        rowData.model.includes('Inspire') ||
        rowData.model.includes('Delta')
      ) {
        request({
          method: 'post',
          url: '/plano/duplicateTCN/' + rowData?._id,
        })
          .then(() => {
            Swal.fire('Success', 'Successfully dumplicated the planogram', 'success');
            setIsLoading(true);
          })
          .catch(() => {
            Swal.fire('Fail', 'Fail to dumplicated the planogram', 'fail');
            setIsLoading(true);
          });
      } else {
        request({
          method: 'post',
          url: '/plano/duplicate/' + rowData?._id,
        })
          .then(() => {
            Swal.fire('Success', 'Successfully dumplicated the planogram', 'success');
            setIsLoading(true);
          })
          .catch(() => {
            Swal.fire('Fail', 'Fail to dumplicated the planogram', 'fail');
            setIsLoading(true);
          });
      }
    } else {
      Swal.fire('Fail', 'Planogram of Multi stocks are not supported', 'fail');
      setIsLoading(true);
    }
  };

  const downloadProductCsv = async (data) => {
    const items = data?.prods;
    let filteredItems = [];
    console.log('items', items);
    try {
      items.forEach((e) => {
        const item = {};
        item.upc = e.id?.upc;
        item.name = e.id?.name?.en;
        item.brand = e.id?.brand?.name?.en ? e.id?.brand?.name?.en : '';
        item.price = e.id?.price;
        if (e.pos) {
          const array = e.pos.split(' ');
          const indexF = array[0].indexOf('F');
          const row = parseInt(array[0].substring(0, indexF));
          const indexL = array[1].indexOf('L');
          const lane = parseInt(array[1].substring(0, indexL));
          item.floor = row;
          item.lane = lane;
        } else {
          item.slot = e.idx;
        }

        // const indexW = array[2].indexOf('W');
        // const width = parseInt(array[2].substring(0, indexW));

        // item.stock = 1;

        item.maxStock = e.max_stock;

        if (item.name) {
          filteredItems.push(item);
        }
      });
      filteredItems = filteredItems.sort((a, b) => {
        if (a.floor > b.floor) {
          return 1;
        }
        if (a.floor < b.floor) {
          return -1;
        } else {
          return a.lane - b.lane;
        }
      });
    } catch (e) {
      console.log(e);
    }

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Planogram CSV',
      useTextFile: false,
      filename: 'Planogram CSV',
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(filteredItems);
  };
  const lookup = [
    ...new Set(
      machines.map((e, i) => {
        if (e.model.includes('Gamma') || e.model.includes('Inspire') || e.model.includes('Delta')) {
          return e.model;
        } else {
          if (e.stock == 1)
            return `${e.model} (${e.lane.toString()} Lanes, ${e.floor.toString()} Floors)`;
          else {
            if (e.modelNum) {
              return `${e.model} (${e.modelNum} Stocks)` + ` + ${e.model1} (${e.modelNum1} Stocks)`;
            } else {
              return `${e.model} (${e.lane.toString()} Lanes, ${e.floor.toString()} Floors * ${
                e.stock
              } Stocks)`;
            }
          }
        }
      })
    ),
  ];

  const columns = [
    { title: 'Planogram Name', field: 'name' },
    {
      title: 'Model',
      field: 'model',
      lookup: lookup,
      render: (rowData) => `${rowData.model}`,
      editable: 'onAdd',
    },
    {
      title: 'Number of Lanes',
      field: 'lineNumber',
      editable: 'never',
      type: 'numeric',
      render: (rowData) => {
        if (
          rowData.model.includes('Gamma') ||
          rowData.model.includes('Inspire') ||
          rowData.model.includes('Delta')
        ) {
          return '';
        } else {
          return rowData.lineNumber;
        }
      },
    },
    { title: 'Number of Floors', field: 'floorNumber', type: 'numeric' },
  ];

  return (
    <div>
      {!isloading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={planos}
          title={tableTitle}
          parentChildData={(row, rows) => rows.find((a) => a._id === row.referPlano)}
          icons={{
            Add: () => (
              <Button variant="contained" color="primary">
                Create Planogram
              </Button>
            ),
          }}
          localization={{
            body: { editRow: { deleteText: 'Are you sure to delete this Planogram' } },
          }}
          editable={
            access && {
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  const index = lookup[newData.model].indexOf('(');
                  // console.log('lookup[newData]', newData);
                  const modelName =
                    index > -1
                      ? lookup[newData.model].substring(0, index).trim()
                      : lookup[newData.model];

                  let mIndex = -1;
                  if (lookup[newData.model].includes('+')) {
                    mIndex = machines.findIndex(
                      (e) =>
                        `${e.model} (${e.modelNum} Stocks)` +
                          ` + ${e.model1} (${e.modelNum1} Stocks)` ===
                        lookup[newData.model]
                    );
                  } else {
                    if (
                      modelName.includes('Gamma') ||
                      modelName.includes('Inspire') ||
                      modelName.includes('Delta')
                    ) {
                      mIndex = machines.findIndex((e) => e.model == modelName);
                    } else {
                      const floor = lookup[newData.model].split(',')[1][1];
                      mIndex = machines.findIndex((e) => e.model == modelName && e.floor == floor);
                    }
                  }

                  console.log('mIndex', mIndex);
                  console.log('modelName', modelName);
                  setTimeout(async () => {
                    setIsLoading(false);

                    if (
                      modelName.includes('Gamma') ||
                      modelName.includes('Inspire') ||
                      modelName.includes('Delta')
                    ) {
                      const findIndex = theModel.findIndex((e) => e.name === lookup[newData.model]);
                      if (findIndex > -1) {
                        await request({
                          method: 'post',
                          url: CreateTCNPlanoURL,
                          data: {
                            name: newData.name,
                            lineNumber: machines[mIndex].lane,
                            floorNumber: newData.floorNumber,
                            referTo: id,
                            model: lookup[newData.model],
                            laneLength: machines[mIndex].laneLength,
                            slot_height: machines[mIndex].slot_height,
                            step_depth: machines[mIndex].step_depth,
                            step_num: machines[mIndex].step_num,
                            height: theModel[findIndex].height,
                            width: theModel[findIndex].width,
                            depth: theModel[findIndex].depth,
                            divider: theModel[findIndex].divider,
                            min_width: theModel[findIndex].min_width,
                            min_height: theModel[findIndex].min_height,
                          },
                        }).then((res) => {
                          setIsLoading(true);
                        });
                      }
                    } else {
                      if (!lookup[newData.model].includes(' Stocks')) {
                        await request({
                          method: 'post',
                          url: CreatePlanoURL,
                          data: {
                            name: newData.name,
                            lineNumber: machines[mIndex].lane,
                            floorNumber: machines[mIndex].floor,
                            referTo: id,
                            model: lookup[newData.model],
                            laneLength: machines[mIndex].laneLength,
                            slot_height: machines[mIndex].slot_height,
                            step_depth: machines[mIndex].step_depth,
                            step_num: machines[mIndex].step_num,
                            height: machines[mIndex].height,
                          },
                        }).then((res) => {
                          setIsLoading(true);
                        });
                      } else {
                        console.log('in1');
                        setIsLoading(false);
                        await request({
                          method: 'post',
                          url: CreatePlanoURL,
                          data: {
                            name: `${newData.name} (${machines[mIndex].stock} Stocks -- Stock 1)`,
                            lineNumber: machines[mIndex].lane,
                            floorNumber: machines[mIndex].floor,
                            referTo: id,
                            model: machines[mIndex].model,
                            stock: machines[mIndex].stock,
                            stockNum: 1,
                            laneLength: machines[mIndex].laneLength,
                            slot_height: machines[mIndex].slot_height,
                            step_depth: machines[mIndex].step_depth,
                            step_num: machines[mIndex].step_num,
                            height: machines[mIndex].height,
                          },
                        }).then(async (res) => {
                          console.log('lookup', lookup);
                          console.log('newData', newData);
                          console.log('machine', machines[mIndex]);
                          if (!machines[mIndex].modelNum) {
                            console.log('in12');
                            for (var i = 1; i < machines[mIndex].stock; i++) {
                              await request({
                                method: 'post',
                                url: CreatePlanoURL,
                                data: {
                                  name: `${newData.name} (${
                                    machines[mIndex].stock
                                  } Stocks -- stock ${i + 1})`,
                                  lineNumber: machines[mIndex].lane,
                                  floorNumber: machines[mIndex].floor,
                                  referTo: id,
                                  stock: machines[mIndex].stock,
                                  stockNum: i + 1,
                                  model: lookup[newData.model],
                                  referPlano: res.data.plano._id,
                                  laneLength: machines[mIndex].laneLength,
                                  slot_height: machines[mIndex].slot_height,
                                  step_depth: machines[mIndex].step_depth,
                                  step_num: machines[mIndex].step_num,
                                  height: machines[mIndex].height,
                                },
                              }).then((res) => {
                                setForRender(i);
                              });
                            }
                          } else {
                            console.log('in13');
                            let number = 1;
                            for (var i = 1; i < machines[mIndex].modelNum; i++) {
                              console.log('inffffffffffff');
                              number += 1;
                              await request({
                                method: 'post',
                                url: CreatePlanoURL,
                                data: {
                                  name: `${newData.name} (${machines[mIndex].stock} Stocks -- stock ${number})`,
                                  lineNumber: machines[mIndex].lane,
                                  floorNumber: machines[mIndex].floor,
                                  referTo: id,
                                  stock: machines[mIndex].stock,
                                  stockNum: number,
                                  model: machines[mIndex].model,
                                  referPlano: res.data.plano._id,
                                  laneLength: machines[mIndex].laneLength,
                                  slot_height: machines[mIndex].slot_height,
                                  step_depth: machines[mIndex].step_depth,
                                  step_num: machines[mIndex].step_num,
                                  height: machines[mIndex].height,
                                },
                              }).then((res) => {
                                setForRender(i);
                              });
                            }
                            const index2 = MagexModel.findIndex(
                              (e) => e.name === machines[mIndex].model1
                            );
                            for (var i = 0; i < machines[mIndex].modelNum1; i++) {
                              number += 1;
                              console.log('222222222');
                              await request({
                                method: 'post',
                                url: CreatePlanoURL,
                                data: {
                                  name: `${newData.name} (${machines[mIndex].stock} Stocks -- stock ${number})`,
                                  lineNumber: machines[mIndex].lane1,
                                  floorNumber: machines[mIndex].floor1,
                                  referTo: id,
                                  stock: machines[mIndex].stock,
                                  stockNum: number,
                                  model: machines[mIndex].model1,
                                  referPlano: res.data.plano._id,
                                  laneLength: MagexModel[index2].laneLength,
                                  slot_height: MagexModel[index2].slot_height,
                                  step_depth: MagexModel[index2].step_depth,
                                  step_num: MagexModel[index2].step_num,
                                  height: MagexModel[index2].height,
                                },
                              }).then((res) => {
                                setForRender(i);
                              });
                            }
                          }
                        });
                        setIsLoading(true);
                      }
                    }

                    resolve();
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setIsLoading(false);
                  setTimeout(async () => {
                    await request({
                      method: 'patch',
                      url: UpdateNameURL + newData._id,
                      data: {
                        model: newData.model,
                        name: newData.name,
                      },
                    }).then((res) => {
                      setIsLoading(true);
                      reflesh ? setReflesh(false) : setReflesh(true);
                    });
                    resolve();
                  }, 1000);
                }),
            }
          }
          actions={[
            (rowData) => ({
              icon: () => {
                return rowData.newPlano ? <FiberNewIcon /> : <ComputerIcon />;
              },
              tooltip: 'View the planogram',
              onClick: (event, rowData) => {
                if (rowData.model.includes('Gamma')) {
                  window.open(`/TCNplano/${rowData._id}/${id}`, 'TCNplanogram');
                } else if (rowData.model.includes('Inspire')) {
                  window.open(`/INSplano/${rowData._id}/${id}`, 'planogram');
                } else if (rowData.model.includes('Delta')) {
                  window.open(`/DELplano/${rowData._id}/${id}`, 'planogram');
                } else {
                  window.open(`/plano/${rowData._id}/${id}`, 'planogram');
                }
              },
            }),
            () => ({
              icon: DownloadIcon,
              tooltip: 'Download csv',
              onClick: (event, rowData) => {
                downloadProductCsv(rowData);
              },
            }),
            (rowData) => ({
              icon: FileCopyIcon,
              tooltip: 'Duplicate the planogram',
              onClick: (event, rowData) => {
                duplicatePlano(rowData);
              },
            }),
            (rowData) =>
              access && rowData.referPlano == null
                ? {
                    icon: 'delete',
                    tooltip: 'Delete This Planogram',
                    onClick: (event, rowData) => {
                      deletePlano(rowData);
                    },
                  }
                : {
                    icon: 'blank',
                  },
          ]}
          options={{
            actionsColumnIndex: -1,
            rowStyle: (rowData) => ({
              backgroundColor: rowData.referPlano != null ? '#EEE' : '#FFF',
            }),
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        <BackButton />
      </div>
    </div>
  );
}

import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, CardMedia, CardContent, Typography } from '@mui/material';
// utils
import { fNumber } from './utils/formatNumber';
//
import BaseOptionChart from './charts/BaseOptionChart';

export default function TopProduct(props) {
  const { prodInfo } = props;

  return (
    <Card>
      <CardHeader title="Top Seller" />
      <div style={{ height: '180px' }} className="d-flex align-items-center">
        <img
          style={{ maxWidth: '100%', margin: 'auto', maxHeight: '100%' }}
          src={
            prodInfo &&
            prodInfo.p &&
            `${process.env.REACT_APP_SERVER_API}/pictures/${prodInfo.p.productPictures[0]}/${prodInfo.p.referTo}`
          }
        />
      </div>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {prodInfo && prodInfo.p && prodInfo.p.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Quantity sold: {prodInfo && prodInfo.hprod && prodInfo.hprod.quantity}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Regular Price: ${prodInfo && prodInfo.hprod && prodInfo.hprod.price.toFixed(2)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Total sales: ${prodInfo && prodInfo.hprod && prodInfo.hprod.totalSale.toFixed(2)}
        </Typography>
      </CardContent>
    </Card>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/userContext';
import { useHistory } from 'react-router';
import request from '../../services/Request';
import MaterialTable from 'material-table';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import BackButton from '../items/buttons/BackButton';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import AddIcon from '@material-ui/icons/Add';
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';
import LoadingIcon from '../items/buttons/LoadingIcon';
import ComputerIcon from '@material-ui/icons/Computer';
import AppsIcon from '@material-ui/icons/Apps';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Swal from 'sweetalert2';
import { Autocomplete } from '@material-ui/lab';
import {
  Checkbox,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Grid, Button } from '@material-ui/core';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { makeStyles } from '@material-ui/core/styles';
import PlanoModal from '../../planogram/PlanoModal';
import PaletteIcon from '@material-ui/icons/Palette';
import UpdateLanguages from '../machines/UpdateLanguages';
import GifIcon from '@material-ui/icons/Gif';
import SSmodal from '../machines/SSmodal';
import MachineUIModal from '../machines/MachineUIModal';
import ReceiptModal from '../machines/ReceiptModal';
import { userLimits } from './uitls/userLimits';
import AddressModal from '../machines/AddressModal';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import AlertModal from '../machines/AlertModal';
import moment from 'moment';
import MachineConfig from '../machines/MachineConfig';
import { PermDataSettingOutlined } from '@material-ui/icons';
import BusinessIcon from '@mui/icons-material/Business';
import StopSale from '../machines/StopSale';
import ScheduleIcon from '@material-ui/icons/Schedule';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  buttom: {
    width: 80,
    height: 40,
    marginTop: 15,
  },
}));

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const getGroupsURL = `/groupsAndMachines/`;

export default function ViewMyMachines(props) {
  const classes = useStyles();
  const { setFiltered, filterd, setTestMode, userEmail } = useContext(UserContext);
  let history = useHistory();
  const [modalUI, showModalUI] = useState(false);

  const { id, tableTitle, superAdmin, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'edit_machine'
  );

  const [reflesh, setReflesh] = useState(false);
  const [machines, setMachines] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showL, setShowL] = useState(false);
  const [showI, setShowI] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showE, setShowE] = useState(false);
  const [check, setCheck] = useState(false);
  const [radio, setRadio] = useState('a');
  const [showAlert, setShowAlert] = useState(false);
  const [sc, setsc] = useState(false);

  const [mConfig, setmConfig] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  const theme1 = JSON.parse(localStorage.getItem('user'))?.theme === '1';
  const extra = user?.features?.extra;

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_mc') ? localStorage.getItem('table_view_mc') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_mc', pagination);
  }, [pagination]);

  function bytesToMegabytes(bytes) {
    return bytes / (1024 * 1024);
  }

  useEffect(() => {
    let isCancelled = false;
    setCheck(false);
    setgroupV([]);
    setRadio('a');
    // getMyMachines

    function getGroups() {
      request({
        method: 'get',
        url:
          props.match.params.group && superAdmin
            ? getGroupsURL + props.match.params.id
            : getGroupsURL + userEmail + '/trans',
      }).then((res) => {
        if (!isCancelled) {
          console.log(res.data);
          setGroups(res.data.groups);
          res.data.machines?.forEach((element) => {
            if (element?.status_info === 'Out Of Service') {
              element.oos = 'NO';
            }
            element.online = 'false';
            if (element.active) {
              const diff = new Date().getTime() - new Date(element.active);
              if (diff <= 90000) {
                element.online = 'true';
                if (element?.status_info === 'Out Of Service' || element?.appStatus === 'false') {
                  element.online = 'oos';
                }
              }
            }
          });
          setMachines(res.data.machines);
          setFiltered(res.data.machines);
          setLoading(true);
        }
      });
    }
    getGroups();

    return () => {
      setGroups([]);
      isCancelled = true;
    };
  }, [reflesh]);

  useEffect(() => {
    if (radio === 'a') {
      setFiltered(machines);
    }
  }, [radio]);

  const updateMachine = async (id, description, name, tax) => {
    setLoading(false);
    let data = new FormData();
    data.append('description', description);
    data.append('tax', tax);
    data.append('name', name);
    // data.append('location', location);
    // data.append('email', email);
    // data.append('phone', phone);
    request({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_API}/machines/edit/${id}`,
      data: data,
    }).then((res) => {
      Swal.fire({
        text: `The machine has been successfully updated`,
        icon: 'success',
      });
      setMachines(res.data);
      setLoading(true);
      reflesh ? setReflesh(false) : setReflesh(true);
    });
  };

  const deleteMachine = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        request({
          method: 'delete',
          url: `${process.env.REACT_APP_SERVER_API}/machines/delete/${id}`,
        }).then((res) => {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          reflesh ? setReflesh(false) : setReflesh(true);
        });
      }
    });
  };

  const handleChange = async (event) => {
    setCheck(true);
    if (radio === 'a') {
      setFiltered(machines);
    }
    if (radio === 'g') {
      const myArray = groupV.map((e) => e.machineID);
      var merged = [].concat.apply([], myArray);
      merged = merged.filter((e, i, self) => i === self.findIndex((t) => t._id == e._id));
      // const filteredArray = machines.filter((e, i) => i == merged.findIndex((t)=>(t._id == e._id)));
      const filteredArray = [];
      console.log(merged);
      machines.forEach((e) => {
        merged.findIndex((t) => {
          if (t._id === e._id) {
            filteredArray.push(e);
          }
        });
      });
      setFiltered(filteredArray);
    }
    if (radio === 'm') {
      setFiltered(groupV);
    }
  };

  const handleCate = () => {
    console.log(filterd);
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(`/dashboard/addCategories/all`);
        }
      });
    } else if (filterd.length == 1) {
      history.push(`/dashboard/addCategories/${filterd[0]._id}`);
    }
  };

  const handleProd = () => {
    const check = filterd.every((v) => v.plano && v.plano !== '');
    if (check) {
      if (filterd.length > 1) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'The action will applied to all of your selected machines.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/dashboard/addProducts/all`);
          }
        });
      } else if (filterd.length == 1) {
        history.push(`/dashboard/addProducts/${filterd[0]._id}`);
      }
    } else {
      Swal.fire({
        text: 'You must select a planogram to machine before edit product.',
        icon: 'error',
      });
    }
  };

  const handleBrand = () => {
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(`/dashboard/addBrands/all`);
        }
      });
    } else if (filterd.length == 1) {
      history.push(`/dashboard/addBrands/${filterd[0]._id}`);
    }
  };

  const handlePlano = () => {
    if (filterd.length > 1) {
      const error = filterd.some((e) => {
        return (
          e.model !== filterd[0].model ||
          e.lane !== filterd[0].lane ||
          e.floor !== filterd[0].floor ||
          e.stock !== filterd[0].stock
        );
      });
      if (error) {
        Swal.fire({
          title: 'Error',
          // text: "The machines your select have different stocks/lanes/floors.",
          text: 'The machines your select have different model.',
          icon: 'error',
        });
      } else {
        Swal.fire({
          title: 'Are you sure?',
          text: 'The action will applied to all of your selected machines.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.isConfirmed) {
            setShow(true);
          }
        });
      }
    } else if (filterd.length === 1) {
      setShow(true);
    }
  };

  const handleEmail = () => {
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setShowE(true);
        }
      });
    }
    setShowE(true);
  };

  const handleUI = () => {
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          showModalUI(true);
        }
      });
    } else if (filterd.length === 1) {
      showModalUI(true);
    }
  };

  const handleLanguage = () => {
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setShowL(true);
        }
      });
    } else if (filterd.length === 1) {
      setShowL(true);
    }
  };

  const handleSS = () => {
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setShowI(true);
        }
      });
    } else if (filterd.length === 1) {
      setShowI(true);
    }
  };

  const handleAddress = () => {
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setShowAddress(true);
        }
      });
    } else if (filterd.length === 1) {
      setShowAddress(true);
    }
  };

  const stopsale = () => {
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setsc(true);
        }
      });
    } else if (filterd.length === 1) {
      setsc(true);
    }
  };

  const options = [
    () => ({
      icon: AppsIcon,
      tooltip: 'Machine UI(1080*1920)',
      onClick: (event, rowData) => {
        if (theme1) {
          window.open(
            `${process.env.REACT_APP_MUI1}/machineUI/home/${rowData._id}/true`,
            'mywin',
            'width=1077,height=1920'
          );
        } else if (extra) {
          window.open(
            `${process.env.REACT_APP_MUI}/machineUI/home/${rowData._id}/true`,
            'mywin',
            'width=1077,height=1920'
          );
        } else {
          window.open(
            `${process.env.REACT_APP_MUI2}/machineUI/home/${rowData._id}/true`,
            'mywin',
            'width=1077,height=1920'
          );
        }

        // history.push(`/machineUI/home/${rowData._id}/true`);
      },
    }),
    () => {
      if (superAdmin)
        return {
          icon: 'delete',
          tooltip: 'Machine Transaction',
          onClick: (event, rowData) => {
            deleteMachine(rowData._id);
          },
        };
    },
    // () =>(
    //   {
    //     icon: ComputerIcon,
    //     tooltip: 'Select Planogram',
    //     onClick: ((event, rowData) => {selectPlano(rowData)})
    //   }
    // ),
    // () =>(
    //   {
    //     icon: PaletteIcon,
    //     tooltip: 'Customize UI',
    //     onClick: ((event, rowData) => selectCustomizeUI(rowData))
    //   }
    // ),
    // () =>(
    //   {
    //     icon: LanguageSharpIcon,
    //     tooltip: 'Update Languages',
    //     onClick: ((event, rowData) => updateLanguages(rowData))
    //   }
    // ),
    // () =>(
    //   {
    //     icon: GifIcon,
    //     tooltip: 'Customized Screen Saver',
    //     onClick: ((event, rowData) => updateSS(rowData))
    //   }
    // ),
  ];

  if (user?.dba) {
    options.push(() => ({
      icon: RemoveRedEyeIcon,
      tooltip: 'Machine UI(Developer Mode View)',
      onClick: (event, rowData) => {
        if (theme1) {
          window.open(`${process.env.REACT_APP_MUI1}/machineUI/home/${rowData._id}/true`);
        } else if (extra) {
          window.open(`${process.env.REACT_APP_MUI}/machineUI/home/${rowData._id}/true`);
        } else {
          window.open(`${process.env.REACT_APP_MUI2}/machineUI/home/${rowData._id}/true`);
        }
      },
    }));
  }

  const handleAlert = () => {
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setShowAlert(true);
        }
      });
    } else if (filterd.length === 1) {
      setShowAlert(true);
    }
  };

  const superAdminColumns = [
    { title: 'Machine ID', field: 'name' },
    // {
    //   title: 'Machine model',
    //   field: 'model',
    //   editable: 'never',
    //   render: (rowData) => {
    //     return rowData.stock == 1
    //       ? `${rowData.model} (${rowData.lane}L${rowData.floor}F)`
    //       : `${rowData.model} (${rowData.lane}L${rowData.floor}F * ${rowData.stock} Stocks)`;
    //   },
    // },
    {
      title: 'Groups',
      render: (rowData) =>
        rowData.group?.map((e, i) => {
          const length = rowData.group?.length;
          if (i !== length - 1) {
            return `${e.name}, `;
          } else {
            return e.name;
          }
        }),
    },
    { title: 'Description', field: 'description' },
    { title: 'Tax', field: 'tax', type: 'numeric', render: (rowData) => `${rowData.tax}%` },
    { title: 'Version', field: 'version', editable: 'never' },
    {
      title: 'Status',
      field: 'online',
      editable: 'never',
      render: (rowData) => {
        if (rowData.online === 'true') {
          return (
            <div>
              <StopRoundedIcon style={{ color: 'green' }} />
            </div>
          );
        } else if (rowData.online === 'false') {
          return (
            <div>
              <StopRoundedIcon style={{ color: 'lightgrey' }} />
            </div>
          );
        } else if (rowData.online === 'oos') {
          return (
            <div>
              <StopRoundedIcon style={{ color: 'red' }} />
            </div>
          );
        }
      },
    },
    {
      title: 'Free Disk',
      field: 'freedisk',
      editable: 'never',
      render: (rowData) => {
        if (rowData.freedisk) {
          return parseInt(bytesToMegabytes(rowData.freedisk)) + 'MB';
        }
      },
    },
    {
      title: 'Last Trans',
      field: 'lastTrans',
      editable: 'never',
      render: (rowData) => {
        if (rowData.lastTrans) {
          let date = new Date(rowData.lastTrans);
          return moment.utc(date).format('MMM Do, h:mm A');
        }
      },
    },
  ];

  const columns = [
    { title: 'Machine ID', field: 'name', editable: 'never' },
    { title: 'Model', field: 'model', editable: 'never' },
    // {
    //   title: 'Machine model',
    //   field: 'model',
    //   editable: 'never',
    //   render: (rowData) => {
    //     return rowData.stock == 1
    //       ? `${rowData.model} (${rowData.lane}L${rowData.floor}F)`
    //       : `${rowData.model} (${rowData.lane}L${rowData.floor}F * ${rowData.stock} Stocks)`;
    //   },
    // },
    {
      title: 'Groups',
      render: (rowData) =>
        rowData.group?.map((e, i) => {
          const length = rowData.group?.length;
          if (i !== length - 1) {
            return `${e.name}, `;
          } else {
            return e.name;
          }
        }),
    },
    { title: 'Description', field: 'description' },
    { title: 'Tax', field: 'tax', type: 'numeric', render: (rowData) => `${rowData.tax}%` },
    { title: 'Version', field: 'version', editable: 'never' },
    {
      title: 'Status',
      field: 'online',
      editable: 'never',
      render: (rowData) => {
        if (rowData.online === 'true') {
          return (
            <div>
              <StopRoundedIcon style={{ color: 'green' }} />
            </div>
          );
        } else if (rowData.online === 'false') {
          return (
            <div>
              <StopRoundedIcon style={{ color: 'lightgrey' }} />
            </div>
          );
        } else if (rowData.online === 'oos') {
          return (
            <div>
              <StopRoundedIcon style={{ color: 'red' }} />
            </div>
          );
        }
      },
    },
    {
      title: 'Free Disk',
      field: 'freedisk',
      editable: 'never',
      render: (rowData) => {
        if (rowData.freedisk) {
          return parseInt(bytesToMegabytes(rowData.freedisk)) + 'MB';
        }
      },
    },
    {
      title: 'Last Trans',
      field: 'lastTrans',
      editable: 'never',
      render: (rowData) => {
        if (rowData.lastTrans) {
          let date = new Date(rowData.lastTrans);
          return moment.utc(date).format('MMM Do, h:mm A');
        }
      },
    },
  ];

  const handleMachineConfig = () => {
    if (filterd.length > 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setmConfig(true);
        }
      });
    } else if (filterd.length === 1) {
      setmConfig(true);
    }
  };

  return (
    <div>
      {!loading && <LoadingIcon />}
      <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Grid container style={{ marginLeft: '20px' }}>
          <Grid item xs={4}>
            <RadioGroup
              name="bg_radio"
              className="d-flex flex-row justify-content-center align-items-center"
              style={{ height: '100%' }}
              onChange={(e) => {
                setRadio(e.target.value);
                setgroupV([]);
                setCheck(false);
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                value="a"
                control={<Radio />}
                label="All Machines"
                checked={radio == 'a'}
              />
              <FormControlLabel
                style={{ margin: 0 }}
                value="g"
                control={<Radio />}
                label="Groups"
                checked={radio == 'g'}
              />
              <FormControlLabel
                style={{ margin: 0 }}
                value="m"
                control={<Radio />}
                label="Machines"
                checked={radio == 'm'}
              />
            </RadioGroup>
          </Grid>
          <Grid container item xs={4} alignItems="center" justifyContent="center">
            <Grid container item xs={8} alignItems="center" justifyContent="center">
              {radio == 'a' && (
                <TextField variant="outlined" label="All Machines" fullWidth disabled />
              )}

              {radio == 'g' && (
                <Autocomplete
                  multiple
                  value={groupV}
                  options={groups}
                  disableCloseOnSelect
                  onChange={(event, value) => {
                    setgroupV(value);
                    setCheck(false);
                  }}
                  getOptionSelected={(option, value) => option._id === value._id}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Select Groups" />
                  )}
                />
              )}

              {radio == 'm' && (
                <Autocomplete
                  multiple
                  value={groupV}
                  options={machines}
                  disableCloseOnSelect
                  onChange={(event, value) => {
                    setgroupV(value);
                    setCheck(false);
                  }}
                  getOptionSelected={(option, value) => option._id === value._id}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.description ? `${option.name}(${option.description})` : option.name}
                    </React.Fragment>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Select Machines" />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={2} style={{ marginLeft: '10px' }}>
              <Button
                disabled={groupV.length == 0 && radio != 'a'}
                onClick={handleChange}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={4} alignItems="center" justifyContent="center">
            <Tooltip title="Edit Categories">
              <IconButton component="span" onClick={handleCate} disabled={!check}>
                <AddCircleOutlineSharpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Products">
              <IconButton component="span" onClick={handleProd} disabled={!check}>
                <AddCircleSharpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Brands">
              <IconButton component="span" onClick={handleBrand} disabled={!check}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Select Planograms">
              <IconButton component="span" onClick={handlePlano} disabled={!check}>
                <ComputerIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Select email template">
              <IconButton component="span" onClick={handleEmail} disabled={!check}>
                <MailOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Customize Machine UI">
              <IconButton component="span" onClick={handleUI} disabled={!check}>
                <PaletteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Update Languages & Time zone">
              <IconButton component="span" onClick={handleLanguage} disabled={!check}>
                <LanguageSharpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Select Screen Saver/Audio">
              <IconButton component="span" onClick={handleSS} disabled={!check}>
                <GifIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Kiosk Address">
              <IconButton component="span" onClick={handleAddress} disabled={!check}>
                <BusinessIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Email Alert">
              <IconButton component="span" onClick={handleAlert} disabled={!check}>
                <AddAlertIcon />
              </IconButton>
            </Tooltip>
            {(id === 'adrian.pittaway@msccruises.com' ||
              id === 'tianlangwu001@gmail.com' ||
              id === '1234@gmail.com') && (
              <Tooltip title="Schedule Sale">
                <IconButton component="span" onClick={stopsale} disabled={!check}>
                  <ScheduleIcon />
                </IconButton>
              </Tooltip>
            )}

            {/* {process.env.REACT_APP_ENV1 === 'TRUE' && (
              <Tooltip title="Machine Config">
                <IconButton component="span" onClick={handleMachineConfig} disabled={!check}>
                  <PermDataSettingOutlined />
                </IconButton>
              </Tooltip>
            )} */}
            {extra && (
              <Tooltip title="Machine Config">
                <IconButton component="span" onClick={handleMachineConfig} disabled={!check}>
                  <PermDataSettingOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>

      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={superAdmin ? superAdminColumns : columns}
          data={filterd}
          title={tableTitle}
          // detailPanel={(rowData) => {
          //   return (
          //     <div
          //       style={{
          //         padding: '13px 50px',
          //         fontSize: '15px',
          //       }}
          //     >
          //       <span style={{ minWidth: '30%', display: 'inline-block' }}>Machine Model:</span>
          //       <span style={{ marginLeft: '20px' }}>{rowData.model}</span>
          //     </div>
          //   );
          // }}
          editable={
            access && {
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    updateMachine(
                      newData._id,
                      newData.description,
                      newData.name,
                      // newData.location,
                      // newData.email,
                      // newData.phone,
                      newData.tax
                    );
                    resolve();
                  }, 1000);
                }),
            }
          }
          actions={options}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        {filterd.length > 0 && (
          <>
            <MachineUIModal
              show={modalUI}
              onHide={() => showModalUI(false)}
              data={filterd}
              reflesh={+reflesh}
              setReflesh={setReflesh}
            />
            <UpdateLanguages
              show={showL}
              reflesh={+reflesh}
              setReflesh={setReflesh}
              onHide={() => setShowL(false)}
              data={filterd}
            />
            <SSmodal
              user={user}
              show={showI}
              showAdmin={access}
              onHide={() => setShowI(false)}
              rowData={filterd}
            />
            <BackButton />
            <PlanoModal
              showAdmin={access}
              show={show}
              rowData={filterd}
              onHide={() => setShow(false)}
            />
            <ReceiptModal
              showAdmin={access}
              show={showE}
              rowData={filterd}
              onHide={() => setShowE(false)}
            />
            <AddressModal
              user={user}
              showAdmin={access}
              reflesh={+reflesh}
              setReflesh={setReflesh}
              show={showAddress}
              data={filterd}
              onHide={() => setShowAddress(false)}
            />
            <AddressModal
              user={user}
              showAdmin={access}
              reflesh={+reflesh}
              setReflesh={setReflesh}
              show={showAddress}
              data={filterd}
              onHide={() => setShowAddress(false)}
            />
            <AlertModal
              user={user}
              showAdmin={access}
              reflesh={+reflesh}
              setReflesh={setReflesh}
              show={showAlert}
              data={filterd}
              onHide={() => setShowAlert(false)}
            />
            <MachineConfig
              user={user}
              showAdmin={access}
              reflesh={+reflesh}
              setReflesh={setReflesh}
              show={mConfig}
              data={filterd}
              onHide={() => setmConfig(false)}
            />
            <StopSale
              showAdmin={access}
              show={sc}
              rowData={filterd}
              onHide={() => setsc(false)}
              reflesh={+reflesh}
              setReflesh={setReflesh}
            />
          </>
        )}
      </div>
    </div>
  );
}

import React, { useState, useContext, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { UserContext } from '../contexts/userContext';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import { Email } from '@material-ui/icons';

import { useForm } from 'react-hook-form';
import request from '../../services/Request';
import './form.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassWord() {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user'));
  const changePasswordURL = `/users/edit/changePassword`;
  const changeEmailURL = `/users/edit/changeEmail`;
  const changeStockingURL = `/users/edit/changeStocking`;

  const defaultValues = {
    role: 'User',
  };

  const form2 = {
    email: user?.email,
  };

  const form3 = {};

  //use to fix style bug

  const { userEmail } = useContext(UserContext);
  const [refresh, setRefresh] = useState(false);
  const [stk, setstk] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ defaultValues });

  const {
    register: register2,
    watch: watch2,
    handleSubmit: handleSubmit2,
    setError: setError2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm({ form2 });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    setError: setError3,
    formState: { errors: errors3 },
  } = useForm({ form3 });

  const password = useRef({});
  password.current = watch('newPassword', '');

  const watchAll = watch2();
  let history = useHistory();

  const onSubmit = async (data) => {
    request({
      method: 'patch',
      url: changePasswordURL,
      data: {
        email: userEmail,
        oldPassword: data.oldPassword,
        newPassword: data.newPassword2,
      },
    })
      .then((res) => {
        Swal.fire({
          text: `Your password has been changed`,
          icon: 'success',
        }).then(() => {
          history.push('/dashboard');
        });
      })
      .catch((err) => {
        if (err && err.response) {
          setError('oldPassword', {
            message: err.response.data.message,
          });
        }
      });
  };

  const onSubmitEmail = async (data) => {
    const email2 = data.email === '' ? userEmail : data.email;
    request({
      method: 'patch',
      url: changeEmailURL,
      data: {
        email: userEmail,
        password: data.password,
        email2: email2,
      },
    })
      .then((res) => {
        Swal.fire({
          text: `Your associated email has been changed`,
          icon: 'success',
        }).then(() => {
          history.push('/dashboard');
        });
      })
      .catch((err) => {
        if (err && err.response) {
          setError2('email', {
            message: err.response.data.message,
          });
        }
      });
  };

  const onSubmitStocking = async (data) => {
    request({
      method: 'patch',
      url: changeStockingURL,
      data: {
        email: userEmail,
        password: data.password1,
        stocking: data.stocking,
      },
    })
      .then((res) => {
        Swal.fire({
          text: `Your stocking password has been changed`,
          icon: 'success',
        }).then(() => {
          history.push('/dashboard');
        });
      })
      .catch((err) => {
        if (err && err.response) {
          setError3('stocking', {
            message: err.response.data.message,
          });
        }
      });
  };

  useEffect(() => {
    setRefresh(false);
    request({
      method: 'get',
      url: `/users/edit/${user?._id}`,
    }).then((res) => {
      reset2({
        email: res.data.email2 ? res.data.email2 : res.data.email,
      });
      setRefresh(true);
      console.log(res.data.stocking);
      if (res.data.stocking && res.data.stocking !== '') {
        setstk(true);
      }
    });
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <Container style={{ marginTop: '19px' }} component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="oldPassword"
                  name="oldPassword"
                  variant="outlined"
                  required
                  fullWidth
                  id="oldPassword"
                  type="password"
                  label="Old Password"
                  autoFocus
                  {...register('oldPassword', {
                    required: 'Old Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must longer than 8',
                    },
                  })}
                />
                {errors.oldPassword && (
                  <p className="signUpErrorMessage">{errors.oldPassword.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="newPassword"
                  label="New Password"
                  name="newPassword"
                  type="password"
                  autoComplete="newPassword"
                  {...register('newPassword', {
                    required: 'New Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must longer than 8',
                    },
                  })}
                />
                {errors.newPassword && (
                  <p className="signUpErrorMessage">{errors.newPassword.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="newPassword2"
                  label="Enter password again"
                  name="newPassword2"
                  type="password"
                  autoComplete="newPassword2"
                  {...register('newPassword2', {
                    required: 'Re-Enter Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must longer than 8',
                    },
                    validate: (value) =>
                      value === password.current || 'The new passwords do not match',
                  })}
                />
                {errors.newPassword2 && (
                  <p className="signUpErrorMessage">{errors.newPassword2.message}</p>
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Change My Password
            </Button>
          </form>
        </div>
        {refresh && (
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <Email />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Associated Email
            </Typography>
            <div>
              (All emails will send to this associated email instead of your account username)
            </div>
            <form className={classes.form} onSubmit={handleSubmit2(onSubmitEmail)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="password"
                    label="Password"
                    {...register2('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 8,
                        message: 'Password must longer than 8',
                      },
                    })}
                  />
                  {errors2.password && (
                    <p className="signUpErrorMessage">{errors2.password.message}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Email"
                    value={watchAll?.email}
                    {...register2('email', {
                      pattern: {
                        value:
                          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                        message: 'Please enter a valid email',
                      },
                    })}
                  />
                  {errors2.email && <p className="signUpErrorMessage">{errors2.email.message}</p>}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Change Email
              </Button>
            </form>
          </div>
        )}
        {stk && (
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <Email />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Stocking Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit3(onSubmitStocking)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="password"
                    label="Password"
                    {...register3('password1', {
                      required: 'Password is required',
                      minLength: {
                        value: 8,
                        message: 'Password must longer than 8',
                      },
                    })}
                  />
                  {errors3.password1 && (
                    <p className="signUpErrorMessage">{errors3.password1.message}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="password"
                    label="Stocking"
                    {...register3('stocking', {
                      required: 'Stocking is required',
                      minLength: {
                        value: 5,
                        message: 'Length of stocking should be 5',
                      },
                      maxLength: {
                        value: 5,
                        message: 'Length of stocking should be 5',
                      },
                    })}
                  />
                  {errors3.stocking && (
                    <p className="signUpErrorMessage">{errors3.stocking.message}</p>
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Change Stocking
              </Button>
            </form>
          </div>
        )}
      </Container>
    </div>
  );
}

const COLOR1 = '#4396CC';
const COLOROrigin = 'lightblue';
const COLORS1 = '#F0F2F3';
const COLORS2 = 'lightgrey';
const COLORS3 = 'grey';

module.exports = {
  COLOR1,
  COLOROrigin,
  COLORS1,
  COLORS2,
  COLORS3,
};

import { Grid, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import request from '../../../services/Request';
import LoadingIcon from '../../items/buttons/LoadingIcon';

import { Autocomplete } from '@material-ui/lab';
import { TextField, Button } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormControlLabel, RadioGroup, Radio, makeStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from 'material-table';
import moment from 'moment';
import { CloudDownload } from '@material-ui/icons';
import { localDateRange } from '../../../services/method/methods';

// ----------------------------------------------------------------------
import { userLimits } from '../uitls/userLimits';
import { ExportToCsv } from 'export-to-csv';

const getMachinesURL = '/machines/getMyMachines/';
const getGroupsURL = `/groupsAndMachinesForReport/`;
const getLogsURL = '/stockLog/';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  buttom: {
    width: 80,
    height: 40,
    marginTop: 15,
  },
}));

export default function StockLog(props) {
  var date = new Date();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const [startDateString, setStartDateString] = useState(
  //   new Date(date.getFullYear(), date.getMonth(), 1).toISOString()
  // );
  // const [endDateString, setEndDateString] = useState(new Date().toISOString());

  const [selectP, setSelectP] = useState({ name: '' });
  const [machines, setMachines] = useState([]);
  const [stockLog, setstockLog] = useState([]);
  const [planoLog, setPlanoLog] = useState([]);
  const [radio, setRadio] = useState('s');
  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_stocking') ? localStorage.getItem('table_view_stocking') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_stocking', pagination);
  }, [pagination]);
  const user = JSON.parse(localStorage.getItem('user'));

  const { _id, id, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'view_report'
  );

  useEffect(() => {
    async function getMachine() {
      await request({
        method: 'get',
        url:
          props.match.params.group && superAdmin
            ? getGroupsURL + props.match.params.id
            : getGroupsURL + user?.email,
      }).then((res) => {
        console.log('res.data', res.data);
        setMachines(res.data.machines);
        setLoading(true);
      });
    }
    getMachine();
    return () => {
      setMachines([]);
      setLoading(true);
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
    // setStartDateString(e.toISOString());
  };

  const handleEndDate = (e) => {
    setEndDate(e);
    // setEndDateString(e.toISOString());
  };

  const handleChange = async () => {
    const { startDateString, endDateString } = localDateRange(startDate, endDate);

    await request({
      method: 'get',
      url: getLogsURL + selectP._id + '/' + startDateString + '/' + endDateString,
    }).then((res) => {
      console.log(res.data);
      const newArray = [];
      const newPlano = [];
      // console.log('log', res.data);
      res.data.forEach((e) => {
        if (e.plano) {
          newPlano.push({
            date: e.localDate,
            user: e.user ? e.user?.firstname + ' ' + e.user?.lastname : '',
            isOld: e.isOld,
          });
        } else {
          e.prods.forEach((pd) => {
            const modify = pd.current_stock - pd.pre_stock;
            newArray.push({
              date: e.localDate,
              user: e.user ? e.user?.firstname + ' ' + e.user?.lastname : '',
              upc: pd.upc,
              name: pd.id?.name?.en,
              modify: modify,
              pos: pd.idx
                ? pd.row + 'F ' + pd.lane + 'L ' + pd.idx + 'S'
                : pd.row + 'F ' + pd.lane + 'L',
              post_stock: pd.current_stock,
              pre_stock: pd.pre_stock,
              isOld: e.isOld,
            });
          });
        }
      });
      // console.log('newArray', newArray);
      setstockLog(newArray);
      setPlanoLog(newPlano);
      console.log('newPlano', newPlano);
    });
  };

  const columns =
    radio === 's'
      ? [
          {
            title: 'Date',
            render: (rowData) => {
              let date = new Date(rowData.date);
              if (rowData.isOld === 'true') {
                date = moment(date).format('lll');
              } else {
                date = moment.utc(date).format('lll');
              }
              return date;
            },
          },
          { title: 'User', field: 'user' },
          { title: 'UPC/SKU', field: 'upc' },
          { title: 'Name', field: 'name' },
          { title: 'Pre Stock', field: 'pre_stock' },
          { title: 'Post Stock', field: 'post_stock' },
          { title: 'Added/Removed', field: 'modify' },
          { title: 'Position', field: 'pos' },
        ]
      : [
          {
            title: 'Date',
            render: (rowData) => {
              let date = new Date(rowData.date);
              if (rowData.isOld === 'true') {
                date = moment(date).format('lll');
              } else {
                date = moment.utc(date).format('lll');
              }
              return date;
            },
          },
          { title: 'User', field: 'user' },
        ];
  return (
    <div>
      <Container maxWidth="xl" sx={{ pt: 5 }}>
        {!loading && <LoadingIcon />}
        <Grid container spacing={3}>
          <Grid container item xs={12} alignItems="center" justifyContent="center">
            <Grid xs={3} item container justifyContent="flex-start">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.formControl}
                  value={startDate}
                  onChange={(e) => handleStartDate(e)}
                  label="Select start date"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-start"
                />
                <KeyboardDatePicker
                  className={classes.formControl}
                  value={endDate}
                  onChange={(e) => handleEndDate(e)}
                  label="Select end date"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-end"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid xs={2}>
              <RadioGroup
                name="bg_radio"
                style={{ height: '100%' }}
                onChange={(e) => {
                  setRadio(e.target.value);
                }}
              >
                <FormControlLabel
                  style={{ margin: 0 }}
                  value="s"
                  control={<Radio />}
                  label="Stocking"
                  checked={radio === 's'}
                />
                <FormControlLabel
                  style={{ margin: 0 }}
                  value="p"
                  control={<Radio />}
                  label="Planogram"
                  checked={radio === 'p'}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={4} style={{ marginLeft: '15px' }}>
              <Autocomplete
                value={selectP}
                fullWidth
                id="addToMachine"
                options={machines}
                onChange={(event, value) => {
                  setSelectP(value);
                }}
                getOptionLabel={(option) =>
                  option.description ? `${option.name}(${option.description})` : option.name
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select a Machine" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: '15px' }}>
              <Button
                style={{ height: '40px' }}
                disabled={!selectP?.name}
                onClick={() => {
                  handleChange();
                }}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div style={{ marginTop: '50px' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={radio === 's' ? stockLog : planoLog}
          title={'Logs'}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100, 200],
          }}
          actions={[
            {
              tooltip: 'Download csv',
              isFreeAction: true,
              icon: CloudDownload,
              onClick: (evt, data) => {
                let outPutArray = [];
                if (radio === 's') {
                  outPutArray = stockLog?.map((e) => {
                    return {
                      Date: moment.utc(e.date).format('YYYY-MM-DD HH:mm:ss a'),
                      User: e.user,
                      UPC: e.upc,
                      Name: e.name,
                      Position: e.idx ? e.row + 'F ' + e.lane + 'L ' + e.idx + 'S' : e.pos,
                      Post_Stock: e.post_stock,
                      Pre_Stock: e.pre_stock,
                      'Added/Removed': e.modify,
                    };
                  });
                } else {
                  outPutArray = planoLog?.map((e) => {
                    return {
                      Date: moment.utc(e.date).format('YYYY-MM-DD HH:mm:ss a'),
                      User: e.user,
                    };
                  });
                }

                const options = {
                  fieldSeparator: ',',
                  quoteStrings: '"',
                  decimalSeparator: '.',
                  useTextFile: false,
                  filename: 'Inventory_report',
                  useKeysAsHeaders: true,
                };

                const csvExporter = new ExportToCsv(options);
                csvExporter.generateCsv(outPutArray);

                // if (outPutArray.length > 0) {
                //   let csv = 'data:text/csv;charset=utf-8,';
                //   let header = Object.keys(outPutArray[0]).join(',');

                //   let values = outPutArray.map((o) => Object.values(o).join(',')).join('\n');
                //   //extra for non sold

                //   csv += header + '\n' + values;

                //   var encodedUri = encodeURI(csv);
                //   var link = document.createElement('a');
                //   link.setAttribute('href', encodedUri);
                //   link.setAttribute('download', 'Inventory_report.csv');
                //   document.body.appendChild(link); // Required for FF

                //   link.click();
                // }
              },
            },
          ]}
          // parentChildData={(row, rows) => rows.find((a) => a.date === row.date)}
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import Swal from 'sweetalert2';
import request from '../../../services/Request';
import { Computer } from '@material-ui/icons';
import { userLimits } from '../uitls/userLimits';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';

import AddAudio from './AddAudio';

const getAudioURL = `/getAudioByEmail/`;

const deleteAudioURL = `/audio/delete/`;

export default function CustomAudio(props) {
  const { _id, tableTitle, superAdmin, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Screen Saver',
    'edit_ss'
  );

  const [reflesh, setReflesh] = useState(false);
  const [audio, setAudio] = useState([]);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [row, setRow] = useState(null);

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_audio') ? localStorage.getItem('table_view_audio') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_audio', pagination);
  }, [pagination]);

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    let isCancelled = false;

    async function getMyAudio() {
      await request({
        method: 'get',
        url: getAudioURL + _id,
      }).then((res) => {
        if (!isCancelled) {
          setAudio(res.data);
          setLoading(true);
        }
      });
    }

    getMyAudio();

    return () => {
      setAudio([]);
      isCancelled = true;
    };
  }, [reflesh]);

  const deleteAudio = async (id) => {
    setLoading(false);
    await request({
      method: 'delete',
      url: deleteAudioURL + id,
    })
      .then(() => {
        setLoading(true);
        reflesh ? setReflesh(false) : setReflesh(true);
      })
      .catch((err) => {
        if (err?.response?.status === 406) {
          Swal.fire({
            text: 'The audio is linked to some machines, please unlink it before delete',
            icon: 'error',
          });
        } else {
          Swal.fire({
            text: `Fail to delete the audio`,
            icon: 'error',
          });
        }

        setLoading(true);
        reflesh ? setReflesh(false) : setReflesh(true);
      });
  };

  const columns = [
    // { title: 'Picture', field: 'picture' , render: rowData =>
    // <img src={`${process.env.REACT_APP_SERVER_API}/brands/pictures/${rowData.picture}`} style={{width: 200}}/>},
    { title: 'Audio Name', field: 'name' },
    {
      title: 'Touch The Screen To Start',
      field: 'start',
    },
    {
      title: 'Open Shopping Cart',
      field: 'cart',
    },
    {
      title: 'Tap On Check Out',
      field: 'checkout',
    },
    {
      title: 'During Dispense',
      field: 'dispense',
    },
    {
      title: 'Door Opens',
      field: 'doorOpen',
    },
    {
      title: 'After Door Closes',
      field: 'doorClose',
    },
  ];
  return (
    <div>
      <AddAudio
        show={show}
        onHide={() => setShow(false)}
        setReflesh={setReflesh}
        _id={_id}
        user={user}
      />
      {!loading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={audio}
          title={'Custom Audio'}
          editable={
            access && {
              onRowDelete: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    deleteAudio(newData._id);
                    resolve();
                  }, 1000);
                }),
            }
          }
          actions={[
            access && {
              icon: 'add',
              tooltip: 'Create Audio',
              isFreeAction: true,
              onClick: (event) => {
                setShow(true);
              },
            },
            // access && {
            //   icon: 'edit',
            //   tooltip: 'Edit Screen Saver',
            //   onClick: (event, rowData) => {
            //     setRow(rowData);
            //     setShowUpdate(true);
            //   },
            // },
          ]}
          detailPanel={(rowData) => {
            // console.log(rowData);
            return (
              <TableContainer component={Paper} style={{ backgroundColor: '#EBEBE8' }}>
                {rowData.start && rowData.start !== '' && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>Audio</TableCell>
                        <TableCell style={{ width: '30%' }}>Section</TableCell>
                        <TableCell style={{ width: '30%' }}>Filename</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <audio controls>
                            <source
                              src={`${process.env.REACT_APP_SERVER_API}/videos/${rowData.start}/${_id}`}
                              type="video/mp4"
                            />
                          </audio>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          Touch the screen to start a transaction
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowData.start}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}

                {rowData.cart && rowData.cart !== '' && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>Audio</TableCell>
                        <TableCell style={{ width: '30%' }}>Section</TableCell>
                        <TableCell style={{ width: '30%' }}>Filename</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <audio controls>
                            <source
                              src={`${process.env.REACT_APP_SERVER_API}/videos/${rowData.cart}/${_id}`}
                              type="video/mp4"
                            />
                          </audio>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          Open Shopping Cart
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowData.cart}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}

                {rowData.checkout && rowData.checkout !== '' && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>Audio</TableCell>
                        <TableCell style={{ width: '30%' }}>Section</TableCell>
                        <TableCell style={{ width: '30%' }}>Filename</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <audio controls>
                            <source
                              src={`${process.env.REACT_APP_SERVER_API}/videos/${rowData.checkout}/${_id}`}
                              type="video/mp4"
                            />
                          </audio>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          Tap On Check out
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowData.checkout}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}

                {rowData.dispense && rowData.dispense !== '' && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>Audio</TableCell>
                        <TableCell style={{ width: '30%' }}>Section</TableCell>
                        <TableCell style={{ width: '30%' }}>Filename</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <audio controls>
                            <source
                              src={`${process.env.REACT_APP_SERVER_API}/videos/${rowData.dispense}/${_id}`}
                              type="video/mp4"
                            />
                          </audio>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          While the machine dispenses
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowData.dispense}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}

                {rowData.doorOpen && rowData.doorOpen !== '' && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>Audio</TableCell>
                        <TableCell style={{ width: '30%' }}>Section</TableCell>
                        <TableCell style={{ width: '30%' }}>Filename</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <audio controls>
                            <source
                              src={`${process.env.REACT_APP_SERVER_API}/videos/${rowData.doorOpen}/${_id}`}
                              type="video/mp4"
                            />
                          </audio>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          Door opens
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowData.doorOpen}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}

                {rowData.doorClose && rowData.doorClose !== '' && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>Audio</TableCell>
                        <TableCell style={{ width: '30%' }}>Section</TableCell>
                        <TableCell style={{ width: '30%' }}>Filename</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <audio controls>
                            <source
                              src={`${process.env.REACT_APP_SERVER_API}/videos/${rowData.doorClose}/${_id}`}
                              type="video/mp4"
                            />
                          </audio>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          After door closes
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowData.doorClose}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            );
          }}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        <BackButton />
      </div>
    </div>
  );
}

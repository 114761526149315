import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import request from '../../services/Request';

const getSSURL = `/screenSaver/edit/`;

const ScreenSaverPreview = (props) => {
  const id = props.match.params.id;
  const timeoutRef = useRef(null);
  const myRef = useRef(null);
  const [SliderData, setSliderData] = useState([]);
  const [current, setCurrent] = useState(0);
  const [referTo, setReferTo] = useState('');
  const [interval, setInterval] = useState(5);

  useEffect(() => {
    console.log(id);
    request({
      method: 'get',
      url: getSSURL + id,
    }).then((res) => {
      setSliderData(res.data.slider);
      setReferTo(res.data.referTo);
      setInterval(res.data.interval * 1000);
      console.log(res.data);
    });
  }, []);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const nextSlide = async () => {
    setCurrent(current === SliderData.length - 1 ? 0 : current + 1);
  };

  useEffect(() => {
    if (SliderData.length > 0) {
      resetTimeout();
      if (SliderData[current].video == true) {
        myRef.current.onended = function () {
          nextSlide();
        };
      } else {
        timeoutRef.current = setTimeout(nextSlide, interval);
      }
      return () => {
        resetTimeout();
      };
    }
  }, [current, SliderData]);

  return (
    <div
      className="l-slider"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setCurrent(current == SliderData.length - 1 ? 0 : current + 1);
      }}
    >
      {SliderData.map((slide, index) => {
        return (
          <div className={index === current ? 'l-slide active' : 'l-slide'} key={index}>
            {slide.video == true
              ? index === current && (
                  <video
                    ref={myRef}
                    src={`${process.env.REACT_APP_SERVER_API}/videos/${slide.url}/${referTo}`}
                    alt="idle video"
                    className="l-video"
                    autoPlay
                    muted
                  />
                )
              : index === current && (
                  <img
                    src={`${process.env.REACT_APP_SERVER_API}/pictures/${slide.url}/${referTo}`}
                    alt="idle_image"
                    className="l-image"
                  />
                )}
          </div>
        );
      })}
    </div>
  );
};

export default ScreenSaverPreview;

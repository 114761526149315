export const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'fr',
    name: 'French',
    country_code: 'fr',
  },
  {
    code: 'es',
    name: 'Spanish',
    country_code: 'es',
  },
];

export const languages_noEn = [
  {
    code: 'fr',
    name: 'French',
    country_code: 'fr',
  },
  {
    code: 'es',
    name: 'Spanish',
    country_code: 'es',
  },
];

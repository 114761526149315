import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import { useForm, Controller } from 'react-hook-form';
import request from '../../services/Request';
import '../user/form.css';
import { languages } from '../../staticData/languages';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Modal } from 'react-bootstrap';

// material
import {
  FormControlLabel,
  RadioGroup,
  Grid,
  Radio,
  TextField,
  Checkbox,
  Button,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { constant } from 'lodash';
import { enIN } from 'date-fns/locale';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AddPlan(props) {
  const classes = useStyles();
  const { data, id, allProds, setReflesh, reflesh, finalProd, ...rest } = props;
  const [code, setCode] = useState(data && data.plans ? data.plans.plan : []);
  var date = new Date();

  const defaultValues =
    data && data.plans
      ? {
          plan: data.plans.plan,
          start: data.plans.start,
          end: data.plans.end,
        }
      : {
          plan: [],
          start: new Date(new Date().setHours(0, 0, 0, 0)),
          end: new Date(new Date().setHours(23, 59, 59, 999)),
        };

  const {
    reset,
    handleSubmit,
    setValue,
    setError,
    watch,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const watchShowStart = watch('start');

  const [plan, setPlan] = useState([]);

  useEffect(() => {
    request({
      method: 'get',
      url: `/products/${id}/plan`,
    }).then((res) => {
      res.data.forEach((e) => {
        e.name = e.name.en;
      });
      setPlan(res.data);
    });
  }, []);

  useEffect(async () => {
    console.log(data);
    reset(defaultValues);
    setCode(data && data.plans ? data.plans.plan : []);
  }, [props.show]);

  const onSubmit = async (formData) => {
    // const index = allProds.findIndex((e) => e.id?._id === data?.id._id);
    const index2 = finalProd.findIndex((e) => e.id?._id === data?.id._id);

    // if (index > -1) {
    //   allProds[index].plans = formData;
    // }

    if (index2 > -1) {
      finalProd[index2].plans = formData;
    }
    props.onHide();
    // await request({
    //   method: 'post',
    //   url: '',
    //   data: {
    //     machineID: data.map((e) => e._id),
    //   },
    // }).then((res) => {
    //   Swal.fire({
    //     text: `The plan has been updated!`,
    //     icon: 'success',
    //   }).then(() => {
    //     setReflesh(!reflesh);
    //   });
    // });
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.2rem' }}>
          Add a Plan
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body" style={{ marginTop: '-30px' }}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="start"
                      render={({ field: { ref, ...rest } }) => (
                        <KeyboardDateTimePicker
                          label="Select start date"
                          format="MM/dd/yyyy"
                          margin="normal"
                          fullWidth
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="end"
                      render={({ field: { ref, ...rest } }) => (
                        <KeyboardDateTimePicker
                          label="Select End date"
                          format="MM/dd/yyyy"
                          margin="normal"
                          fullWidth
                          minDate={new Date(watchShowStart)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={12}>
                  <Controller
                    name="plan"
                    rules={{ required: 'Plan is required' }}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        id="plan"
                        value={code}
                        options={plan}
                        disableCloseOnSelect
                        onChange={(event, value) => {
                          setCode(value);
                          field.onChange(value);
                        }}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Plans" />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Add/Edit the plans
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

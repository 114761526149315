import { Modal } from 'react-bootstrap';
import '../../items/formFields.css';
import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import { v4 as uuidv4 } from 'uuid';
import request from '../../../services/Request';
import { ProgressBar } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const SSURL = '/screenSaver/create';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function CreateSS(props) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [counter, setCounter] = useState('5');
  const [e1, setE1] = useState('');
  const [e2, setE2] = useState('');
  const [percent, setPercent] = useState(0);
  const [formData, setFormData] = useState(new FormData());
  const [inputFields, setInputFields] = useState([{ id: uuidv4(), file: '', promo: '' }]);

  const cleanUP = () => {
    setName('');
    setFormData(new FormData());
    setInputFields([{ id: uuidv4(), file: '' }]);
    setPercent(0);
    setE1('');
    setE2('');
    setCounter('5');
  };

  useEffect(() => {
    cleanUP();
  }, [props.show]);

  const handlePromoChange = (index, e) => {
    const { value } = e.target;
    const list = [...inputFields];
    list[index].promo = value;
    setInputFields(list);
    console.log(list);
  };

  const onSubmit = async (e) => {
    let go = true;
    if (name === '') {
      setE1('Please enter a name for your screen saver');
      go = false;
    }
    if (!inputFields[0].file) {
      setE2('Screen saver must contain at least one picture or video');
      go = false;
    }
    for (var value of formData.values()) {
      console.log(value);
    }
    formData.set('fields', JSON.stringify(inputFields));
    e.preventDefault();
    if (go) {
      formData.set('name', name);
      formData.set('interval', counter);
      await request({
        method: 'post',
        url: SSURL,
        data: formData,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setPercent(percent);
          }
        },
      })
        .then((res) => {
          setPercent(100, () => {
            setTimeout(() => {
              setPercent(0);
            }, 1000);
          });
          Swal.fire({
            text: `New screen Saver has been successfully created`,
            icon: 'success',
          }).then(() => {
            props.onHide();
            props.reflesh ? props.setReflesh(false) : props.setReflesh(true);
          });
        })
        .catch((err) => {
          console.log(err.response.data.message);
          setE2(err.response.data.message);
        });
    }
  };

  const onFileChange = (id, e) => {
    setPercent(0);
    formData.set('referTo', props._id);
    if (e.target.files[0]) {
      setE2('');
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          var FormValues = formData.getAll('picture');
          console.log(FormValues);
          var index = FormValues.findIndex((e) => id === e.name.split('.').slice(0, -1).join('.'));
          if (index > -1) {
            FormValues.splice(index, 1);
            formData.delete('picture');
            console.log(FormValues);
            FormValues.forEach((e) => {
              formData.append('picture', e);
            });
          }
          const extention = e.target.files[0].name.split('.').pop();
          formData.append('picture', e.target.files[0], `${e.target.files[0].name}`);
          i.file = e.target.files[0].name;
          // formData.set('id', e.target.files[0]);
          // i.file = e.target.files[0].name
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { id: uuidv4(), file: null }]);
  };

  const handleRemoveFields = (id) => {
    console.log(id);
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    var FormValues = formData.getAll('picture');
    console.log(FormValues);
    var i = FormValues.findIndex((e) => id === e.name.split('.').slice(0, -1).join('.'));
    if (i > -1) {
      FormValues.splice(i, 1);
      formData.delete('picture');
      console.log(FormValues);
      FormValues.forEach((e) => {
        formData.append('picture', e);
      });
    }
    console.log(i);
  };

  return (
    <Modal className="modal-w" {...props} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add New Screen Saver</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form className={classes.root} onSubmit={onSubmit}>
          <TextField
            name="name"
            label="name"
            variant="filled"
            value={name}
            onChange={(e) => {
              e.target.value == '' ? setE1('Please enter a name for your screen saver') : setE1('');
              setName(e.target.value);
            }}
          />
          <TextField
            style={{ width: 219 }}
            name="counter"
            label="swapping images interval(s)"
            variant="filled"
            type="number"
            value={counter}
            inputProps={{
              step: '0.1',
              min: '0',
              max: '100',
            }}
            onChange={(e) => {
              e.target.value == '' ? setE1('Please enter a name for your screen saver') : setE1('');
              setCounter(e.target.value);
            }}
          />
          <p className="signUpErrorMessage" style={{ marginLeft: '10px' }}>
            {e1 != '' && e1}
          </p>
          {inputFields.length > 0 &&
            inputFields.map((inputField, index) => {
              return (
                <div key={inputField.id}>
                  <div style={{ margin: '8px', display: 'inline-block' }}>
                    {/* <input
                        {...register("file", { required: true})}
                        onChange={event => onFileChange(inputField.id, event)}
                        name="file"
                        type="file"
                        accept="video/*, image/*"
                    /> */}
                    <Button variant="contained" component="label">
                      Upload File
                      <input
                        type="file"
                        name="file"
                        accept=".mp4,.jpg,.png,.jpeg"
                        onChange={(event) => onFileChange(inputField.id, event)}
                        hidden
                      />
                    </Button>
                    <span style={{ marginLeft: '20px' }}>{inputField.file}</span>
                  </div>
                  <div style={{ display: 'inline-block' }}>
                    <IconButton
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={handleAddFields}>
                      <AddIcon />
                    </IconButton>
                  </div>
                  <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <FormControl fullWidth>
                      <InputLabel style={{ paddingLeft: '20px' }}>
                        Link a promotion to your screen saver (optional)
                      </InputLabel>
                      <Select
                        value={inputField.promo}
                        defaultValue=""
                        variant="outlined"
                        onChange={(e) => handlePromoChange(index, e)}
                      >
                        <MenuItem value="">None</MenuItem>
                        {props.promo?.map((e) => {
                          return <MenuItem value={e._id}>{e.title}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              );
            })}
          <p style={{ marginLeft: '10px' }} className="signUpErrorMessage">
            {e2 != '' && e2}
          </p>
          {percent > 0 && (
            <ProgressBar
              style={{ marginLeft: '9px', marginTop: '10px' }}
              now={percent}
              active
              label={`${percent}%`}
            />
          )}
          <p style={{ marginLeft: '10px', marginTop: '10px' }}>
            Images and Videos must be 1920 (h) x 1080 (w) pixels.
          </p>
          <p style={{ marginLeft: '10px', marginTop: '10px' }}>
            Only jpeg, jpg, png and mp4 are accepted! Total files size can not be exceed 100MB.
          </p>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            endIcon={<Icon>send</Icon>}
            onClick={onSubmit}
          >
            Send
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

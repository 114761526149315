import React, { useState, useEffect } from 'react';
import request from '../../../services/Request';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import CreatePromo from './createPromo';
import Button from '@material-ui/core/Button';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Swal from 'sweetalert2';
import QRpage from './QRpage';
import { userLimits } from '../uitls/userLimits';
import { CloudDownload } from '@material-ui/icons';
import { ExportToCsv } from 'export-to-csv';
import { UserContext } from '../../contexts/userContext';
import { useContext } from 'react';

const getPromoURL = `/promos/`;
const getGroupsURL = `/groupsAndMachinesForReport/`;

export default function Promo(props) {
  const { userEmail } = useContext(UserContext);
  const { showAdmin, id, tableTitle, superAdmin, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Promotions',
    'edit_promo'
  );
  const [update, setUpdate] = useState('false');
  const [show, setShow] = useState(false);
  const [showQ, setShowQ] = useState(false);
  const [buttonTitle, setButtonTitle] = useState('Show Expired Promos');
  const [reflesh, setreflesh] = useState(false);
  const [promo, setPromo] = useState([]);
  const [expire, setExpire] = useState([]);
  const [active, setActive] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [machine, setMachine] = useState([]);
  const [group, setGroup] = useState([]);

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_promo') ? localStorage.getItem('table_view_promo') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_promo', pagination);
  }, [pagination]);

  const [qrcode, setQrcode] = useState('');
  const downloadCSV = () => {
    const sample = ['ABCD1234', '4321DCBA'];
    const csvJson = sample.map((e) => {
      return {
        code: e,
      };
    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      useTextFile: false,
      filename: 'Sample CSV',
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csvJson);
  };

  useEffect(() => {
    let isCancelled = false;
    // getMyMachines
    setLoading(false);

    async function getGroups() {
      await request({
        method: 'get',
        url: getGroupsURL + userEmail,
      }).then((res) => {
        res.data.machines.forEach((element) => {
          element.product?.forEach((e) => {
            if (e?.id) {
              e.id.name = e?.id?.name?.en;
            }
          });
        });
        if (!isCancelled) {
          console.log('machines', res.data.machines);
          setMachine(res.data.machines);
          setGroup(res.data.groups);
          const gp = res.data.groups.map((e) => e._id);
          const mc = res.data.machines.map((e) => e._id);
          getMyGroups(gp, mc);
        }
      });
    }
    getGroups();

    async function getMyGroups(gp, mc) {
      console.log('gp', gp);
      await request({
        method: 'post',
        url: !props.match.params.group && superAdmin ? getPromoURL : 'promosByEmail',
        data: {
          id: id,
          userEmail: userEmail,
          showAdmin: showAdmin,
          group: gp,
          machine: mc,
        },
      }).then((res) => {
        if (!isCancelled) {
          console.log('res.', res.data);
          res.data.forEach((e) => {
            e?.product?.forEach((a) => {
              a.name = a.name.en;
            });
            e?.category?.forEach((a) => {
              a.name = a.name.en;
            });
          });
          const today = new Date();
          const activePromo = res.data.filter((e) => new Date(e.endDate) > today);
          const expirePromo = res.data.filter((e) => new Date(e.endDate) < today);
          setPromo(buttonTitle === 'Show Expired Promos' ? activePromo : expirePromo);
          setActive(activePromo);
          setExpire(expirePromo);
          setLoading(true);
        }
      });
    }

    return () => {
      isCancelled = true;
    };
  }, [reflesh, buttonTitle]);

  const deleteGroup = async (id) => {
    await request({
      method: 'delete',
      url: getPromoURL + id,
    }).then();
    reflesh ? setreflesh(false) : setreflesh(true);
  };

  const columns = [
    { title: 'Description', field: 'title' },
    {
      title: 'Code',
      field: 'code',
      render: (rowData) => {
        if (rowData.promoType === 'oneTime' && rowData.isOne) {
          return rowData.originCode[0];
        } else {
          return rowData.code;
        }
      },
    },
    {
      title: 'Promo Type',
      field: 'promoType',
      render: (rowData) => {
        const string = rowData.promoType;
        if (string?.length > 1) {
          return string.charAt(0).toUpperCase() + string.slice(1);
        } else {
          return '';
        }
      },
    },
    {
      title: 'Amount',
      field: 'amount',
      render: (rowData) =>
        rowData.percentage ? `${(rowData.amount * 100).toFixed(2)}%` : `$${rowData.amount}`,
    },
    {
      title: 'Start Date',
      field: 'startDate',
      render: (rowData) =>
        new Date(rowData.startDate).toISOString().replace('T', ' ').substr(0, 19),
    },
    {
      title: 'End Date',
      field: 'endDate',
      render: (rowData) => new Date(rowData.endDate).toISOString().replace('T', ' ').substr(0, 19),
    },
    {
      title: 'Product/Category',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) =>
        rowData.cateOrProd === 'All Products' ? (
          `All Products`
        ) : rowData.cateOrProd === 'cate' ? (
          rowData.category?.length > 1 ? (
            <Button
              variant="contained"
              style={{ fontSize: '0.7rem', minHeight: '50px' }}
              onClick={() => {
                let html = '<br/>';
                rowData.category.map((e) => {
                  html += `<p>${e.name}</p>`;
                });
                Swal.fire({
                  html: html,
                  showCloseButton: true,
                  showConfirmButton: false,
                });
              }}
            >
              Show Categoryies
            </Button>
          ) : rowData.category[0] ? (
            `Category: ` + rowData?.category[0].name
          ) : (
            `Category deleted`
          )
        ) : rowData.cateOrProd === 'prod' ? (
          rowData.product.length > 1 ? (
            <Button
              variant="contained"
              style={{ fontSize: '0.7rem', minHeight: '50px' }}
              onClick={() => {
                let html = '<br/>';
                rowData.product.map((e) => {
                  html += `<p>${e.name}</p>`;
                });
                Swal.fire({
                  html: html,
                  showCloseButton: true,
                  showConfirmButton: false,
                });
              }}
            >
              Show Products
            </Button>
          ) : rowData.product?.length > 0 ? (
            `Product: ` + rowData.product[0].name
          ) : (
            'Product deleted'
          )
        ) : null,
    },
    { title: 'Products to buy', field: 'productsToBuy' },
    {
      title: 'Machine / Group',
      field: 'machine',
      render: (rowData) =>
        rowData.machine.all
          ? `All Machines`
          : rowData.machine.group
          ? rowData.machine.group.name +
            ' (' +
            rowData.machine.group.machineID.map((a) => a.name) +
            ') '
          : rowData.machine.id
          ? rowData.machine.id.name
          : '',
    },
    {
      title: 'Is Active',
      field: 'productsToBuy',
      render: (rowData) => (
        <div>
          {rowData.active === true && new Date(rowData.endDate) > new Date() ? (
            <StopRoundedIcon style={{ color: 'green' }} />
          ) : (
            <StopRoundedIcon style={{ color: 'red' }} />
          )}{' '}
        </div>
      ),
    },
    {
      title: 'Created By',
      field: 'created_by',
      render: (rowData) =>
        rowData?.created_by?.name ? (
          <div>
            <div> Name : {rowData?.created_by?.name}</div>
            <div>Email : {rowData?.created_by?.email}</div>
          </div>
        ) : (
          <div></div>
        ),
    },
  ];
  return (
    <div>
      {!loading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={promo}
          title={tableTitle}
          actions={[
            {
              icon: QrCodeIcon,
              tooltip: 'Generate QR Code',
              onClick: (event, rowData) => {
                if (
                  rowData.promoType === 'oneTime' &&
                  rowData.isOne &&
                  rowData.originCode.length > 0
                ) {
                  setQrcode(rowData.originCode[0]);
                  setShowQ(true);
                } else {
                  setShowQ(true);
                  setQrcode(rowData.code);
                }
              },
            },
            access && {
              icon: 'add',
              tooltip: 'Create A New Promotion',
              isFreeAction: true,
              onClick: (event) => {
                setUpdate('false');
                setData(null);
                setShow(true);
                reflesh ? setreflesh(false) : setreflesh(true);
              },
            },
            {
              icon: CloudDownload,
              tooltip: 'Download sample csv',
              isFreeAction: true,
              onClick: (event) => {
                downloadCSV();
              },
            },
            () =>
              access && {
                icon: 'edit',
                tooltip: 'Edit Promo',
                onClick: (event, rowData) => {
                  setUpdate('true');
                  setData(rowData);
                  setShow(true);
                  reflesh ? setreflesh(false) : setreflesh(true);
                },
              },
          ]}
          editable={
            access && {
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    deleteGroup(oldData._id);
                    Swal.fire({
                      text: `The promotion has been successfully deleted: ${oldData.title}`,
                      icon: 'success',
                    });
                    resolve();
                  }, 1000);
                }),
            }
          }
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        <BackButton />
        <Button
          style={{ marginTop: -36, display: 'flex', justifyContent: 'flex-front', marginLeft: 15 }}
          variant="contained"
          color="primary"
          onClick={() => {
            setButtonTitle(
              buttonTitle === 'Show Expired Promos' ? 'Show Active Promos' : 'Show Expired Promos'
            );
            setPromo(buttonTitle === 'Show Expired Promos' ? expire : active);
          }}
        >
          {buttonTitle}
        </Button>
        <CreatePromo
          group={group}
          machine={machine}
          showAdmin={showAdmin}
          userEmail={userEmail}
          id={id}
          reflesh={+reflesh}
          setreflesh={setreflesh}
          update={update}
          show={show}
          data={data}
          setData={setData}
          onHide={() => setShow(false)}
        />
        <QRpage show={showQ} qrcode={qrcode} onHide={() => setShowQ(false)} />
      </div>
    </div>
  );
}

import React from 'react';
import { useState, useEffect } from 'react';
import { Modal, Container, Col, Row, Carousel } from 'react-bootstrap';
import ImageSlider from '../items/slides/ImageSlider';

export default function ProductInfo(props) {
  const [current, setCurrent] = useState(0);
  var length = props.image.length;
  props.video ? (length = length + 1) : (length = length);

  const scrollToImage = (event) => {
    if (document.querySelector('.activeImg') !== null) {
      document.querySelector('.activeImg').scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    scrollToImage();
  }, [current]);

  const lineStyle = {
    color: 'rgb(63, 81, 181)',
    backgroundColor: 'rgb(63, 81, 181)',
    height: '5',
  };

  const textStyle = {
    textAlign: 'center',
    justifyContent: 'center',
  };

  const videoStyle = {
    borderRadius: '6px',
  };

  const rowStyle = {
    justifyContent: 'center',
  };

  return (
    <Modal
      {...props}
      centered
      className="mt-5"
      style={{
        maxHeight: '90%',
      }}
      scrollable
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Product info: {props.name?.en}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row style={rowStyle}>
            {props.image.map((slide, index) => {
              return (
                <div
                  className={`${
                    index === current ? 'slideActive' : 'slide'
                  } mt-2 d-flex justify-content-center align-items-center`}
                  key={index}
                  style={{ maxWidth: '500px', maxHeight: '500px' }}
                >
                  {index === current && (
                    <img
                      src={`${process.env.REACT_APP_SERVER_API}/pictures/${slide}/${props.referTo}`}
                      alt="travelImage"
                      className="mb-4"
                      style={{ maxWidth: '100%', maxHeight: '90%' }}
                    />
                  )}
                </div>
              );
            })}
            {props.video && (
              <div className={`${current === length - 1 ? 'slideActive' : 'slide'} mb-2`}>
                {current === length - 1 && (
                  <video width="380" controls>
                    <source
                      src={`${process.env.REACT_APP_SERVER_API}/videos/${props.video}/${props.referTo}`}
                      type="video/mp4"
                    />
                  </video>
                )}
              </div>
            )}
          </Row>
          <Row style={rowStyle}>
            <ImageSlider
              slides={props.image}
              index={current}
              nextSlide={nextSlide}
              prevSlide={prevSlide}
              referTo={props.referTo}
              video={props?.video}
            />
          </Row>
          <Row
            style={{
              outline: 'thick double lightgrey',
              outlineOffset: '4px',
            }}
          >
            <Col>
              <Row style={textStyle}>
                <h4>
                  <strong>
                    Name: <br />{' '}
                  </strong>{' '}
                  {props.name?.en}
                </h4>
              </Row>
              <hr style={lineStyle} />
              <Row style={textStyle}>
                <h4>
                  <strong>
                    Description: <br />{' '}
                  </strong>{' '}
                  {props.description?.en}
                </h4>
              </Row>
            </Col>
            <Col>
              <Row style={textStyle}>
                <Col>
                  <h4>
                    <strong>
                      Category: <br />{' '}
                    </strong>
                    {props.category?.map((e, i) => {
                      if (i === props.category.length - 1) return e.name.en;
                      else return e.name.en + ', ';
                    })}
                  </h4>
                </Col>
                <Col>
                  <h4>
                    <strong>
                      Brand: <br />{' '}
                    </strong>{' '}
                    {props.brand?.name?.en}
                  </h4>
                </Col>
              </Row>
              <hr style={lineStyle} />
              <Row style={textStyle}>
                <Col>
                  <h4>
                    <strong>
                      In stock: <br />{' '}
                    </strong>{' '}
                    {props.quantity}
                  </h4>
                </Col>
                <Col>
                  <h4>
                    <strong>
                      Price: <br />{' '}
                    </strong>{' '}
                    {props.price}
                  </h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

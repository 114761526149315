import RotateLoader from 'react-spinners/RotateLoader';
import React, { useState } from 'react';

const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: '1000',
};

export default function LoadingIcon(props) {
  let [color, setColor] = useState('#3f51b5');

  return (
    <div style={style}>
      <RotateLoader size={15} color={color}></RotateLoader>
    </div>
  );
}

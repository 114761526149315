import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
export default function BrandChart(props) {
  const { data, machinesName } = props;
  const [cate, setCate] = useState([]);
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const result = [];
      const detail = [];
      data.forEach((e) => {
        result.push(e.name);
        detail.push(e.detail);
      });
      setCate(result);
      setDetail(detail);
    }
  }, [data]);

  const options = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: cate,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],

    title: {
      text: 'Brand Page(' + machinesName + ')',
      offsetY: 4,
    },
  };

  return <ReactApexChart options={options} series={detail} type="pie" height={250} />;
  // return null;
}

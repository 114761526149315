import React, { useState, useContext, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import TCNPropertyModal from './TCNPropertyModal';

export default function TCNLane(props) {
  const [show, setShow] = useState(false);
  const {
    item,
    index,
    board,
    setBoard,
    length,
    max_stock,
    prods,
    MULTIPLE,
    totalWidth,
    plano,
    floor,
    floors,
  } = props;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'image',
      item: { prod: item },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (monitor.didDrop()) {
          // const { x, y } = monitor.getClientOffset();
          // const myEle = document.elementFromPoint(x, y);
          // if (myEle && myEle.id) {
          //   console.log(myEle.id);
          // } else {
          // }
          board.splice(index, 1);
          setBoard([...board]);
        }
      },
    }),
    [board]
  );
  // werweerwerw
  return (
    <div
      style={{ display: 'flex', width: `${(plano.divider + length) * MULTIPLE}px` }}
      onClick={() => {
        // console.log(floors[floor - 1].lines[index].num);
      }}
    >
      <div
        style={{ width: `${plano.divider * MULTIPLE}px`, background: 'red', height: '100%' }}
      ></div>
      <div style={{ width: `${length * MULTIPLE}px`, background: 'yellow', height: '100%' }}>
        <img
          onClick={() => {
            console.log(show);
            setShow(true);
          }}
          id={`TCNPlano-${index}`}
          ref={drag}
          style={{
            maxWidth: '100%',
            maxHeight: '100px',
            border: isDragging ? '5px solid pink' : '0px',
          }}
          alt=""
          src={
            !item || item?.empty
              ? `https://api.magexusa.com/api/pictures/GlOnLonmt29nREuLYg5VK-empty.jpg/tianlangwu001@gmail.com`
              : `${process.env.REACT_APP_SERVER_API}/pictures/${item.productPictures[0]}/${item.referTo}`
          }
        ></img>
        <div>Max {max_stock}</div>
        {floors[floor - 1].lines[index] && <div>{floors[floor - 1].lines[index].num}</div>}

        <TCNPropertyModal
          prods={prods}
          show={show}
          board={board}
          setBoard={setBoard}
          index={index}
          setShow={setShow}
          totalWidth={totalWidth}
          plano={plano}
          onHide={() => setShow(false)}
        />
      </div>
    </div>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/userContext';
import { useHistory } from 'react-router';
import request from '../../services/Request';
import MaterialTable from 'material-table';
import { Button, makeStyles } from '@material-ui/core';
import LoadingIcon from '../items/buttons/LoadingIcon';
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';
import BallotIcon from '@material-ui/icons/Ballot';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import FilterIcon from '@material-ui/icons/Filter';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import BackButton from '../items/buttons/BackButton';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import ComputerIcon from '@material-ui/icons/Computer';
import BarChartIcon from '@mui/icons-material/BarChart';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Swal from 'sweetalert2';
import { Movie } from '@material-ui/icons';
import EditFeatures from '../user/EditFeatures';
import DownloadIcon from '@mui/icons-material/Download';
import { ExportToCsv } from 'export-to-csv';
import PlaceProduct from '../user/PlaceProduct';
import { InsertDriveFile } from '@material-ui/icons';

const getAllEmployeesURL = `/users/getAllEmployees`;
const showUsersURL = `/users/getAllEmployees/`;
const deleteUserURL = `/users/delete/`;
const updateUserURL = `/users/updateAccess/`;
const getAdminProds = `/products/`;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(-5.6),
    marginRight: theme.spacing(40),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ViewAllGroups() {
  const { role } = useContext(UserContext);
  const classes = useStyles();

  const initialState = role === 'SuperAdmin' ? true : false;
  const initialTitle = role === 'SuperAdmin' ? 'Groups' : 'Employees Accounts';

  let history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [show, setshow] = useState(false);
  const [showProduct, setshowProd] = useState(false);
  const [rowdata, setrowdata] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [reflesh, setReflesh] = useState(false);
  const [showIcon, setShowIcon] = useState(initialState);
  const [isSuperAdmin] = useState(initialState);
  const [isloading, setIsLoading] = useState(false);

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_allGroup') ? localStorage.getItem('table_view_allGroup') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_allGroup', pagination);
  }, [pagination]);

  useEffect(() => {
    let isCancelled = false;

    //getAllEmployees
    async function getAllEmployees() {
      await request({
        method: 'get',
        url: getAllEmployeesURL,
      }).then((res) => {
        if (!isCancelled) {
          setEmployees(res.data);
          setIsLoading(true);
          console.log(res.data);
        }
      });
    }

    getAllEmployees();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  const showUsers = async (email, groupname) => {
    setIsLoading(false);
    await request({
      method: 'get',
      url: showUsersURL + email,
    }).then((res) => {
      setEmployees(res.data);
      setTitle('All Employees of ' + groupname);
      setShowIcon(false);
      setIsLoading(true);
    });
  };

  const deleteUser = async (id, referTo) => {
    await request({
      method: 'delete',
      url: deleteUserURL + id,
    }).then(
      Swal.fire({
        text: `The group has been successfully deleted`,
        icon: 'success',
      })
    );
    if (isSuperAdmin) {
      if (!showIcon) {
        showUsers(referTo);
      } else reflesh ? setReflesh(false) : setReflesh(true);
    } else reflesh ? setReflesh(false) : setReflesh(true);
  };

  const downloadProductCsv = async (email) => {
    await request({
      method: 'get',
      url: getAdminProds + email,
    }).then((res) => {
      const newArray = [];
      res.data.forEach((element) => {
        const newObj = {};
        newObj.name = element.name?.en;
        newObj.upc = element.upc;
        newArray.push(newObj);
      });

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'My Awesome CSV',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(newArray);
    });
  };

  const columns =
    isSuperAdmin && showIcon
      ? [
          { title: 'Group Name', field: 'groupname', editable: 'never' },
          { title: 'Group Code', field: 'groupCode', editable: 'never' },
          { title: 'Email', field: 'email', editable: 'never' },
        ]
      : [
          { title: 'Last Name', field: 'lastname', editable: 'never' },
          { title: 'First Name', field: 'firstname', editable: 'never' },
          {
            title: 'Access Level',
            render: (rowData) => (rowData.fullAccess === true ? 'Full Access' : 'Limited Access'),
            lookup: { true: 'Full Access', false: 'Limited Access' },
          },
          { title: 'Email', field: 'email', editable: 'never' },
        ];
  return (
    <div>
      {!isloading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={employees}
          title={title}
          localization={{ body: { editRow: { deleteText: 'Are you sure to delete this user' } } }}
          editable={{
            onRowDelete: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  deleteUser(newData._id, newData.referTo);
                  resolve();
                }, 1000);
              }),
          }}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                request({
                  method: 'patch',
                  url: updateUserURL + rowData._id,
                }).then(() => {
                  rowData.fullAccess = newValue;
                  window.location.reload();
                });
                setTimeout(resolve, 1000);
              });
            },
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Create Admin',
              isFreeAction: true,
              onClick: (event) => {
                history.push('/dashboard/signUp');
              },
            },
            () =>
              showIcon && {
                icon: 'people',
                tooltip: 'show users',
                onClick: (event, rowData) => {
                  history.push(
                    `/dashboard/viewmyusers/${rowData.email}/${rowData.groupname}/${rowData._id}`
                  );
                },
              },
            () =>
              showIcon && {
                icon: PeopleOutlineIcon,
                tooltip: 'show groups',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/group/${rowData.email}/${rowData.groupname}`);
                },
              },
            () =>
              showIcon && {
                icon: LanguageSharpIcon,
                tooltip: 'show machines',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/viewmymachines/${rowData.email}/${rowData.groupname}`);
                },
              },
            () =>
              showIcon && {
                icon: BallotOutlinedIcon,
                tooltip: 'show categories',
                onClick: (event, rowData) => {
                  history.push(
                    `/dashboard/manage-all-categories/${rowData.email}/${rowData.groupname}`
                  );
                },
              },
            () =>
              showIcon && {
                icon: BallotIcon,
                tooltip: 'show products',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/products/${rowData.email}`);
                },
              },
            () =>
              showIcon && {
                icon: ListAltOutlinedIcon,
                tooltip: 'show orders',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/order/${rowData.email}/${rowData.groupname}`);
                },
              },
            () =>
              showIcon && {
                icon: MoneyOffIcon,
                tooltip: 'show promos',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/promo/${rowData.email}/${rowData.groupname}`);
                },
              },
            () =>
              showIcon && {
                icon: FilterIcon,
                tooltip: 'show brands',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/brand/${rowData.email}/${rowData.groupname}`);
                },
              },
            () =>
              showIcon && {
                icon: ComputerIcon,
                tooltip: 'show planograms',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/planogram/${rowData.email}/${rowData.groupname}`);
                },
              },
            () =>
              showIcon && {
                icon: Movie,
                tooltip: 'show screen saver',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/screenSaver/${rowData.email}/${rowData.groupname}`);
                },
              },
            () =>
              showIcon && {
                icon: MailOutlineIcon,
                tooltip: 'show receipt',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/emailReceipt/${rowData.email}/${rowData.groupname}`);
                },
              },
            () =>
              showIcon && {
                icon: BarChartIcon,
                tooltip: 'show report',
                onClick: (event, rowData) => {
                  history.push(`/dashboard/report/${rowData.email}/${rowData.groupname}`);
                },
              },
            () =>
              showIcon && {
                icon: SettingsSuggestIcon,
                tooltip: 'edit features',
                onClick: (event, rowData) => {
                  setshow(true);
                  setrowdata(rowData);
                },
              },
            () =>
              showIcon && {
                icon: InsertDriveFile,
                tooltip: 'Move Product',
                onClick: (event, rowData) => {
                  setshowProd(true);
                  setrowdata(rowData);
                },
              },
            () =>
              showIcon && {
                icon: DownloadIcon,
                tooltip: 'download products csv',
                onClick: (event, rowData) => {
                  downloadProductCsv(rowData.email);
                },
              },
            // showIcon &&
            // {
            //   icon: 'addCircleOutlineIcon',
            //   tooltip: 'Add Machine To This Account',
            //   onClick: (event, rowData) => { showUsers(rowData._id); }
            // },
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [20, 50, 100],
          }}
        />
        <BackButton />
        <PlaceProduct
          employees={employees}
          show={showProduct}
          data={rowdata}
          setReflesh={setReflesh}
          reflesh={reflesh}
          onHide={() => {
            setshowProd(false);
          }}
        />
        <EditFeatures
          show={show}
          data={rowdata}
          setReflesh={setReflesh}
          reflesh={reflesh}
          onHide={() => {
            setshow(false);
          }}
        />
      </div>
    </div>
  );
}

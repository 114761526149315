import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import request from '../../../services/Request';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import BackButton from '../../items/buttons/BackButton';
import Swal from 'sweetalert2';
import { userLimits } from '../uitls/userLimits';

const getAllEmployeesURL = `/users/hf/getUsers`;
const deleteUserURL = `/users/delete/`;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(-5.6),
    marginRight: theme.spacing(40),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ViewHFuser(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  const dbaUser = user.dba;
  const classes = useStyles();
  const { id, tableTitle, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Users'
  );

  let history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [reflesh, setReflesh] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [rowdata, setrowdata] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    //getAllEmployees
    async function getAllEmployees() {
      await request({
        method: 'post',
        url: getAllEmployeesURL,
      }).then((res) => {
        if (!isCancelled) {
          console.log('res.data', res.data);
          setEmployees(res.data);
          setIsLoading(true);
        }
      });
    }

    getAllEmployees();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  // const deleteUser = async (id, referTo) => {
  //   await request({
  //     method: 'delete',
  //     url: deleteUserURL + id,
  //   }).then();
  //   Swal.fire({
  //     text: `The user has been successfully deleted`,
  //     icon: 'success',
  //   });
  //   reflesh ? setReflesh(false) : setReflesh(true);
  // };

  const columns = [
    { title: 'ID', field: 'hfid', editable: 'never' },
    {
      title: 'Usage',
      width: '75%',
      render: (rowData) => {
        const a = rowData?.categories?.map((cate) => {
          return (
            <span style={{ marginLeft: '8px', marginRight: '8px' }}>
              <span style={{ fontWeight: 'bold' }}>{cate.category?.name?.en}</span>:
              <span style={{ color: 'blue' }}>{cate.quantity}</span>
            </span>
          );
        });
        return a;
      },
    },
  ];

  return (
    <div>
      {!isloading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          columns={columns}
          data={employees}
          title={tableTitle}
          localization={{ body: { editRow: { deleteText: 'Are you sure to delete this user' } } }}
          // editable={{
          //   onRowDelete: (newData, oldData) =>
          //     new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         deleteUser(newData._id, newData.referTo);
          //         resolve();
          //       }, 1000);
          //     }),
          // }}

          options={{
            actionsColumnIndex: -1,
            rowStyle: (rowData) => {
              if (rowData.dba) {
                return {
                  backgroundColor: 'lightblue',
                };
              }
            },
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        <BackButton />

        <div className={classes.paper}></div>
      </div>
    </div>
  );
}

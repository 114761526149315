import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import request from '../../services/Request';
import '../user/form.css';
import { languages } from '../../staticData/languages';
import { currencies } from '../../staticData/currencies';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Modal } from 'react-bootstrap';
import TimezoneSelect from 'react-timezone-select';
import { InputAdornment } from '@material-ui/core';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const updateMachineLanguageURL = '/machines/updateLanguage';

export default function UpdateLanguages(props) {
  const {
    reset,
    handleSubmit,
    setValue,
    register,
    watch,
    setError,
    control,
    formState: { errors },
  } = useForm({});
  const classes = useStyles();
  const { data, ...rest } = props;
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [code, setCode] = useState(data && data.length > 0 ? data[0].languages : []);
  const additionPriceOn = JSON.parse(localStorage.getItem('user'))?.features?.additionPrice;
  const watchAll = watch();
  const defaultValues = {
    lan: data[0].languages ? data[0].languages : [],
    dl_radio: data[0].tz ? (data[0].tz.tz ? '1' : '0') : '0',
    Price_radio: data[0].enablePriceChange ? (data[0].enablePriceChange ? '1' : '0') : '0',
    currency: data[0].currency?.code ? data[0].currency?.code : 'USD',
    typeA: data[0].tax_rate?.typeA ? data[0].tax_rate?.typeA : 0,
    typeB: data[0].tax_rate?.typeB ? data[0].tax_rate?.typeB : 0,
    typeC: data[0].tax_rate?.typeC ? data[0].tax_rate?.typeC : 0,
    typeD: data[0].tax_rate?.typeD ? data[0].tax_rate?.typeD : 0,
  };

  useEffect(async () => {
    reset(defaultValues);
    setSelectedTimezone(data[0].tz ? data[0].tz : {});
    if (data && data.length > 0) {
      setCode(data[0].languages);
    }
  }, [data]);

  const onSubmit = async (formData) => {
    const tax_rate = {
      typeA: parseFloat(formData.typeA),
      typeB: parseFloat(formData.typeB),
      typeC: parseFloat(formData.typeC),
      typeD: parseFloat(formData.typeD),
    };
    selectedTimezone.tz = watchAll.dl_radio === '0' ? false : true;
    const enablePriceChange = watchAll.Price_radio === '0' ? false : true;
    const index = currencies.findIndex((e) => e.code === formData.currency);
    const currency = index >= 0 ? currencies[index] : currencies[0];

    await request({
      method: 'patch',
      url: updateMachineLanguageURL,
      data: {
        machineID: data.map((e) => e._id),
        languages: formData.lan,
        tz: selectedTimezone,
        currency: currency,
        enablePriceChange: enablePriceChange,
        tax_rate: tax_rate,
      },
    }).then((res) => {
      Swal.fire({
        text: `Updated Successfully`,
        icon: 'success',
      }).then(() => {
        props.reflesh ? props.setReflesh(false) : props.setReflesh(true);
        props.onHide();
      });
    });
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.2rem' }}>
          Update Tax, Languages, timezone & currency
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <FormLabel component="legend">Tax (Percentage %)</FormLabel>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Type A"
                          name="typeA"
                          type="number"
                          inputProps={{
                            min: 0,
                            step: '0.01',
                            max: 99,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          {...register('typeA', {})}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Type B"
                          name="typeB"
                          type="number"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          inputProps={{
                            min: 0,
                            step: '0.01',
                            max: 99,
                          }}
                          {...register('typeB', {})}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Type C"
                          name="typeC"
                          type="number"
                          inputProps={{
                            min: 0,
                            step: '0.01',
                            max: 99,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          {...register('typeC', {})}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Type D"
                          name="typeD"
                          type="number"
                          inputProps={{
                            min: 0,
                            step: '0.01',
                            max: 99,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          {...register('typeD', {})}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <p style={{ marginBottom: '10px' }}>Select a currency</p>
                  <Controller
                    render={({ field }) => (
                      <Select fullWidth defaultValue="USD" {...field} variant="outlined">
                        {currencies.map((e) => {
                          return <MenuItem value={e.code}>{e.name + ' (' + e.code + ')'}</MenuItem>;
                        })}
                      </Select>
                    )}
                    name="currency"
                    control={control}
                  />
                </Grid>

                {additionPriceOn && (
                  <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                      <FormLabel component="legend">Enable/Disable Price Change</FormLabel>
                      <RadioGroup
                        row
                        name="Price_radio"
                        {...register('price_radio1', {
                          onChange: (e) => {
                            setValue('Price_radio', e.target.value);
                          },
                        })}
                      >
                        <FormControlLabel
                          style={{ minWidth: '50%' }}
                          value="1"
                          checked={watchAll.Price_radio === '1'}
                          control={<Radio />}
                          label="Enable"
                        />
                        <FormControlLabel
                          value="0"
                          checked={watchAll.Price_radio === '0'}
                          control={<Radio />}
                          label="Disable"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className="select-wrapper">
                      <p style={{ marginBottom: '10px' }}>Select a time zone</p>
                      <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <FormLabel component="legend">Daylight Saving Time</FormLabel>
                    <RadioGroup
                      row
                      name="dl_radio"
                      {...register('dl_radio1', {
                        onChange: (e) => {
                          setValue('dl_radio', e.target.value);
                        },
                      })}
                    >
                      <FormControlLabel
                        style={{ minWidth: '50%' }}
                        value="1"
                        checked={watchAll.dl_radio === '1'}
                        control={<Radio />}
                        label="On"
                      />
                      <FormControlLabel
                        value="0"
                        checked={watchAll.dl_radio === '0'}
                        control={<Radio />}
                        label="Off"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="select-wrapper">
                    <p style={{ marginBottom: '10px' }}>Select a time zone</p>
                    <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
                  </div>
                </Grid>
                <Grid item xs={12} style={{ zIndex: 0 }}>
                  <p style={{ marginBottom: '10px' }}>Select Languages</p>
                  <Controller
                    name="lan"
                    rules={{ required: 'language is required' }}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        id="lan"
                        value={code}
                        options={languages}
                        disableCloseOnSelect
                        onChange={(event, value) => {
                          setCode(value);
                          field.onChange(value);
                        }}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name + '(' + option.code + ')'}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name + '(' + option.code + ')'}
                          </React.Fragment>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Languages" />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <p style={{ marginTop: '5px', marginBottom: '10px' }}>
                The first language you select will be the primary language
              </p>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Update
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CartAlert from '../cartAlert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6BBB, #FF8E53)',
    border: 0,
    borderRadius: 30,
    marginBottom: 20,
    color: 'black',
    fontWeight: 'bold',
    padding: '13px 85px',
    textTransform: 'none',
    fontSize: 18,
    // '@media (max-width: 992px)':{
    //     padding: '8px 70px',
    // },
    '@media (max-width: 600px)': {
      padding: '8px 70px',
    },
  },
});

function AddToCardButton(props) {
  const classes = useStyles();

  const [show, setShow] = useState(false);

  function handleAddItem(e) {
    const index = props.cart?.findIndex((item) => item.id === props.id);
    console.log(index);
    if (index >= 0) {
      props.alterquantity({ id: props.id }, props.cart[index].quantity + 1);
    } else {
      props.add({
        id: props.id,
        key: props.id,
        title: props.name,
        price: parseFloat(props.price.slice(2)),
        discount: props.discount,
        percentage: props.percentage,
        discountedPrice: props.discountedPrice
          ? props.discountedPrice
          : parseFloat(props.price.slice(2)),
        image: props.image,
        buyX: props.buyX,
        quantity: 1,
      });
    }
  }

  return (
    <div>
      <CartAlert show={show} />
      <Button
        className={classes.root}
        size="large"
        onClick={(e) => {
          handleAddItem(e);
          props.decreaseStock();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 2500);
        }}
        variant="contained"
        color="secondary"
      >
        Add to cart
      </Button>
    </div>
  );
}

export default AddToCardButton;

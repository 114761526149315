import React, { useState, useEffect } from 'react';
import TCNFloor from './TCNFloor';

export default function TCNPlano({
  plano,
  prods,
  heights,
  setHeights,
  name,
  floorNumber,
  lineNumber,
  floors,
  setFloors,
  newPlano,
  setNewPlano,
  height,
  url,
  laneHeight,
}) {
  // const [heights, setHeights] = useState([]);
  const [floor, setfloor] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (url === 'INSplano') {
      for (let i = floors.length - 1; i >= 0; i--) {
        floors[i].lines.forEach((e, laneIndex) => {
          //last two Item will be 18 19 20
          const floorStartSlot = (floors.length - i - 1) * 2 * 10;
          if (floors[i].lines.length > 5) {
            if (laneIndex === floors[i].lines.length - 1) {
              e.num = floorStartSlot + 20;
            } else if (laneIndex === floors[i].lines.length - 2) {
              e.num = floorStartSlot + 19;
            } else if (laneIndex === floors[i].lines.length - 3) {
              e.num = floorStartSlot + 18;
            } else {
              e.num = floorStartSlot + laneIndex + 1;
            }
          } else {
            e.num = floorStartSlot + laneIndex + 1;
          }
        });
      }
    } else if (url === 'DELplano') {
      for (let i = floors.length - 1; i >= 0; i--) {
        floors[i].lines.forEach((e, laneIndex) => {
          //last two Item will be 18 and 20
          const floorStartSlot = (floors.length - i - 1) * 10;
          e.num = floorStartSlot + laneIndex + 1;
        });
      }
    } else {
      for (let i = floors.length - 1; i >= 0; i--) {
        floors[i].lines.forEach((e, laneIndex) => {
          //last two Item will be 18 and 20
          const floorStartSlot = (floors.length - i - 1) * 2 * 10;
          if (floors[i].lines.length > 5) {
            if (laneIndex === floors[i].lines.length - 1) {
              e.num = floorStartSlot + 20;
            } else if (laneIndex === floors[i].lines.length - 2) {
              e.num = floorStartSlot + 18;
            } else {
              e.num = floorStartSlot + laneIndex + 1;
            }
          } else {
            e.num = floorStartSlot + laneIndex + 1;
          }
        });
      }
    }

    setFloors([...floors]);

    let Floors = [];
    for (let i = 1; i <= floorNumber; i++) {
      Floors.push(
        <TCNFloor
          trigger={trigger}
          setTrigger={setTrigger}
          newPlano={newPlano}
          plano={plano}
          prods={prods}
          heights={heights}
          setHeights={setHeights}
          floorNumber={floorNumber}
          setNewPlano={setNewPlano}
          floors={floors}
          setFloors={setFloors}
          height={height}
          lineNumber={lineNumber}
          floor={floorNumber - i + 1}
          key={floorNumber - i + 1}
          i={i}
        />
      );
    }
    setfloor(Floors);
  }, [heights, trigger]);

  return (
    <div>
      <h5
        onClick={() => {
          console.log('floors', floors);
        }}
      >
        {name}
      </h5>
      <div>{floor}</div>
    </div>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/userContext';
import request from '../../../services/Request';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import Swal from 'sweetalert2';
import BrandPositionModal from './BrandPositionModal';
import { userLimits } from '../../dashboard/uitls/userLimits';

const getAllBrandURL = `/machines/getFilteredBrand/`;
const addBrandToMachineURL = `/machines/updateBrand`;
const getMachineURL = `/machines/edit/`;

export default function AddBrandToMachine(props) {
  const { filterd } = useContext(UserContext);
  const { id, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    '',
    'edit_machine'
  );

  const machineID = props.match.params.id !== 'all' ? props.match.params.id : null;
  const theme1 = JSON.parse(localStorage.getItem('user'))?.theme === '1';

  const [allBrands, setAllBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [machine, setMachine] = useState(null);
  const [reflesh, setReflesh] = useState(false);
  const [title, setTitle] = useState('');
  const [show, setShow] = useState(false);
  const [b_row, setB_row] = useState(null);
  const [checkBrand, setCheckBrand] = useState([]);

  useEffect(() => {
    let machineURL1 = machineID ? machineID : filterd[0]._id;

    if (machineID) {
      const getMachine = async () => {
        await request({
          method: 'get',
          url: getMachineURL + machineURL1,
        }).then((res) => {
          setMachine(res.data);
          if (res.data.themeColor?.b_row) {
            setB_row(res.data.themeColor?.b_row);
          }
          // console.log(res.data);
        });
      };
      getMachine();
    } else {
      let string = '';
      filterd.forEach((e) => {
        string += e.name + ', ';
      });
      string = string.substring(0, string.length - 2);
      setTitle(string);
    }

    const getBrands = async () => {
      await request({
        method: 'get',
        url: getAllBrandURL + machineURL1,
      }).then((res) => {
        let brand0 = res.data[0];
        let brand1 = res.data[1];

        brand0.forEach((e) => {
          e.name = e.name?.en;
        });

        brand1.forEach((e) => {
          e.name = e.name?.en;
        });
        const checkedCate = res.data[0];
        checkedCate.forEach((e) => (e.tableData = { checked: true }));
        const cate = checkedCate.concat(res.data[1]);
        setAllBrands(cate);
        setLoading(true);
      });
    };
    getBrands();

    return () => {
      setAllBrands([]);
      setMachine(null);
      setB_row(null);
    };
  }, [reflesh]);

  const handleSubmit = async () => {
    const cateIDs = checkBrand.length !== undefined ? checkBrand.map((cate) => cate._id) : [];
    await request({
      method: 'post',
      url: addBrandToMachineURL,
      data: {
        machineID: machineID ? new Array(machineID) : filterd.map((e) => e._id),
        ids: cateIDs,
        b_row: b_row,
      },
    }).then((res) => {
      setShow(false);
      Swal.fire({
        text: `The Brand has been successfully updated`,
        icon: 'success',
        target: document.getElementById('swal2-container'),
      });
      reflesh ? setReflesh(false) : setReflesh(true);
    });
  };

  const addBrand = async (data) => {
    const cateIDs = data.length !== undefined ? data.map((cate) => cate._id) : [];
    data.forEach((element, i) => {
      element.id = i + 1;
    });
    setCheckBrand(data);
    await request({
      method: 'post',
      url: addBrandToMachineURL,
      data: {
        machineID: machineID ? new Array(machineID) : filterd.map((e) => e._id),
        ids: cateIDs,
        b_row: b_row,
      },
    }).then((res) => {
      if (!theme1) {
        Swal.fire({
          text: `The Brand has been successfully updated`,
          icon: 'success',
          target: document.getElementById('swal2-container'),
        });
      } else {
        Swal.fire({
          text: `The Brand has been successfully updated, Please arrange your brand position`,
          icon: 'success',
          target: document.getElementById('swal2-container'),
        }).then((e) => {
          setShow(true);
        });
      }

      reflesh ? setReflesh(false) : setReflesh(true);
    });
  };

  const columns = [
    {
      title: 'Picture',
      field: 'picture',
      editable: 'never',
      render: (rowData) => (
        <img
          src={`${process.env.REACT_APP_SERVER_API}/pictures/${rowData.picture}/${id}`}
          style={{ width: 200 }}
        />
      ),
    },
    {
      title: 'Logo',
      field: 'logo',
      render: (rowData) =>
        rowData.picture != '' ? (
          <img
            src={`${process.env.REACT_APP_SERVER_API}/pictures/${rowData.logo}/${id}`}
            style={{ maxWidth: 200 }}
          />
        ) : (
          'No image'
        ),
    },
    { title: 'Brand Name', field: 'name' },
  ];

  return (
    <div>
      <BrandPositionModal
        machine={machine}
        b_row={b_row}
        setB_row={setB_row}
        show={show}
        brand={checkBrand}
        setBrand={setCheckBrand}
        onHide={() => setShow(false)}
        handleSubmit={handleSubmit}
      />
      {machine ? (
        <h4
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '80%',
          }}
        >
          {' '}
          Update Brands for the Machine: {machine.name}{' '}
        </h4>
      ) : (
        <h4
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '80%',
          }}
        >
          {' '}
          Update Brands for the Machine: {title}{' '}
        </h4>
      )}
      {!loading ? (
        <LoadingIcon />
      ) : (
        <div style={{ maxWidth: '100%' }}>
          <MaterialTable
            columns={columns}
            data={allBrands}
            title="Add Brands To the Machine"
            // editable={
            //   role === "Admin" &&
            //   {
            //   onRowUpdate: (newData, oldData) =>
            //   new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       resolve();
            //     }, 1000)
            //   }),
            // }}
            options={{
              actionsColumnIndex: -1,
              selection: true,
            }}
            actions={[
              access && {
                tooltip: 'Save My changes',
                icon: 'save',
                onClick: (evt, data) => {
                  addBrand(data);
                },
              },
              access && {
                tooltip: 'Save My changes',
                icon: 'save',
                isFreeAction: true,
                onClick: (evt, data) => {
                  addBrand(data);
                },
              },
            ]}
          />
          <BackButton />
          {/* <SaveChangeButton title="Save Changes" handle={saveChange}/> */}
        </div>
      )}
    </div>
  );
}

import React from 'react';
import ReactApexChart from 'react-apexcharts';
export default function BasicChart(props) {
  const { data, machinesName } = props;

  // const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
  //   <Typography variant={variant} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //     {`Product Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
  //   </Typography>
  // );

  const FinalData = [
    {
      name: 'Basic Info',
      data: [
        data.session_start,
        data.session_com,
        data.all_prod,
        data.all_brand,
        data.cart_aband,
        data.cart_page,
        data.faq,
        data.language?.en,
        data.language?.fr,
        data.language?.es,
        // data.aveSession,
      ],
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 400,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        columnWidth: '25%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetX: 21,
      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'Session Start',
        'Session Complete',
        'All Products',
        'All Brand',
        'Cart Abandonment',
        'Cart Page',
        'FAQ Page',
        'English',
        'French',
        'Spanish',
        // 'Session Time(seconds)',
      ],
    },
    yaxis: {
      title: {},
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
    },
    title: {
      text: 'Basic Info(' + machinesName + ')',
      offsetY: 15,
      align: 'center',
    },
  };

  return <ReactApexChart options={options} series={FinalData} type="bar" height={350} />;
  // return null;
}

import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/userContext';
import axios from 'axios';
import MaterialTable from 'material-table';
import BackButton from '../items/buttons/BackButton';
import LoadingIcon from '../items/buttons/LoadingIcon';

import { Grid, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const getLogByEmail = `${process.env.REACT_APP_SERVER_API}/logs/`;
const getAllLog = `${process.env.REACT_APP_SERVER_API}/allLogs/`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  buttom: {
    width: 120,
    height: 40,
    marginTop: 15,
  },
}));

Date.prototype.toShortFormat = function () {
  let monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let day = this.getDate();

  let monthIndex = this.getMonth();
  let monthName = monthNames[monthIndex];

  let year = this.getFullYear();

  return `${day}-${monthName}-${year}`;
};

export default function Order(props) {
  const classes = useStyles();
  const { userEmail, role, referTo } = useContext(UserContext);

  //start at first date of this month
  var date = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate())
  );
  const [startDateString, setStartDateString] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1).toISOString()
  );
  const [endDateString, setEndDateString] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString()
  );
  const [loading, setLoading] = useState(true);

  let id = '';
  let group = props.match.params.group;
  let tableTitle = 'Transactions';
  let superAdmin = false;
  if (role === 'SuperAdmin') {
    id = props.match.params.id;
    if (group) tableTitle = `Transactions of ${group}`;
    else tableTitle = `Transactions`;
    superAdmin = true;
  } else if (role === 'Admin') id = userEmail;
  else if (role === 'User') id = referTo;

  useEffect(() => {
    let isCancelled = false;

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
    setStartDateString(e.toISOString());
  };

  const handleEndDate = (e) => {
    setEndDate(e);
    setEndDateString(e.toISOString());
  };

  const handleSearch = async () => {
    console.log(startDateString);
    console.log(endDateString);
    await axios
      .get(
        !group && superAdmin
          ? getAllLog + '/' + startDateString + '/' + endDateString
          : getLogByEmail + id + '/' + startDateString + '/' + endDateString,
        {
          headers: {
            'auth-token': localStorage.getItem('token'),
          },
        }
      )
      .then(async (res) => {
        setLoading(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {!loading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <Grid style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '10px' }}>
          {/* select start and end date */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.formControl}
              value={startDate}
              onChange={(e) => handleStartDate(e)}
              label="Select start date"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-start"
            />
            <KeyboardDatePicker
              className={classes.formControl}
              value={endDate}
              onChange={(e) => handleEndDate(e)}
              label="Select end date"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-end"
            />
          </MuiPickersUtilsProvider>
          <Button
            className={classes.buttom}
            variant="contained"
            color="primary"
            onClick={handleSearch}
          >
            Download
          </Button>
        </Grid>
      </div>
    </div>
  );
}

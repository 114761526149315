// material
import MaterialTable from 'material-table';

export default function BasicTable(props) {
  const { data, machinesName } = props;

  // const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
  //   <Typography variant={variant} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //     {`Product Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
  //   </Typography>
  // );

  const Array = [data];

  const columns = [
    {
      title: 'Session Start',
      field: 'session_start',
    },
    {
      title: 'Session Complete',
      field: 'session_com',
    },
    {
      title: 'All Product',
      field: 'all_prod',
    },
    {
      title: 'All Brand',
      field: 'all_brand',
    },
    {
      title: 'Cart Page',
      field: 'cart_page',
    },
    {
      title: 'Cart Abandonment',
      field: 'cart_aband',
    },
    {
      title: 'FAQ Page',
      field: 'faq',
    },
    {
      title: 'Ave Session Time(s)',
      field: 'aveSession',
    },
    {
      title: 'English',
      field: 'language.en',
      render: (rowData) => rowData.language?.en,
    },
    {
      title: 'French',
      field: 'language.fr',
      render: (rowData) => rowData.language?.fr,
    },
    {
      title: 'Spanish',
      field: 'language.es',
      render: (rowData) => rowData.language?.es,
    },
  ];

  return (
    <MaterialTable
      title={'Basic Info: ' + machinesName}
      columns={columns}
      data={Array}
      options={{
        actionsColumnIndex: -1,
        pageSize: 5,
      }}
    />
  );
}

import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Swal from 'sweetalert2';

import { Modal } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 200,
    maxWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function TCNPropertyModal(props) {
  const classes = useStyles();

  const { index, board, setBoard, setShow, prods, totalWidth, plano, ...rest } = props;

  const [pd, setpd] = useState(board[index].product);

  const handleChange = (event, value) => {
    // Swal.fire({
    //   title: 'Are you sure?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes, change it!',
    //   cancelButtonText: 'No, cancel!',
    //   reverseButtons: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {

    //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    //     Swal.fire('Cancelled', '', 'success');
    //   }
    // });

    if (value?.dimension.length > board[index].length) {
      Swal.fire('Error', "Incoming product's width excess the width of the lane", 'error');
      return;
    }
    // console.log(value);
    board[index].product = value;
    setBoard([...board]);
    // setpd(event.target.value.name);
    // console.log('select', event.target.value);
    // console.log(board[index].product.dimension.length);
  };

  useEffect(() => {
    setpd(board[index].product);
    console.log('board', board);
  }, [board]);

  return (
    <Modal
      className="modal-w"
      {...rest}
      centered
      size="md"
      // onClick={() => {
      //   console.log(pd);
      // }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        {/* <Grid item xs={12} style={{ fontSize: '20px', marginBottom: '10px' }}>
          {board[index].product?.name?.en}
        </Grid> */}

        <Grid className="mt-2">
          <Autocomplete
            fullWidth
            disableClearable
            options={prods}
            getOptionLabel={(option) =>
              option?.name?.en
                ? `${option.name.en} (${option.upc})`
                : `${option.name} (${option.upc})`
            }
            value={pd}
            onChange={(event, value) => {
              handleChange(event, value);
            }}
            renderInput={(params) => (
              <TextField fullWidth {...params} label="Product" variant="outlined" />
            )}
          />
        </Grid>

        <Grid className="mt-2">
          <TextField
            type="number"
            label="Width"
            variant="outlined"
            inputProps={{
              min: board[index].product?.dimension.length,
              step: '0.1',
            }}
            fullWidth
            value={board[index].length}
            onChange={(e) => {
              if (!e.target.value || e.target.value < 1) {
                e.target.value = 1;
                Swal.fire('Error', 'Value must be greater than 0', 'error');
              }
              // console.log('target', parseFloat(e.target.value) + totalWidth);
              // console.log('target', plano.width);
              if (parseFloat(e.target.value) > board[index].length && totalWidth >= plano.width) {
                Swal.fire('Error', "Can't increase the width due to maximum floor width", 'error');
              } else {
                board[index].length = parseFloat(e.target.value);
                setBoard([...board]);
              }
            }}
          />
        </Grid>

        <Grid className="mt-2">
          <TextField
            type="number"
            label="Max_Stock"
            variant="outlined"
            inputProps={{
              min: '1',
              step: '1',
            }}
            fullWidth
            value={board[index].max_stock}
            onChange={(e) => {
              // console.log('target', parseFloat(e.target.value) + totalWidth);
              // console.log('target', plano.width);
              if (parseFloat(e.target.value) < 1 || !e.target.value) {
                Swal.fire('Error', 'Value must be greater than 0', 'error');
              } else {
                board[index].max_stock = parseFloat(e.target.value);
                setBoard([...board]);
              }
            }}
          />
        </Grid>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            setShow(false);
          }}
        >
          CLOSE
        </Button>
      </Modal.Body>
    </Modal>
  );
}

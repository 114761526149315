import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  Radio,
  RadioGroup,
  FormLabel,
  TextField,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Modal } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Swal from 'sweetalert2';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { useForm, Controller } from 'react-hook-form';
import request from '../../../services/Request';
import Papa from 'papaparse';
import { ExportToCsv } from 'export-to-csv';
import { localDateRange } from '../../../services/method/methods';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CreatePromo(props) {
  const classes = useStyles();

  const getMyMachineURL = `/machines/getMyMachines/`;
  const getMyGroupsURL = `/groups/`;
  const getMyProdURL = `/products/`;
  const createPromoURL = `/promos/create`;
  const updatePromoURL = `/promos/update/`;
  const getMyCateURL = `/categories/`;
  const downloadCSV = `/promos/getEmailCode/`;
  const getGroupsURL = `/groupsAndMachinesForReport/`;

  const title = props.update === 'false' ? 'Create Promotion' : 'Update Promotion';
  const button = props.update === 'false' ? 'Create Promotion' : 'Update Promotion';

  const {
    data,
    setData,
    reflesh,
    setreflesh,
    id,
    setname,
    update,
    userEmail,
    machine,
    group,
    showAdmin,
    ...rest
  } = props;

  const [prod, setProd] = useState([]);
  // const [ratio, setRatio] = useState('All Products');
  const [cate, setCate] = useState([]);
  const [emailCode, setEmailCode] = useState([]);
  const [csvError, setCsvErorr] = useState('');
  let offset = new Date().getTimezoneOffset();
  const theme = JSON.parse(localStorage.getItem('user'))?.theme;

  const defaultValues = data
    ? {
        datePickerEnd: new Date(
          new Date(data.endDate).setTime(new Date(data.endDate).getTime() + offset * 60 * 1000)
        ).toISOString(),
        datePickerStart: new Date(
          new Date(data.startDate).setTime(new Date(data.startDate).getTime() + offset * 60 * 1000)
        ).toISOString(),
        code: data.isOne ? data.originCode[0] : data.code,
        title: data.title,
        percentage: data.percentage.toString(),
        product: data.product ? data.product : [],
        category: data.category ? data.category : [],
        machine: data.machine.all
          ? `All Machines`
          : data.machine.group
          ? data.machine.group._id
          : data.machine.id
          ? '.' + data.machine.id._id
          : '',
        promo: data.promoType,
        active: data.active,
        GWCEmail: data.GWCEmail,
        value: data.percentage ? data.amount * 100 : data.amount,
        number: data.productsToBuy,
        selectFor: data.cateOrProd,
      }
    : {
        datePickerEnd: new Date().setHours(0, 0, 0, 0),
        datePickerStart: new Date().setHours(0, 0, 0, 0),
        percentage: 'true',
        selectFor: 'All Products',
        machine: 'All Machines',
        promo: 'general',
        active: true,
        value: 0.0,
        number: 2,
        code: '',
        product: [],
        category: [],
      };

  const {
    reset,
    handleSubmit,
    setError,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const watchShowNumber = watch('promo');
  const watchShowStart = watch('datePickerStart');
  const watchAll = watch();

  useEffect(() => {
    if (watchShowNumber === 'buyXProducts') {
      setValue('selectFor', 'prod');
    }
    if (watchShowNumber === 'buyXAll') {
      setValue('selectFor', 'All Products');
    }
  }, [watchShowNumber]);

  useEffect(() => {
    let isCancelled = false;
    //getAllEmployees

    async function getMyProds() {
      await request({
        method: 'get',
        url: getMyProdURL + id,
      }).then((res) => {
        if (!isCancelled) {
          res.data.forEach((e) => {
            e.name = e.name.en;
          });
          setProd(res.data);
        }
      });
    }

    async function getMyCates() {
      await request({
        method: 'get',
        url: getMyCateURL + id,
      }).then((res) => {
        if (!isCancelled) {
          res.data.forEach((e) => {
            e.name = e.name.en;
          });
          setCate(res.data);
        }
      });
    }

    getMyProds();
    getMyCates();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  useEffect(async () => {
    await reset(defaultValues);
    if (data && data.cateOrProd === 'cate') {
      setValue('category', data.category);
    }
    if (data && data.cateOrProd === 'prod') {
      setValue('product', data.product);
    }
    if (data && data.promoType === 'emailCode') {
      if (data.emailCode) {
        setEmailCode(data.emailCode);
      }
    }
    if (data && data.promoType === 'oneTime') {
      if (data.oneTimeCode) {
        setEmailCode(data.oneTimeCode);
      }
    }
    if (!data) {
      setEmailCode([]);
    }
  }, [reflesh]);

  const onSubmit = async (formData) => {
    console.log('aceess', showAdmin);
    if (!showAdmin && formData.machine === 'All Machines') {
      Swal.fire({
        text: `You do not have access to all machines, please select a machine/group`,
        icon: 'error',
      });
      return;
    }
    let go = true;
    if (watchShowNumber !== 'emailCode') {
      if (parseFloat(formData.value) <= 0) {
        setError('value', { message: 'value must be greater than 0' });
        go = false;
      }
    }
    console.log(emailCode);

    if (watchShowNumber === 'emailCode' || watchShowNumber === 'oneTime') {
      if (watchShowNumber === 'oneTime') {
        if (formData.code === '' && emailCode.length === 0) {
          setCsvErorr('No Code Provided');
          go = false;
        }
      } else {
        if (emailCode.length === 0) {
          setCsvErorr('No Code Provided');
          go = false;
        }
      }
    }

    if (formData.percentage === 'true') {
      if (parseFloat(formData.value) > 100) {
        setError('value', { message: 'percentage value must be less than 100' });
        go = false;
      }
    }

    if (go) {
      //change date to utc
      const { startDateString, endDateString } = localDateRange(
        formData.datePickerStart,
        formData.datePickerEnd,
        true
      );
      let isGroup = true;
      let machine = formData.machine;
      let product = [];

      if (formData.selectFor === 'cate') product = formData.category.map((e) => e._id);
      if (formData.selectFor === 'prod') product = formData.product.map((e) => e._id);

      console.log(formData.selectFor);
      if (formData.machine[0] === '.') {
        isGroup = false;
        machine = machine.substring(1);
      }

      const productsToBuy = formData.promo === 'buyXProducts' ? formData.number : 0;
      if (props.update === 'false') {
        await request({
          method: 'post',
          url: createPromoURL,
          data: {
            code: formData.promo === 'generalWithCode' ? formData.code : '',
            title: formData.title,
            startDate: startDateString,
            endDate: endDateString,
            machine: machine,
            percentage: formData.percentage === 'true' ? true : false,
            promoType: formData.promo,
            product: product,
            amount:
              formData.percentage === 'true'
                ? Math.round(parseFloat(formData.value)) / 100
                : Math.round(parseFloat(formData.value) * 100) / 100,
            referTo: id,
            active: formData.active,
            isGroup: isGroup,
            productsToBuy: productsToBuy,
            cateOrProd: formData.selectFor,
            emailCode: formData.promo !== 'oneTime' ? emailCode : [],
            oneTimeCode:
              formData.promo === 'oneTime'
                ? formData.code === ''
                  ? emailCode
                  : [formData.code]
                : [],
            isOne: formData.promo === 'oneTime' && formData.code !== '' ? true : false,
            GWCEmail: formData.GWCEmail,
          },
        })
          .then((res) => {
            props.onHide();
            props.setreflesh(reflesh ? false : true);
            Swal.fire({
              text: `New promotion has been successfully created: ${formData.title}`,
              icon: 'success',
            });
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 406 && err.response.data.code === 1) {
                if (formData.promo === 'emailCode') {
                  setError('csv', {
                    message: err.response.data.message,
                  });
                }
                setError('code', {
                  message: err.response.data.message,
                });
              }
              if (
                err.response.status === 406 &&
                (err.response.data.code === 2 || err.response.data.code === 3)
              ) {
                setError('selectFor', {
                  message: err.response.data.message,
                });
              }
            }
          });
      }
      if (props.update === 'true') {
        await request({
          method: 'patch',
          url: updatePromoURL + data._id,
          data: {
            oldCode: data.code,
            code: formData.promo === 'generalWithCode' ? formData.code : '',
            title: formData.title,
            startDate: startDateString,
            endDate: endDateString,
            machine: machine,
            percentage: formData.percentage === 'true' ? true : false,
            promoType: formData.promo,
            product: product,
            amount:
              formData.percentage === 'true'
                ? Math.round(parseFloat(formData.value)) / 100
                : Math.round(parseFloat(formData.value) * 100) / 100,
            referTo: id,
            active: formData.active,
            isGroup: isGroup,
            productsToBuy: productsToBuy,
            cateOrProd: formData.selectFor,
            emailCode: formData.promo !== 'oneTime' ? emailCode : [],
            oneTimeCode:
              formData.promo === 'oneTime'
                ? formData.code === ''
                  ? emailCode
                  : [formData.code]
                : [],
            isOne: formData.promo === 'oneTime' && formData.code !== '' ? true : false,
            GWCEmail: formData.GWCEmail,
          },
        })
          .then((res) => {
            props.onHide();
            props.setreflesh(reflesh ? false : true);
            Swal.fire({
              text: `The promotion has been successfully updated: ${formData.title}`,
              icon: 'success',
            });
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 406 && err.response.data.code === 1) {
                if (formData.promo === 'emailCode') {
                  setError('csv', {
                    message: err.response.data.message,
                  });
                } else {
                  setError('code', {
                    message: err.response.data.message,
                  });
                }
              }
              if (
                err.response.status === 406 &&
                (err.response.data.code === 2 || err.response.data.code === 3)
              ) {
                setError('selectFor', {
                  message: err.response.data.message,
                });
              }
            }
          });
      }
    }
  };

  const clearState = () => {
    setValue('category', []);
    setValue('product', []);
  };

  const importCode = (e, option) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;

        const array = [];
        data.forEach((element) => {
          array.push(element.code);
        });
        setEmailCode(array);
        Swal.fire({
          text: `The email code has been succssfully imported`,
          icon: 'success',
        });
      },
    });
    e.target.value = null;
  };

  const downloadProductCsv = async () => {
    console.log('emailCode', emailCode);
    if (emailCode?.length > 0) {
      const csvJson = emailCode.map((e) => {
        return {
          code: e,
        };
      });

      console.log(csvJson);
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        useTextFile: false,
        filename: 'Code CSV',
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(csvJson);
    } else {
      Swal.fire({
        text: `No Active codes found`,
        icon: 'info',
      });
    }
    if (data?.originCode?.length > 0) {
      const csvJson2 = data.originCode.map((e) => {
        return {
          originalCode: e,
        };
      });
      console.log(csvJson2);
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        useTextFile: false,
        filename: 'Original_Code CSV',
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(csvJson2);
    }
    // console.log('downloadtata', result);
  };

  return (
    <Modal className="modal-w" {...rest} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main">
          <CssBaseline />
          <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="datePickerStart"
                      render={({ field: { ref, ...rest } }) => (
                        <KeyboardDateTimePicker
                          label="Select Start Date"
                          format="MM/dd/yyyy"
                          margin="normal"
                          fullWidth
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="datePickerEnd"
                      render={({ field: { ref, ...rest } }) => (
                        <KeyboardDateTimePicker
                          label="Select End Date"
                          format="MM/dd/yyyy"
                          margin="normal"
                          fullWidth
                          minDate={new Date(watchShowStart)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                {(watchShowNumber === 'generalWithCode' || watchShowNumber === 'oneTime') && (
                  <Grid item xs={6}>
                    <Controller
                      name="code"
                      control={control}
                      rules={
                        watchShowNumber === 'generalWithCode'
                          ? { required: 'Code is required' }
                          : {}
                      }
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          disabled={update === 'true'}
                          label="Code"
                          variant="outlined"
                          fullWidth
                          defaultValue={defaultValues.code}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.code && <p className="signUpErrorMessage">{errors.code.message}</p>}
                  </Grid>
                )}
                <Grid
                  item
                  xs={
                    watchShowNumber === 'generalWithCode' || watchShowNumber === 'oneTime' ? 6 : 12
                  }
                >
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: 'Description is required' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Description"
                        variant="outlined"
                        required
                        fullWidth
                        defaultValue={defaultValues.title}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.title && <p className="signUpErrorMessage">{errors.title.message}</p>}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="promo-Select-Machine-Group">
                          Select Machine/Group
                        </InputLabel>
                        <Select {...field} disabled={update === 'true'}>
                          <MenuItem key="all" value="All Machines">
                            All Machines
                          </MenuItem>
                          <ListSubheader>Groups</ListSubheader>
                          {group.map((m) => (
                            <MenuItem key={m._id} value={m._id}>
                              {`${m.name} (`}
                              {m.machineID.map((e, i) => {
                                if (i !== m.machineID.length - 1) return e.name + ', ';
                                else return e.name;
                              })}
                              )
                            </MenuItem>
                          ))}
                          <ListSubheader>Machines</ListSubheader>
                          {machine.map((m) => (
                            <MenuItem key={m._id} value={'.' + m._id}>
                              {m.description ? `${m.name}(${m.description})` : `${m.name}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    name="machine"
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="promo-select-promo">Promo Type</InputLabel>
                        <Select {...field} disabled={update === 'true'}>
                          <MenuItem value={'general'}>General</MenuItem>
                          <MenuItem value={'generalWithCode'}>General With Code</MenuItem>
                          <MenuItem value={'oneTime'}>One Time</MenuItem>
                          {/* <MenuItem value={'buyXProducts'}>Buy X Products</MenuItem> */}
                          {/* <MenuItem value={'buyXAll'}>Any Price Products</MenuItem> */}
                          {theme === '1' && <MenuItem value={'emailCode'}>Email Code</MenuItem>}

                          {/* <MenuItem value={'nextPurchase'}>Next Purchase</MenuItem> */}
                        </Select>
                      </FormControl>
                    )}
                    name="promo"
                    control={control}
                  />
                </Grid>
                {(watchShowNumber === 'buyXProducts' || watchShowNumber === 'buyXAll') && (
                  <Grid item xs={3}>
                    <Controller
                      name="number"
                      control={control}
                      rules={{
                        required: 'number is required',
                        min: { value: 2, message: 'min number is 2' },
                        max: { value: 3, message: 'max number is 3' },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          disabled={update === 'true'}
                          label="Number of products"
                          variant="standard"
                          type="number"
                          inputProps={{ min: '2', max: '3' }}
                          defaultValue={defaultValues.number}
                          required
                          fullWidth
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.number && <p className="signUpErrorMessage">{errors.number.message}</p>}
                  </Grid>
                )}
                <Grid item xs={2}>
                  <Controller
                    name="value"
                    control={control}
                    rules={{ required: 'Value is required' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        disabled={update === 'true'}
                        label="Value"
                        variant="standard"
                        type="number"
                        inputProps={{ min: '0.00', step: '1' }}
                        defaultValue={defaultValues.value}
                        required
                        fullWidth
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.value && <p className="signUpErrorMessage">{errors.value.message}</p>}
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup
                        row
                        aria-label="percentage"
                        {...field}
                        style={{ justifyContent: 'space-around' }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio disabled={update === 'true'} />}
                          label="Percentage"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio disabled={update === 'true'} />}
                          label="Dollars Off"
                        />
                      </RadioGroup>
                    )}
                    name="percentage"
                    control={control}
                  />
                  {/* </FormControl> */}
                </Grid>
                {/* {watchShowNumber === 'generalWithCode' && (
                  <Grid item xs={12}>
                    <section>
                      <Controller
                        name="GWCEmail"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            onChange={(e) => field.onChange(e.target.checked)}
                            checked={field.value}
                          />
                        )}
                      />
                      <label style={{ marginBottom: 0 }}>Unique Email</label>
                    </section>
                  </Grid>
                )} */}

                {(watchShowNumber === 'emailCode' ||
                  watchShowNumber === 'oneTime' ||
                  watchShowNumber === 'nextPurchase') && (
                  <Grid item xs={12}>
                    <Button
                      disabled={(watchAll?.code && watchAll?.code !== '') || update === 'true'}
                      component="label"
                      variant="contained"
                      color="primary"
                      style={{ marginRight: '35px', width: '220px' }}
                    >
                      <input
                        type="file"
                        accept=".csv"
                        hidden
                        onChange={(e) => {
                          importCode(e, true);
                        }}
                      />
                      IMPORT CSV
                    </Button>
                    <Button
                      disabled={watchAll?.originCode?.length > 0}
                      variant="contained"
                      color="primary"
                      onClick={downloadProductCsv}
                      style={{ marginRight: '15px', width: '220px' }}
                    >
                      download CSV
                    </Button>
                  </Grid>
                )}
                {errors.csv && <p className="signUpErrorMessage">{errors.csv.message}</p>}
                {csvError !== '' && <p className="signUpErrorMessage">{csvError}</p>}
                {watchShowNumber !== 'buyXAll' && (
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" style={{ fontSize: '0.8rem' }}>
                        {' '}
                        Create Promotion For{' '}
                      </FormLabel>
                      <Controller
                        render={({ field }) => (
                          <RadioGroup
                            aria-label="Role"
                            name="Role1"
                            row
                            {...field}
                            value={watchAll.selectFor}
                          >
                            {/* {watchAll.promo === 'oneTime' && (
                              <FormControlLabel
                                checked={watchAll.selectFor === 'cart'}
                                value="cart"
                                control={<Radio disabled={update === 'true'} />}
                                onChange={(e, v) => {
                                  clearState();
                                }}
                                label="Shopping Cart"
                              />
                            )} */}
                            <FormControlLabel
                              checked={watchAll.selectFor === 'All Products'}
                              value="All Products"
                              control={<Radio disabled={update === 'true'} />}
                              onChange={(e, v) => {
                                clearState();
                              }}
                              label="All Products"
                            />
                            <FormControlLabel
                              checked={watchAll.selectFor === 'cate'}
                              value="cate"
                              control={
                                <Radio
                                  disabled={update === 'true' || watchShowNumber === 'buyXProducts'}
                                />
                              }
                              onChange={(e, v) => {
                                clearState();
                              }}
                              label="Categories"
                            />
                            <FormControlLabel
                              checked={watchAll.selectFor === 'prod'}
                              value="prod"
                              control={<Radio disabled={update === 'true'} />}
                              onChange={(e, v) => {
                                clearState();
                              }}
                              label="Products"
                            />
                          </RadioGroup>
                        )}
                        name="selectFor"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                )}

                {watchAll.selectFor === 'cate' ? (
                  <Grid item xs={12}>
                    <Controller
                      name="category"
                      control={control}
                      rules={{ required: 'categories is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          multiple
                          disabled={update === 'true'}
                          id="categoryMulti"
                          options={cate}
                          value={watchAll.category}
                          disableCloseOnSelect
                          onChange={(event, value) => {
                            field.onChange(value);
                          }}
                          getOptionSelected={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          fullWidth
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Categories" />
                          )}
                        />
                      )}
                    />
                  </Grid>
                ) : watchAll.selectFor === 'prod' ? (
                  <Grid item xs={12}>
                    <Controller
                      name="product"
                      rules={{ required: 'product is required' }}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          disabled={update === 'true'}
                          multiple
                          id="productMulti"
                          value={watchAll.product}
                          options={prod}
                          disableCloseOnSelect
                          onChange={(event, value) => {
                            field.onChange(value);
                          }}
                          getOptionSelected={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          fullWidth
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Products" />
                          )}
                        />
                      )}
                    />
                  </Grid>
                ) : null}
                {errors.category && <p className="signUpErrorMessage">{errors.category.message}</p>}
                {errors.selectFor && (
                  <p className="signUpErrorMessage">{errors.selectFor.message}</p>
                )}
                {errors.product && <p className="signUpErrorMessage">{errors.product.message}</p>}
                {/* <Grid item xs={12}>
                  <section>
                    <label>Activate the promotion now</label>
                    <Controller
                      name="active"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      )}
                    />
                  </section>
                </Grid> */}

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {button}
                  </Button>
                </Grid>

                {/* <Grid item xs={6}>
              <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={()=>{
                
              }}
            >
              generate qr code
            </Button>
          </Grid> */}
              </Grid>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

import ProductCard from './ProductCard';
import React, { useState, useEffect, useContext } from 'react';
import { PlanoContext } from './PlanoContext';
import { useDrop } from 'react-dnd';
import { TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';

export default function ProductList({
  cates,
  handleSave,
  laneLength,
  search,
  setSearch,
  TCN,
  setProds,
}) {
  const { prods, error, setError, dragProd, setDragProd } = useContext(PlanoContext);

  // const [search, setSearch] = useState('');
  const [cate, setCate] = useState('All Categories');

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'image',
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  let newProds = prods.filter((val) => {
    let c1 = val?.category?.map((e) => e.name);
    if (search == '') {
      if (cate == 'All Categories') {
        return val;
      } else if (c1?.length > 0 && c1.join(', ').toLowerCase().includes(cate.toLowerCase())) {
        return val;
      }
    } else if (val.name.toLowerCase().includes(search.toLowerCase())) {
      if (cate == 'All Categories') {
        return val;
      } else if (c1?.length > 0 && c1.join(', ').toLowerCase().includes(cate.toLowerCase())) {
        return val;
      }
    }
    // }
  });

  // newProds.filter((val) => {
  //   // console.log(val);
  //   console.log('dgprod', dragProd);
  //   if (val.name === dragProd) {
  //     console.log(val.name);
  //     return val;
  //   }
  // });

  return (
    <div className="Plano-Products" ref={drop}>
      <div className="plano-wrapper">
        <div className="plano-product-title">
          <h4 style={{ margin: '0px' }}>Products List</h4>
        </div>
        <div className="searchPlano">
          <TextField
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            fullWidth
            label="Search..."
          />
        </div>
        <div className="searchPlano">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={cate}
              onChange={(event) => {
                setCate(event.target.value);
              }}
            >
              <MenuItem value="All Categories">All Categories</MenuItem>;
              {cates.map((item, i) => {
                return (
                  <MenuItem key={i} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="plano-scrollable">
          {newProds.map((prod, i) => {
            return (
              <ProductCard
                newProds={newProds}
                i={i}
                TCN={TCN}
                url={`${process.env.REACT_APP_SERVER_API}/pictures/${prod.productPictures[0]}/${prod.referTo}`}
                error={error}
                setError={setError}
                prod={prod}
                key={i}
                laneLength={laneLength}
                setProds={setProds}
              />
            );
          })}
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ height: '50px', marginTop: '20px' }}
        >
          Save My Planogram
        </Button>
      </div>
    </div>
  );
}

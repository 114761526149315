import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import BackButton from '../../items/buttons/BackButton';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import Swal from 'sweetalert2';
import request from '../../../services/Request';
import CreateSS from './CreateSS';
import UpdateSS from './UpdateSS';
import { Computer } from '@material-ui/icons';
import { userLimits } from '../uitls/userLimits';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';

const getSSURL = `/screenSaver/`;

const getPromo = `/activePromos/`;
const deleteSSURL = `/screenSaver/delete/`;

export default function ScreenSaver(props) {
  const { _id, tableTitle, superAdmin, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Screen Saver',
    'edit_ss'
  );

  const [reflesh, setReflesh] = useState(false);
  const [SS, setSS] = useState([]);
  const [promo, setPromo] = useState([]);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [row, setRow] = useState(null);

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_ss') ? localStorage.getItem('table_view_ss') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_ss', pagination);
  }, [pagination]);

  useEffect(() => {
    let isCancelled = false;

    async function getMySS() {
      await request({
        method: 'get',
        url: !props.match.params.group && superAdmin ? getSSURL : getSSURL + _id,
      }).then((res) => {
        if (!isCancelled) {
          setSS(res.data);
          setLoading(true);
        }
      });
    }

    async function getMyPromo() {
      await request({
        method: 'get',
        url:
          !props.match.params.group && superAdmin
            ? getPromo + props.match.params.group
            : getPromo + _id,
      }).then((res) => {
        if (!isCancelled) {
          console.log(res.data);
          const newArray = [];
          //filter category or product that has only 1
          res.data.forEach((e) => {
            // console.log(e);
            if ((e.category && e.category.length === 1) || (e.product && e.product.length === 1)) {
              newArray.push(e);
            }
          });
          setPromo(newArray);
          setLoading(true);
        }
      });
    }
    getMySS();
    getMyPromo();

    return () => {
      setSS([]);
      setPromo([]);
      isCancelled = true;
    };
  }, [reflesh]);

  const deleteSS = async (id) => {
    setLoading(false);
    await request({
      method: 'delete',
      url: deleteSSURL + id,
    })
      .then(() => {
        Swal.fire({
          text: `The screensaver has been successfully deleted`,
          icon: 'success',
        });
        setLoading(true);
        reflesh ? setReflesh(false) : setReflesh(true);
      })
      .catch((err) => {
        if (err?.response?.status === 406) {
          Swal.fire({
            text: 'The screen saver is linked to some machines, please unlink it before delete',
            icon: 'error',
          });
        } else {
          Swal.fire({
            text: `Fail to delete the screensaver`,
            icon: 'error',
          });
        }

        setLoading(true);
        reflesh ? setReflesh(false) : setReflesh(true);
      });
  };

  const columns = [
    // { title: 'Picture', field: 'picture' , render: rowData =>
    // <img src={`${process.env.REACT_APP_SERVER_API}/brands/pictures/${rowData.picture}`} style={{width: 200}}/>},
    { title: 'Screen Saver Name', field: 'name' },
    {
      title: 'Swapping Images Interval',
      field: 'interval',
      render: (rowData) => `${rowData.interval} seconds`,
    },
  ];
  return (
    <div>
      {!loading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={SS}
          title={tableTitle}
          editable={
            access && {
              onRowDelete: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    deleteSS(newData._id);

                    resolve();
                  }, 1000);
                }),
            }
          }
          actions={[
            access && {
              icon: 'add',
              tooltip: 'Create Screen Saver',
              isFreeAction: true,
              onClick: (event) => {
                setShow(true);
              },
            },
            {
              icon: Computer,
              tooltip: 'Screen Saver Preview',
              onClick: (event, rowData) => {
                window.open(`/screenSaverPreview/${rowData._id}`, 'ss');
              },
            },
            access && {
              icon: 'edit',
              tooltip: 'Edit Screen Saver',
              onClick: (event, rowData) => {
                setRow(rowData);
                setShowUpdate(true);
              },
            },
          ]}
          detailPanel={(rowData) => {
            // console.log(rowData);
            return (
              <TableContainer component={Paper} style={{ backgroundColor: '#EBEBE8' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Picture/Videos</TableCell>
                      <TableCell>Filename</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData.slider.map((s) => (
                      <TableRow key={s.url}>
                        <TableCell component="th" scope="row">
                          {s.video ? (
                            <video width="320" height="240" controls>
                              <source
                                src={`${process.env.REACT_APP_SERVER_API}/videos/${s.url}/${_id}`}
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_SERVER_API}/pictures/${s.url}/${_id}`}
                              style={{ width: '320px' }}
                            />
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {s.url}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        <BackButton />
        <CreateSS
          _id={_id}
          show={show}
          promo={promo}
          reflesh={reflesh}
          setReflesh={setReflesh}
          onHide={() => setShow(false)}
        />
        <UpdateSS
          _id={_id}
          data={row}
          promo={promo}
          show={showUpdate}
          reflesh={reflesh}
          setReflesh={setReflesh}
          onHide={() => setShowUpdate(false)}
        />
      </div>
    </div>
  );
}

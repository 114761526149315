import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/userContext';
import request from '../../services/Request';
import MaterialTable from 'material-table';
import BackButton from '../items/buttons/BackButton';
import LoadingIcon from '../items/buttons/LoadingIcon';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Container } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { Email } from '@material-ui/icons';
import Swal from 'sweetalert2';
import MachineSelect from '../dashboard/report/MachineSelect';
import { userLimits } from '../dashboard/uitls/userLimits';
import moment from 'moment';
import { localDateRange } from '../../services/method/methods';
import { ExportToCsv } from 'export-to-csv';
import ForwardIcon from '@mui/icons-material/Forward';

const getOrderByEmail = `/orders`;
const getAllOrder = `/allOrders/`;
const getGroupsURL = `/groupsAndMachines/`;

Date.prototype.toShortFormat = function () {
  let monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let day = this.getDate();

  let monthIndex = this.getMonth();
  let monthName = monthNames[monthIndex];

  let year = this.getFullYear();

  return `${day}-${monthName}-${year}`;
};

export default function Order(props) {
  const { userEmail, filterd, setFiltered } = useContext(UserContext);

  //start at first date of this month
  var date = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [order, setOrder] = useState([]);
  const [machines, setMachines] = useState([]);
  const [machineName, setMachineName] = useState('All Machines');
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [radio, setRadio] = useState('a');
  const user = JSON.parse(localStorage.getItem('user'));
  const thirdParty = user.thirdPartyLink;

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_trans') ? localStorage.getItem('table_view_trans') : 20
  );

  useEffect(() => {
    localStorage.setItem('table_view_trans', pagination);
  }, [pagination]);

  // console.log(user);

  const { id, tableTitle, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Transactions',
    'view_trans'
  );

  useEffect(() => {
    let allMachines = [];
    let isCancelled = false;

    async function getGroups() {
      await request({
        method: 'get',
        url: !props.match.params.group && superAdmin ? getGroupsURL : getGroupsURL + userEmail,
      }).then((res) => {
        if (!isCancelled) {
          setGroups(res.data.groups);
          setMachines(res.data.machines);
          setLoading(true);
          allMachines = res.data.machines.map((e) => e._id);
        }
      });
    }

    getGroups();

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleSearch = async (event) => {
    // console.log('startDateString', new Date(startDateString));
    // console.log('endDateString', new Date(endDateString));
    let filteredArray = [];
    setLoading(false);
    let myIDs = 0;
    if (radio == 'a') {
      setFiltered(machines);
      myIDs = machines.map((e) => e._id).join(',');
      setMachineName('All Machines');
      filteredArray = [...machines];
    }
    if (radio == 'g') {
      const myArray = groupV.map((e) => e.machineID);
      var merged = [].concat.apply([], myArray);
      merged = merged.filter((e, i, self) => i === self.findIndex((t) => t._id == e._id));
      // const filteredArray = machines.filter((e, i) => i == merged.findIndex((t)=>(t._id == e._id)));
      machines.forEach((e) => {
        merged.findIndex((t) => {
          if (t._id == e._id) {
            filteredArray.push(e);
          }
        });
      });
      setFiltered(filteredArray);
      myIDs = filteredArray.map((e) => e._id).join(',');
      setMachineName(`Groups: ${groupV.map((e) => e.name).join(', ')} `);
    }
    if (radio == 'm') {
      setFiltered(groupV);
      filteredArray = [...groupV];
      myIDs = groupV.map((e) => e._id).join(',');
      setMachineName(`Machines: ${groupV.map((e) => e.name).join(', ')} `);
    }
    // console.log(myIDs);
    // console.log(startDateString)
    // console.log(endDateString)
    if (myIDs.length > 0) {
      const { startDateString, endDateString } = localDateRange(startDate, endDate);
      await request({
        method: 'post',
        url:
          !props.match.params.group && superAdmin
            ? getAllOrder + myIDs + '/' + startDateString + '/' + endDateString
            : getOrderByEmail,
        data: {
          start: startDateString,
          end: endDateString,
          ids: myIDs,
          id: id,
        },
      }).then(async (res) => {
        setOrder(res.data);
        setLoading(true);
      });
    } else {
      setLoading(true);
    }
  };
  let columns = [
    {
      title: 'Order ID',
      field: 'id',
    },
    {
      title: 'Order Date',
      field: 'createdAt',
      render: (rowData) => {
        let date = new Date(rowData.createdAt);
        //show keep old trans date
        if (rowData.realTime) {
          date = moment.utc(date).format('lll');
        } else {
          date = moment(date).format('lll');
        }
        return date;
      },
    },
    { title: 'Machine ID', field: 'machineInfo.name' },
    { title: 'Machine Desc', field: 'machineInfo.description' },
    { title: 'Currency', field: 'currency' },
    { title: 'Tax', field: 'tax', render: (rowData) => `${rowData.tax.toFixed(2)}` },
    { title: 'Sale Amount', field: 'total', render: (rowData) => `${rowData.total.toFixed(2)}` },
    {
      title: 'Email Receipt',
      field: 'email',
      render: (rowData) => {
        if (rowData.email) {
          if (rowData.isCheck) {
            return <span style={{ color: 'blue' }}>{rowData.email}</span>;
          } else {
            return `${rowData.email}`;
          }
        } else {
          return null;
        }
      },
    },
    { title: 'Code Used', field: 'otc' },
    { title: 'Payment Type', field: 'payment_type' },
    {
      title: 'Status',
      field: 'status',
    },
  ];

  if (thirdParty) {
    columns.push({
      title: 'Third Party',
      field: 'isSent',
      render: (rowData) => {
        let result = '';
        if (rowData.isSent && rowData.send_order_third === 'TRUE') {
          result = 'Succeed';
        }
        if (!rowData.isSent && rowData.send_order_third === 'TRUE') {
          result = 'Failed';
        }
        return result;
      },
    });
  }

  const resentTransaction = async (rowData) => {
    // console.log(rowData);
    const sales = [];

    rowData.products.forEach((element, i) => {
      const pd = {
        type: rowData.payment_type ? rowData.payment_type : 'CREDIT',
        price: element.soldPrice + element.tax_amount,
        quantity: element.quantity,
        item: element.port,
        transid: rowData.id + i,
      };
      sales.push(pd);
    });

    const newObject = {
      batchid: rowData.id,
      posid: user.groupCode.toUpperCase() + rowData.machineInfo.name,
      batchtimestamp: rowData.realTime,
      batchservertimestamp: rowData.createdAt,
      sales: sales,
    };

    await request({
      method: 'post',
      url: 'orders/sendThirdParty',
      data: {
        order: newObject,
        thirdParty: thirdParty,
      },
    })
      .then((res) => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully resent the transaction',
          icon: 'success',
        });
      })
      .catch((res) => {
        let text = '';
        console.log('errorduring resend', res.response);
        res.response?.data?.salesfailures?.forEach((element, i) => {
          text += i + 1 + 'sale failed by: ' + element.reason + '\n';
        });
        if (text) {
          Swal.fire({
            title: 'Error',
            text: text,
            icon: 'error',
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: JSON.stringify(res.response),
            icon: 'error',
          });
        }
      });
    // console.log('newObject', newObject);
  };

  return (
    <div>
      {!loading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <Container maxWidth="xl" style={{ marginTop: '5px', marginBottom: '5px', padding: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MachineSelect
                filterd={filterd}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                startDate={startDate}
                endDate={endDate}
                setRadio={setRadio}
                setgroupV={setgroupV}
                radio={radio}
                groupV={groupV}
                groups={groups}
                machines={machines}
                handleChange={handleSearch}
              />
            </Grid>
          </Grid>
        </Container>
        {/* </Box> */}
        <MaterialTable
          onChangeRowsPerPage={setPagination}
          columns={columns}
          data={order}
          detailPanel={(rowData) => {
            // console.log(rowData);
            return (
              <TableContainer component={Paper} style={{ backgroundColor: '#EBEBE8' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell>UPC/SKU</TableCell>
                      <TableCell align="right">Discount</TableCell>
                      <TableCell align="right">Promotion Used</TableCell>
                      <TableCell align="right">Code Sent</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Cost Price(unit)</TableCell>
                      <TableCell align="right">Cost Price(total)</TableCell>
                      <TableCell align="right">Unit Price</TableCell>
                      <TableCell align="right">Sub Total without Tax</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData.products.map(
                      (prod, i) =>
                        prod.productInfo && (
                          <TableRow key={i} style={prod.err ? { backgroundColor: '#fea7a5' } : {}}>
                            <TableCell component="th" scope="row">
                              {prod.productInfo.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {prod.productInfo.upc}
                            </TableCell>
                            <TableCell align="right">{'' + prod.discount.toFixed(2)}</TableCell>
                            {prod.promoType ? (
                              <TableCell align="right">
                                {prod.codeEntered} ({prod.promoType})
                              </TableCell>
                            ) : prod.promoInfo ? (
                              <TableCell align="right">
                                {prod.promoInfo.title} ({prod.promoInfo.promoType})
                              </TableCell>
                            ) : (
                              <TableCell align="right">No Promotion</TableCell>
                            )}
                            {prod.code ? (
                              <TableCell align="right">{prod.code}</TableCell>
                            ) : (
                              <TableCell align="right">No Code</TableCell>
                            )}
                            <TableCell align="right">{prod.quantity}</TableCell>
                            <TableCell align="right">{prod.productInfo?.costPrice}</TableCell>
                            <TableCell align="right">
                              {(prod.productInfo?.costPrice * prod.quantity).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {(prod.soldPrice / prod.quantity).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">{prod.soldPrice.toFixed(2)}</TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          options={{
            actionsColumnIndex: -1,
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100, 200],
          }}
          actions={[
            {
              tooltip: 'Download CSV(Per Trans)',
              isFreeAction: true,
              icon: CloudDownload,
              onClick: (evt, d) => {
                let length = 0;
                console.log(order);

                const filteredArray = [...order].filter((e) => e.products.some((e) => !e.err));

                const outPutArray = filteredArray.map((e) => {
                  if (e.products.length > length) length = e.products.length;
                  let date = new Date(e.createdAt);
                  let date1 = new Date(e.createdAt);
                  if (e.realTime) {
                    date = moment.utc(date).format('lll');
                    date1 = moment.utc(date).format('MM/DD/YYYY');
                  } else {
                    date = moment(date).format('lll');
                    date1 = moment(date).format('MM/DD/YYYY');
                  }
                  date = date.replaceAll(',', ' ');
                  //replace description too, because it may contain comma
                  if (e.machineInfo.description) {
                    e.machineInfo.description = e.machineInfo.description.replaceAll(',', ';');
                  }
                  return {
                    Order_ID: e.id,
                    Order_Date: date,
                    Order_Date_Only: date1,
                    Machine_ID: e.machineInfo.name,
                    Machine_Desc: e.machineInfo.description,
                    currency: e.currency,
                    Tax: e.tax,
                    Sale_Amount: e.total,
                    Products: e.products,
                    Email: e.email,
                    One_Time_Code: e.otc,
                    Subscripted: e.isCheck,
                    payment_type: e.payment_type,
                  };
                });
                let csv = 'data:text/csv;charset=utf-8,';

                let header = `Order_ID,Order_Date,Order_Date_Only,Machine_ID,Machine_Desc,Currency,Tax,Sale_Amount,Email,Email_Subscribed,Code_Used,Payment_Type,`;

                for (var i = 1; i <= length; i++) {
                  header += `Product${i} Name,Product${i} UPC/SKU,Product${i} Cost Price(unit),Product${i} Cost Price(total),Product${i} Unit Price, Product${i} Quantity,Product${i} Sub Total without Tax,Product${i} Tax,Product${i} Promotion Used,Product${i} Code Sent,Product${i} Discount, `;
                }

                let values = outPutArray
                  .map((o) => {
                    let r = '';
                    r +=
                      o.Order_ID +
                      ',' +
                      o.Order_Date +
                      ',' +
                      o.Order_Date_Only +
                      ',' +
                      o.Machine_ID +
                      ',' +
                      o.Machine_Desc +
                      ',' +
                      o.currency +
                      ',' +
                      o.Tax +
                      ',' +
                      o.Sale_Amount +
                      ',';
                    if (o.Email) r += o.Email + ',';
                    else r += ' ,';
                    if (o.Subscripted) r += o.Subscripted + ',';
                    else r += ' ,';

                    if (o.One_Time_Code) r += o.One_Time_Code + ',';
                    else r += ' ,';

                    if (o.payment_type) r += o.payment_type + ',';
                    else r += ' ,';

                    o.Products.forEach((element, idx) => {
                      if (!element.err) {
                        let i = idx + 1;
                        if (element.productInfo) {
                          const name = element.productInfo?.name?.replaceAll(',', ';');

                          r += name + ',';

                          r += element.productInfo.upc + ',';
                          r += element.productInfo.costPrice + ',';
                          r += element.quantity + ',';
                          r += element.productInfo.price + ',';
                        } else {
                          r += ' ,';
                          r += ' ,';
                          r += ' ,';
                          r += ' ,';
                        }

                        r += element.quantity + ',' + element.soldPrice + ',';
                        if (element.tax_amount) {
                          r += element.tax_amount + ',';
                        } else {
                          r += 0 + ',';
                        }
                        if (element.promoInfo)
                          r +=
                            element.promoInfo.title + '(' + element.promoInfo.promoType + ')' + ',';
                        else r += ' ,';
                        if (element.code) r += element.code + ',';
                        else r += ' ,';
                        r += element.discount + ' ,';
                      }
                    });
                    return r;
                  })
                  .join('\n');
                csv += header + '\n' + values;
                var encodedUri = encodeURI(csv);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', 'my_transaction_data.csv');
                document.body.appendChild(link); // Required for FFo

                link.click();
              },
            },
            {
              tooltip: 'Download CSV(Per Item)',
              isFreeAction: true,
              icon: CloudDownload,
              onClick: (evt, data) => {
                const filteredArray = [...order];
                let length = 0;
                let outPutArray = [];
                filteredArray.forEach((e) => {
                  let date = new Date(e.createdAt);
                  let date1 = new Date(e.createdAt);
                  if (e.realTime) {
                    date = moment.utc(date).format('lll');
                    date1 = moment.utc(date).format('MM/DD/YYYY');
                  } else {
                    date = moment(date).format('lll');
                    date1 = moment(date).format('MM/DD/YYYY');
                  }
                  date = date.replace(',', ' ');
                  for (var i = 0; i < e.products?.length; i++) {
                    const thisData = {
                      Order_ID: e.id,
                      Order_Date: date,
                      Order_Date_Only: date1,
                      Machine_ID: e.machineInfo.name,
                      Machine_Desc: e.machineInfo.description,
                      Currency: e.currency,
                      Tax: e.tax,
                      Sale_Amount: e.total,
                      Email: e.email ? e.email : '',
                      Email_Subscribed: e.isCheck ? e.isCheck : '',
                      Code_Used: e.otc ? e.otc : '',
                      Payment_Type: e.payment_type ? e.payment_type : '',
                      Product_Name: e.products[i].productInfo?.name,
                      Product_UPC_SKU: e.products[i].productInfo?.upc,
                      Product_Cost_Price: e.products[i].productInfo?.costPrice,
                      Product_Unit_Price: e.products[i].productInfo?.price,
                      Product_Quantity: e.products[i].quantity,
                      Product_Sub_Total_Without_Tax: e.products[i].soldPrice,
                      Product_Tax: e.products[i].tax_amount ? e.products[i].tax_amount : 0,

                      Product_Promotion: e.products[i].promoInfo
                        ? e.products[i].promoInfo?.title +
                          '(' +
                          e.products[i].promoInfo?.promoType +
                          ')'
                        : '',
                      Product_Code_Sent: e.products[i].code ? e.products[i].code : '',
                      Product_Discount: e.products[i].discount,
                    };

                    if (!e.products[i].err) {
                      outPutArray.push(thisData);
                    }
                  }
                });

                const options = {
                  fieldSeparator: ',',
                  quoteStrings: '"',
                  decimalSeparator: '.',
                  useTextFile: false,
                  filename: 'Sales Report CSV(per item)',
                  useKeysAsHeaders: true,
                };

                const csvExporter = new ExportToCsv(options);
                csvExporter.generateCsv(outPutArray);
              },
            },
            {
              tooltip: 'Send Email Receipt',
              icon: Email,
              onClick: async (event, rowData) => {
                const { value: email } = await Swal.fire({
                  title: 'Enter the email address',
                  input: 'text',
                  inputPlaceholder: 'Enter the email ("delete" for deleting email address)',
                });
                if (email) {
                  Swal.fire({
                    title: `Please Wait for response`,
                    didOpen: () => {
                      Swal.showLoading();
                    },
                  });
                  console.log('rowData', rowData);
                  await request({
                    method: 'post',
                    url: '/receipt/manual',
                    data: {
                      email: email,
                      orderID: rowData.id,
                    },
                  })
                    .then(() => {
                      Swal.fire({
                        icon: 'success',
                        text: `The receipt has been send.`,
                      });
                    })
                    .catch((err) => {
                      Swal.fire({
                        icon: 'error',
                        text: err?.response?.data,
                      });
                    });
                }
              },
            },
            thirdParty && {
              tooltip: 'Resend transaction to third party',
              icon: ForwardIcon,
              onClick: async (evt, data) => {
                if (!data.isSent && data.send_order_third === 'TRUE') {
                  resentTransaction(data);
                } else {
                  Swal.fire({
                    icon: 'error',
                    text: `This transaction has been sent to third party`,
                  });
                }
              },
            },
            // {
            //   tooltip: 'Send to my email',
            //   isFreeAction: true,
            //   icon: Email,
            //   onClick: async (evt, data) => {
            //     //redundent code
            //     let length = 0;
            //     const outPutArray = order.map((e) => {
            //       if (e.products.length > length) length = e.products.length;
            //       let date = new Date(e.createdAt);
            //       if (e.realTime) {
            //         date = moment.utc(date).format('lll');
            //       } else {
            //         date = moment(date).format('lll');
            //       }
            //       date = date.replace(',', ' ');

            //       return {
            //         Order_ID: e._id,
            //         Order_Date: date,
            //         Machine_ID: e.machineInfo.name,
            //         Machine_Desc: e.machineInfo.description,
            //         currency: e.currency,
            //         Tax: e.tax,
            //         Sale_Amount: e.total,
            //         Products: e.products,
            //       };
            //     });
            //     let csv = '';
            //     let header = `Order_ID,Order_Date,Machine_ID,Machine_Desc,currency,Tax,Sale_Amount,`;
            //     for (var i = 1; i <= length; i++) {
            //       header += `Product${i} Name,Product${i} Cost Price(unit),Product${i} Cost Price(total),Product${i} Unit Price, Product${i} Quantity,Product${i} Sub Total without Tax,Product${i} Promotion Used,Product${i} Discount, `;
            //     }

            //     let values = outPutArray
            //       .map((o) => {
            //         let r = '';
            //         r +=
            //           o.Order_ID +
            //           ',' +
            //           o.Order_Date +
            //           ',' +
            //           o.Machine_ID +
            //           ',' +
            //           o.Machine_Desc +
            //           ',' +
            //           o.currency +
            //           ',' +
            //           o.Tax +
            //           ',' +
            //           o.Sale_Amount +
            //           ',';
            //         o.Products.forEach((element, idx) => {
            //           let i = idx + 1;
            //           if (element.product) {
            //             r += element.product.name?.en + ',';
            //             r += element.product.costPrice + ',';
            //             r += element.quantity + ',';
            //             r += element.product.price + ',';
            //           } else {
            //             r += ' ,';
            //             r += ' ,';
            //             r += ' ,';
            //             r += ' ,';
            //           }

            //           r += element.quantity + ',' + element.soldPrice + ',';
            //           if (element.promo)
            //             r += element.promo.code + '(' + element.promo.promoType + ')' + ',';
            //           else r += ' ,';
            //           r += element.discount + ' ,';
            //         });
            //         return r;
            //       })
            //       .join('\n');
            //     csv += header + '\n' + values;

            //     const { value: email } = await Swal.fire({
            //       title: 'Enter your email address',
            //       input: 'email',
            //       inputPlaceholder: 'Enter your email address',
            //     });
            //     if (email) {
            //       await request({
            //         method: 'post',
            //         url: '/email/sendcsv',
            //         data: {
            //           email: email,
            //           csv: csv,
            //         },
            //       }).then((res) => {
            //         Swal.fire(`The table has been send to your email as csv file.`);
            //       });
            //     }
            //   },
            // },
          ]}
        />
        <BackButton />
      </div>
    </div>
  );
}

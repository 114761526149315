export const themes = [
  {
    code: '0',
    name: 'Default theme',
  },
  {
    code: '1',
    name: 'Travel wifi theme',
  },
];

import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/userContext';
import { useHistory } from 'react-router';
import LoadingIcon from '../items/buttons/LoadingIcon';
import request from '../../services/Request';
import Swal from 'sweetalert2';
import MaterialTable from 'material-table';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { CloudDownload, CloudDownloadOutlined } from '@material-ui/icons';
import { sortPOS } from '../../services/method/methods';
import UnitSold from '../dashboard/report/Cards/UnitSold';
import TotalRev from '../dashboard/report/Cards/TotalRev';
import Preket from './prekit/Preket';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ExportToCsv } from 'export-to-csv';

import {
  FormControlLabel,
  RadioGroup,
  Grid,
  Radio,
  Checkbox,
  Button,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  Table,
  TableHead,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { userLimits } from '../dashboard/uitls/userLimits';
import ViewCashModal from './ViewCashModal';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  buttom: {
    width: 80,
    height: 40,
    marginTop: 15,
  },
}));

const getGroupsURL = `/groupsAndMachinesForReport/`;
const getMachineURL = `/machines/getMachineProductOverPlano`;
const updateMinStock = `/machines/updateMinStock`;
const getProdURL = `/products/`;
const getInventoryByUPC = `/machines/getMachineByProductID/`;

export default function ViewMyMachines(props) {
  const classes = useStyles();
  const { setFiltered, filterd, setTestMode, userEmail } = useContext(UserContext);
  let history = useHistory();

  // const [reflesh, setReflesh] = useState(false);
  const [machines, setMachines] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prods, setProds] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [radio, setRadio] = useState('a');
  const [select, setSelect] = useState({ name: '' });
  const [selectP, setSelectP] = useState({});
  const [sub, setSub] = useState(false);
  const [checked, setChecked] = useState(false);
  const [machineName, setMachineName] = useState('All Machines');
  const [showSelect, setShowSelect] = useState(false);
  const [min, setMin] = useState(0);
  const [row, setRow] = useState([]);
  const [totalStock, setTotalStock] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [products, setProducts] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [diffPlano, setDiffPlano] = useState([]);

  const [tableUPC, settableUPC] = useState([]);

  const [showCash, setshowCash] = useState(false);

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_inven') ? localStorage.getItem('table_view_inven') : 20
  );

  useEffect(() => {
    localStorage.setItem('table_view_inven', pagination);
  }, [pagination]);

  const { tableTitle, _id, superAdmin, access } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Machines',
    'edit_machine'
  );

  useEffect(() => {
    let isCancelled = false;
    // getMyMachines

    async function getGroups() {
      await request({
        method: 'get',
        url:
          props.match.params.group && superAdmin
            ? getGroupsURL + props.match.params.id
            : getGroupsURL + userEmail,
      }).then((res) => {
        if (!isCancelled) {
          console.log(res.data.machines);
          setGroups(res.data.groups);
          setMachines(res.data.machines);
          setFiltered(res.data.machines);
          setLoading(true);
        }
      });
    }

    async function getProducts() {
      await request({
        method: 'get',
        url:
          props.match.params.group && superAdmin
            ? getProdURL + props.match.params.id + '/report'
            : getProdURL + _id + '/report',
      }).then((res) => {
        if (!isCancelled) {
          setProducts(res.data);
          console.log('allproduct', res.data);
        }
      });
    }

    getGroups();
    getProducts();

    return () => {
      isCancelled = true;
    };
  }, [refresh]);

  const handleChange = async (machines) => {
    setChecked(true);
    if (radio === 'a') {
      setSub(false);
      filterProd(machines);
      setFiltered(machines);
      setMachineName('All Machines');
    }
    if (radio === 'g') {
      setSub(false);
      const myArray = groupV.map((e) => e.machineID);
      var merged = [].concat.apply([], myArray);
      merged = merged.filter((e, i, self) => i === self.findIndex((t) => t._id == e._id));
      // const filteredArray = machines.filter((e, i) => i == merged.findIndex((t)=>(t._id == e._id)));
      const filteredArray = [];
      machines.forEach((e) => {
        merged.findIndex((t) => {
          if (t._id === e._id) {
            filteredArray.push(e);
          }
        });
      });
      setMachineName(`Groups: ${groupV.map((e) => e.name).join(', ')} `);
      filterProd(filteredArray);
      setFiltered(filteredArray);
    }
    if (radio === 'm') {
      setSub(false);
      filterProd(groupV);
      setFiltered(groupV);

      setMachineName(`Machines: ${groupV.map((e) => e.name).join(', ')} `);
    }
    if (radio === 'im') {
      setMachineName(select?.name);
      setSub(true);
      setFiltered(groupV);
      setLoading(false);
      await request({
        method: 'post',
        url: getMachineURL,
        data: {
          machineID: select._id,
        },
      }).then((res) => {
        let myP = [...res.data[0]];

        if (myP.length > 0 && myP[0].idx) {
          myP.forEach((item) => {
            item.pos = item.floor + 'F ' + item.lane + 'L ' + item.idx + 'S';
          });
          setProds(myP);
        } else {
          myP = sortPOS(myP);
          console.log(myP);
          setProds(myP);
        }

        setLoading(true);
      });
    }
    if ((radio === 'p' || radio === 'ip') && selectP?.upc) {
      // console.log('selectP', selectP);
      setUnitPrice(selectP?.costPrice);
      setMachineName(`Product: ${selectP?.upc} (${selectP?.name?.en})`);
      await request({
        method: 'get',
        url: getInventoryByUPC + selectP?._id,
      }).then((res) => {
        const newArray = [];
        console.log('return product machine', res.data);
        const diffArray = [];
        let total = 0;
        res.data.forEach((machine) => {
          if (machine.diff) {
            diffArray.push(machine.name);
          }
          machine.product.forEach((prod) => {
            if (selectP._id === prod.id) {
              total += prod.current_stock;
              newArray.push({
                name: machine.name,
                description: machine.description,
                current_stock: prod.current_stock,
                max_stock: prod.max_stock,
                pos: prod.pos,
              });
            }
          });
        });
        setTotalStock(total);
        setDiffPlano(diffArray);
        // console.log('nar', newArray);
        if (radio === 'p') {
          settableUPC(newArray);
          setLoading(true);
        }
        if (radio === 'ip') {
          const array2 = [];
          newArray.forEach((prod) => {
            if (array2.length === 0) {
              array2.push({
                name: prod.name,
                description: prod.description,
                current_stock: prod.current_stock,
                max_stock: prod.max_stock,
              });
            } else {
              const index = array2.findIndex((x) => x.name === prod.name);
              if (index === -1) {
                const addP = {
                  name: prod.name,
                  description: prod.description,
                  current_stock: prod.current_stock,
                  max_stock: prod.max_stock,
                };
                array2.push(addP);
              } else {
                array2[index].current_stock += prod.current_stock;
                array2[index].max_stock += prod.max_stock;
              }
            }
          });

          settableUPC(array2);
          setLoading(true);
        }
      });
    }
  };

  const filterProd = (filterd) => {
    if (radio !== 'im' && radio !== 'p' && radio !== 'ip') {
      let finalProd = [];
      const diffArray = [];

      filterd.forEach((e) => {
        if (e.diff) {
          diffArray.push(e.name);
        }

        e?.product.forEach((prod) => {
          const index = e?.products_min?.findIndex((e) => e?.id === prod?.id?._id);

          if (index > -1) {
            prod.min_stock = e.products_min[index].min;
          }

          if (prod.id) {
            const index = finalProd.findIndex((x) => x._id === prod.id._id);
            if (index === -1) {
              const addP = {
                _id: prod.id._id,
                upc: prod.id.upc,
                name: prod.id.name?.en,
                pos: prod.pos,
                current_stock: prod.current_stock,
                max_stock: prod.max_stock,
                min_stock: prod.min_stock,
                costPrice: prod.id?.costPrice,
                mc: [
                  {
                    name: e.name,
                    description: e.description,
                    current_stock: prod.current_stock,
                    max_stock: prod.max_stock,
                  },
                ],
                indi: [
                  {
                    name: e.name,
                    description: e.description,
                    current_stock: prod.current_stock,
                    max_stock: prod.max_stock,
                    pos: prod?.pos,
                  },
                ],
              };

              finalProd.push(addP);
            } else {
              const findMachine = finalProd[index].mc.findIndex((x) => {
                return x.name === e.name;
              });
              finalProd[index].indi.push({
                name: e.name,
                description: e.description,
                current_stock: prod.current_stock,
                max_stock: prod.max_stock,
                pos: prod?.pos,
              });
              if (findMachine > -1) {
                finalProd[index].mc[findMachine].current_stock += prod.current_stock;
                finalProd[index].mc[findMachine].max_stock += prod.max_stock;
              } else {
                finalProd[index].mc.push({
                  name: e.name,
                  description: e.description,
                  current_stock: prod.current_stock,
                  max_stock: prod.max_stock,
                });
              }

              finalProd[index].current_stock += prod.current_stock;
              finalProd[index].max_stock += prod.max_stock;
            }
          }
        });
      });
      // console.log(diffArray);
      setDiffPlano(diffArray);
      console.log('finalProd', finalProd);
      setProds(finalProd);
    }
  };

  const toggleEditMinStock = () => {
    if (filterd.length > 1 && !showSelect) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The action will applied to all of your selected machines.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          setShowSelect(!showSelect);
        }
      });
    } else {
      setShowSelect(!showSelect);
    }
  };

  useEffect(() => {
    setShowSelect(false);
    setChecked(false);
  }, [radio]);

  const submitMin = async () => {
    const filterdID = filterd.map((e) => e._id);
    const newData = [];
    const newProds = [...prods];
    newProds.forEach((e) => {
      if (e.min_stock || e.min_stock === 0) {
        let myData = {};
        myData.id = e._id;
        myData.min = e.min_stock;
        myData.sent = false;
        newData.push(myData);
      }
    });

    await request({
      method: 'post',
      url: updateMinStock,
      data: {
        machineID: filterdID,
        products_min: newData,
      },
    }).then((res) => {
      Swal.fire({
        text: `The min stock has been successfully updated`,
        icon: 'success',
        target: document.getElementById('swal2-container'),
      }).then((e) => {
        setRefresh(!refresh);
      });
    });
  };

  const updateMin = async () => {
    const newProds = [...prods];
    newProds.forEach((element) => {
      const index = row.findIndex((idx) => idx._id === element._id);
      if (index > -1) {
        element.min_stock = min;
      }
    });

    setProds(newProds);
  };

  const columns = sub
    ? [
        { title: 'Product Name', editable: 'never', render: (rowData) => rowData.id?.name?.en },
        { title: 'UPC/SKU', editable: 'never', field: 'id.upc' },
        // { title: 'plan', editable: 'never', field:'plans', render: rowData => rowData.plans && rowData.plans.plan && rowData.plans.plan.map(e=>{return (<p>{e.name}</p>)})  },
        {
          title: 'Unit Cost',
          field: 'id.costPrice',
        },
        {
          title: 'Current Stock',
          field: 'current_stock',
        },
        {
          title: 'Max Stock',
          field: 'max_stock',
        },
        {
          title: 'Shortage',
          render: (rowData) => rowData?.max_stock - rowData?.current_stock,
        },
        {
          title: 'Position',
          field: 'pos',
        },
      ]
    : [
        {
          title: 'Product Name',
          editable: 'never',
          field: 'name',
          render: (rowData) => rowData.name,
        },
        { title: 'UPC', editable: 'never', field: 'upc' },
        // { title: 'plan', editable: 'never', field:'plans', render: rowData => rowData.plans && rowData.plans.plan && rowData.plans.plan.map(e=>{return (<p>{e.name}</p>)})  },
        {
          title: 'Unit Cost',
          field: 'costPrice',
        },
        {
          title: 'Current Stock',
          field: 'current_stock',
        },
        {
          title: 'Max Stock',
          field: 'max_stock',
        },
        {
          title: 'Shortage',
          render: (rowData) => rowData?.max_stock - rowData?.current_stock,
        },
        {
          title:
            'Min Stock (showing the value of the first machine(if you selected multiple machines)',
          field: 'min_stock',
        },
      ];

  const prod_column =
    radio === 'p'
      ? [
          {
            title: 'Machine ID',
            field: 'name',
          },
          { title: 'Machine Desc', field: 'description' },
          {
            title: 'Current Stock',
            field: 'current_stock',
          },
          {
            title: 'Max Stock',
            field: 'max_stock',
          },
          {
            title: 'Position',
            field: 'pos',
          },
        ]
      : [
          {
            title: 'Machine ID',
            field: 'name',
          },
          { title: 'Machine Desc', field: 'description' },
          {
            title: 'Current Stock',
            field: 'current_stock',
          },
          {
            title: 'Max Stock',
            field: 'max_stock',
          },
        ];

  return (
    <div>
      {!loading && <LoadingIcon />}
      <h6 style={{ marginRight: '20px', marginTop: '20px' }}>
        Positions and are only available for individual machine
      </h6>

      <ViewCashModal show={showCash} onHide={() => setshowCash(false)} data={select} />
      <Grid
        container
        style={{
          paddingLeft: '40px',
          width: '80%',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Grid lg={3} item container justifyContent="flex-start" style={{ marginRight: '20px' }}>
          <RadioGroup
            name="bg_radio"
            style={{ height: '100%' }}
            onChange={(e) => {
              setRadio(e.target.value);
              setgroupV([]);
            }}
          >
            <FormControlLabel
              style={{ margin: 0 }}
              value="a"
              control={<Radio />}
              label="All Machines"
              checked={radio === 'a'}
            />
            <FormControlLabel
              style={{ margin: 0 }}
              value="g"
              control={<Radio />}
              label="Groups"
              checked={radio === 'g'}
            />
            <FormControlLabel
              style={{ margin: 0 }}
              value="m"
              control={<Radio />}
              label="Machines"
              checked={radio === 'm'}
            />
            <FormControlLabel
              style={{ margin: 0 }}
              value="im"
              control={<Radio />}
              label="Individual Machine"
              checked={radio === 'im'}
            />
            <FormControlLabel
              style={{ margin: 0 }}
              value="ip"
              control={<Radio />}
              label="Product(combined)"
              checked={radio === 'ip'}
            />
            <FormControlLabel
              style={{ margin: 0 }}
              value="p"
              control={<Radio />}
              label="Product(individual)"
              checked={radio === 'p'}
            />
          </RadioGroup>
        </Grid>

        <Grid container item xs={6} alignItems="center" justifyContent="flex-start">
          <Grid container item xs={8} alignItems="center" justifyContent="center">
            {radio === 'a' && (
              <TextField variant="outlined" label="All Machines" fullWidth disabled />
            )}

            {radio === 'g' && (
              <Autocomplete
                multiple
                value={groupV}
                options={groups}
                disableCloseOnSelect
                onChange={(event, value) => {
                  setgroupV(value);
                  setChecked(false);
                }}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Groups" />
                )}
              />
            )}

            {radio === 'm' && (
              <Autocomplete
                multiple
                value={groupV}
                options={machines}
                disableCloseOnSelect
                onChange={(event, value) => {
                  setgroupV(value);
                  setChecked(false);
                }}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.description ? `${option.name}(${option.description})` : option.name}
                  </React.Fragment>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Machines" />
                )}
              />
            )}

            {radio === 'im' && (
              <Autocomplete
                value={select}
                fullWidth
                id="addToMachine"
                options={machines}
                onChange={(event, value) => {
                  setSelect(value);
                }}
                getOptionLabel={(option) =>
                  option.description ? `${option.name}(${option.description})` : option.name
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select a machine" variant="outlined" />
                )}
              />
            )}

            {(radio === 'p' || radio === 'ip') && (
              <Autocomplete
                value={selectP}
                fullWidth
                id="addToMachine"
                options={products}
                onChange={(event, value) => {
                  setSelectP(value);
                }}
                getOptionLabel={(option) =>
                  option?.upc ? `${option.upc} (${option.name?.en})` : ''
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select a Product" variant="outlined" />
                )}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={1} style={{ marginLeft: '15px' }}>
          <Button
            disabled={
              groupV.length === 0 &&
              radio !== 'a' &&
              radio !== 'im' &&
              radio !== 'p' &&
              radio !== 'ip'
            }
            style={{ height: '40px', marginBottom: '20px' }}
            onClick={() => {
              handleChange(machines);
            }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
          {radio === 'im' && select?.cashInfo && (
            <Button
              style={{ height: '40px', width: '120px' }}
              onClick={() => {
                // console.log('select', select);
                setshowCash(true);
              }}
              variant="contained"
              color="primary"
            >
              View Cash
            </Button>
          )}
        </Grid>
      </Grid>

      {diffPlano?.length > 0 && (
        <div className="mb-3">
          <span style={{ color: 'red' }}>
            Planogram between machine and dashboard is different on the following machine(s):
            {diffPlano.map((e, i) => {
              if (i === diffPlano.length - 1) {
                return <span>{` ${e}`}</span>;
              } else {
                return <span>{` ${e},`}</span>;
              }
            })}
          </span>
          <div style={{ color: 'red' }}>It may result incorrect inventory</div>
        </div>
      )}

      <div style={{ maxWidth: '100%', marginBottom: '100px' }}>
        {showSelect && 'set min to -1 if you do not want to receive the email on this product'}
        {radio !== 'im' && radio !== 'p' && radio !== 'ip' && (
          <div
            style={{ padding: '30px 20px', gap: '30px' }}
            className="d-flex justify-content-center align-items-center"
          >
            {/* <Grid container xs={12} spacing={3}> */}
            {checked && (
              <Button onClick={toggleEditMinStock} variant="contained" color="primary">
                {showSelect ? `Edit Min Stock Off` : `Edit Min Stock On`}
              </Button>
            )}

            {showSelect && (
              <TextField
                value={min}
                variant="outlined"
                onChange={(e) => {
                  setMin(e.target.value);
                }}
                type="number"
                label="Min Stock"
                InputProps={{ inputProps: { min: -1 } }}
              />
            )}
            {showSelect && (
              <Button onClick={updateMin} variant="contained" color="primary" disabled={!checked}>
                Edit
              </Button>
            )}
            {showSelect && (
              <Button onClick={submitMin} variant="contained" color="primary" disabled={!checked}>
                Submit
              </Button>
            )}
            {/* </Grid> */}
          </div>
        )}

        {radio === 'p' || radio === 'ip' ? (
          <div>
            <Grid container item spacing={3} justifyContent={'space-evenly'} className="mt-3 mb-3">
              <Grid item xs={12} sm={6} md={3}>
                <UnitSold title="Total Stock" unit={totalStock} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TotalRev title="Cost Price" rev={unitPrice?.toFixed(2)} />
              </Grid>
            </Grid>
            <MaterialTable
              columns={prod_column}
              data={tableUPC}
              title={`${machineName}`}
              options={{
                actionsColumnIndex: -1,
                pageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 200],
                selection: showSelect,
              }}
              onSelectionChange={(rows) => {
                setRow(rows);
              }}
              actions={[
                {
                  tooltip: 'Download csv',
                  isFreeAction: true,
                  icon: CloudDownload,
                  onClick: (evt, data) => {
                    const outPutArray = tableUPC.map((prod) => {
                      if (radio === 'ip') {
                        return {
                          machine_ID: prod.name,
                          description: prod.description,
                          current_stock: prod.current_stock,
                          max_stock: prod.max_stock,
                        };
                      }
                      return {
                        machine_ID: prod.name,
                        description: prod.description,
                        current_stock: prod.current_stock,
                        max_stock: prod.max_stock,
                        Position: prod.pos,
                      };
                    });

                    const options = {
                      fieldSeparator: ',',
                      quoteStrings: '"',
                      decimalSeparator: '.',
                      useTextFile: false,
                      filename: 'Inventory_report',
                      useKeysAsHeaders: true,
                    };

                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(outPutArray);
                  },
                },
              ]}
            />
          </div>
        ) : (
          <MaterialTable
            onChangeRowsPerPage={setPagination}
            columns={columns}
            data={prods}
            title={`${machineName}`}
            options={{
              actionsColumnIndex: -1,
              pageSize: parseInt(pagination),
              pageSizeOptions: [10, 20, 50, 100, 200],
              selection: showSelect,
            }}
            detailPanel={(rowData) => {
              if (radio === 'im') return <div></div>;
              else
                return (
                  <TableContainer
                    component={Paper}
                    style={{ backgroundColor: '#EBEBE8', paddingLeft: '46px' }}
                  >
                    <TableContainer>
                      <TableHead>
                        <TableRow>
                          <TableCell>Machine Name</TableCell>
                          <TableCell>Machine Desc</TableCell>
                          <TableCell>Current_Stock</TableCell>
                          <TableCell>Max_Stock</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowData.mc.map((m, index) => {
                          return (
                            <TableRow key={m.name}>
                              <TableCell align="left">{m.name}</TableCell>
                              <TableCell align="left">{m.description}</TableCell>
                              <TableCell align="left">{m.current_stock}</TableCell>
                              <TableCell align="left">{m.max_stock}</TableCell>
                            </TableRow>
                          );
                        })}

                        {/* {rowData.machine.map(
                        (prod) =>
                          prod.product && (
                            <TableRow key={prod.product._id}>
                              <TableCell component="th" scope="row">
                                {prod.product.name}
                              </TableCell>
                              <TableCell align="right">{'$' + prod.discount.toFixed(2)}</TableCell>
                              {prod.promo ? (
                                <TableCell align="right">
                                  {prod.promo.code}({prod.promo.promoType})
                                </TableCell>
                              ) : (
                                <TableCell align="right">No Promotion</TableCell>
                              )}
                              <TableCell align="right">{prod.quantity}</TableCell>
                              <TableCell align="right">{prod.soldPrice.toFixed(2)}</TableCell>
                            </TableRow>
                          )
                      )} */}
                      </TableBody>
                    </TableContainer>
                  </TableContainer>
                );
            }}
            onSelectionChange={(rows) => {
              setRow(rows);
            }}
            actions={[
              radio === 'im' && {
                tooltip: 'Download csv',
                isFreeAction: true,
                icon: CloudDownload,
                onClick: (evt, data) => {
                  const outPutArray = prods.map((e) => {
                    if (sub) {
                      return {
                        Product_Name: e.id?.name?.en,
                        UPC: e.id?.upc,
                        Unit_Cost: e.id?.costPrice ? e.id?.costPrice : '',
                        Current_Stock: e.current_stock,
                        Max_Stock: e.max_stock,
                        Position: e.pos,
                      };
                    }
                    return {
                      Product_Name: e.name,
                      UPC: e.upc,
                      Unit_Cost: e.costPrice,
                      Current_Stock: e.current_stock,
                      Max_Stock: e.max_stock,
                      Min_Stock: e.min_stock,
                    };
                  });
                  const options = {
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    useTextFile: false,
                    filename: 'Inventory_report',
                    useKeysAsHeaders: true,
                  };

                  const csvExporter = new ExportToCsv(options);
                  csvExporter.generateCsv(outPutArray);
                },
              },
              // let csv = 'data:text/csv;charset=utf-8,';
              // let header = Object.keys(outPutArray[0]).join(',');
              // console.log('outPutArray', prods);
              // let r = '';
              // if (radio === 'im') {
              //   r = outPutArray.map((o) => Object.values(o).join(',')).join('\n');
              // }
              // else {
              //   prods.forEach((prod) => {
              //     r +=
              //       prod.name +
              //       ',' +
              //       prod.upc +
              //       ',' +
              //       prod.costPrice +
              //       ',' +
              //       prod.current_stock +
              //       ',' +
              //       prod.max_stock +
              //       ',' +
              //       prod.min_stock +
              //       '\n';
              //     if (prod?.mc?.length > 0) {
              //       r += 'Machine Name,Machine Desc,Current_Stock,Max_Stock\n';
              //       prod.mc.forEach((m) => {
              //         r +=
              //           m.name +
              //           ',' +
              //           m.description +
              //           ',' +
              //           m.current_stock +
              //           ',' +
              //           m.max_stock +
              //           '\n';
              //       });
              //     }
              //   });
              // }

              // let values = r;
              // //extra for non sold

              // csv += header + '\n' + values;

              // var encodedUri = encodeURI(csv);
              // var link = document.createElement('a');
              // link.setAttribute('href', encodedUri);
              // link.setAttribute('download', 'Inventory_report.csv');
              // document.body.appendChild(link); // Required for FF

              //     link.click();
              //   },
              // },
              prods.length > 0 &&
                prods[0].idx && {
                  tooltip: 'Download Prekit Report',
                  isFreeAction: true,
                  icon: CloudDownloadOutlined,
                  onClick: (evt, data) => {
                    const input = document.getElementById('pkToPrint');
                    if (input) {
                      html2canvas(input, { scale: 2, useCORS: true }).then(async (canvas) => {
                        const imgData = await canvas.toDataURL('image/png');

                        const input2 = document.getElementById('pkToPrint2');

                        html2canvas(input2, { scale: 2, useCORS: true }).then(async (canvas) => {
                          const imgData2 = await canvas.toDataURL('image/png');

                          // document.write('<img src="' + imgData + '"/>');
                          const pdf = new jsPDF('p', 'mm', 'a4');
                          const imgProps = pdf.getImageProperties(imgData);
                          const pdfWidth = pdf.internal.pageSize.getWidth();
                          console.log('pdfWidth', pdfWidth);
                          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                          const imgProps2 = pdf.getImageProperties(imgData2);
                          const pdfWidth2 = pdf.internal.pageSize.getWidth();
                          console.log('pdfWidth2', pdfWidth2);
                          const pdfHeight2 = (imgProps2.height * pdfWidth2) / imgProps2.width;
                          // return;
                          pdf.addImage(imgData2, 'PNG', 0, 0, pdfWidth2, pdfHeight2);
                          pdf.addPage();
                          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                          pdf.save('download.pdf');
                        });
                      });
                    }
                  },
                },
              !sub && {
                tooltip: 'Download All Products csv',
                isFreeAction: true,
                icon: CloudDownloadOutlined,
                onClick: (evt, data) => {
                  if (!sub) {
                    let obj = [];
                    prods.forEach((e) => {
                      e?.mc.forEach((mc) => {
                        obj.push({
                          Product_Name: e.name,
                          UPC: e.upc,
                          Machine_Name: mc.name,
                          Machine_Description: mc.description,
                          Unit_Cost: e.costPrice ? e.costPrice : '',
                          Current_Stock: mc.current_stock,
                          Max_Stock: mc.max_stock,
                        });
                      });
                    });
                    const options = {
                      fieldSeparator: ',',
                      quoteStrings: '"',
                      decimalSeparator: '.',
                      useTextFile: false,
                      filename: 'Inventory_report',
                      useKeysAsHeaders: true,
                    };

                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(obj);
                  }
                },
              },
            ]}
          />
        )}
      </div>
      {prods?.length > 0 && prods[0].idx && <Preket data={prods} referTo={_id} select={select} />}
    </div>
  );
}

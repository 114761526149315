import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import request from '../../../services/Request';
import {
  Checkbox,
  TextField,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  buttom: {
    width: 80,
    height: 40,
    marginTop: 15,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SelectMachinesForMap(props) {
  const converIP = async (event) => {
    request({
      method: 'post',
      url: '/machines/convertAllIps',
    })
      .then(() => {
        Swal.fire({
          text: 'Success',
          timer: 2000,
        });
      })
      .catch((err) => {
        Swal.fire({
          text: JSON.stringify(err),
        });
      });
  };
  const handleChange = async (event) => {
    let result = '';
    const url = window.location.href;
    var index = url.indexOf('/dashboard');
    // console.log('index', index);
    if (index !== -1) {
      result = url.substring(0, index);
      let newUrl = '';

      console.log('result', result);

      if (radio == 'a') {
        const newMapMc = ips.map((mc) => mc.name);
        const machineStrings = newMapMc.join(',');
        console.log('machineStrings', machineStrings);
        newUrl = `${result}/showMachineAddress/62dad76310a90b5720/62dad7639bb0910a90b5720`;
        newUrl = `${result}/showMachineAddress/6499fda854d9c1004cbdf3/62dad7639bb0910a90b5720/deleteThisForSharing`;
      } else if (radio == 'g') {
        const newMapMc = groupV.map((mc) => mc.email);
        const machineStrings = newMapMc.join(',');
        console.log('groupV', groupV);
        newUrl = `${result}/showMachineAddress/${machineStrings}/group/deleteThisForSharing`;
      } else if (radio == 'm') {
        const newMapMc = groupV.map((mc) => mc.name);
        const machineStrings = newMapMc.join(',');
        console.log('groupV', groupV);
        newUrl = `${result}/showMachineAddress/${machineStrings}/individual/deleteThisForSharing`;
      } else if (radio == 'l') {
        newUrl = `${result}/showMachineAddress/${groupV.join(',')}/location/deleteThisForSharing`;
      }

      window.open(newUrl, 'googlemap');
    } else {
      console.log("'/dashabord' not found in the input string.");
    }
  };

  const classes = useStyles();
  const getAllEmployeesURL = `/users/getAllEmployees`;
  const getAllMachinesURL = '/machines/getSuperAdminMC/';

  const [employees, setEmployees] = useState([]);
  const [radio, setRadio] = useState('a');
  const [groups, setGroups] = useState([]);
  const [groupV, setgroupV] = useState([]);
  const [machines, setMachines] = useState([]);
  const [ips, setIps] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    //get all Admin
    request({
      method: 'get',
      url: getAllEmployeesURL,
    }).then((res) => {
      if (!isCancelled) {
        setGroups(res.data);
        console.log('groups', res.data);
      }
    });

    //GET ALL machines
    request({
      method: 'get',
      url: getAllMachinesURL + process.env.REACT_APP_ENV1,
    }).then((res) => {
      if (!isCancelled) {
        setMachines(res.data);
        let ip = [];

        const extractedSet = new Set();

        res.data.forEach((mc) => {
          if (mc.address_by_ip?.ip) {
            ip.push(mc);
          }
          if (mc.address?.city) {
            extractedSet.add(mc.address.city);
          }
          if (mc.address?.country) {
            extractedSet.add(mc.address.country);
          }
        });
        const extractedArray = Array.from(extractedSet);
        setCity(extractedArray);
        console.log('extractedArray', extractedArray);
        console.log('ip', ip);
        setIps(ip);
        console.log('machines', res.data);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Grid container style={{ marginLeft: '20px' }}>
          <Grid item xs={4}>
            <RadioGroup
              name="bg_radio"
              className="d-flex flex-row justify-content-center align-items-center"
              style={{ height: '100%' }}
              onChange={(e) => {
                setRadio(e.target.value);
                setgroupV([]);
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                value="a"
                control={<Radio />}
                label="All Machines"
                checked={radio == 'a'}
              />
              <FormControlLabel
                style={{ margin: 0 }}
                value="g"
                control={<Radio />}
                label="Groups"
                checked={radio == 'g'}
              />
              <FormControlLabel
                style={{ margin: 0 }}
                value="m"
                control={<Radio />}
                label="Machines"
                checked={radio == 'm'}
              />
              <FormControlLabel
                style={{ margin: 0 }}
                value="l"
                control={<Radio />}
                label="City/Country"
                checked={radio == 'l'}
              />
            </RadioGroup>
          </Grid>
          <Grid container item xs={4} alignItems="center" justifyContent="center">
            <Grid container item xs={8} alignItems="center" justifyContent="center">
              {radio == 'a' && (
                <TextField variant="outlined" label="All Machines" fullWidth disabled />
              )}

              {radio == 'g' && (
                <Autocomplete
                  multiple
                  value={groupV}
                  options={groups}
                  disableCloseOnSelect
                  onChange={(event, value) => {
                    setgroupV(value);
                  }}
                  getOptionSelected={(option, value) => option?._id === value?._id}
                  getOptionLabel={(option) =>
                    option?.groupCode ? option?.groupCode : option.groupname
                  }
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.groupCode ? option?.groupCode : option.groupname}
                    </React.Fragment>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Select Groups" />
                  )}
                />
              )}

              {radio == 'm' && (
                <Autocomplete
                  multiple
                  value={groupV}
                  options={ips}
                  disableCloseOnSelect
                  onChange={(event, value) => {
                    setgroupV(value);
                  }}
                  getOptionSelected={(option, value) => option._id === value._id}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.description ? `${option.name}(${option.description})` : option.name}
                    </React.Fragment>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Select Machines" />
                  )}
                />
              )}

              {radio == 'l' && (
                <Autocomplete
                  multiple
                  value={groupV}
                  options={city}
                  disableCloseOnSelect
                  onChange={(event, value) => {
                    setgroupV(value);
                  }}
                  getOptionSelected={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </React.Fragment>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Select Machines" />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={2} style={{ marginLeft: '10px' }}>
              <Button
                disabled={groupV.length == 0 && radio != 'a'}
                onClick={handleChange}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: '10px' }}>
          <Button onClick={converIP} variant="contained" color="primary" className={classes.submit}>
            Convert All IP to Address
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

import React from 'react';
import { Image, Col, Row, Container } from 'react-bootstrap';
import { Button, ButtonGroup } from '@material-ui/core';
import { UserContext } from '../contexts/userContext';
import TrashBinModal from '../items/TrashBinModal';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

export default function CategoryList(props) {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const { role, fullAccess } = useContext(UserContext);
  const showAdmin =
    role === 'SuperAdmin' || role === 'Admin' || (role === 'User' && fullAccess) ? true : false;

  const imageStyle = {
    width: '90%',
  };

  return (
    <Row>
      <Col xs={4} md={3}>
        <div>
          <Image
            style={imageStyle}
            src={`${process.env.REACT_APP_SERVER_API}/pictures/${props.picture_name}/${props.referTo}`}
          />
        </div>
      </Col>
      <Col>
        <div>
          <h2>{props.name}</h2>
        </div>
      </Col>
      {showAdmin && (
        <Col>
          <ButtonGroup
            size="large"
            variant="contained"
            color="primary"
            aria-label="large primary button group"
          >
            <Button
              onClick={() => {
                setShowUpdate(true);
                console.log(props.name);
              }}
            >
              <span className="iconify" data-icon="akar-icons:edit" data-inline="false"></span>
            </Button>
            <Button onClick={() => setShowDelete(true)}>
              <span className="iconify" data-icon="mdi-trash-can" data-inline="false"></span>
            </Button>
            <TrashBinModal
              id={props.id}
              catename={props.name}
              show={showDelete}
              onHide={() => setShowDelete(false)}
            />
          </ButtonGroup>
        </Col>
      )}
    </Row>
  );
}

export function userLimits(id, group, title, bool) {
  const user = JSON.parse(localStorage.getItem('user'));
  const limits = JSON.parse(localStorage.getItem('limits'));
  const access = limits[bool];
  const { role, fullAccess, referTo, email } = user;
  const showAdmin =
    role === 'SuperAdmin' || role === 'Admin' || (role === 'User' && fullAccess) ? true : false;
  let _id = '';
  let groupName = group;
  let tableTitle = title;
  let superAdmin = false;
  if (role === 'SuperAdmin') {
    _id = id;
    if (groupName) tableTitle = `${title} of ${groupName}`;
    superAdmin = true;
  } else if (role === 'Admin') _id = email;
  else if (role === 'User') _id = referTo;
  return { showAdmin, _id, id: _id, tableTitle, superAdmin, access };
}

// material
import MaterialTable from 'material-table';
import { CloudDownload } from '@material-ui/icons';
// import React, { useEffect } from 'react';
import { Email } from '@material-ui/icons';
import request from '../../../services/Request';
import Swal from 'sweetalert2';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import { fShortenNumber } from './utils/formatNumber';
import { fCurrency } from './utils/formatNumber';
import { useState } from 'react';
import { useEffect } from 'react';

export default function TableUPC(props) {
  const { soldProd, machinesName, endDate, startDate, prodInfo } = props;

  const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
    <Typography variant={variant} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {`Product Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
    </Typography>
  );

  // useEffect(() => {
  //   console.log(soldProd);
  // }, [soldProd]);

  const columns = [
    {
      title: 'Machine ID',
      field: 'name',
      cellStyle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '30%',
      },
    },
    { title: 'Machine Desc', field: 'description' },
    { title: 'Quantity Sold', field: 'quantity' },
    {
      title: 'Cost Price(unit)',
      field: 'costPrice',
      render: (rowData) =>
        `${
          rowData.costPrice && rowData.costPrice !== '' ? '$' + rowData.costPrice.toFixed(2) : ''
        }`,
    },
    {
      title: 'Cost Price(total)',
      field: 'costPrice',
      render: (rowData) =>
        `${
          rowData.costPrice && rowData.costPrice !== ''
            ? '$' + fShortenNumber(rowData.costPrice * rowData.quantity)
            : ''
        }`,
    },
    {
      title: 'Unit Price',
      field: 'price',
      render: (rowData) => `$${(rowData.soldPrice / rowData.quantity).toFixed(2)}`,
    },
    {
      title: 'Sale Amount(before tax)',
      field: 'soldPrice',
      render: (rowData) => `$${fShortenNumber(rowData.soldPrice)}`,
    },
  ];

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_upc') ? localStorage.getItem('table_view_upc') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_upc', pagination);
  }, [pagination]);

  return (
    <MaterialTable
      onChangeRowsPerPage={setPagination}
      columns={columns}
      data={soldProd}
      title={<MyNewTitle variant="h6" />}
      options={{
        actionsColumnIndex: -1,
        pageSize: parseInt(pagination),
        pageSizeOptions: [5, 10, 20, 50, 100, 200],
      }}
      actions={[
        {
          tooltip: 'Download csv',
          isFreeAction: true,
          icon: CloudDownload,
          onClick: (evt, data) => {
            console.log(prodInfo);
            const outPutArray = soldProd.map((e) => {
              return {
                Machine_ID: e.name,
                Machine_Desc: e.description,
                Quantity_Sold: e.quantity,
                Unit_Cost_Price: e.costPrice?.toFixed(2),
                Total_Cost_Price: (e.costPrice * e.quantity)?.toFixed(2),
                Unit_Sold_Price: (e.soldPrice / e.quantity)?.toFixed(2),
                Sale_Amount: e.soldPrice?.toFixed(2),
              };
            });
            let csv = 'data:text/csv;charset=utf-8,';
            let header = Object.keys(outPutArray[0]).join(',');

            let values = outPutArray.map((o) => Object.values(o).join(',')).join('\n');

            csv += header + '\n' + values;

            var encodedUri = encodeURI(csv);
            var link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'my_product_data.csv');
            document.body.appendChild(link); // Required for FF

            link.click();
          },
        },
        {
          tooltip: 'Send to my email',
          isFreeAction: true,
          icon: Email,
          onClick: async (evt, data) => {
            const outPutArray = soldProd.map((e) => {
              return {
                Machine_ID: e.name,
                Machine_Desc: e.description,
                Quantity_Sold: e.quantity,
                Unit_Cost_Price: e.costPrice?.toFixed(2),
                Total_Cost_Price: (e.costPrice * e.quantity)?.toFixed(2),
                Unit_Sold_Price: (e.soldPrice / e.quantity)?.toFixed(2),
                Sale_Amount: e.soldPrice?.toFixed(2),
              };
            });
            let csv = '';
            let header = Object.keys(outPutArray[0]).join(',');

            let values = outPutArray.map((o) => Object.values(o).join(',')).join('\n');

            csv += header + '\n' + values;

            const { value: email } = await Swal.fire({
              title: 'Enter your email address',
              input: 'email',
              inputPlaceholder: 'Enter your email address',
            });
            if (email) {
              await request({
                method: 'post',
                url: '/email/sendcsv',
                data: {
                  email: email,
                  csv: csv,
                },
              }).then((res) => {
                Swal.fire(`The table has been send to your email as csv file.`);
              });
            }
          },
        },
      ]}
    />
  );
}

export const Thank = {
  en: 'Thank you!',
  fr: 'Merci!',
  es: 'Gracias!',
};
export const Receipt = {
  en: 'Order Receipt',
  fr: 'Le reçu',
  es: 'Recibo',
};
export const DateS = {
  en: 'Date',
  fr: 'Date',
  es: 'Fecha',
};
export const Order = {
  en: 'Order ID',
  fr: 'Commande ID',
  es: 'Pedido ID',
};
export const Machine = {
  en: 'Machine ID',
  fr: 'Machine ID',
  es: 'Máquina ID',
};
export const Item = {
  en: 'Items',
  fr: 'Articles',
  es: 'Artículos',
};
export const Price = {
  en: 'Price',
  fr: 'Prix',
  es: 'precio',
};
export const Tax = {
  en: 'Tax',
  fr: 'Impôt',
  es: 'Importe',
};

export const Discount = {
  en: 'Discounted Price',
  fr: 'Prix ​​réduit',
  es: 'Precio descontado',
};

// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fCurrency } from '../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: '#04297A',
  backgroundColor: '#D0F2FF',
}));
// ----------------------------------------------------------------------

export default function AverageRevenueTrans(props) {
  const { rev, trans } = props;
  const ave = rev / trans;
  return (
    <RootStyle style={{ borderRadius: '16px', fontFamily: 'Public Sans, sans-serif' }}>
      <Typography variant="h3">{fCurrency(ave)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Revenue/Transaction
      </Typography>
    </RootStyle>
  );
}

import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Swal from 'sweetalert2';
import { FormLabel } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import request from '../../services/Request';
import '../user/form.css';
import { Modal } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const updateAdress = '/machines/updateMachineConfig';

export default function MachineConfig(props) {
  const {
    reset,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({});
  const classes = useStyles();
  const { data, user, ...rest } = props;

  const watchfield = watch();

  const defaultValues = {
    local_url: data[0].machineConfig?.local_url
      ? data[0].machineConfig?.local_url
      : 'http://localhost:8080',
    payment_url: data[0].machineConfig?.payment_url
      ? data[0].machineConfig?.payment_url
      : 'http://localhost:8080',
    isLocal: data[0].machineConfig?.isLocal ? data[0].machineConfig?.isLocal : 'TRUE',
    SKIP_PAYMENT: data[0].machineConfig?.SKIP_PAYMENT
      ? data[0].machineConfig?.SKIP_PAYMENT
      : 'FALSE',
    desc_image_start: data[0].machineConfig?.desc_image_start
      ? data[0].machineConfig?.desc_image_start
      : '0',
    thankyou: data[0].machineConfig?.thankyou ? data[0].machineConfig?.thankyou : '0',
    back_to_tech_password: data[0].machineConfig?.back_to_tech_password
      ? data[0].machineConfig?.back_to_tech_password
      : '87654',
    isTest: data[0].machineConfig?.isTest ? data[0].machineConfig?.isTest : 'FALSE',
    enable_cash: data[0].machineConfig?.enable_cash ? data[0].machineConfig?.enable_cash : 'FALSE',
    send_order_third: data[0].machineConfig?.send_order_third
      ? data[0].machineConfig?.send_order_third
      : 'FALSE',
    send_order_sqs: data[0].machineConfig?.send_order_sqs
      ? data[0].machineConfig?.send_order_sqs
      : 'FALSE',
    sqs_sales: data[0].machineConfig?.sqs_sales ? data[0].machineConfig?.sqs_sales : '',
    sqs_inventory: data[0].machineConfig?.sqs_inventory ? data[0].machineConfig?.sqs_inventory : '',
    sqs_region: data[0].machineConfig?.sqs_region ? data[0].machineConfig?.sqs_region : '',
    sqs_access_key: data[0].machineConfig?.sqs_access_key
      ? data[0].machineConfig?.sqs_access_key
      : '',
    sqs_secret_key: data[0].machineConfig?.sqs_secret_key
      ? data[0].machineConfig?.sqs_secret_key
      : '',
  };

  useEffect(async () => {
    reset(defaultValues);
  }, [data]);

  const onSubmit = async (formData) => {
    const machineConfig = {
      local_url: formData.local_url,
      payment_url: formData.payment_url,
      isLocal: formData.isLocal,
      SKIP_PAYMENT: formData.SKIP_PAYMENT,
      desc_image_start: parseInt(formData.desc_image_start),
      thankyou: formData.thankyou,
      back_to_tech_password: formData.back_to_tech_password,
      isTest: formData.isTest,
      enable_cash: formData.enable_cash,
      send_order_third: formData.send_order_third,
      send_order_sqs: formData.send_order_sqs,
      sqs_sales: formData.sqs_sales,
      sqs_inventory: formData.sqs_inventory,
      sqs_region: formData.sqs_region,
      sqs_access_key: formData.sqs_access_key,
      sqs_secret_key: formData.sqs_secret_key,
    };
    await request({
      method: 'post',
      url: updateAdress,
      data: {
        machineID: data.map((e) => e._id),
        machineConfig: machineConfig,
      },
    }).then((res) => {
      Swal.fire({
        text: `Your changes has been updated!`,
        icon: 'success',
      }).then(() => {
        props.reflesh ? props.setReflesh(false) : props.setReflesh(true);
        props.onHide();
      });
    });
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.2rem' }}>
          Update Machine Config
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlignLast: 'center' }}>
                  <FormLabel component="legend">Machine Config File</FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="local_url"
                    name="local_url"
                    inputProps={{
                      maxLength: '100',
                    }}
                    {...register('local_url', {})}
                  />
                  {errors.local_url && (
                    <p className="signUpErrorMessage">{errors.local_url.message}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="payment_url"
                    name="payment_url"
                    inputProps={{
                      maxLength: '100',
                    }}
                    {...register('payment_url', {})}
                  />
                  {errors.payment_url && (
                    <p className="signUpErrorMessage">{errors.payment_url.message}</p>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="SKIP_PAYMENT"
                    name="SKIP_PAYMENT"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('SKIP_PAYMENT', {})}
                  />
                  {errors.SKIP_PAYMENT && (
                    <p className="signUpErrorMessage">{errors.SKIP_PAYMENT.message}</p>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="desc_image_start"
                    name="desc_image_start"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('desc_image_start', {})}
                  />
                  {errors.desc_image_start && (
                    <p className="signUpErrorMessage">{errors.desc_image_start.message}</p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="thankyou"
                    name="thankyou"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('thankyou', {})}
                  />
                  {errors.thankyou && (
                    <p className="signUpErrorMessage">{errors.thankyou.message}</p>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Enable Cash"
                    name="enable_cash"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('enable_cash', {})}
                  />
                  {errors.enable_cash && (
                    <p className="signUpErrorMessage">{errors.enable_cash.message}</p>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Order To Third Party"
                    name="send_order_third"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('send_order_third', {})}
                  />
                  {errors.send_order_third && (
                    <p className="signUpErrorMessage">{errors.send_order_third.message}</p>
                  )}
                </Grid>

                <Grid item xs={12} style={{ textAlignLast: 'center' }}>
                  <FormLabel component="legend">Secondary</FormLabel>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="isTest"
                    name="isTest"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('isTest', {})}
                  />
                  {errors.isTest && <p className="signUpErrorMessage">{errors.isTest.message}</p>}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="isLocal"
                    name="isLocal"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('isLocal', {})}
                  />
                  {errors.isLocal && <p className="signUpErrorMessage">{errors.isLocal.message}</p>}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="back_to_tech_password"
                    name="back_to_tech_password"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('back_to_tech_password', {})}
                  />
                  {errors.back_to_tech_password && (
                    <p className="signUpErrorMessage">{errors.back_to_tech_password.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} style={{ textAlignLast: 'center' }}>
                  <FormLabel component="legend">AWS SQS</FormLabel>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Send Order To SQS"
                    name="send_order_sqs"
                    inputProps={{
                      maxLength: '25',
                    }}
                    {...register('send_order_sqs', {})}
                  />
                  {errors.send_order_sqs && (
                    <p className="signUpErrorMessage">{errors.send_order_sqs.message}</p>
                  )}
                </Grid>

                {watchfield?.send_order_sqs === 'TRUE' && (
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="SQS Region"
                      name="sqs_region"
                      inputProps={{
                        maxLength: '25',
                      }}
                      {...register('sqs_region', {})}
                    />
                    {errors.sqs_region && (
                      <p className="signUpErrorMessage">{errors.sqs_region.message}</p>
                    )}
                  </Grid>
                )}

                {watchfield?.send_order_sqs === 'TRUE' && (
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="SQS Access Key"
                      name="sqs_access_key"
                      {...register('sqs_access_key', {})}
                    />
                    {errors.sqs_access_key && (
                      <p className="signUpErrorMessage">{errors.sqs_access_key.message}</p>
                    )}
                  </Grid>
                )}

                {watchfield?.send_order_sqs === 'TRUE' && (
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="SQS Secret Key"
                      name="sqs_secret_key"
                      {...register('sqs_secret_key', {})}
                    />
                    {errors.sqs_secret_key && (
                      <p className="signUpErrorMessage">{errors.sqs_secret_key.message}</p>
                    )}
                  </Grid>
                )}

                {watchfield?.send_order_sqs === 'TRUE' && (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="SQS Sales URL"
                      name="sqs_sales"
                      {...register('sqs_sales', {})}
                    />
                    {errors.sqs_sales && (
                      <p className="signUpErrorMessage">{errors.sqs_sales.message}</p>
                    )}
                  </Grid>
                )}

                {watchfield?.send_order_sqs === 'TRUE' && (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="SQS Inventory URL"
                      name="sqs_inventory"
                      {...register('sqs_inventory', {})}
                    />
                    {errors.sqs_inventory && (
                      <p className="signUpErrorMessage">{errors.sqs_inventory.message}</p>
                    )}
                  </Grid>
                )}
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Update
              </Button>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import request from '../../services/Request';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core';
import LoadingIcon from '../items/buttons/LoadingIcon';
import BackButton from '../items/buttons/BackButton';
import Swal from 'sweetalert2';
import EditUserModal from '../user/EditUserModal';
import { userLimits } from './uitls/userLimits';

const getAllEmployeesURL = `/users/getAllEmployees`;
const deleteUserURL = `/users/delete/`;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(-5.6),
    marginRight: theme.spacing(40),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ViewMyUser(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  const dbaUser = user.dba;
  const classes = useStyles();
  const { id, tableTitle, superAdmin } = userLimits(
    props.match.params.id,
    props.match.params.group,
    'Users'
  );

  let history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [reflesh, setReflesh] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [rowdata, setrowdata] = useState(null);
  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_user') ? localStorage.getItem('table_view_user') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_user', pagination);
  }, [pagination]);

  useEffect(() => {
    let isCancelled = false;

    //getAllEmployees
    async function getAllEmployees() {
      await request({
        method: 'post',
        url: getAllEmployeesURL,
        data: {
          id: id,
          dba: dbaUser,
        },
      }).then((res) => {
        if (!isCancelled) {
          setEmployees(res.data);
          setIsLoading(true);
        }
      });
    }

    getAllEmployees();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  const deleteUser = async (id, referTo) => {
    await request({
      method: 'delete',
      url: deleteUserURL + id,
    }).then();
    Swal.fire({
      text: `The user has been successfully deleted`,
      icon: 'success',
    });
    reflesh ? setReflesh(false) : setReflesh(true);
  };

  const columns = [
    { title: 'Last Name', field: 'lastname', editable: 'never' },
    { title: 'First Name', field: 'firstname', editable: 'never' },
    {
      title: 'Access Level',
      render: (rowData) => (rowData.fullAccess === true ? 'Full Access' : 'Limited Access'),
      lookup: { true: 'Full Access', false: 'Limited Access' },
    },
    { title: 'Email', field: 'email', editable: 'never' },
  ];

  return (
    <div>
      {!isloading && <LoadingIcon />}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          columns={columns}
          data={employees}
          onChangeRowsPerPage={setPagination}
          title={tableTitle}
          localization={{ body: { editRow: { deleteText: 'Are you sure to delete this user' } } }}
          editable={{
            onRowDelete: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  deleteUser(newData._id, newData.referTo);
                  resolve();
                }, 1000);
              }),
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Add User',
              isFreeAction: true,
              onClick: (event) => {
                if (superAdmin)
                  history.push(
                    `/dashboard/signUp/${props.match.params.id}/${props.match.params._id}`
                  );
                else history.push(`/dashboard/signUp`);
              },
            },
            {
              icon: 'edit',
              tooltip: 'Edit User',
              onClick: (event, rowdata) => {
                setshow(true);
                setrowdata(rowdata);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            rowStyle: (rowData) => {
              if (rowData.dba) {
                return {
                  backgroundColor: 'lightblue',
                };
              }
            },
            pageSize: parseInt(pagination),
            pageSizeOptions: [10, 20, 50, 100],
          }}
        />
        <BackButton />
        <EditUserModal
          show={show}
          data={rowdata}
          setReflesh={setReflesh}
          reflesh={reflesh}
          onHide={() => {
            setshow(false);
          }}
        />
        <div className={classes.paper}></div>
      </div>
    </div>
  );
}

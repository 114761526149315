const invoiceTemplate = (data) => {
  const billed = data?.operator?.location_info;
  const paidedPeroid = `${data?.startDate} - ${data?.endDate}`;
  let subtotal = 0;

  let serviceString = '';

  data?.operator?.service?.forEach((item) => {
    let sst = 0;
    let { unit_price, quantity } = item?.service;
    let { description, service } = item?.service?._id;

    if (service === 'LICENCE FEE') {
      subtotal += data?.serviceFee;
      description += `\n\nMONTHLY SW LICENCE FEE - Controller #: ${data?.machineString}`;
      unit_price = data?.license_fee;
      quantity = data?.mcNO;
      sst = data?.serviceFee;
      // quantity = ;
    } else {
      sst = parseFloat(unit_price) * parseFloat(quantity);
      subtotal += parseFloat(unit_price) * parseFloat(quantity);
    }

    serviceString += `<tr>
        <td class="prewrap">${description}</td>
        <td>$${unit_price}</td>
        <td>${quantity}</td>
        <td>$${sst}</td>
      </tr>
    `;
  });
  const tax = subtotal * data?.taxRate;

  return `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Invoice</title>
      <style>
        .fortyp{
          width: 40%;
        }
        .container-invoice-2 {
          max-width: 842px; /* A4 width in pixels */
          margin: 0 auto;
          padding: 20px;
          // border: 1px solid #ccc;
          // border-radius: 5px;
        }
        .prewrap {
          white-space: pre-wrap;
        }
        .header-invoice-2 {
          text-align: center;
        }
        .invoice-details {
          text-align: start;
          flex-direction: column;
          margin-bottom: 20px;
          display: flex;
          align-items: flex-start;
        }
        .invoice-details .logo {
          max-width:100%; 
          height: auto;
        }
        .invoice-details .details-invoice {
          flex-grow: 1;
        }
        .invoice-details p {
          margin: 5px 0;
        }
        .table-container-invoice-2 {
          overflow-x: auto;
          margin-bottom: 20px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          padding: 8px;
          text-align: left;
          border-bottom: 1px solid #ddd;
        }
        th {
          background-color: #f2f2f2;
        }
        .totals-invoice {
          font-size: 18px;
          text-align: right;
          margin-top: 20px;
        }
        .footer-invoice {
          page-break-after: always;
          text-align: center;
          font-style: italic;
          margin-top: 40px;
        }
        .margintb {
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .imgdiv-invoice{
          width:200px;
          height:50px;
        }
        .details-invoice{
          display: flex;
          width: 100%;
        }
        .invoice-to{
          width: 60%;
        }
      </style>
    </head>
    <body>
      <div class="container-invoice-2">
        <div class="header-invoice-2">
          <h5>Invoice</h5>
        </div>
        <div class="invoice-details">
          <div class="margintb imgdiv-invoice">
            <img src="https://img1.wsimg.com/isteam/ip/d8559901-2cc1-4d65-a345-5ef13433941f/blob-255728d.png" alt="Company Logo" class="logo">
          </div class="margintb">
          
          <div class="margintb details-invoice">
            <div class="invoice-to">Date: 05/12/2020</div>
            <div>Sale Invoice #:${data?.invoiceNO}</div>
          </div>

        <div class="margintb details-invoice">
          <div class="invoice-to">Paid Peroid: ${paidedPeroid}</div>
      
        </div>
  
          <div class="details-invoice">
            <div class="margintb invoice-to">
              <strong>Invoiced To:</strong><br />Alps Innovations Group Inc.<br /> - MAGEX USA, a MAGEX Partner<br />225, GIBRALTAR ROAD, UNIT 5<br />HWoodbridge ON.<br /> L4H-4P9, CANADA
              <br />Phone - (905) 264-2014<br />GST No. 829644376 RT0001
            </div>
            <div class="margintb d2 fortyp">
              <strong>Billed / Prepared For:</strong><br />
              <div class="prewrap">${billed}</div>
            </div>
          </div>
        </div>
        <div class="table-container-invoice-2">
          <table>
            <thead>
              <tr>
                <th>Service Description</th>
                <th>Unit Price</th>
                <th>QTY</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              ${serviceString}
            </tbody>
          </table>
        </div>
        <div class="totals-invoice">
          <p><strong>Sub Total:</strong> $${subtotal}</p>
          <p><strong>Tax:</strong> $${tax}</p>
          <p><strong>Total:</strong> $${subtotal + tax}</p>
        </div>
        <div class="footer-invoice">
  
        </div>
      </div>
    </body>
  </html>  
`;
};

export default invoiceTemplate;

// material
import MaterialTable from 'material-table';
import { CloudDownload } from '@material-ui/icons';
import { Email } from '@material-ui/icons';
import request from '../../../services/Request';
import Swal from 'sweetalert2';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import { fShortenNumber } from './utils/formatNumber';
import { fCurrency } from './utils/formatNumber';
import { useEffect } from 'react';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { useState } from 'react';

export default function ProdTable(props) {
  const { soldProd, machinesName, endDate, startDate, prodInfo, order } = props;

  if (soldProd.length > 0) {
    soldProd.sort(function (a, b) {
      return b.quantity - a.quantity;
    });
  }

  const [pagination, setPagination] = useState(
    localStorage.getItem('table_view_report2') ? localStorage.getItem('table_view_report2') : 10
  );

  useEffect(() => {
    localStorage.setItem('table_view_report2', pagination);
  }, [pagination]);

  const perProductwithMachineTable = () => {
    const newArray = [...order];
    let result = [];
    newArray.forEach((order) => {
      let date = new Date(order.createdAt);

      if (order.realTime) {
        date = moment.utc(date).format('MM/DD/yyyy');
      } else {
        date = moment(date).format('MM/DD/yyyy');
      }

      order.products.forEach((element) => {
        if (element.err) {
          return;
        }
        // const address = order.machineID?.address;
        const product = element?.productInfo;
        const tax = element.tax_amount;
        const total = element.soldPrice + tax;

        // let location = '';

        // if (order.machineID?.address?.location || order.machineID?.address?.street) {
        //   let postfix = address.city + ', ' + address.province;
        //   if (order.machineID?.address?.location) {
        //     location += order.machineID?.address?.location + ', ';
        //   }
        //   if (order.machineID?.address?.street) {
        //     location += order.machineID?.address?.street + ', ';
        //   }
        //   location += postfix;
        // }

        const findindex = result.findIndex((x) => x['UPC/SKU'] === product.upc && x.date === date);
        if (findindex > -1) {
          result[findindex].units_sold += element.quantity;
          result[findindex].amount_before_tax = parseFloat(
            (parseFloat(result[findindex].amount_before_tax) + element.soldPrice).toFixed(2)
          ).toFixed(2);

          result[findindex].tax_amount = parseFloat(
            (parseFloat(result[findindex].tax_amount) + tax).toFixed(2)
          ).toFixed(2);

          result[findindex].amount_after_tax = parseFloat(
            (parseFloat(result[findindex].amount_after_tax) + total).toFixed(2)
          ).toFixed(2);
        } else {
          result.push({
            store: order.machineInfo.name,
            description: order.machineInfo.description,
            // location: location,
            date: date,
            'UPC/SKU': product.upc,
            product_name: product.name,
            units_sold: element.quantity,
            amount_before_tax: element.soldPrice.toFixed(2),
            tax_amount: tax.toFixed(2),
            amount_after_tax: total.toFixed(2),
          });
        }
      });
    });

    result = result.sort((a, b) => {
      // Compare by first field (name)
      if (new Date(a.date) < new Date(b.date)) return -1;
      if (new Date(a.date) > new Date(b.date)) return 1;

      // If first field is equal, compare by second field (age)
      if (a.store < b.store) return -1;
      if (a.store > b.store) return 1;

      // If both fields are equal, return 0
      return 0;
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      useTextFile: false,
      filename: 'Sales Report CSV(products report per machine per day)',
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(result);

    // console.log('order', order);
    // console.log('result', result);
  };

  const MyNewTitle = ({ text = 'Table Title', variant = 'h6' }) => (
    <Typography variant={variant} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {`Product Table (${machinesName} from ${startDate.toShortFormat()} to  ${endDate.toShortFormat()})`}
    </Typography>
  );

  const columns = [
    {
      title: 'Product Name',
      field: 'name',
      cellStyle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '30%',
      },
    },
    { title: 'UPC/SKU', field: 'upc' },
    { title: 'Quantity Sold', field: 'quantity' },
    // { title: 'Cost Price', field: 'costPrice',render: (rowData) => `$${(rowData.costPrice / rowData.quantity)}` },
    {
      title: 'Cost Price(unit)',
      field: 'costPrice',
      render: (rowData) =>
        `${
          rowData.costPrice && rowData.costPrice !== '' ? '$' + rowData.costPrice.toFixed(2) : ''
        }`,
    },
    {
      title: 'Cost Price(total)',
      field: 'costPrice',
      render: (rowData) =>
        `${
          rowData.costPrice && rowData.costPrice !== ''
            ? '$' + fShortenNumber(rowData.costPrice * rowData.quantity)
            : ''
        }`,
    },
    {
      title: 'Unit Price',
      field: 'price',
      render: (rowData) => `$${(rowData.totalSale / rowData.quantity).toFixed(2)}`,
    },
    {
      title: 'Sale Amount(before tax)',
      field: 'totalSale',
      render: (rowData) => `$${fShortenNumber(rowData.totalSale)}`,
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      onChangeRowsPerPage={setPagination}
      data={soldProd}
      title={<MyNewTitle variant="h6" />}
      options={{
        actionsColumnIndex: -1,
        pageSize: parseInt(pagination),
        pageSizeOptions: [10, 20, 50, 100, 200],
      }}
      detailPanel={(rowData) => {
        // console.log(rowData);
        return (
          <TableContainer
            component={Paper}
            style={{ backgroundColor: '#EBEBE8', paddingLeft: '46px' }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Machine ID</TableCell>
                  <TableCell>Quantity Sold</TableCell>
                  <TableCell>{`Cost Price(total)`}</TableCell>
                  <TableCell>Sale Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.machine.map((m, index) => {
                  return (
                    <TableRow key={m.name}>
                      <TableCell align="left">{m.name}</TableCell>
                      <TableCell align="left">{m.quantity}</TableCell>
                      <TableCell align="left">
                        ${fShortenNumber(rowData.costPrice * m.quantity)}
                      </TableCell>
                      <TableCell align="left">${fShortenNumber(m.soldPrice)}</TableCell>
                    </TableRow>
                  );
                })}

                {/* {rowData.machine.map(
                  (prod) =>
                    prod.product && (
                      <TableRow key={prod.product._id}>
                        <TableCell component="th" scope="row">
                          {prod.product.name}
                        </TableCell>
                        <TableCell align="right">{'$' + prod.discount.toFixed(2)}</TableCell>
                        {prod.promo ? (
                          <TableCell align="right">
                            {prod.promo.code}({prod.promo.promoType})
                          </TableCell>
                        ) : (
                          <TableCell align="right">No Promotion</TableCell>
                        )}
                        <TableCell align="right">{prod.quantity}</TableCell>
                        <TableCell align="right">{prod.soldPrice.toFixed(2)}</TableCell>
                      </TableRow>
                    )
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }}
      actions={[
        {
          tooltip: 'Download csv(product table)',
          isFreeAction: true,
          icon: CloudDownload,
          onClick: (evt, data) => {
            console.log(prodInfo);
            const outPutArray = soldProd.map((e) => {
              return {
                Product_Name: e.name.replaceAll(',', ';'),
                UPC: e.upc,
                Quantity_Sold: e.quantity,
                Unit_Cost_Price: e.costPrice?.toFixed(2),
                Total_Cost_Price: (e.costPrice * e.quantity)?.toFixed(2),
                Unit_Sold_Price: (e.totalSale / e.quantity)?.toFixed(2),
                Sale_Amount: e.totalSale?.toFixed(2),
              };
            });
            let csv = 'data:text/csv;charset=utf-8,';
            let header = Object.keys(outPutArray[0]).join(',');

            let values = outPutArray.map((o) => Object.values(o).join(',')).join('\n');
            //extra for non sold
            if (prodInfo && prodInfo.nprod) {
              prodInfo.nprod.forEach((e) => {
                const name = e.id.name.replaceAll(',', ';');
                values += '\n' + name + ',' + e.id.upc + ',' + '0,0,0,0,0,';
              });
            }

            csv += header + '\n' + values;

            var encodedUri = encodeURI(csv);
            var link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'my_product_data.csv');
            document.body.appendChild(link); // Required for FF

            link.click();
          },
        },
        // {
        //   tooltip: 'Send to my email',
        //   isFreeAction: true,
        //   icon: Email,
        //   onClick: async (evt, data) => {
        //     const outPutArray = soldProd.map((e) => {
        //       return {
        //         Product_Name: e.name,
        //         UPC: e.upc,
        //         Quantity_Sold: e.quantity,
        //         Unit_Cost_Price: e.costPrice.toFixed(2),
        //         Total_Cost_Price: (e.costPrice * e.quantity).toFixed(2),
        //         Unit_Sold_Price: (e.totalSale / e.quantity).toFixed(2),
        //         Sale_Amount: e.totalSale.toFixed(2),
        //       };
        //     });
        //     let csv = '';
        //     let header = Object.keys(outPutArray[0]).join(',');

        //     let values = outPutArray.map((o) => Object.values(o).join(',')).join('\n');
        //     //extra for non sold
        //     if (prodInfo && prodInfo.nprod) {
        //       prodInfo.nprod.forEach((e) => {
        //         values += '\n' + e.id.name + ',' + e.id.upc + ',' + '0,0,0,0,0,';
        //       });
        //     }

        //     csv += header + '\n' + values;

        //     const { value: email } = await Swal.fire({
        //       title: 'Enter your email address',
        //       input: 'email',
        //       inputPlaceholder: 'Enter your email address',
        //     });
        //     if (email) {
        //       await request({
        //         method: 'post',
        //         url: '/email/sendcsv',
        //         data: {
        //           email: email,
        //           csv: csv,
        //         },
        //       }).then((res) => {
        //         Swal.fire(`The table has been send to your email as csv file.`);
        //       });
        //     }
        //   },
        // },

        {
          tooltip: 'Download csv(Products per machine per day)',
          isFreeAction: true,
          icon: CloudDownload,
          onClick: (evt, data) => {
            perProductwithMachineTable();
          },
        },
      ]}
    />
  );
}

import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Checkbox,
} from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import { v4 as uuidv4 } from 'uuid';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2';
import { useForm, Controller } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import request from '../../services/Request';
import '../user/form.css';
import { Modal } from 'react-bootstrap';
import { Select, MenuItem } from '@material-ui/core';
import { languages, languages_noEn } from '../../staticData/languages';
import QRCode from 'qrcode.react';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
const user = JSON.parse(localStorage.getItem('user'));
const advanced = user?.features?.advanced;
const extra = user?.features?.extra;
const langs = user?.langs;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function MachineUIModal(props) {
  const defaultValues = {
    text: '#ffffff',
    button: '#2a5989',
    product_text: '#000000',
    theme: '#2a5989',
    price: '#2a5989',
    plus: '#2a5989',
    minus: '#ffffff',
    soldout: '#2a5989',
    soldout_text: '#ffffff',
    highlight_color: '#2a5989',
    cart_color: '#2a5989',
    navibar: '#2a5989',
    container: '#dbdbdb',
    bg_radio: 'color',
    pl_radio: '3',
    cl_radio: '1',
    bl_radio: '1',
    backgroundC: '#ededed',
    term: [],
    select: 'en',
    cart: '0',
    autoTrans: false,
    hide_all_prohide_all_product_banner: false,
    duct_banner: false,
    hide_category_banner: false,
    brand_image_button: false,
    cate_image_button: false,
    qrcode: '',
  };

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    setError,
    watch,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({ defaultValues });
  const theme1 = JSON.parse(localStorage.getItem('user'))?.theme === '1';
  const classes = useStyles();
  const { data, ...rest } = props;
  const [fileNames, setFileNames] = useState([]);
  const [t1, sett1] = useState({ t1: true, i1: '' });
  const [t2, sett2] = useState({ t2: true, i2: '' });
  const [t3, sett3] = useState({ t3: true, i3: '' });
  const [t4, sett4] = useState({ t3: true, i3: '' });
  const [t5, sett5] = useState({ t3: true, i3: '' });
  const [t6, sett6] = useState({ t3: true, i3: '' });
  const [t7, sett7] = useState({ t3: true, i3: '' });
  const [enArray, setenArray] = useState([]);
  const [Privacy, setPrivacy] = useState([
    { id: uuidv4(), header: { en: '' }, content: { en: '' } },
  ]);
  const [FAQ, setFAQ] = useState([{ id: uuidv4(), header: { en: '' }, content: { en: '' } }]);
  const [TERM, setTERM] = useState([{ id: uuidv4(), header: { en: '' }, content: { en: '' } }]);

  const handleAddPrivacy = () => {
    setPrivacy([...Privacy, { id: uuidv4(), header: { en: '' }, content: { en: '' } }]);
  };

  const handleRemovePrivacy = (id) => {
    const values = [...Privacy];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setPrivacy(values);
  };

  const handleAddFAQ = () => {
    setFAQ([...FAQ, { id: uuidv4(), header: { en: '' }, content: { en: '' } }]);
  };

  const handleRemoveFAQ = (id) => {
    const values = [...FAQ];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setFAQ(values);
  };

  const handleAddTERM = () => {
    setTERM([...TERM, { id: uuidv4(), header: { en: '' }, content: { en: '' } }]);
  };

  const handleRemoveTERM = (id) => {
    const values = [...TERM];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setTERM(values);
  };

  const onPrivacyHeader = (id, e) => {
    const findIndex = Privacy.findIndex((e) => e.id === id);
    Privacy[findIndex].header[watchAll.select] = e.target.value;
    setPrivacy([...Privacy]);
  };

  const onPrivacyContent = (id, e) => {
    const findIndex = Privacy.findIndex((e) => e.id === id);
    Privacy[findIndex].content[watchAll.select] = e.target.value;
    setPrivacy([...Privacy]);
  };

  const onFAQHeader = (id, e) => {
    const findIndex = FAQ.findIndex((e) => e.id === id);
    FAQ[findIndex].header[watchAll.select] = e.target.value;
    setFAQ([...FAQ]);
  };

  const onFAQContent = (id, e) => {
    const findIndex = FAQ.findIndex((e) => e.id === id);
    FAQ[findIndex].content[watchAll.select] = e.target.value;
    setFAQ([...FAQ]);
  };

  const onTERMHeader = (id, e) => {
    const findIndex = TERM.findIndex((e) => e.id === id);
    TERM[findIndex].header[watchAll.select] = e.target.value;
    setTERM([...TERM]);
  };

  const onTERMContent = (id, e) => {
    const findIndex = TERM.findIndex((e) => e.id === id);
    TERM[findIndex].content[watchAll.select] = e.target.value;
    setTERM([...TERM]);
  };

  const watchAll = watch();

  useEffect(() => {
    let a2 = [languages[0]];
    if (langs?.length > 0) {
      langs.forEach((lan) => {
        const index = languages_noEn.findIndex((e) => e.code === lan);
        if (index > -1) {
          a2.push(languages_noEn[index]);
        }
      });
    }
    setenArray(a2);
    if (data && data.length > 0 && data[0].themeColor) {
      setFileNames([]);
      sett1({ t1: true, i1: '' });
      setPrivacy(
        data[0].themeColor?.privacy?.length > 0
          ? data[0].themeColor?.privacy
          : [{ id: uuidv4(), header: { en: '' }, content: { en: '' } }]
      );
      setFAQ(
        data[0].themeColor?.faq?.length > 0
          ? data[0].themeColor.faq
          : [{ id: uuidv4(), header: { en: '' }, content: { en: '' } }]
      );
      setTERM(
        data[0].themeColor?.termCondition?.length > 0
          ? data[0].themeColor.termCondition
          : [{ id: uuidv4(), header: { en: '' }, content: { en: '' } }]
      );
      sett2({ t2: true, i2: '' });
      sett3({ t3: true, i3: '' });
      sett4({ t3: true, i3: '' });
      sett5({ t3: true, i3: '' });
      sett6({ t3: true, i3: '' });
      sett7({ t3: true, i3: '' });
      reset({
        text: data[0].themeColor.text ? data[0].themeColor.text : '#FFFFFF',
        product_text: data[0].themeColor.product_text ? data[0].themeColor.product_text : '#000000',
        button: data[0].themeColor.button ? data[0].themeColor.button : '#2a5989',
        theme: data[0].themeColor.theme ? data[0].themeColor.theme : '#2a5989',
        price: data[0].themeColor.price ? data[0].themeColor.price : '#2a5989',
        plus: data[0].themeColor.plus ? data[0].themeColor.plus : '#2a5989',
        minus: data[0].themeColor.minus ? data[0].themeColor.minus : '#ffffff',
        soldout: data[0].themeColor.soldout ? data[0].themeColor.soldout : '#2a5989',
        cart_color: data[0].themeColor.cart_color ? data[0].themeColor.cart_color : '#2a5989',
        highlight_color: data[0].themeColor.highlight_color
          ? data[0].themeColor.highlight_color
          : '#2a5989',
        soldout_text: data[0].themeColor.soldout_text ? data[0].themeColor.soldout_text : '#FFFFFF',
        navibar: data[0].themeColor.navibar ? data[0].themeColor.navibar : '#2a5989',
        container: data[0].themeColor.secondary ? data[0].themeColor.secondary : '#dbdbdb',
        bg_radio: data[0].themeColor.backgroundImage ? 'picture' : 'color',
        pl_radio: data[0].themeColor.p_column ? data[0].themeColor.p_column : '3',
        cl_radio: data[0].themeColor.c_column ? data[0].themeColor.c_column : '1',
        bl_radio: data[0].themeColor.b_column ? data[0].themeColor.b_column : '1',
        backgroundC: data[0].themeColor.background ? data[0].themeColor.background : '#ededed',
        backgroundString: data[0].themeColor.backgroundImage
          ? data[0].themeColor.backgroundImage
          : null,
        logoString: data[0].themeColor.logo ? data[0].themeColor.logo : null,
        allProductString: data[0].themeColor.allProduct ? data[0].themeColor.allProduct : null,
        allCateString: data[0].themeColor.allCate ? data[0].themeColor.allCate : null,
        allBrandString: data[0].themeColor.allBrand ? data[0].themeColor.allBrand : null,
        thankYouString: data[0].themeColor.thankYou ? data[0].themeColor.thankYou : null,
        paymentString: data[0].themeColor.payment ? data[0].themeColor.payment : null,
        referTo: data[0].belongTo,
        term: data[0].themeColor.termCondition ? data[0].themeColor.termCondition : '',
        select: 'en',
        autoTrans: false,
        hide_all_product_banner:
          data[0].themeColor.hide_all_product_banner === 'true' ? true : false,
        hide_category_banner: data[0].themeColor.hide_category_banner === 'true' ? true : false,
        brand_image_button: data[0].themeColor.brand_image_button === 'true' ? true : false,
        cate_image_button: data[0].themeColor.cate_image_button === 'true' ? true : false,
        cart: data[0].themeColor?.cart ? data[0].themeColor?.cart : '0',
        qrcode: data[0].themeColor?.qrcode ? data[0].themeColor?.qrcode : '',
      });
      if (data[0].themeColor.backgroundImage) {
        request({
          method: 'head',
          url: '/pictures/' + data[0].themeColor.backgroundImage + '/' + data[0].belongTo,
        })
          .then()
          .catch((e) => {
            setValue('backgroundString', 'Image not found, please provide a new image.');
          });
      }
      if (data[0].themeColor.logo) {
        request({
          method: 'head',
          url: '/pictures/' + data[0].themeColor.logo + '/' + data[0].belongTo,
        })
          .then()
          .catch((e) => {
            setValue('logoString', 'Image not found, please provide a new image.');
          });
      }
      if (data[0].themeColor.allProduct) {
        let ext = data[0].themeColor.allProduct.split('.').pop();
        console.log('ext', ext);
        if (ext === 'mp4') {
          request({
            method: 'head',
            url: '/videos/' + data[0].themeColor.allProduct + '/' + data[0].belongTo,
          })
            .then()
            .catch((e) => {
              setValue('allProductString', 'Image not found, please provide a new image.');
            });
        } else {
          request({
            method: 'head',
            url: '/pictures/' + data[0].themeColor.allProduct + '/' + data[0].belongTo,
          })
            .then()
            .catch((e) => {
              setValue('allProductString', 'Image not found, please provide a new image.');
            });
        }
      }
      if (data[0].themeColor.allCate) {
        request({
          method: 'head',
          url: '/pictures/' + data[0].themeColor.allCate + '/' + data[0].belongTo,
        })
          .then()
          .catch((e) => {
            setValue('allCateString', 'Image not found, please provide a new image.');
          });
      }
      if (data[0].themeColor.allBrand) {
        request({
          method: 'head',
          url: '/pictures/' + data[0].themeColor.allBrand + '/' + data[0].belongTo,
        })
          .then()
          .catch((e) => {
            setValue('allBrandString', 'Image not found, please provide a new image.');
          });
      }
      if (data[0].themeColor.thankyou) {
        request({
          method: 'head',
          url: '/pictures/' + data[0].themeColor.thankyou + '/' + data[0].belongTo,
        })
          .then()
          .catch((e) => {
            setValue('thankYouString', 'Image not found, please provide a new image.');
          });
      }
      if (data[0].themeColor.payment) {
        request({
          method: 'head',
          url: '/pictures/' + data[0].themeColor.payment + '/' + data[0].belongTo,
        })
          .then()
          .catch((e) => {
            setValue('paymentString', 'Image not found, please provide a new image.');
          });
      }
    }
  }, [props.show]);

  const handleRemove = (input1, input2) => {
    // setPercent(0);
    setValue(input1);
    setValue(input2);
    clearErrors('logo');
    clearErrors('allProduct');
    if (watchAll[input2]) fileNames.push(watchAll[input2]);
  };

  const handleReset = () => {
    reset({
      product_text: '#000000',
      text: '#ffffff',
      button: '#2a5989',
      theme: '#2a5989',
      price: '#2a5989',
      plus: '#2a5989',
      minus: '#ffffff',
      soldout: '#2a5989',
      soldout_text: '#ffffff',
      cart_color: '#2a5989',
      highlight_color: '#2a5989',
      navibar: '#2a5989',
      container: '#dbdbdb',
      bg_radio: 'color',
      pl_radio: '3',
      cl_radio: '1',
      bl_radio: '1',
      cart: '0',
      backgroundC: '#ededed',
      term: [],
      select: 'en',
      autoTrans: false,
      backgroundString: null,
      referTo: data[0].belongTo,
      logoString: data[0].themeColor.logo ? data[0].themeColor.logo : null,
      allProductString: data[0].themeColor.allProduct ? data[0].themeColor.allProduct : null,
      allCateString: data[0].themeColor.allCate ? data[0].themeColor.allCate : null,
      allBrandString: data[0].themeColor.allBrand ? data[0].themeColor.allBrand : null,
      thankYouString: data[0].themeColor.thankYou ? data[0].themeColor.thankYou : null,
      paymentString: data[0].themeColor.payment ? data[0].themeColor.payment : null,
      hide_all_product_banner: data[0].themeColor.hide_all_product_banner === 'true' ? true : false,
      hide_category_banner: data[0].themeColor.hide_category_banner === 'true' ? true : false,
      brand_image_button: data[0].themeColor.brand_image_button === 'true' ? true : false,
      cate_image_button: data[0].themeColor.cate_image_button === 'true' ? true : false,
      qrcode: data[0].themeColor?.qrcode ? data[0].themeColor?.qrcode : '',
    });
    if (watchAll.backgroundString) fileNames.push(watchAll.backgroundString);
  };

  const onSubmit = async (formData) => {
    const myData = new FormData();
    myData.append('text', formData.text);
    myData.append('product_text', formData.product_text);
    myData.append('price', formData.price);
    myData.append('plus', formData.plus);
    myData.append('minus', formData.minus);
    myData.append('soldout', formData.soldout);
    myData.append('soldout_text', formData.soldout_text);
    myData.append('cart_color', formData.cart_color);
    myData.append('highlight_color', formData.highlight_color);
    myData.append('navibar', formData.navibar);
    myData.append('button', formData.button);
    myData.append('theme', formData.theme);
    myData.append('secondary', formData.container);
    myData.append('referTo', data[0].belongTo);
    myData.append('p_column', formData.pl_radio);
    myData.append('c_column', formData.cl_radio);
    myData.append('b_column', formData.bl_radio);
    myData.append('cart', formData.cart);
    myData.append('auto', formData.autoTrans);
    myData.append('hide_all_product_banner', formData.hide_all_product_banner);
    myData.append('hide_category_banner', formData.hide_category_banner);
    myData.append('brand_image_button', formData.brand_image_button);
    myData.append('cate_image_button', formData.cate_image_button);
    myData.append('qrcode', formData.qrcode);
    if (langs?.length > 0) {
      langs.forEach((e) => {
        myData.append('langs', e);
      });
    }
    myData.append('privacy', JSON.stringify(Privacy));
    myData.append('faq', JSON.stringify(FAQ));
    myData.append('term', JSON.stringify(TERM));
    if (formData.bg_radio === 'color') {
      myData.append('background', formData.backgroundC);
      if (formData.backgroundString) fileNames.push(formData.backgroundString);
    } else {
      if (formData.backgroundP && formData.backgroundP.length > 0) {
        myData.set('backgroundImage', formData.backgroundP[0]);
        if (formData.backgroundString) fileNames.push(formData.backgroundString);
      }
    }
    if (formData.logo && formData.logo.length > 0) {
      myData.set('logo', formData.logo[0]);
      if (formData.logoString) {
        fileNames.push(formData.logoString);
      }
    }
    if (formData.allProduct && formData.allProduct.length > 0) {
      myData.set('allProduct', formData.allProduct[0]);
      if (formData.allProductString) {
        fileNames.push(formData.allProductString);
      }
    }
    if (formData.allCate && formData.allCate.length > 0) {
      myData.set('allCate', formData.allCate[0]);
      if (formData.allCateString) {
        fileNames.push(formData.allCateString);
      }
    }
    if (formData.allBrand && formData.allBrand.length > 0) {
      myData.set('allBrand', formData.allBrand[0]);
      if (formData.allBrandString) {
        fileNames.push(formData.allBrandString);
      }
    }
    if (formData.thankYou && formData.thankYou.length > 0) {
      myData.set('thankYou', formData.thankYou[0]);
      if (formData.thankYouString) {
        fileNames.push(formData.thankYouString);
      }
    }
    if (formData.payment && formData.payment.length > 0) {
      myData.set('payment', formData.payment[0]);
      if (formData.paymentString) {
        fileNames.push(formData.paymentString);
      }
    }

    fileNames.forEach((e) => {
      myData.append('deleteFiles', e);
    });
    if (formData.logoString) myData.append('logoString', formData.logoString);
    if (formData.allProductString) myData.append('allProductString', formData.allProductString);
    if (formData.allCateString) myData.append('allCateString', formData.allCateString);
    if (formData.allBrandString) myData.append('allBrandString', formData.allBrandString);
    if (formData.thankYouString) myData.append('thankYouString', formData.thankYouString);
    if (formData.paymentString) myData.append('paymentString', formData.paymentString);
    if (formData.backgroundString) myData.append('backgroundString', formData.backgroundString);
    data.forEach((e) => {
      myData.append('id', e._id);
    });

    await request({
      method: 'patch',
      url: '/machines/updateUI',
      data: myData,
    }).then((res) => {
      Swal.fire({
        text: `The machine UI has been updated!`,
        icon: 'success',
      }).then(() => {
        props.reflesh ? props.setReflesh(false) : props.setReflesh(true);
        props.onHide();
      });
    });
  };

  return (
    <Modal className="modal-w myModal" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '1.2rem' }}>
          Customized Machine UI
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container component="main">
          <CssBaseline />
          <div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container spacing={2}>
                {!theme1 && (
                  <Grid item container spacing={2}>
                    <Grid item xs={12} style={{ padding: '0 8px' }}>
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend">Display categories</FormLabel>
                        <RadioGroup
                          row
                          name="cl_radio"
                          {...register('cl_radio1', {
                            onChange: (e) => {
                              setValue('cl_radio', e.target.value);
                            },
                          })}
                        >
                          <FormControlLabel
                            style={{ minWidth: '25%' }}
                            value="0"
                            control={<Radio />}
                            label="Hide"
                            checked={watchAll.cl_radio === '0'}
                          />
                          <FormControlLabel
                            style={{ minWidth: '25%' }}
                            value="1"
                            control={<Radio />}
                            label={extra ? 'One Column' : 'Show'}
                            checked={watchAll.cl_radio === '1'}
                          />
                          {!theme1 && (
                            <FormControlLabel
                              style={{ minWidth: '25%' }}
                              value="2"
                              control={<Radio />}
                              label={extra ? 'Two Column' : 'Category Home Page(2 columns)'}
                              checked={watchAll.cl_radio === '2'}
                            />
                          )}
                          {!theme1 && !extra && (
                            <FormControlLabel
                              style={{ minWidth: '25%' }}
                              value="3"
                              control={<Radio />}
                              label={'Category Home Page(1 column)'}
                              checked={watchAll.cl_radio === '3'}
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 8px' }}>
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend">Display brands</FormLabel>
                        <RadioGroup
                          row
                          name="bl_radio"
                          {...register('bl_radio1', {
                            onChange: (e) => {
                              setValue('bl_radio', e.target.value);
                            },
                          })}
                        >
                          <FormControlLabel
                            style={{ minWidth: '25%' }}
                            value="0"
                            control={<Radio />}
                            label="Hide"
                            checked={watchAll.bl_radio === '0'}
                          />
                          <FormControlLabel
                            style={{ minWidth: '25%' }}
                            value="1"
                            control={<Radio />}
                            label={extra ? 'One Column' : 'Show'}
                            checked={watchAll.bl_radio === '1'}
                          />
                          {extra && (
                            <FormControlLabel
                              style={{ minWidth: '25%' }}
                              value="2"
                              control={<Radio />}
                              label="Two Columns"
                              checked={watchAll.bl_radio === '2'}
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} spacing={2}>
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend">Additional Settings</FormLabel>
                        {!extra && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              alignContent: 'center',
                              alignItems: 'center',
                              fontSize: '16px',
                            }}
                          >
                            <Controller
                              name="hide_category_banner"
                              control={control}
                              render={({ field }) => (
                                <Checkbox {...field} checked={watchAll.hide_category_banner} />
                              )}
                            />
                            <span>Hide Banner Image</span>
                          </Grid>
                        )}

                        {!extra && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              alignContent: 'center',
                              alignItems: 'center',
                              fontSize: '16px',
                            }}
                          >
                            <Controller
                              name="cate_image_button"
                              control={control}
                              render={({ field }) => (
                                <Checkbox {...field} checked={watchAll.cate_image_button} />
                              )}
                            />
                            <span>Show Category Image Instead of Button</span>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            fontSize: '16px',
                          }}
                        >
                          <Controller
                            name="brand_image_button"
                            control={control}
                            render={({ field }) => (
                              <Checkbox {...field} checked={watchAll.brand_image_button} />
                            )}
                          />
                          <span>
                            {!extra
                              ? `Show Brand Image Instead of Button`
                              : 'Show category image without text'}
                          </span>
                        </Grid>
                      </FormControl>
                    </Grid>

                    {extra && (
                      <Grid item xs={12} style={{ padding: '0 8px' }}>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                          <FormLabel component="legend">Display products</FormLabel>
                          <RadioGroup
                            row
                            name="pl_radio"
                            {...register('pl_radio1', {
                              onChange: (e) => {
                                setValue('pl_radio', e.target.value);
                              },
                            })}
                          >
                            <FormControlLabel
                              style={{ minWidth: '50%' }}
                              value="2"
                              control={<Radio />}
                              label="Two Columns"
                              checked={watchAll.pl_radio === '2'}
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio />}
                              label="Three Columns"
                              checked={watchAll.pl_radio === '3'}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}

                    {/* {!extra && (
                      <Grid item xs={12} style={{ padding: '0 8px' }}>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                          <FormLabel component="legend">Cart Style</FormLabel>
                          <RadioGroup
                            row
                            name="cart"
                            {...register('cart1', {
                              onChange: (e) => {
                                setValue('cart', e.target.value);
                              },
                            })}
                          >
                            <FormControlLabel
                              style={{ minWidth: '50%' }}
                              value="0"
                              control={<Radio />}
                              label="Page"
                              checked={watchAll.cart === '0' || !watchAll.cart}
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Bottom"
                              checked={watchAll.cart === '1'}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )} */}
                    {!extra && (
                      <Grid item container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            className="colorTextField"
                            fullWidth
                            {...register('theme')}
                            type="color"
                            label="Theme Color"
                            variant="outlined"
                            tooltip="This is the main color of the machine"
                          ></TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            className="colorTextField"
                            fullWidth
                            {...register('product_text')}
                            type="color"
                            label="Text Color(product)"
                            variant="outlined"
                          ></TextField>
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            className="colorTextField"
                            fullWidth
                            {...register('minus')}
                            type="color"
                            label="Icon Color(shopping cart)"
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            className="colorTextField"
                            fullWidth
                            {...register('soldout')}
                            type="color"
                            label="Sold Out Color"
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            className="colorTextField"
                            fullWidth
                            {...register('soldout_text')}
                            type="color"
                            label="Sold Out Text"
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            className="colorTextField"
                            fullWidth
                            {...register('navibar')}
                            type="color"
                            label="Navi Bar"
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            className="colorTextField"
                            fullWidth
                            {...register('cart_color')}
                            type="color"
                            label="Cart Color"
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            className="colorTextField"
                            fullWidth
                            {...register('highlight_color')}
                            type="color"
                            label="Highlight Color"
                            variant="outlined"
                          ></TextField>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      <TextField
                        className="colorTextField"
                        fullWidth
                        {...register('price')}
                        type="color"
                        label="Price Color"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        className="colorTextField"
                        fullWidth
                        {...register('plus')}
                        type="color"
                        label={extra ? 'Description color' : 'Icon Color'}
                        variant="outlined"
                      ></TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        className="colorTextField"
                        fullWidth
                        {...register('button')}
                        type="color"
                        label="Button Color"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className="colorTextField"
                        fullWidth
                        {...register('text')}
                        type="color"
                        label="Text Color(under buttons)"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className="colorTextField"
                        fullWidth
                        {...register('container')}
                        type="color"
                        label="Container Color"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 8px' }}>
                      <RadioGroup
                        row
                        name="bg_radio"
                        {...register('bg_radio1', {
                          onChange: (e) => {
                            setValue('bg_radio', e.target.value);
                          },
                        })}
                      >
                        <FormControlLabel
                          value="color"
                          control={<Radio />}
                          label="Background Color"
                          checked={watchAll.bg_radio === 'color'}
                        />
                        <FormControlLabel
                          value="picture"
                          control={<Radio />}
                          label="Background Picture"
                          checked={watchAll.bg_radio === 'picture'}
                        />
                      </RadioGroup>
                    </Grid>
                    {watchAll.bg_radio === 'color' ? (
                      <Grid item xs={12}>
                        <TextField
                          className="colorTextField"
                          fullWidth
                          {...register('backgroundC')}
                          type="color"
                          label="Background Color"
                          variant="outlined"
                        ></TextField>
                      </Grid>
                    ) : (
                      <Grid item xs={12} style={{ marginTop: '-15px' }}>
                        <p style={{ marginBottom: 0 }}>
                          Please provide image with 16:9 aspect ratio for better view
                        </p>
                        <div className="d-flex align-items-center" style={{ height: '48px' }}>
                          <Grid item xs={3}>
                            <Button fullWidth variant="contained" component="label">
                              Image
                              <input
                                {...register('backgroundP', {
                                  onChange: (e) => {
                                    clearErrors('bg_radio');
                                    if (
                                      e.target.files.length > 0 &&
                                      e.target.files[0].size > 5000000
                                    ) {
                                      setError('bg_radio', {
                                        message:
                                          'File size too big, please provide the right size.',
                                      });
                                    } else if (
                                      e.target.files.length > 0 &&
                                      e.target.files[0].name.indexOf(' ') >= 0
                                    ) {
                                      setError('bg_radio', {
                                        message: 'File name can not be contain white space',
                                      });
                                    } else {
                                      if (e.target.files && e.target.files.length > 0) {
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                          if (reader.readyState === 2) {
                                            sett2({ t2: false, i2: reader.result });
                                          }
                                        };
                                        reader.readAsDataURL(e.target.files[0]);
                                      }
                                    }
                                  },
                                })}
                                type="file"
                                accept=".jpg,.png,.jpeg"
                                hidden
                              />
                            </Button>
                          </Grid>
                          <Grid className="d-flex align-items-center" item xs={8}>
                            <span className="product-fileName-span">
                              {watchAll.backgroundP && watchAll.backgroundP.length > 0
                                ? watchAll.backgroundP[0].name
                                : watchAll.backgroundString}
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    )}
                    {watchAll.backgroundString && watchAll.bg_radio == 'picture' && (
                      <Grid container item xs={12} style={{ marginTop: '5px' }}>
                        <img
                          className="imagePreview-UI"
                          src={
                            t2.t2
                              ? `${process.env.REACT_APP_SERVER_API}/pictures/${watchAll.backgroundString}/${watchAll.referTo}`
                              : t2.i2
                          }
                        />
                      </Grid>
                    )}
                    {errors.bg_radio && (
                      <p style={{ color: 'red', marginLeft: '8px' }}>{errors.bg_radio.message}</p>
                    )}
                  </Grid>
                )}

                <Grid item xs={12} style={{ padding: '0 8px' }}>
                  <p>Logo Image (please provide wide logo for better view)</p>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '-15px' }}>
                  <div className="d-flex align-items-center" style={{ height: '48px' }}>
                    <Grid item xs={3}>
                      <Button fullWidth variant="contained" component="label">
                        Logo
                        <input
                          {...register('logo', {
                            onChange: (e) => {
                              clearErrors('logo');
                              if (e.target.files.length > 0 && e.target.files[0].size > 5000000) {
                                setError('logo', {
                                  message: 'File size too big, please provide the right size.',
                                });
                              } else if (
                                e.target.files.length > 0 &&
                                e.target.files[0].name.indexOf(' ') >= 0
                              ) {
                                console.log('111');
                                setError('logo', {
                                  message: 'File name can not be contain white space',
                                });
                              } else {
                                if (e.target.files && e.target.files.length > 0) {
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    if (reader.readyState === 2) {
                                      sett1({ t1: false, i1: reader.result });
                                    }
                                  };
                                  reader.readAsDataURL(e.target.files[0]);
                                }
                              }
                            },
                          })}
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          hidden
                        />
                      </Button>
                    </Grid>
                    <Grid className="d-flex align-items-center" item xs={8}>
                      <span className="product-fileName-span">
                        {watchAll.logo && watchAll.logo.length > 0
                          ? watchAll.logo[0].name
                          : watchAll.logoString}
                      </span>
                    </Grid>
                    {(watchAll.logoString || (watchAll.logo && watchAll.logo.length > 0)) && (
                      <IconButton onClick={() => handleRemove('logo', 'logoString')}>
                        <DeleteForeverOutlined />
                      </IconButton>
                    )}
                  </div>
                  {errors.logo && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.logo.message}</p>
                  )}
                </Grid>

                {watchAll.logoString &&
                  watchAll.logoString != 'Image not found, please provide a new image.' && (
                    <Grid container item xs={12} style={{ marginTop: '5px' }}>
                      <img
                        className="imagePreview-UI"
                        src={
                          t1.t1
                            ? `${process.env.REACT_APP_SERVER_API}/pictures/${watchAll.logoString}/${watchAll.referTo}`
                            : t1.i1
                        }
                      />
                    </Grid>
                  )}

                <Grid item xs={12} style={{ padding: '0 8px' }}>
                  <p>
                    All Products Image (please provide image with 1080 × 600 or similar aspect
                    ratio)
                  </p>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '-15px' }}>
                  <div className="d-flex align-items-center" style={{ height: '48px' }}>
                    <Grid item xs={3}>
                      <Button fullWidth variant="contained" component="label">
                        IMAGE
                        <input
                          {...register('allProduct', {
                            onChange: (e) => {
                              clearErrors('allProduct');
                              if (e.target.files.length > 0 && e.target.files[0].size > 50000000) {
                                setError('allProduct', {
                                  message: 'File size too big, please provide the right size.',
                                });
                              } else if (
                                e.target.files.length > 0 &&
                                e.target.files[0].name.indexOf(' ') >= 0
                              ) {
                                setError('allProduct', {
                                  message: 'File name can not be contain white space',
                                });
                              } else {
                                if (e.target.files && e.target.files.length > 0) {
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    if (reader.readyState === 2) {
                                      sett3({ t3: false, i3: reader.result });
                                    }
                                  };
                                  reader.readAsDataURL(e.target.files[0]);
                                }
                              }
                            },
                          })}
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          hidden
                        />
                      </Button>
                    </Grid>
                    <Grid className="d-flex align-items-center" item xs={8}>
                      <span className="product-fileName-span">
                        {watchAll.allProduct && watchAll.allProduct.length > 0
                          ? watchAll.allProduct[0].name
                          : watchAll.allProductString}
                      </span>
                    </Grid>
                    {(watchAll.allProductString ||
                      (watchAll.allProduct && watchAll.allProduct.length > 0)) && (
                      <IconButton onClick={() => handleRemove('allProduct', 'allProductString')}>
                        <DeleteForeverOutlined />
                      </IconButton>
                    )}
                  </div>
                  {errors.allProduct && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.allProduct.message}</p>
                  )}
                </Grid>

                {watchAll.allProductString &&
                  watchAll.allProductString != 'Image not found, please provide a new image.' && (
                    <Grid container item xs={12} style={{ marginTop: '5px' }}>
                      <img
                        className="imagePreview-UI"
                        src={
                          t3.t3
                            ? `${process.env.REACT_APP_SERVER_API}/pictures/${watchAll.allProductString}/${watchAll.referTo}`
                            : t3.i3
                        }
                      />
                    </Grid>
                  )}

                <div className="hr-line mb-2"></div>

                <Grid item xs={12} style={{ padding: '0 8px' }}>
                  <p>
                    {!theme1
                      ? 'All Category Image (please provide image with 1080 × 600 or similar aspect ratio)'
                      : 'QR code Page Image Upload(the bottom part image of the QR code page)'}
                  </p>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '-15px' }}>
                  <div className="d-flex align-items-center" style={{ height: '48px' }}>
                    <Grid item xs={3}>
                      <Button fullWidth variant="contained" component="label">
                        IMAGE
                        <input
                          {...register('allCate', {
                            onChange: (e) => {
                              clearErrors('allCate');
                              if (e.target.files.length > 0 && e.target.files[0].size > 5000000) {
                                setError('allCate', {
                                  message: 'File size too big, please provide the right size.',
                                });
                              } else if (
                                e.target.files.length > 0 &&
                                e.target.files[0].name.indexOf(' ') >= 0
                              ) {
                                setError('allCate', {
                                  message: 'File name can not be contain white space',
                                });
                              } else {
                                if (e.target.files && e.target.files.length > 0) {
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    if (reader.readyState === 2) {
                                      sett4({ t3: false, i3: reader.result });
                                    }
                                  };
                                  reader.readAsDataURL(e.target.files[0]);
                                }
                              }
                            },
                          })}
                          type="file"
                          accept=".jpg,.png,.jpeg"
                          hidden
                        />
                      </Button>
                    </Grid>
                    <Grid className="d-flex align-items-center" item xs={8}>
                      <span className="product-fileName-span">
                        {watchAll.allCate && watchAll.allCate.length > 0
                          ? watchAll.allCate[0].name
                          : watchAll.allCateString}
                      </span>
                    </Grid>
                    {(watchAll.allCateString ||
                      (watchAll.allCate && watchAll.allCate.length > 0)) && (
                      <IconButton onClick={() => handleRemove('allCate', 'allCateString')}>
                        <DeleteForeverOutlined />
                      </IconButton>
                    )}
                  </div>
                  {errors.allCate && (
                    <p style={{ color: 'red', marginLeft: '8px' }}>{errors.allCate.message}</p>
                  )}
                </Grid>

                {watchAll.allCateString &&
                  watchAll.allCateString != 'Image not found, please provide a new image.' && (
                    <Grid container item xs={12} style={{ marginTop: '5px' }}>
                      <img
                        className="imagePreview-UI"
                        src={
                          t4.t3
                            ? `${process.env.REACT_APP_SERVER_API}/pictures/${watchAll.allCateString}/${watchAll.referTo}`
                            : t4.i3
                        }
                      />
                    </Grid>
                  )}

                {/* {theme1 && (
                  <Grid item xs={12}>
                    <TextField
                      className="mb-3 mt-3"
                      fullWidth
                      {...register('qrcode')}
                      type="text"
                      label="QR code (used for generating QR code)"
                      variant="outlined"
                    ></TextField>
                    <div className="d-flex justify-content-center">
                      {watchAll?.qrcode && (
                        <QRCode
                          id="qr-preview"
                          value={watchAll?.qrcode}
                          level={'H'}
                          size={290}
                          // includeMargin={true}
                        />
                      )}
                    </div>
                  </Grid>
                )} */}

                <Grid item xs={12} style={{ padding: '0 8px' }}>
                  <p>
                    {!theme1
                      ? 'All Brand Image (please provide image with 1080 × 600 or similar aspect ratio)'
                      : 'QR code Upload'}
                  </p>
                </Grid>

                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} style={{ marginTop: '-15px' }}>
                    <div className="d-flex align-items-center" style={{ height: '48px' }}>
                      <Grid item xs={3}>
                        <Button fullWidth variant="contained" component="label">
                          IMAGE
                          <input
                            {...register('allBrand', {
                              onChange: (e) => {
                                clearErrors('allBrand');
                                if (e.target.files.length > 0 && e.target.files[0].size > 5000000) {
                                  setError('allBrand', {
                                    message: 'File size too big, please provide the right size.',
                                  });
                                } else if (
                                  e.target.files.length > 0 &&
                                  e.target.files[0].name.indexOf(' ') >= 0
                                ) {
                                  setError('allBrand', {
                                    message: 'File name can not be contain white space',
                                  });
                                } else {
                                  if (e.target.files && e.target.files.length > 0) {
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                      if (reader.readyState === 2) {
                                        sett5({ t3: false, i3: reader.result });
                                      }
                                    };
                                    reader.readAsDataURL(e.target.files[0]);
                                  }
                                }
                              },
                            })}
                            type="file"
                            accept=".jpg,.png,.jpeg"
                            hidden
                          />
                        </Button>
                      </Grid>
                      <Grid className="d-flex align-items-center" item xs={8}>
                        <span className="product-fileName-span">
                          {watchAll.allBrand && watchAll.allBrand.length > 0
                            ? watchAll.allBrand[0].name
                            : watchAll.allBrandString}
                        </span>
                      </Grid>
                      {(watchAll.allBrandString ||
                        (watchAll.allBrand && watchAll.allBrand.length > 0)) && (
                        <IconButton onClick={() => handleRemove('allBrand', 'allBrandString')}>
                          <DeleteForeverOutlined />
                        </IconButton>
                      )}
                    </div>
                    {errors.allBrand && (
                      <p style={{ color: 'red', marginLeft: '8px' }}>{errors.allBrand.message}</p>
                    )}
                  </Grid>

                  {watchAll.allBrandString &&
                    watchAll.allBrandString != 'Image not found, please provide a new image.' && (
                      <Grid container item xs={12} style={{ marginTop: '5px' }}>
                        <img
                          className="imagePreview-UI"
                          src={
                            t5.t3
                              ? `${process.env.REACT_APP_SERVER_API}/pictures/${watchAll.allBrandString}/${watchAll.referTo}`
                              : t5.i3
                          }
                        />
                      </Grid>
                    )}
                </Grid>

                <div className="hr-line mb-1"></div>

                {extra && (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} style={{ padding: '0 8px' }}>
                      <p>
                        Thank you image (please provide image with 1080 × 1920 or similar aspect
                        ratio)
                      </p>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '-15px' }}>
                      <div className="d-flex align-items-center" style={{ height: '48px' }}>
                        <Grid item xs={3}>
                          <Button fullWidth variant="contained" component="label">
                            IMAGE
                            <input
                              {...register('thankYou', {
                                onChange: (e) => {
                                  clearErrors('thankYou');
                                  if (
                                    e.target.files.length > 0 &&
                                    e.target.files[0].size > 5000000
                                  ) {
                                    setError('thankYou', {
                                      message: 'File size too big, please provide the right size.',
                                    });
                                  } else if (
                                    e.target.files.length > 0 &&
                                    e.target.files[0].name.indexOf(' ') >= 0
                                  ) {
                                    setError('thankYou', {
                                      message: 'File name can not be contain white space',
                                    });
                                  } else {
                                    if (e.target.files && e.target.files.length > 0) {
                                      const reader = new FileReader();
                                      reader.onload = () => {
                                        if (reader.readyState === 2) {
                                          sett6({ t3: false, i3: reader.result });
                                        }
                                      };
                                      reader.readAsDataURL(e.target.files[0]);
                                    }
                                  }
                                },
                              })}
                              type="file"
                              accept=".jpg,.png,.jpeg"
                              hidden
                            />
                          </Button>
                        </Grid>
                        <Grid className="d-flex align-items-center" item xs={8}>
                          <span className="product-fileName-span">
                            {watchAll.thankYou && watchAll.thankYou.length > 0
                              ? watchAll.thankYou[0].name
                              : watchAll.thankYouString}
                          </span>
                        </Grid>
                        {(watchAll.thankYouString ||
                          (watchAll.thankYou && watchAll.thankYou.length > 0)) && (
                          <IconButton onClick={() => handleRemove('thankYou', 'thankYouString')}>
                            <DeleteForeverOutlined />
                          </IconButton>
                        )}
                      </div>
                      {errors.thankYou && (
                        <p style={{ color: 'red', marginLeft: '8px' }}>{errors.thankYou.message}</p>
                      )}
                    </Grid>

                    {watchAll.thankYouString &&
                      watchAll.thankYouString != 'Image not found, please provide a new image.' && (
                        <Grid container item xs={12} style={{ marginTop: '5px' }}>
                          <img
                            className="imagePreview-UI"
                            src={
                              t6.t3
                                ? `${process.env.REACT_APP_SERVER_API}/pictures/${watchAll.thankYouString}/${watchAll.referTo}`
                                : t6.i3
                            }
                          />
                        </Grid>
                      )}
                  </Grid>
                )}

                {/* {extra && (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} style={{ padding: '0 8px' }}>
                      <p>
                        Payment Image (please provide image with 1080 × 1920 or similar aspect
                        ratio)
                      </p>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: '-15px' }}>
                      <div className="d-flex align-items-center" style={{ height: '48px' }}>
                        <Grid item xs={3}>
                          <Button fullWidth variant="contained" component="label">
                            IMAGE
                            <input
                              {...register('payment', {
                                onChange: (e) => {
                                  clearErrors('payment');
                                  if (
                                    e.target.files.length > 0 &&
                                    e.target.files[0].size > 5000000
                                  ) {
                                    setError('payment', {
                                      message: 'File size too big, please provide the right size.',
                                    });
                                  } else if (
                                    e.target.files.length > 0 &&
                                    e.target.files[0].name.indexOf(' ') >= 0
                                  ) {
                                    setError('payment', {
                                      message: 'File name can not be contain white space',
                                    });
                                  } else {
                                    if (e.target.files && e.target.files.length > 0) {
                                      const reader = new FileReader();
                                      reader.onload = () => {
                                        if (reader.readyState === 2) {
                                          sett7({ t3: false, i3: reader.result });
                                        }
                                      };
                                      reader.readAsDataURL(e.target.files[0]);
                                    }
                                  }
                                },
                              })}
                              type="file"
                              accept=".jpg,.png,.jpeg"
                              hidden
                            />
                          </Button>
                        </Grid>
                        <Grid className="d-flex align-items-center" item xs={8}>
                          <span className="product-fileName-span">
                            {watchAll.payment && watchAll.payment.length > 0
                              ? watchAll.payment[0].name
                              : watchAll.paymentString}
                          </span>
                        </Grid>
                        {(watchAll.paymentString ||
                          (watchAll.payment && watchAll.payment.length > 0)) && (
                          <IconButton onClick={() => handleRemove('payment', 'paymentString')}>
                            <DeleteForeverOutlined />
                          </IconButton>
                        )}
                      </div>
                      {errors.payment && (
                        <p style={{ color: 'red', marginLeft: '8px' }}>{errors.payment.message}</p>
                      )}
                    </Grid>

                    {watchAll.paymentString &&
                      watchAll.paymentString != 'Image not found, please provide a new image.' && (
                        <Grid container item xs={12} style={{ marginTop: '5px' }}>
                          <img
                            className="imagePreview-UI"
                            src={
                              t7.t3
                                ? `${process.env.REACT_APP_SERVER_API}/pictures/${watchAll.paymentString}/${watchAll.referTo}`
                                : t7.i3
                            }
                          />
                        </Grid>
                      )}
                  </Grid>
                )} */}

                <Grid item xs={advanced ? 7 : 12}>
                  <Controller
                    render={({ field }) => (
                      <Select fullWidth defaultValue="en" {...field} variant="outlined">
                        {enArray.map((e) => {
                          return <MenuItem value={e.code}>{e.name}</MenuItem>;
                        })}
                      </Select>
                    )}
                    name="select"
                    control={control}
                  />
                </Grid>
                {advanced && (
                  <Grid
                    item
                    xs={5}
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      fontSize: '16px',
                    }}
                  >
                    <Controller
                      name="autoTrans"
                      control={control}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                    <span>Auto Translate</span>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <p>Privacy (Term&Condition)</p>
                  {Privacy.length > 0 &&
                    Privacy.map((inputField, i) => {
                      return (
                        <div key={inputField.id}>
                          <div style={{ display: 'inline-block' }}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              className="mb-3"
                              fullWidth
                              label={'Header ' + (i + 1)}
                              variant="outlined"
                              multiline
                              value={Privacy[i].header[watchAll.select]}
                              // defaultValue={Privacy[i].header[watchAll.select]}
                              onChange={(event) => onPrivacyHeader(inputField.id, event)}
                              maxRows={5}
                            ></TextField>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              className=""
                              fullWidth
                              label={
                                !extra
                                  ? 'Content ' + (i + 1) + ` (use ";" to insert a new line)`
                                  : ''
                              }
                              variant="outlined"
                              value={Privacy[i].content[watchAll.select]}
                              // defaultValue={Privacy[i].content[watchAll.select]}
                              multiline
                              onChange={(event) => onPrivacyContent(inputField.id, event)}
                              maxRows={5}
                            ></TextField>
                            <IconButton
                              disabled={Privacy.length === 1}
                              onClick={() => handleRemovePrivacy(inputField.id)}
                            >
                              <RemoveIcon />
                            </IconButton>
                            <IconButton onClick={handleAddPrivacy}>
                              <AddIcon />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                </Grid>

                {!extra && (
                  <Grid item xs={12}>
                    <p>FAQ</p>
                    {FAQ.length > 0 &&
                      FAQ.map((inputField, i) => {
                        return (
                          <div key={inputField.id}>
                            <div style={{ display: 'inline-block' }}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                className="mb-3"
                                fullWidth
                                label={'Header ' + (i + 1)}
                                multiline
                                value={FAQ[i].header[watchAll.select]}
                                // defaultValue={FAQ[i].header}
                                maxRows={5}
                                onChange={(event) => onFAQHeader(inputField.id, event)}
                                variant="outlined"
                              ></TextField>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                label={'Content ' + (i + 1) + ` (use ";" to insert a new line)`}
                                variant="outlined"
                                multiline
                                value={FAQ[i].content[watchAll.select]}
                                // defaultValue={FAQ[i].content}
                                maxRows={5}
                                onChange={(event) => onFAQContent(inputField.id, event)}
                              ></TextField>
                              <IconButton
                                disabled={FAQ.length === 1}
                                onClick={() => handleRemoveFAQ(inputField.id)}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <IconButton onClick={handleAddFAQ}>
                                <AddIcon />
                              </IconButton>
                            </div>
                          </div>
                        );
                      })}
                  </Grid>
                )}

                {/* {extra && (
                  <Grid item xs={12}>
                    <p>Terms and Conditions</p>
                    {TERM.length > 0 &&
                      TERM.map((inputField, i) => {
                        return (
                          <div key={inputField.id}>
                            <div style={{ display: 'inline-block' }}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                className="mb-3"
                                fullWidth
                                label={'Header ' + (i + 1)}
                                multiline
                                value={TERM[i].header[watchAll.select]}
                                // defaultValue={FAQ[i].header}
                                maxRows={3}
                                onChange={(event) => onTERMHeader(inputField.id, event)}
                                variant="outlined"
                              ></TextField>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                label={'Content ' + (i + 1) + ` (use ";" to insert a new line)`}
                                variant="outlined"
                                multiline
                                value={TERM[i].content[watchAll.select]}
                                // defaultValue={FAQ[i].content}
                                maxRows={3}
                                onChange={(event) => onTERMContent(inputField.id, event)}
                              ></TextField>
                              <IconButton
                                disabled={TERM.length === 1}
                                onClick={() => handleRemoveTERM(inputField.id)}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <IconButton onClick={handleAddTERM}>
                                <AddIcon />
                              </IconButton>
                            </div>
                          </div>
                        );
                      })}
                  </Grid>
                )} */}

                <Grid item xs={4}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={Object.keys(errors).length !== 0}
                  >
                    Submit
                  </Button>
                </Grid>

                <Grid item xs={4}></Grid>

                <Grid item xs={4}>
                  {!theme1 && (
                    <Button
                      fullWidth
                      onClick={handleReset}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Reset
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: '#7A4F01',
  backgroundColor: '#FFF7CD',
}));
// ----------------------------------------------------------------------

export default function AveUnitTrans(props) {
  const { trans, unit } = props;
  const ave = unit / trans;
  return (
    <RootStyle style={{ borderRadius: '16px', fontFamily: 'Public Sans, sans-serif' }}>
      <Typography variant="h3">{fShortenNumber(ave)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Units/Transaction
      </Typography>
    </RootStyle>
  );
}

import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Modal } from 'react-bootstrap';

import request from '../../services/Request';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 200,
    maxWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function StopSale(props) {
  const classes = useStyles();

  const stopSale = `/machines/stopSale`;
  const { rowData, showAdmin, ...rest } = props;

  const [date, setDate] = useState({});

  useEffect(() => {
    console.log('rowData', rowData);
    if (rowData && rowData[0].stopSale && props.show) {
      setDate(rowData[0].stopSale);
    }
  }, [props.show]);

  const clearDate = () => {
    setDate({});
  };

  const onSubmit = async (data) => {
    if (showAdmin) {
      request({
        method: 'post',
        url: stopSale,
        data: {
          date: date,
          id: rowData.map((e) => e._id),
        },
      })
        .then((res) => {
          Swal.fire({ text: `Update successfully`, icon: 'success' }).then(() => {
            props.reflesh ? props.setReflesh(false) : props.setReflesh(true);
            props.onHide();
          });
        })
        .catch((err) => {
          Swal.fire({ text: `Update failed`, icon: 'error' });
        });
    } else {
      Swal.fire({
        text: `You have no permission to change the receipt template`,
        icon: 'error',
      });
    }
  };

  const handleDate = (name, e) => {
    const utcDate = new Date(e.getTime() - e.getTimezoneOffset() * 60000);
    // console.log('e', utcDate.toUTCString());
    // console.log('t2', e.toUTCString());
    setDate({ ...date, [name]: utcDate });
  };

  const utcBackToDate = (e) => {
    const nowDate = new Date(new Date(e).getTime() + new Date().getTimezoneOffset() * 60000);
    return nowDate;
  };

  return (
    <Modal className="modal-w" {...rest} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Scheduled Sale</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="d-flex justify-content-around align-items-center mb-2">
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.startDate1 ? utcBackToDate(date.startDate1) : null}
                  onChange={(e) => handleDate('startDate1', e)}
                  label="Select stop sale date 1"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.endDate1 ? utcBackToDate(date.endDate1) : null}
                  onChange={(e) => handleDate('endDate1', e)}
                  label="Select resume sale date 1"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
              </div>
              <div className="d-flex justify-content-around mb-2">
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.startDate2 ? utcBackToDate(date.startDate2) : null}
                  onChange={(e) => handleDate('startDate2', e)}
                  label="Select stop sale date 2"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.endDate2 ? utcBackToDate(date.endDate2) : null}
                  onChange={(e) => handleDate('endDate2', e)}
                  label="Select resume sale date 2"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
              </div>
              <div className="d-flex justify-content-around mb-2">
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.startDate3 ? utcBackToDate(date.startDate3) : null}
                  onChange={(e) => handleDate('startDate3', e)}
                  label="Select stop sale date 3"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.endDate3 ? utcBackToDate(date.endDate3) : null}
                  onChange={(e) => handleDate('endDate3', e)}
                  label="Select resume sale date 3"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
              </div>
              <div className="d-flex justify-content-around mb-2">
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.startDate4 ? utcBackToDate(date.startDate4) : null}
                  onChange={(e) => handleDate('startDate4', e)}
                  label="Select stop sale date 4"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.endDate4 ? utcBackToDate(date.endDate4) : null}
                  onChange={(e) => handleDate('endDate4', e)}
                  label="Select resume sale date 4"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
              </div>
              <div className="d-flex justify-content-around mb-2">
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.startDate5 ? utcBackToDate(date.startDate5) : null}
                  onChange={(e) => handleDate('startDate5', e)}
                  label="Select stop sale date 5"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.endDate5 ? utcBackToDate(date.endDate5) : null}
                  onChange={(e) => handleDate('endDate5', e)}
                  label="Select resume sale date 5"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
              </div>
              <div className="d-flex justify-content-around mb-2">
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.startDate6 ? utcBackToDate(date.startDate6) : null}
                  onChange={(e) => handleDate('startDate6', e)}
                  label="Select stop sale date 6"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
                <KeyboardDateTimePicker
                  className={classes.formControl}
                  value={date.endDate6 ? utcBackToDate(date.endDate6) : null}
                  onChange={(e) => handleDate('endDate6', e)}
                  label="Select resume sale date 6"
                  format="MM/dd/yyyy hh:mm aaa"
                  margin="normal"
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <Container component="main">
            <CssBaseline />
          </Container>
          <Button
            fullWidth
            onClick={clearDate}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Clear Date
          </Button>
          <Button
            fullWidth
            onClick={onSubmit}
            // disabled={!date.startDate1 || !date.endDate1}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

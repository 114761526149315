import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import request from '../../../services/Request';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core';
import LoadingIcon from '../../items/buttons/LoadingIcon';
import BackButton from '../../items/buttons/BackButton';
import Swal from 'sweetalert2';
import { userLimits } from '../uitls/userLimits';
import MagexUserModal from './MagexUserModal';
import EditLocationOutlinedIcon from '@material-ui/icons/EditLocationOutlined';
import { MenuItem, Select } from '@mui/material';
import VisibilityIcon from '@material-ui/icons/Visibility';

const getAllEmployeesURL = `/magexuser/viewAllMagexUsers`;
const deleteUserURL = `/magexuser/deleteMagexUsers/`;

const DropDown = ({ value, onChange }) => (
  <Select onChange={onChange} value={value || ''}>
    <MenuItem value="USD">USD</MenuItem>
    <MenuItem value="CAD">CAD</MenuItem>
  </Select>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(-5.6),
    marginRight: theme.spacing(40),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ViewMagexUser(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  const dbaUser = user.dba;
  const classes = useStyles();

  let history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [reflesh, setReflesh] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [select, setSelect] = useState(null);
  const [field, setField] = useState([]);
  const [rowdata, setrowdata] = useState(null);

  const access = user?.role === 'SuperAdmin' || (user?.role === 'MagexAdmin' && user?.fullAccess);

  useEffect(() => {
    let isCancelled = false;

    //getAllEmployees
    async function getAllEmployees() {
      await request({
        method: 'get',
        url: getAllEmployeesURL,
      }).then((res) => {
        if (!isCancelled) {
          // console.log('res.data', res.data);
          setEmployees(res.data);
          setIsLoading(true);
        }
      });
    }

    //getAllEmployees
    async function getFields() {
      await request({
        method: 'get',
        url: `/magex/getAllInvoiceFields`,
      }).then((res) => {
        if (!isCancelled) {
          // console.log('res.data', res.data);
          setField(res.data);
          setIsLoading(true);
        }
      });
    }

    getFields();
    getAllEmployees();

    return () => {
      isCancelled = true;
    };
  }, [reflesh]);

  const deleteUser = async (id, referTo) => {
    await request({
      method: 'delete',
      url: deleteUserURL + id,
    }).then(() => {
      Swal.fire({
        text: `The user has been successfully deleted`,
        icon: 'success',
      });
      reflesh ? setReflesh(false) : setReflesh(true);
    });
  };

  const columns = [
    { title: 'Operator', field: 'operator' },
    { title: 'Username', field: 'name' },
    {
      title: 'Password',
      field: 'pw',
      render: () => {
        return '********';
      },
    },
    {
      title: 'Currency',
      field: 'currency',
      editComponent: (props) => (
        <DropDown
          value={props.value}
          onChange={(e) => {
            console.log(e.target.value);
            props.onChange(e.target.value);
          }}
        />
      ),
    },
    {
      title: 'Tolerance($)',
      field: 'tolerance',
      type: 'numeric',
      validate: (rowData) =>
        rowData.tolerance >= -1 || !rowData.tolerance ? true : 'Tolerance cannot be lower than -1',
    },
    {
      title: 'License Fee($)',
      field: 'license_fee',
      type: 'numeric',
      validate: (rowData) =>
        rowData.license_fee >= 0 || !rowData.tolerance
          ? true
          : 'License Fee cannot be lower than 0',
    },
    {
      title: 'Tax(%)',
      field: 'tax',
      type: 'numeric',
      render: (rowData) => `${rowData.tax}%`,
    },
    {
      title: 'Paid Period(M)',
      field: 'paid_period',
      type: 'numeric',
    },
  ];

  return (
    <div>
      {!isloading && <LoadingIcon />}
      <div style={{ maxWidth: '100%', marginBottom: '100px' }}>
        <MaterialTable
          columns={columns}
          data={employees}
          title={'User List'}
          localization={{ body: { editRow: { deleteText: 'Are you sure to delete this user' } } }}
          actions={[
            {
              icon: EditLocationOutlinedIcon,
              tooltip: 'Edit Info',
              onClick: (event, rowdata) => {
                // console.log('setSelect', rowdata);
                console.log('setSelect', rowdata);
                setSelect(rowdata);
                setshow(true);
              },
            },
            // {
            //   icon: VisibilityIcon,
            //   tooltip: 'Preview Ivoice',
            //   onClick: (event, rowdata) => {
            //     // console.log('setSelect', rowdata);
            //     console.log('setSelect', rowdata);
            //     setSelect(rowdata);
            //     setshow(true);
            //   },
            // },
          ]}
          editable={
            !access
              ? {}
              : {
                  onRowDelete: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        deleteUser(newData._id);
                        resolve();
                      }, 1000);
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        request({
                          method: 'post',
                          url: `/magexuser/editMagexUser/` + newData?._id,
                          data: {
                            name: newData.name,
                            pw: newData.pw,
                            operator: newData.operator,
                            tolerance: newData.tolerance,
                            license_fee: newData.license_fee,
                            currency: newData.currency,
                            paid_period: newData.paid_period,
                            tax: newData.tax,
                          },
                        }).then(() => {
                          Swal.fire({
                            title: 'Success',
                            text: 'User has been successfully updated',
                            timer: 2000,
                          });
                          setReflesh(!reflesh);
                        });

                        resolve();
                      }, 1000);
                    }),
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        // console.log('newData', newData);
                        request({
                          method: 'post',
                          url: `/magexuser/create`,
                          data: {
                            name: newData.name,
                            pw: newData.pw,
                            operator: newData.operator,
                            tolerance: newData.tolerance,
                            license_fee: newData.license_fee,
                            currency: newData.currency,
                            paid_period: newData.paid_period,
                            tax: newData.tax,
                          },
                        }).then(() => {
                          Swal.fire({
                            title: 'Success',
                            text: 'User has been successfully created',
                            timer: 2000,
                          });
                          setReflesh(!reflesh);
                        });
                        resolve();
                      }, 1000);
                    }),
                }
          }
          options={{
            actionsColumnIndex: -1,
            rowStyle: (rowData) => {
              if (rowData.dba) {
                return {
                  backgroundColor: 'lightblue',
                };
              }
            },
            pageSize: 50,
            pageSizeOptions: [50, 100, 150, 200],
          }}
        />
        <BackButton />
        <MagexUserModal
          show={show}
          field={field}
          onHide={() => setshow(false)}
          setshow={setshow}
          data={select}
          reflesh={+reflesh}
          setReflesh={setReflesh}
        />
        <div className={classes.paper}></div>
      </div>
    </div>
  );
}

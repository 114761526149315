import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { InputAdornment } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Swal from 'sweetalert2';

import { Modal, Row, Col } from 'react-bootstrap';

import request from '../../services/Request';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
  formControl: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 200,
    maxWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SSmodal(props) {
  const classes = useStyles();

  const getSSURL = `/screenSaver/`;
  const getAudioURL = `/getAudioByEmail/`;
  const updatSSURL = `/machines/updateSS`;
  const { rowData, showAdmin, user, ...rest } = props;

  const [ss, setSS] = useState([]);
  const [audio, setAudio] = useState([]);
  const [ssid, setSsid] = useState('None');
  const [auid, setAuid] = useState('None');
  const [idleTimer, setidleTimer] = useState('60');
  const [ssErr, setssErr] = useState('');
  const disableAudio = user?.feature?.disableAudio;

  useEffect(() => {
    setidleTimer('60');
    let isCancelled = false;
    const belongTo =
      rowData && rowData.length > 0 ? (rowData[0].belongTo ? rowData[0].belongTo : '') : '';
    if (belongTo != '') {
      setSsid(
        rowData && rowData.length
          ? rowData[0].screenSaver
            ? rowData[0].screenSaver
            : 'None'
          : 'None'
      );

      setidleTimer(
        rowData && rowData.length
          ? rowData[0].time_to_idle
            ? rowData[0].time_to_idle
            : '60'
          : '60'
      );
      setAuid(rowData && rowData.length ? (rowData[0].audio ? rowData[0].audio : 'None') : 'None');
      setssErr('');
      //getAllEmployees
      async function getSS() {
        request({
          method: 'get',
          url: getSSURL + belongTo,
        }).then((res) => {
          if (!isCancelled) {
            setSS(res.data);
          }
        });
      }

      async function getAudio() {
        request({
          method: 'get',
          url: getAudioURL + belongTo,
        }).then((res) => {
          if (!isCancelled) {
            setAudio(res.data);
          }
        });
      }

      getSS();
      getAudio();

      return () => {
        isCancelled = true;
      };
    }
  }, [rowData]);

  const onSubmit = async (data) => {
    let finalAU = auid;
    let finalID = ssid;
    if (ssid === 'None') finalID = '';
    if (auid === 'None') finalAU = '';
    if (showAdmin) {
      request({
        method: 'patch',
        url: updatSSURL,
        data: {
          audio: finalAU,
          id: finalID,
          time_to_idle: idleTimer,
          machineID: rowData.map((e) => e._id),
        },
      })
        .then((res) => {
          setssErr('');
          Swal.fire({
            text: `The screen saver has been selected`,
            icon: 'success',
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              setssErr(err.response.data.message);
            }
          }
        });
    } else {
      Swal.fire({
        text: `You have no permission to change the screen saver`,
        icon: 'error',
      });
    }
  };

  const handleChange = async (event) => {
    setssErr('');
    setSsid(event.target.value);
  };

  const handleAU = async (event) => {
    setAuid(event.target.value);
  };

  const handleIdle = async (event) => {
    setidleTimer(event.target.value);
  };

  return (
    <Modal className="modal-w" {...rest} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Select Your Screen Saver/Audio</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <Container>
          <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
            <Col xs={6}>
              <TextField
                fullWidth
                label="time to idle(s)"
                variant="outlined"
                value={idleTimer}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">s</InputAdornment>,
                }}
                inputProps={{
                  min: '30',
                }}
                onChange={handleIdle}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FormControl className={classes.formControl}>
                <InputLabel>Select Screen Saver</InputLabel>
                <Select fullWidth value={ssid} onChange={handleChange}>
                  <MenuItem key={'0'} value={'None'}>
                    None
                  </MenuItem>
                  {ss.map((m) => {
                    if (m.referPlano == null)
                      return (
                        <MenuItem key={m._id} value={m._id}>
                          {m.name}
                        </MenuItem>
                      );
                  })}
                </Select>
              </FormControl>
              {ssErr != '' && <p style={{ color: 'red' }}>{ssErr}</p>}
            </Col>
            <Col xs={5}>
              <Button
                style={{ marginTop: '12px' }}
                onClick={() => {
                  // window.open(`/screenSaverPreview/${ssid}`, 'width=1080,height=1920');
                  window.open(`/screenSaverPreview/${ssid}`, 'mywin', 'width=1080,height=1920');
                }}
                fullWidth
                disabled={ssid === '' || ssid === 'None'}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Preview
              </Button>
            </Col>
          </Row>
          {!disableAudio && (
            <Row>
              <Col xs={7}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Select Audio</InputLabel>
                  <Select fullWidth value={auid} onChange={handleAU}>
                    <MenuItem key={'0'} value={'None'}>
                      None
                    </MenuItem>
                    {audio.map((m) => {
                      if (m.referPlano == null)
                        return (
                          <MenuItem key={m._id} value={m._id}>
                            {m.name}
                          </MenuItem>
                        );
                    })}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          )}

          <Container component="main">
            <CssBaseline />
          </Container>
          <Button
            fullWidth
            onClick={onSubmit}
            disabled={ssid == ''}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Select
          </Button>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
